<template>
  <modal
    v-model:show="show"
    modal-classes="modal-white"
    header-classes="align-items-center"
    body-classes="pt-0"
    size="lg"
    :show-close="showClose"
  >
    <template v-slot:header>
      <h6 class="modal-title text-capitalize text-default">
        {{ i18n.$t("location.add.title") }}
      </h6>
    </template>
    <div class="">
      <p class="text-default mb-3">
        {{ i18n.$t("location.add.subTitle") }}
      </p>
      <div>
        <Form
          ref="form"
          class="pt-2"
          @submit="addLocationClick"
          :validation-schema="schema"
          v-slot="{ errors }"
        >
          <div class="row">
            <div class="col-md-12">
              <span>{{ errors[0] }}</span>
              <div class="form-group">
                <div
                  class="input-group"
                  :class="{ 'has-danger-border-red': errors.location_name }"
                >
                  <div class="input-group-prepend">
                    <span class="input-group-text"
                      ><i class="ni ni-single-copy-04"></i
                    ></span>
                  </div>

                  <Field
                    class="form-control"
                    name="location_name"
                    type="text"
                    id="location_name"
                    :placeholder="i18n.$t('location.add.locationName')"
                    maxlength="30"
                    v-model="addLocationModel.location_name"
                  ></Field>

                  <!-- <input
                    class="form-control"
                    name="locationName"
                    id="locationName"
                    type="text"
                    maxlength="30"
                    v-model="addLocationModel.location_name"
                    required
                    :placeholder="i18n.$t('location.add.locationName')"
                  /> -->
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-8">
              <div class="form-group">
                <div
                  class="input-group"
                  :class="{
                    'has-danger-border-red': errors.location_address_street,
                  }"
                >
                  <div class="input-group-prepend">
                    <span class="input-group-text"
                      ><i class="ni ni-pin-3"></i
                    ></span>
                  </div>
                  <Field
                    class="form-control"
                    name="location_address_street"
                    type="text"
                    maxlength="50"
                    id="location_address_street"
                    :placeholder="i18n.$t('location.add.street')"
                    v-model="addLocationModel.location_address_street"
                  ></Field>
                  <!-- <input
                    class="form-control"
                    name="street"
                    id="street"
                    type="text"
                    maxlength="50"
                    required
                    v-model="addLocationModel.location_address_street"
                    :placeholder="i18n.$t('location.add.street')"
                  /> -->
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <div
                  class="input-group"
                  :class="{
                    'has-danger-border-red': errors.location_address_houseno,
                  }"
                >
                  <div class="input-group-prepend">
                    <span class="input-group-text"
                      ><i class="ni ni-pin-3"></i
                    ></span>
                  </div>
                  <Field
                    class="form-control"
                    name="location_address_houseno"
                    type="text"
                    maxlength="5"
                    id="location_address_houseno"
                    :placeholder="i18n.$t('location.add.houseNumber')"
                    v-model="addLocationModel.location_address_houseno"
                  ></Field>
                  <!-- <input
                    class="form-control"
                    name="location_address_houseno"
                    id="location_address_houseno"
                    maxlength="5"
                    type="text"
                    required
                    v-model="addLocationModel.location_address_houseno"
                    :placeholder="i18n.$t('location.add.houseNumber')"
                  /> -->
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <div
                  class="input-group"
                  :class="{
                    'has-danger-border-red': errors.location_address_postcode,
                  }"
                >
                  <div class="input-group-prepend">
                    <span class="input-group-text"
                      ><i class="ni ni-pin-3"></i
                    ></span>
                  </div>
                  <Field
                    class="form-control"
                    name="location_address_postcode"
                    type="text"
                    maxlength="5"
                    @keypress="isNumber($event)"
                    id="location_address_postcode"
                    :placeholder="i18n.$t('location.add.postalCode')"
                    v-model="addLocationModel.location_address_postcode"
                  ></Field>
                  <!-- <input
                    class="form-control"
                    name="postalcode"
                    id="postalcode"
                    type="number"
                    pattern="[0-9]{4}"
                    title="Four digit zip code"
                    required
                    v-model="addLocationModel.location_address_postcode"
                    :placeholder="i18n.$t('location.add.postalCode')"
                  /> -->
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <div
                  class="input-group"
                  :class="{
                    'has-danger-border-red': errors.location_address_city,
                  }"
                >
                  <div class="input-group-prepend">
                    <span class="input-group-text"
                      ><i class="ni ni-pin-3"></i
                    ></span>
                  </div>
                  <Field
                    class="form-control"
                    name="location_address_city"
                    type="text"
                    maxlength="30"
                    id="location_address_city"
                    :placeholder="i18n.$t('location.add.city')"
                    v-model="addLocationModel.location_address_city"
                  ></Field>
                  <!-- <input
                    class="form-control"
                    name="city"
                    id="city"
                    required
                    maxlength="30"
                    type="text"
                    v-model="addLocationModel.location_address_city"
                    :placeholder="i18n.$t('location.add.city')"
                  /> -->
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex align-items-ceter justify-content-between pt-4">
            <base-button
              type="warning"
              native-type="submit"
              class="text-white p-2 px-3"
            >
              <div class="d-flex align-items-center">
                <spinner v-show="loader" />
                <span>{{ i18n.$t("location.add.addButton") }}</span>
              </div>
            </base-button>
            <base-button
              type=""
              class="ml-auto text-default p-2 px-3"
              @click="handleClose"
            >
              {{ i18n.$t("logout.button.cancel") }}
            </base-button>
          </div>
        </Form>
      </div>
    </div>
    <!-- <template v-slot:footer>
      <base-button
        native-type="button"
        type="warning"
        class="text-white p-2 px-3"
        @click="generateInviteLink"
      >
        <div class="d-flex align-items-center">
          <spinner v-show="loader" color="text-black" />
          <span>{{ i18n.$t("staff.modal.invitationLink.createLink") }}</span>
        </div>
      </base-button>
      <base-button
        type=""
        class="ml-auto text-default p-2 px-3"
        @click="handleClose"
      >
        {{ i18n.$t("logout.button.cancel") }}
      </base-button>
    </template> -->
  </modal>
</template>

<script>
import Modal from "@/components/Modal";
import { useI18n } from "@/util/i18n";
import { ADDNEWLOCATIONAPI } from "@/services/private/Location";
import { onMounted, reactive, ref, watch } from "vue";
import { Field, Form } from "vee-validate";
import * as Yup from "yup";

export default {
  name: "add-location-modal",
  props: {
    showModal: Boolean,
    setSelectedRole: String,
  },
  components: {
    Modal,
    Field,
    Form,
  },
  data() {
    return {
      loader: false,
      invite_link: "",

      addLocationModel: {
        location_name: "",
        location_address_street: "",
        location_address_postcode: "",
        location_address_city: "",
        location_image_path: "",
        location_text: "",
        location_address_houseno: "",
        location_address_country: "",
      },
      schema: Yup.object().shape({
        location_name: Yup.string().required(),
        location_address_street: Yup.string().required(),
        location_address_postcode: Yup.string().required(),
        location_address_city: Yup.string().required(),
        location_address_houseno: Yup.string().required(),
      }),
    };
  },
  computed: {
    show() {
      return this.showModal;
    },
    showClose() {
      return true;
    },
    company_id() {
      return this.$store.state.user.company_id;
    },
  },
  methods: {
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    handleClose() {
      this.$emit("close");
    },
    async addLocationClick() {
      this.loader = true;
      const {
        location_name,
        location_address_street,
        location_address_postcode,
        location_address_city,
        location_image_path,
        location_text,
        location_address_houseno,
        location_address_country,
      } = this.addLocationModel;

      const data = {
        location_name,
        location_address_street,
        location_address_postcode,
        location_address_city,
        location_image_path,
        location_text,
        location_address_houseno,
        location_address_country,
      };

      const result = await ADDNEWLOCATIONAPI(data);
      this.loader = false;
      if (result && result.status === 200) {
        this.$emit("update", result);
      }
    },
  },
  watch: {
    showModal(new_value, old_value) {
      if (new_value != old_value) {
        this.$nextTick(() => {
          this.$refs.form.resetForm();
          this.addLocationModel = {
            location_name: "",
            location_address_street: "",
            location_address_postcode: "",
            location_address_city: "",
            location_image_path: "",
            location_text: "",
            location_address_houseno: "",
            location_address_country: "",
          };
        });
      }
    },
  },
  setup(props, { emit }) {
    const i18n = useI18n();
    // let schema = Yup.object().shape({
    //   location_name: Yup.string().required(),
    //   location_address_street: Yup.string().required(),
    //   location_address_postcode: Yup.string().required(),
    //   location_address_city: Yup.string().required(),
    //   location_address_houseno: Yup.string().required(),
    // });
    // const initialState = {
    //   location_name: "",
    //   location_address_street: "",
    //   location_address_postcode: "",
    //   location_address_city: "",
    //   location_image_path: "",
    //   location_text: "",
    //   location_address_houseno: "",
    //   location_address_country: "",
    // };
    // let addLocationModel = reactive(initialState);

    // watch(() => {
    //   if (props.showModal) {
    //     resetData();
    //   }
    // });

    // function resetData() {
    //   addLocationModel.location_name = "";
    //   addLocationModel.location_address_street = "";
    //   addLocationModel.location_address_postcode = "";
    //   addLocationModel.location_address_city = "";
    //   addLocationModel.location_image_path = "";
    //   addLocationModel.location_text = "";
    //   addLocationModel.location_address_houseno = "";
    //   addLocationModel.location_address_country = "";
    // }

    // async function addLocationClick() {
    //   const {
    //     location_name,
    //     location_address_street,
    //     location_address_postcode,
    //     location_address_city,
    //     location_image_path,
    //     location_text,
    //     location_address_houseno,
    //     location_address_country,
    //   } = addLocationModel;

    //   const data = {
    //     location_name,
    //     location_address_street,
    //     location_address_postcode,
    //     location_address_city,
    //     location_image_path,
    //     location_text,
    //     location_address_houseno,
    //     location_address_country,
    //   };

    //   const result = await ADDNEWLOCATIONAPI(data);
    //   if (result && result.status === 200) {
    //     emit("update");
    //   }
    // }

    // function onSubmit(data) {
    //   //   const {
    //   //     location_name,
    //   //     location_address_street,
    //   //     location_address_postcode,
    //   //     location_address_city,
    //   //   } = addLocationModel;
    //   console.log("On Submit");
    //   //console.log(location_name);
    // }

    // function getRoleI18n(type) {
    //   const roleName = type && type.toLowerCase();
    //   return i18n.$t(`role.${roleName}`);
    // }

    return {
      i18n,
      //   getRoleI18n,
      //   addLocationModel,
      //   addLocationClick,
      //   resetData,
      //   schema,
      //   onSubmit,
    };
  },
};
</script>

<style scoped>
.modal .modal-huge {
  max-width: 96%;
  width: 585px !important;
}
</style>
