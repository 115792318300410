<template>
  <div class="card">
    <el-table
      class="table-responsive table-flush rounded"
      header-row-class-name="thead-light"
      style="width: 100%"
      :data="networks"
      :default-sort="{ prop: 'name', order: 'ascending' }"
      @row-click="goToNetworkPage"
    >
      <el-table-column
        :label="i18n.$t('network.Table.Favorite')"
        width="140"
        prop="star"
        sortable
      >
        <template v-slot="{ row }">
          <div>
            <span
              v-if="row.is_favourite"
              class="cursor-pointer"
              @click="favourite_click(row, false)"
              ><i class="fas fa-star fa-2x"></i
            ></span>
            <span
              v-if="!row.is_favourite"
              class="cursor-pointer"
              @click="favourite_click(row, true)"
              ><i class="far fa-star fa-2x"></i
            ></span>
          </div>
        </template>
      </el-table-column>
      <el-table-column width="90" prop="icon">
        <template v-slot="{ row }">
          <div class="media justify-content-center align-items-center">
            <span class="avatar avatar-sm bg-success rounded-circle">
              {{ getInitialsName(row.company_name) }}
            </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :label="i18n.$t('network.Table.Name')"
        width="500"
        prop="name"
        sortable
      >
        <template v-slot="{ row }">
          <div class="media align-items-center">
            <span
              class="
                font-weight-600
                name
                mb-0
                text-sm text-capitalize
                cursor-pointer
              "
            >
              {{ row.company_name }}
            </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :label="i18n.$t('network.Table.Status')"
        width="150"
        prop="status"
        sortable
      >
        <template v-slot="{ row }">
          <!-- <badge
            style="cursor: pointer"
            @click="redirectToCompany(row)"
            class="badge-dot mr-4"
            :type="`${row.is_active ? 'success' : 'danger'}`"
          >
            <i :class="`bg-${row.is_active ? 'success' : 'danger'}`"></i> -->
          <span class="font-weight-600 name mb-0 text-sm">
            {{ getStatusI18n(row.is_active) }}
          </span>
          <!-- </badge> -->
        </template>
      </el-table-column>
      <el-table-column prop="actions" class-name="text-right">
        <template v-slot="{ row }">
          <el-dropdown
            v-if="row.is_active && isAccessible(permission.network_list_action)"
            class="dropdown"
            trigger="click"
            placement="bottom-start"
          >
            <span class="p-2 btn-icon-only text-light">
              <i class="fas fa-ellipsis-v mt-2"></i>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <li
                  class="dropdown-item"
                  @click="disconnectCompany(row.partner_company_id)"
                >
                  {{ i18n.$t("common.remove") }}
                </li>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>

    <!-- Remove user modal -->
    <disconnect-company-modal
      :showModal="models.removeCompnayModalVisible"
      :loader="loader"
      @close="closeRemoveCompanyModal"
      @confirm="showConfirmDisconnectModal"
    ></disconnect-company-modal>
    <confirm-disconnect-modal
      :showModal="models.confirmDisconnectModalVisible"
      :loader="loader"
      @close="closeConfirmDisconnectModal"
      @remove="sendRemoveCompanyAPI"
    ></confirm-disconnect-modal>
  </div>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
} from "element-plus";
import { useToast } from "vue-toastification";
import initialsName from "@/util/initialsName";
import { useI18n } from "@/util/i18n";
import {
  GETALLNETWORKAPI,
  DISCONNECTUSERAPI,
  UPDATEFAVORITEAPI,
} from "@/services/private/Network";
import DisconnectCompanyModal from "./DisconnectCompanyModal.vue";
import ConfirmDisconnectModal from "./ConfirmDisconnectModal.vue";
import { useRouter, useRoute } from "vue-router";
import { has_permission } from "@/services/private/Role_Service";
import { permission_list } from "@/services/_helper/user_permission";
// const route = useRoute();
// const router = useRouter();

export default {
  name: "light-table",
  components: {
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
    DisconnectCompanyModal,
    ConfirmDisconnectModal,
  },
  props: {
    networks: Array,
  },
  data() {
    return {
      loader: false,
      router: useRouter(),
      i18n: useI18n(),
      permission: permission_list,
      models: {
        removeCompnayModalVisible: false,
        removeCompanyId: "",
        confirmDisconnectModalVisible: false,
      },
      currentPage: 1,
    };
  },
  methods: {
    isAccessible(permission) {
      return has_permission(permission.value);
    },
    redirectToCompany(row) {
      if (row.company_id) {
        this.router.push({
          name: "CompanyProfile",
          params: { company_id: row.partner_company_id },
        });
      }
    },
    async favourite_click(raw, selected) {
      const toast = useToast();

      raw.is_favourite = selected;
      const data = { network_id: raw.network_id, is_favourite: selected };
      const result = await UPDATEFAVORITEAPI(data);
      if (result.status == 200) {
        if (selected) {
          toast.success(this.i18n.$t("network.message.AddToFavorites"));
        } else {
          toast.success(this.i18n.$t("network.message.RemovedFromFavorites"));
        }
      } else {
        toast.error(this.i18n.$t("message.wrong"));
      }
    },
    getInitialsName(company_name) {
      return initialsName(company_name, 1);
    },
    // async getAllNetwork() {
    //   const result = await GETALLNETWORKAPI();
    //   if (result && result.status === 200) {
    //     this.networks = result.data.data;
    //   }
    // },
    //Remove Company from network
    disconnectCompany(partner_company_id) {
      this.models.removeCompanyId = partner_company_id;
      this.models.removeCompnayModalVisible = true;
    },
    closeRemoveCompanyModal() {
      this.models.removeCompanyId = "";
      this.models.removeCompnayModalVisible = false;
    },
    showConfirmDisconnectModal() {
      this.models.removeCompnayModalVisible = false;
      this.models.confirmDisconnectModalVisible = true;
    },
    closeConfirmDisconnectModal() {
      this.models.confirmDisconnectModalVisible = false;
      this.closeRemoveCompanyModal();
    },
    async sendRemoveCompanyAPI() {
      const toast = useToast();
      //Call API
      const partner_company_id = this.models.removeCompanyId;
      const data = { partner_company_id };
      const result = await DISCONNECTUSERAPI(partner_company_id);
      if (result && result.status === 200) {
        this.closeConfirmDisconnectModal();
        this.$emit("update");
        // await this.getAllNetwork();
        toast.success(
          "Das Unternehmen wurde erfolgreich vom Netzwerk getrennt"
        );
      } else {
        toast.error(
          "Das Unternehmen konnte nicht aus dem Netzwerk entfernt werden"
        );
      }
    },
    goToNetworkPage(row, column) {
      if (column && column.property !== "actions" && row.company_id) {
        this.$router.push({
          name: "CompanyProfile",
          params: { company_id: row.partner_company_id },
        });
      }
    },
  },
  setup() {
    const i18n = useI18n();
    function getStatusI18n(status) {
      const findStatus = status === true ? "connected" : "disconnected";
      return i18n.$t(`common.${findStatus}`);
    }
    return { i18n, getStatusI18n };
  },
  mounted() {
    //this.getAllNetwork();
  },
};
</script>
