<template>
  <card>
    <Form onsubmit="return false;">
      <div class="row align-items-center">
        <div class="col-12">
          <h3 class="mb-0">
            {{ i18n.$t("editProfile.profile") }}
          </h3>
        </div>
      </div>
      <hr class="my-3" />
      <h6 class="heading-small text-muted mb-4">
        {{ i18n.$t("editProfile.personalInformation") }}
      </h6>
      <div class="pl-lg-4">
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label>{{ i18n.$t("registration.form.input.firstName") }}</label>
              <Field
                class="form-control"
                name="first_name"
                type="text"
                :placeholder="i18n.$t('registration.form.input.firstName')"
                v-model="user.first_name"
                :disabled="!showEditBtn"
              >
              </Field>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label>{{ i18n.$t("registration.form.input.lastName") }}</label>
              <Field
                class="form-control"
                name="last_name"
                type="text"
                :placeholder="i18n.$t('registration.form.input.lastName')"
                v-model="user.last_name"
                :disabled="!showEditBtn"
              >
              </Field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label>{{ i18n.$t("registration.form.input.email") }}</label>
              <Field
                class="form-control"
                name="email"
                type="email"
                :placeholder="i18n.$t('registration.form.input.email')"
                v-model="user.email"
                :disabled="!showEditBtn"
              >
              </Field>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label>{{ i18n.$t("editProfile.form.telePhoneNumber") }}</label>
              <Field
                class="form-control"
                name="mobile_no"
                type="text"
                :placeholder="i18n.$t('editProfile.form.telePhoneNumber')"
                v-model="user.mobile_no"
                :disabled="!showEditBtn"
              >
              </Field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label>{{ i18n.$t("editProfile.form.birthDay") }}</label>
              <flat-picker
                :config="config"
                name="date_of_birth"
                class="form-control datepicker"
                v-model="user.date_of_birth"
                :disabled="!showEditBtn"
                id="date_of_birth"
              >
              </flat-picker>
            </div>
          </div>
          <div class="col-lg-6">
            <label for="gender">
              {{ i18n.$t("editProfile.form.gender") }}
            </label>
            <select
              class="form-control"
              :disabled="!showEditBtn"
              v-model="user.gender"
              id="gender"
            >
              <option hidden disabled value="">
                {{ i18n.$t("editProfile.gender.header") }}
              </option>
              <option value="M">{{ i18n.$t("editProfile.gender.M") }}</option>
              <option value="F">{{ i18n.$t("editProfile.gender.F") }}</option>
              <option value="O">{{ i18n.$t("editProfile.gender.O") }}</option>
            </select>
          </div>
        </div>
      </div>
      <hr class="my-4" />

      <!-- Address -->
      <h6 class="heading-small text-muted mb-4">
        {{ i18n.$t("editProfile.contact") }}
      </h6>

      <div class="pl-lg-4">
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label>{{ i18n.$t("editProfile.form.street") }}</label>
              <Field
                class="form-control"
                name="address_street"
                type="text"
                :placeholder="i18n.$t('editProfile.form.street')"
                v-model="user.address_street"
                :disabled="!showEditBtn"
              >
              </Field>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label>{{ i18n.$t("editProfile.form.house") }}</label>
              <Field
                class="form-control"
                name="address_houseno"
                type="text"
                :placeholder="i18n.$t('editProfile.form.house')"
                v-model="user.address_houseno"
                :disabled="!showEditBtn"
              >
              </Field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <div class="form-group">
              <label>{{ i18n.$t("editProfile.form.postCode") }}</label>
              <Field
                class="form-control"
                name="address_postcode"
                type="text"
                :placeholder="i18n.$t('editProfile.form.postCode')"
                v-model="user.address_postcode"
                :disabled="!showEditBtn"
              >
              </Field>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="form-group">
              <label>{{ i18n.$t("editProfile.form.city") }}</label>
              <Field
                class="form-control"
                name="address_city"
                type="text"
                :placeholder="i18n.$t('editProfile.form.city')"
                v-model="user.address_city"
                :disabled="!showEditBtn"
              >
              </Field>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="form-group">
              <label>{{ i18n.$t("editProfile.form.country") }}</label>
              <Field
                class="form-control"
                name="address_country"
                type="text"
                :placeholder="i18n.$t('editProfile.form.country')"
                v-model="user.address_country"
                :disabled="!showEditBtn"
              >
              </Field>
            </div>
          </div>
        </div>
      </div>
    </Form>
  </card>
</template>
<script>
import { Form, Field } from "vee-validate";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { German } from "flatpickr/dist/l10n/de.js";
import { useI18n } from "@/util/i18n";
export default {
  components: {
    Form,
    Field,
    flatPicker,
  },
  props: ["user"],
  data() {
    return {
      // user: this.userData,
      showEditBtn: false,
      config: {
        wrap: true,
        allowInput: true,
        altInput: true,
        dateFormat: "Y.m.d",
        altFormat: "d.m.Y",
        locale: German, // locale for this instance only
      },
    };
  },

  setup() {
    const i18n = useI18n();
    return {
      i18n,
    };
  },
};
</script>
