<template>
    <div class="data-section">
        

        <div class="pic">
            <h5>{{ username }}</h5>
            <span class="avatar avatar-xl rounded-circle">
                <div></div>
                {{ initials }}
            </span>
        </div>
        <div class="profil-data">
            <div class="data-1">
                <div class="value">1</div>
                <div>
                    <p>Jobs</p>
                </div>

            </div>
            <div class="data-1">
                <div class="value">{{ store.getters.skills.length }}</div>
                <div>
                    <p>Skills</p>
                </div>

            </div>
            <div class="data-1">
                <div class="value">{{ store.getters.skills.filter(skill => skill.status === "done").length }}</div>
                <div>
                    <p>Angeschaut</p>
                </div>

            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { useStore } from "vuex";
import storage from "@/util/storage";
import { LOGOUTAPI } from "@/services/public/Auth";

const store = useStore();
const username = ref("");
const initials = ref("");
username.value = store.getters.fullName;
initials.value = store.getters.initialsName



</script>

<style scoped>
.logout {
    cursor: pointer;
    margin-left: 10px;
    font-size: 0.8em;
}

h5 {
    padding: 0;
}

.value {
    color: #000041;
    font-weight: bolder;
}

.scrollable {
    overflow-y: scroll;

}

.profilpage {
    background-color: #ffffff;
    width: 100%;
    height: 100vh;
}

.data-section {
    background-color: #ffffff;
    width: 100%;
    height: 40vh;
    align-content: center;
    position: relative;
}

.pic {
    text-align: center;
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translateX(-50%);
    color: #000041;
    margin-top: 10px;
}

.profil-data {
    display: flex;
    width: 80%;
    text-align: center;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translateX(-50%);
}

.data-1 {
    flex: 1;
}
</style>