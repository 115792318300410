import axios from '../Axios';
import {
  GETUSERACTION,
} from '../CONSTANTURL';

export const GETUSERACTIONAPI = async () => {
  try {
    const result = await axios.get(`${GETUSERACTION}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};