import axios from '../Axios';
import { GETPROJECTSHIFTS, GETSHIFTBYID, GETOTHERUSERROLEPROJECTSHIFTS, CREATESHIFT, UPDATESHIFT, GETSHIFTPARTNERREQUEST, GETSHIFTREQUEST, UPDATESHIFTREQUEST, UPDATEICSHIFTREQUEST, GETALLSHIFTMEMBER, CREATESHIFTREQUEST, GETALLBYSHIFT, GETALLBYPARTNERSHIFT, CREATESHIFTPARTNERREQUEST, CREATESHIFTDOCUMENT, GETSHIFTDOCUMENTS, DELETESHIFTDOC, GETSHIFTBYPARTNER, UPDATESHIFTPARTNERREQUEST, GETALLSHIFTREQUEST, UPDATEOCMULTIPLEREQUEST, REMOVEBOOKEDSHIFTUSER, DELETESHIFT } from '../CONSTANTURL';

//FOr admin and dispatch
export const GETPROJECTSHIFTSAPI = async (data) => {
  try {
    const { project_id, flag } = data;
    const result = await axios.get(`${GETPROJECTSHIFTS}/${project_id}/deleted/${flag}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const GETSHIFTBYIDAPI = async (shift_id) => {
  try {

    const result = await axios.get(`${GETSHIFTBYID}/${shift_id}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const GETOTHERUSERROLEPROJECTSHIFTSAPI = async (data) => {
  try {
    const { flag, user_id, project_id } = data;
    const result = await axios.get(`${GETOTHERUSERROLEPROJECTSHIFTS}/${project_id}/${user_id}/deleted/${flag}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const CREATESHIFTAPI = async (data) => {
  try {
    const result = await axios.post(`${CREATESHIFT}`, data);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const UPDATESHIFTAPI = async (data) => {
  try {
    const { shift_id } = data;
    const result = await axios.put(`${UPDATESHIFT}/${shift_id}`, data);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const GETSHIFTPARTNERREQUESTAPI = async (data) => {
  try {
    const { shift_id, flag } = data;
    const result = await axios.get(`${GETSHIFTPARTNERREQUEST}/${shift_id}/deleted/${flag}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const GETALLSHIFTREQUESTAPI = async (data) => {
  try {
    const { shift_id, flag } = data;
    const result = await axios.get(`${GETALLSHIFTREQUEST}/${shift_id}/deleted/${flag}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};
export const GETSHIFTREQUESTAPI = async (data) => {
  try {
    const { shift_id, flag } = data;
    const result = await axios.get(`${GETSHIFTREQUEST}/${shift_id}/deleted/${flag}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const GETALLSHIFTMEMBERAPI = async (data) => {
  try {
    const { shift_id, flag } = data;
    const result = await axios.get(`${GETALLSHIFTMEMBER}/${shift_id}/deleted/${flag}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

// Shift OC booking api

export const CREATESHIFTREQUESTAPI = async (data) => {
  try {
    const result = await axios.post(`${CREATESHIFTREQUEST}`, data);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const GETALLBYSHIFTAPI = async (data) => {
  try {
    const { shift_id, flag } = data;
    const result = await axios.get(`${GETALLBYSHIFT}/${shift_id}/deleted/${flag}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const UPDATESHIFTREQUESTAPI = async (data) => {
  try {
    const { shift_request_id } = data;
    const result = await axios.put(`${UPDATESHIFTREQUEST}/${shift_request_id}`, data);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};
export const UPDATEICSHIFTREQUESTAPI = async (data) => {
  try {
    const { shift_request_id } = data;
    const result = await axios.put(`${UPDATEICSHIFTREQUEST}/${shift_request_id}`, data);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const UPDATEOCMULTIPLEREQUESTAPI = async (data) => {
  try {
    const result = await axios.put(`${UPDATEOCMULTIPLEREQUEST}`, data);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};


// Partner Shift Booking Api
export const GETSHIFTBYPARTNERAPI = async (data) => {
  try {
    const { project_id, user_id, flag } = data;
    const result = await axios.get(`${GETSHIFTBYPARTNER}/${project_id}/${user_id}/deleted/${flag}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};
export const GETALLBYPARTNERSHIFTAPI = async (data) => {
  try {
    const { shift_id, flag } = data;
    const result = await axios.get(`${GETALLBYPARTNERSHIFT}/${shift_id}/deleted/${flag}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const CREATESHIFTPARTNERREQUESTAPI = async (data) => {
  try {
    const result = await axios.post(`${CREATESHIFTPARTNERREQUEST}`, data);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const UPDATESHIFTPARTNERREQUESTAPI = async (data) => {
  try {
    const { shift_partner_request_id } = data;
    const result = await axios.put(`${UPDATESHIFTPARTNERREQUEST}/${shift_partner_request_id}`, data);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

// Shift Document api
export const CREATESHIFTDOCUMENTAPI = async (formData) => {
  try {
    const result = await axios.post(`${CREATESHIFTDOCUMENT}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};


export const GETSHIFTDOCUMENTSAPI = async (documentId, isDeleted) => {
  try {
    const result = await axios.get(`${GETSHIFTDOCUMENTS}/${documentId}/deleted/${isDeleted}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const DELETESHIFTDOCAPI = async (data) => {
  try {
    const result = await axios.delete(`${DELETESHIFTDOC}/${data}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const REMOVEBOOKEDSHIFTUSERAPI = async (shift_request_id) => {
  try {
    const result = await axios.delete(`${REMOVEBOOKEDSHIFTUSER}/${shift_request_id}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const DELETESHIFTAPI = async (data) => {
  try {
    const { shift_id } = data;
    const result = await axios.delete(`${DELETESHIFT}/${shift_id}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};
