<template>
  <modal
    v-model:show="show"
    modal-classes="modal-white"
    header-classes="align-items-center"
    body-classes="pt-0"
    @close="handleClose"
    size=""
  >
    <template v-slot:header>
      <h6 class="modal-title text-capitalize text-default">
        {{ i18n.$t("project.projectManagerModal.title") }}
      </h6>
    </template>
    <div class="">
      <p class="text-default mb-3"></p>
      <div
        class="row document-content"
        v-for="manager in projectManagers"
        :key="manager.shift_id"
      >
        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
          <div class="row">
            <div class="col-2 project-col">
              <span
                class="avatar avatar-sm bg-success rounded-circle"
                translate="no"
              >
                {{ getInitialsName(manager.first_name, manager.last_name) }}
              </span>
            </div>

            <div class="col-10">
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div>
                      <h4
                        class="mb-0 text-sm font-weight-bolder text-capitalize"
                      >
                        {{ manager.first_name }} {{ manager.last_name }}
                      </h4>
                    </div>
                  </div>
                  <p class="text-sm-2 mb-0">{{ manager.shift_name }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-2"></div>
            <div class="col-10" style="margin-top: 10px">
              <div class="row">
                <div class="col-6">
                  <span v-if="manager.shift_date" class="p-0 text-xs text-left">
                    <i class="fas fa-calendar-alt text-gray mr-1"></i>
                    <span>{{ formatDate(manager.shift_date) }}</span>
                  </span>
                  <span
                    v-if="manager.shift_start_time"
                    class="p-0 m-1 text-xs text-left"
                  >
                    <i class="fas fa-clock text-gray mr-1"></i>
                    <span class="margin-left">
                      {{ shiftTime(manager.shift_start_time) }}
                    </span>
                  </span>
                </div>
                <div class="col-6">
                  <span v-if="manager.email" class="p-0 text-xs text-left">
                    <i class="fas fa-envelope text-gray mr-1"></i>
                    <span>{{ manager.email }}</span>
                  </span>
                </div>
              </div>
              <div class="row" style="margin-top: 5px">
                <div class="col-6">
                  <span v-if="manager.shift_end_date" class="p-0 text-xs text-left">
                    <i class="fas fa-calendar-alt text-gray mr-1"></i>
                    <span>{{ formatDate(manager.shift_end_date) }}</span>
                  </span>
                  <span
                    v-if="manager.shift_end_time"
                    class="p-0 m-1 text-xs text-left"
                  >
                    <i class="fas fa-clock text-gray mr-1"></i>
                    <span class="margin-left">
                      {{ shiftTime(manager.shift_end_time) }}
                    </span>
                  </span>
                </div>
                <div class="col-6">
                  <span v-if="manager.mobile_no" class="p-0 text-xs text-left">
                    <i class="fas fa-mobile-alt text-gray mr-1"></i>
                    <span>{{ manager.mobile_no }}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </div>
      <div class="d-flex align-items-ceter justify-content-between pt-4">
        <base-button
          type=""
          class="ml-auto text-default p-2 px-3"
          @click="handleClose"
        >
          {{ i18n.$t("logout.button.cancel") }}
        </base-button>
      </div>
    </div>
  </modal>
</template>
<script>
import Modal from "@/components/Modal";
import { useI18n } from "@/util/i18n";
import { useRouter, useRoute } from "vue-router";
import { ElDropdownMenu, ElDropdownItem, ElDropdown } from "element-plus";
import initialsName from "@/util/initialsName";
import date from "@/util/date";

export default {
  name: "project-manager-list-modal",
  props: {
    projectManagers: Array,
    showModal: Boolean,
    loader: Boolean,
  },
  components: {
    Modal,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
  },
  data() {
    return {
      models: {
        removeDocumentModalVisible: false,
        confirmDeleteDocumentModalVisible: false,
        removeDocumentId: "",
        listDocumentModalVisible: false,
      },
      route: useRoute(),
    };
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
    getInitialsName(firstName, lastName) {
      return initialsName(`${firstName} ${lastName}`);
    },
    formatDate(projectDate) {
      if (projectDate !== null) {
        return date().formatDate(projectDate, "DD.MM.YYYY");
      }
      return "-";
    },
    shiftTime(shiftStartTime) {
      if (shiftStartTime !== null) {
        return `${date().formatTime(`${shiftStartTime}`, "HH:mm")} Uhr`;
      }
      return "-";
    },
  },
  computed: {
    show() {
      return this.showModal;
    },
    showClose() {
      return true;
    },
    documentList() {
      return this.documents;
    },
  },
  mounted() {},
  setup(props, { emit }) {
    const i18n = useI18n();

    return {
      i18n,
    };
  },
};
</script>
<style scoped>
.form-control[readonly] {
  background-color: #ffffff;
  opacity: 1;
}

.col-6 {
  padding-right: 0 !important;
}
</style>
