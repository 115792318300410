<template>
  <div>
    <base-header type="default" class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">
            {{ i18n.$t("skills.title") }}
          </h6>
        </div>

        <div class="col-lg-6 col-5 text-right">
          <!-- 
          <base-button
            v-if="isAccessible(permission.location_add)"
            size="sm"
            type="warning"
            @click="openLocationModal()"
          >
            {{ i18n.$t("skills.list.addSkills") }}
          </base-button>
        -->
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <light-table :skills="skills" @update="loadAllSkills" />
        </div>
      </div>
    </div>

    <!-- Add Skill modal 
    <add-location-modal
      :showModal="models.addLocationModalVisible"      
      @close="closeModal"
      @update="updateLocation"
    ></add-location-modal>
    -->
  </div>
</template>
<script>
import { ref, reactive, onMounted } from "vue";
import LightTable from "./LightTable";
import { GETALLSKILLSAPI } from "@/services/private/Skills";
import { useI18n } from "@/util/i18n";
import AddLocationModal from "../Locations/AddLocationModal.vue";
import { useToast } from "vue-toastification";
import { has_permission } from "@/services/private/Role_Service";
import { permission_list } from "@/services/_helper/user_permission";

export default {
  components: {
    LightTable,
    AddLocationModal,
  },
  data() {
    return {
      toast: useToast(),
      i18n: useI18n(),
      permission: permission_list,
      models: {
        addLocationModalVisible: false,
      },
      showHideDeleted: "",
      skills: [],
      showAll: false,
      isDeletedState: false,
    };
  },
  async mounted() {
    this.showHideDeleted = this.i18n.$t("location.list.showDeletedLocation");
    await this.getSkillsList(false);
  },
  methods: {
    async loadAllSkills() {
      await this.getSkillsList(this.isDeletedState);
    },
    isAccessible(permission) {
      return has_permission(permission.value);
    },
    openLocationModal() {
      this.models.addLocationModalVisible = true;
    },
    async updateLocation() {
      this.toast.success(this.i18n.$t("location.add.successMessage"));
      this.models.addLocationModalVisible = false;
      await this.getSkillsList(this.isDeletedState);
    },
    closeModal() {
      this.models.addLocationModalVisible = false;
    },
    async getSkillsList(isDeleted) {
      const result = await GETALLSKILLSAPI();
      if (result && result.status === 200) {
        this.skills = result.data.data;
      }
    },
    toggleLocationList(state) {
      this.showAll = state;
      this.isDeletedState = state;
      if (state) {
        this.showHideDeleted = this.i18n.$t(
          "location.list.hideDeletedLocation"
        );
        this.getSkillsList(true);
      } else {
        this.showHideDeleted = this.i18n.$t(
          "location.list.showDeletedLocation"
        );
        this.getSkillsList(false);
      }
    },
  },
  setup() {
    const i18n = useI18n();
    return {
      i18n,
    };
  },
};
</script>
<style scoped>
.modal-dialog {
  max-width: 585px !important;
}
.btn-sm{
  margin-bottom: 15px;
}
</style>
