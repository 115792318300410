<template>
  <div>
    <div class="header pb-6 d-flex align-items-center pt-4">
      <!-- Mask -->
      <span class="mask profile-background opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-7 col-md-10">
            <h1 class="display-2 text-white">
              {{ i18n.$t("editProfile.hello") }} {{ firstName }}
            </h1>
            <p class="text-white mt-0 mb-5">
              {{ i18n.$t("editProfile.description") }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid mt--6 xs-mt--3">
      <div class="row">
        <div class="col-xl-4 order-xl-2">
          <user-card :user="user"></user-card>
        </div>
        <div class="col-xl-8 order-xl-1">
          <profile-card :user="user"></profile-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useI18n } from "@/util/i18n";
import ProfileCard from "./StaffProfile/ProfileCard.vue";
import UserCard from "./StaffProfile/UserCard.vue";
import { GETEMPLOYEEPROFILEAPI } from "@/services/private/Company";

export default {
  components: {
    ProfileCard,
    UserCard,
  },
  computed: {
    firstName() {
      if (this.user && this.user.first_name) {
        return `${this.user.first_name}`;
      }
      return "";
    },
  },
  data() {
    return {
      user: {},
    };
  },
  methods: {
    async showProfile() {
      const data = {
        version_no: this.$route.params.version,
        user_id: this.$route.params.user_id,
      };
      const result = await GETEMPLOYEEPROFILEAPI(data);
      if (result && result.status === 200) {
        this.user = result && result.data && result.data.data[0];
      }
    },
  },
  async mounted() {
    await this.showProfile();
  },
  setup() {
    const i18n = useI18n();
    return { i18n };
  },
};
</script>
<style>
.profile-header {
  background-size: cover;
  background-position: center top;
  min-height: 500px;
}
.profile-background {
  background: linear-gradient(
    90deg,
    rgba(124, 123, 159, 1) 0%,
    rgba(29, 26, 80, 1) 67%
  ) !important;
}
</style>
