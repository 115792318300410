<template>
  <card>
    <Form onsubmit="return false;">
      <div class="row align-items-center">
        <div class="col-12">
          <h3 class="mb-0">{{ i18n.$t("network.companyProfile.title") }}</h3>
        </div>
      </div>
      <hr class="my-3" />
      <h6 class="heading-small text-muted mb-4">
        {{ i18n.$t("network.companyProfile.basicInfo") }}
      </h6>
      <div class="pl-lg-4">
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label>{{ i18n.$t("network.companyProfile.companyName") }}</label>
              <Field
                class="form-control"
                name="company_name"
                ref="company_name"
                type="text"
                :placeholder="i18n.$t('network.companyProfile.companyName')"
                v-model="company.company_name"
                disabled
              >
              </Field>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label>{{ i18n.$t("network.companyProfile.companyType") }}</label>
              <Field
                class="form-control"
                name="company_type"
                type="text"
                :placeholder="i18n.$t('network.companyProfile.companyType')"
                v-model="company.company_type"
                disabled
              >
              </Field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label>
                {{ i18n.$t("network.companyProfile.companyAddress") }}
              </label>
              <Field
                class="form-control"
                name="company_address"
                ref="company_address"
                type="text"
                :placeholder="i18n.$t('network.companyProfile.companyAdress')"
                v-model="company.company_address"
                disabled
              >
              </Field>
            </div>
          </div>
        </div>
      </div>
    </Form>
  </card>
</template>
<script>
import { onMounted, reactive, ref } from "vue";
import { useRoute } from "vue-router";
import { Form, Field } from "vee-validate";
import "flatpickr/dist/flatpickr.css";
import { useI18n } from "@/util/i18n";
import { GETCOMPANYPROFILEAPI } from "@/services/private/Company";
export default {
  components: {
    Form,
    Field,
  },
  data() {
    return {};
  },
  setup() {
    const i18n = useI18n();
    const route = useRoute();

    let company = reactive({
      company_id: "",
      company_name: "",
      company_address: "",
      company_type: "",
    });

    let showEditBtn = ref(false);
    const company_id = route.params.company_id;
    async function showCompanyProfile() {
      const result = await GETCOMPANYPROFILEAPI(company_id);
      if (result && result.status === 200 && result.data) {
        company.company_name = result.data.data[0].company_name;
        company.company_address = result.data.data[0].company_address;
        company.company_type = result.data.data[0].company_type;
      }
      showEditBtn.value = false;
    }

    onMounted(async () => {
      if (
        company_id === undefined ||
        company_id === "" ||
        company_id === null
      ) {
        router.push({ name: "Unauthorized" });
      } else {
        await showCompanyProfile();
      }
    });
    return {
      i18n,
      company,
      showEditBtn,
      showCompanyProfile,
    };
  },
};
</script>
<style></style>
