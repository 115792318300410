<template>
  <modal
    v-model:show="show"
    modal-classes="modal-white"
    header-classes="align-items-center"
    body-classes="pt-0"
    size="lg"
    :show-close="showClose"
  >
    <div class="modal-header" style="position: sticky; top: = 0;">
      <h6 class="modal-title text-capitalize text-default">
        {{ i18n.$t("projectDetails.shiftRequest.titleModal") }}
      </h6>
    </div>
    <div class="modal-body">
      <div class="nav-wrapper">
        <ul
          class="nav nav-pills nav-fill flex-column flex-md-row"
          id="tabs-icons-text"
          role="tablist"
        >
          <li class="nav-item">
            <a
              class="nav-link mb-sm-3 mb-md-0 active font-weight-600"
              id="tabs-icons-text-1-tab"
              data-toggle="tab"
              href="#tabs-icons-text-1"
              role="tab"
              aria-controls="tabs-icons-text-1"
              aria-selected="true"
              >{{ i18n.$t("projectDetails.shiftRequest.userTab") }}</a
            >
          </li>
        </ul>
      </div>
      <div class="row tab-content">
        <div class="col-12">
          <div class="card shadow">
            <div class="card-body">
              <div class="tab-content" id="myTabContent">
                <div
                  class="tab-pane fade show active"
                  id="tabs-icons-text-1"
                  role="tabpanel"
                  aria-labelledby="tabs-icons-text-1-tab"
                >
                  <div class="row">
                    <div v-bind:class="classObject">
                      <Field
                        class="form-control"
                        style="border: none"
                        name="search"
                        type="text"
                        label="search"
                        placeholder="Search"
                        v-model="search"
                      >
                      </Field>

                      <el-table
                        class="table-responsive table-flush rounded"
                        ref="userTable"
                        header-row-class-name="thead-light"
                        :row-class-name="tableRowClassName"
                        :show-header="false"
                        :current-row-key="1"
                        :data="
                          staffs.filter(
                            (data) =>
                              !search ||
                              data.first_name
                                .toLowerCase()
                                .includes(search.toLowerCase()) ||
                              data.last_name
                                .toLowerCase()
                                .includes(search.toLowerCase())
                          )
                        "
                        highlight-current-row
                        @selection-change="handleSelectionChange"
                      >
                        <el-table-column min-width="50px" prop="icon">
                          <template v-slot="{ row }">
                            <div class="media align-items-center">
                              <div>
                                <span
                                  class="avatar avatar-sm bg-success color-grey rounded-circle"
                                  translate="no"
                                >
                                  {{
                                    getInitialsName(
                                      row.first_name,
                                      row.last_name
                                    )
                                  }}
                                </span>
                              </div>
                              <div
                                style="margin-left: 15px; width: 100%"
                                @click="handleCurrentChange(row)"
                              >
                                <div class="media-body font-weight-600">
                                  {{ row.first_name }} {{ row.last_name }}
                                </div>
                                <span class="status text-capitalize">
                                  {{ getStatus(row.shift_request_status) }}
                                </span>
                              </div>
                            </div>
                          </template>
                        </el-table-column>

                        <el-table-column width="100px">
                          <template v-slot="{ row }">
                            <base-checkbox
                              v-model="row.isSelected"
                              :disabled="row.isDisable"
                              @change="check(row)"
                              class="mb-3"
                            >
                            </base-checkbox>
                          </template>
                        </el-table-column>
                      </el-table>

                      <div id="block_container">
                        <div id="bloc1">
                          <label style="color: #8898aa">
                            {{
                              i18n.$t("projectDetails.shiftRequest.selectAll")
                            }}
                          </label>
                        </div>
                        <div id="bloc2">
                          <base-checkbox
                            @change="selectUnSelectAll"
                            label="search"
                            class="mb-3"
                          >
                          </base-checkbox>
                        </div>
                      </div>

                      
                    </div>
                    <div v-if="profileData" class="col-6">
                      <div class="row">
                        <div class="col-xl-12 order-xl-2">
                          <user-profile-view :profile="profileData">
                          </user-profile-view>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
    <div class="modal-footer" style="position: sticky;bottom: 0;background-color: #FCFCFD; z-index: 1055;">
      <div class="d-flex align-items-ceter pt-4">
                      <base-button
                          :disabled="!users || users <= 0"
                          style="margin-left: auto"
                          type="primary"
                          class="text-white p-2 px-3"
                          @click="assignUser"
                        >
                          <div class="d-flex align-items-center">
                            <span>{{
                              i18n.$t("projectDetails.shiftRequest.assignBtn")
                            }}</span>
                          </div>
                        </base-button>
                        <base-button
                          :disabled="!users || users <= 0 || sendingData"
                          type="primary"
                          class="text-white p-2 px-3"
                          @click="sendRequest"
                        >
                          {{
                            i18n.$t("projectDetails.shiftRequest.requestBtn")
                          }}
                        </base-button>
                      </div>
                      <base-button
            type=""
            style="float: right"
            class="ml-auto text-default p-2 px-3"
            @click="handleClose"
          >
            {{ i18n.$t("message.cancel") }}
          </base-button>
    </div>
  </modal>
</template>
<script>
import { ref, reactive, onMounted } from "vue";
import Modal from "@/components/Modal";
import { useI18n } from "@/util/i18n";
import { COMPANYUSERAPI } from "@/services/private/Company";
import { GETUSERINFOAPI } from "@/services/private/Profile";
import {
  UPDATEICSHIFTREQUESTAPI,
  CREATESHIFTREQUESTAPI,
  GETALLBYPARTNERSHIFTAPI,
} from "@/services/private/ProjectShift";
import { useToast } from "vue-toastification";
import UserProfileView from "./UserProfileView.vue";
import initialsName from "@/util/initialsName";
import { Field, Form } from "vee-validate";
import {
  ElTable,
  ElTableColumn,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
} from "element-plus";
export default {
  name: "user-booking-modal",
  props: {
    showModal: Boolean,
    shiftData: Object,
    staffData: Object,
  },
  components: {
    Modal,
    Field,
    UserProfileView,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
  },
  data() {
    return {
      sendingData: false,
      invite_link: "",
      toast: useToast(),
      showModalPopup: this.showModal,
      i18n: useI18n(),
      currentRow: null,
      profileData: null,
      search: "",
      users: [],
      shiftDataObject: this.shiftData,
      existingShiftUsers: [],
      availableShiftUser: [],
      scheduleUserList: [],
      staffs: [],
      selectAll: false,
    };
  },
  created: function () {},
  computed: {
    classObject() {
      if (this.profileData) {
        return "col-6";
      } else {
        return "col-12";
      }
    },
    shiftDataAll() {
      return this.shiftData;
    },
    show() {
      return this.showModal;
    },
    showClose() {
      return true;
    },
    company_id() {
      return this.$store.state.user.company_id;
    },
  },
  methods: {
    tableRowClassName({ row }) {
      if (row.isSelected) {
        return "currentRow-background";
      } else {
        return "";
      }
    },
    setCurrent(row) {
      this.$refs.userTable.setCurrentRow(row);
    },
    handleCurrentChange(val) {
      this.currentRow = val;
      //console.log(val);
      this.loadUserProfile(val.user_id, val.version_no);
    },
    selectUnSelectAll() {
      if (this.selectAll) {
        this.selectAll = false;
        this.users = [];
        this.staffs.forEach((element) => {
          if (!element.isDisable) {
            element.isSelected = false;
          }
        });
      } else {
        this.selectAll = true;
        this.staffs.forEach((element) => {
          if (!element.isDisable && !element.isSelected) {
            element.isSelected = true;
            this.users.push({
              user_id: element.user_id,
              user_name: `${element.first_name} ${element.last_name}`,
            });
          }
        });
      }
    },
    check(val) {
      if (val.isSelected == true) {
        this.users.push({
          user_id: val.user_id,
          user_name: val.first_name + val.last_name,
        });
      } else {
        const index = this.users
          .map((item) => item.user_id)
          .indexOf(val.user_id);
        if (index > -1) {
          this.users.splice(index, 1);
        }
      }
    },
    handleClose() {
      this.$emit("close");
    },
    handleSelectionChange(val) {
      //console.log(val);
      //this.loadUserProfile(userId,Version);
    },
    async sendRequest() {
      this.sendingData = false;
      const result = await this.generateShiftRequest("ic_user_requested");
      if (result && result.status === 200) {
        this.users = [];
        const { data } = result.data;
        this.toast.success(
          this.i18n.$t("projectDetails.shiftRequest.userRequestSuccess")
        );
        this.$emit("update", this.shiftData.shift_id);
        this.getAllByShift();
      }
      this.sendingData = false;
    },
    async assignUser() {
      var staff = this.staffData;
      var shift = this.shiftData;

      // Update shift detials if wildcard is present. If not present then throw error.

      if (this.users && this.users.length > 0) {
        for(let index = 0; index < this.users.length; index++) {
          for(let i = 0; i < staff.length; i++) {
            if(staff[i].user_id === null) {
              const user = this.users[index];
              const updateShiftRequestData = {};
              updateShiftRequestData.shift_request_id = staff[i].shift_request_id;
              updateShiftRequestData.shift_id = staff[i].shift_id;
              if(staff[i].shift_request_status === 'ic_user_open') {
              updateShiftRequestData.shift_request_status = 'ic_user_open';
              }
              if(staff[i].shift_request_status === 'ic_user_added') {
              updateShiftRequestData.shift_request_status = 'ic_user_added';
              }
              updateShiftRequestData.shift_name = shift.shift_name;
              updateShiftRequestData.user_name = user.user_name;
              updateShiftRequestData.user_id = user.user_id;
              updateShiftRequestData.partner_company_id = staff[i].company_id;
              const data = {
              ...updateShiftRequestData,
              request_feedback: "",
              };
              const result = await UPDATEICSHIFTREQUESTAPI(data);
              if (result && result.status === 200) {
                this.toast.success(
                this.i18n.$t("projectDetails.shiftRequest.userRequestSuccess")
              );
              } else {
                toast.error(i18n.$t("projectDetails.shiftRequest.shiftRequestFailed"));
              }
              break;
            }
          }
        }
      }
      this.sendingData = false;
    },
    async generateShiftRequest(requestType) {
      var shift = this.shiftData;
      if (shift) {
        if (this.users && this.users.length > 0) {
          let data = {
            shift_id: shift.shift_id,
            users: this.users,
            shift_request_status: requestType,
          };
          return await CREATESHIFTREQUESTAPI(data);
        } else {
          this.toast.error(
            this.i18n.$t("projectDetails.shiftRequest.pleaseSelectUser")
          );
        }
      }
    },
    async getAllByShift() {
      let shift = this.shiftData; //this.shiftDataAll();
      if (shift) {
        var result = await GETALLBYPARTNERSHIFTAPI({
          shift_id: shift.shift_id,
          flag: false,
        });
        if (result && result.status === 200) {
          const { data } = result.data;
          if (data && data.length && data[0].requestedUserList) {
            this.existingShiftUsers = data[0].requestedUserList;
            this.availableShiftUser = data[0].availableUserList;
            this.scheduleUserList = data[0].scheduleUserList;

            this.staffs.forEach((element) => {
              var existData = this.scheduleUserList.find(
                (o) => o.user_id === element.user_id
              );
              if (existData) {
                element.isSelected = true;
                element.isDisable = true;
                element.shift_request_status = existData.shift_request_status;
              }
            });

            this.staffs.forEach((element) => {
              var existData = this.existingShiftUsers.find(
                (o) => o.user_id === element.user_id
              );
              if (existData) {
                element.isSelected = true;
                element.isDisable = true;
                element.shift_request_status = existData.shift_request_status;
              }
            });

            this.staffs.forEach((element) => {
              var existData = this.availableShiftUser.find(
                (o) => o.user_id === element.user_id
              );
              if (existData) {
                element.isSelected = true;
                element.isDisable = true;
                element.shift_request_status = existData.shift_request_status;
              }
            });
          }
        }
      }
    },
    getInitialsName(firstName, lastName) {
      return initialsName(`${firstName} ${lastName}`);
    },

    handleUpdateCompany() {
      this.$emit("update", this.shiftData.shift_id);
    },

    getStatus(status) {
      return this.i18n.$t(`projectDetails.shiftRequest.status.${status}`);
    },

    async loadUserProfile(userId, version) {
      //const user = this.userId;
      const data = {
        version_no: version,
        user_id: userId,
      };
      const result = await GETUSERINFOAPI(data);
      if (result && result.status === 200) {
        const { data } = result.data;
        this.profileData = data[0];
      }
    },
    async getCompanyStaff(state) {
      const result = await COMPANYUSERAPI({ is_active: state });
      if (result && result.status === 200) {
        this.staffs = result.data.data.map((r) => {
          return {
            company_id: r.company_id,
            email: r.email,
            first_name: r.first_name,
            is_active: r.role.is_active,
            last_name: r.last_name,
            role_name: r.role.role_name,
            user_id: r.user_id,
            role_code: r.role.role_code,
            version_no: r.version_no,
          };
        });
      }
      this.getAllByShift();
    },
  },

  mounted() {
    this.getCompanyStaff(true);
    //this.getAllByShift();
  },

  setup() {
    const i18n = useI18n();

    function getRoleI18n(type) {
      const roleName = type && type.toLowerCase();
      return i18n.$t(`role.${roleName}`);
    }

    return {
      i18n,
      getRoleI18n,
    };
  },
};
</script>
<style>
.modal .modal-huge {
  max-width: 96%;
  width: 1000px !important;
}

tr.currentRow-background > td {
  background: #e5e5e5 !important;
}

.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: grey !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: grey !important;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  border-color: grey !important ;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: grey !important ;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: grey !important ;
  border-color: grey !important ;
}

.el-table__row {
  cursor: pointer;
}
</style>
<style scoped>
div.el-table .el-table__row:hover {
  background: #f5f7fa !important;
}
.el-table__body tr.current-row > td {
  background: #e5e5e5 !important;
}
tr.currentRow-background > td {
  background: #e5e5e5 !important;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: black !important;
  background-color: #fff !important;
}
.nav-pills .nav-link {
  color: black !important;
  background-color: #b8b7b7 !important;
}

.color-grey {
  background-color: #b8b7b7 !important;
}

#block_container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

#bloc1 {
  margin-right: 39px;
}

#bloc2 {
  margin-right: 50px;
}

.el-table__row {
  cursor: pointer;
}
</style>
