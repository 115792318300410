<template>
  <div class="card card-profile">
    <div style="margin-top: 80px" class="row justify-content-center">
      <div class="col-lg-3 order-lg-2">
        <div class="card-profile-image">
          <router-link to="/dashboard">
            <span translate="no">{{ initialsName }}</span>
          </router-link>
        </div>
      </div>
    </div>

    <div class="card-body pt-5">
      <div class="row">
        <div class="col">
          <div class="card-profile-stats d-flex justify-content-center">
            <div class="text-center">
              <h5 class="h3">
                {{ company.company_name }}
              </h5>
              <div
                class="
                  h5
                  font-weight-300
                  d-flex
                  align-items-center
                  justify-content-center
                "
                v-if="getCityCountry !== ''"
              >
                <i class="ni ni-pin-3 mr-1"></i>
                <span>{{ getCityCountry }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <Form>
            <div class="pl-lg-4">
              <div class="row">
                <div class="col-lg-12">
                  <label>
                    {{
                      i18n.$t("projectDetails.shiftRequest.label.contactPerson")
                    }}
                  </label>
                  <Field
                    class="form-control"
                    name="contact_person"
                    ref="contact_person"
                    type="text"
                    :placeholder="
                      i18n.$t('projectDetails.shiftRequest.label.contactPerson')
                    "
                    :value="company.contact_person"
                    disabled
                  >
                  </Field>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <label>
                    {{
                      i18n.$t(
                        "projectDetails.shiftRequest.label.companyAddress"
                      )
                    }}
                  </label>
                  <Field
                    class="form-control"
                    name="company_address"
                    ref="company_address"
                    type="text"
                    :placeholder="
                      i18n.$t(
                        'projectDetails.shiftRequest.label.companyAddress'
                      )
                    "
                    v-model="company.company_address"
                    disabled
                  >
                  </Field>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <label>
                    {{
                      i18n.$t("projectDetails.shiftRequest.label.companyPhone")
                    }}
                  </label>
                  <Field
                    class="form-control"
                    name="mobile_no"
                    type="text"
                    id="mobile_no"
                    :placeholder="
                      i18n.$t('projectDetails.shiftRequest.label.companyPhone')
                    "
                    v-model="company.company_phone"
                    disabled="true"
                    maxlength="25"
                  ></Field>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <label>
                    {{
                      i18n.$t("projectDetails.shiftRequest.label.companyEmail")
                    }}
                  </label>
                  <Field
                    class="form-control"
                    name="email"
                    type="email"
                    label="E-mail"
                    :placeholder="
                      i18n.$t('projectDetails.shiftRequest.label.companyEmail')
                    "
                    v-model="company.company_email"
                    disabled
                  >
                  </Field>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useI18n } from "@/util/i18n";
import { Field, Form } from "vee-validate";
import age from "@/util/age";
import initialsName from "@/util/initialsName";

export default {
  props: {
    company: Object,
  },
  components: {
    Field,
    Form,
  },
  data() {
    return {
      //user: this.profile,
    };
  },
  computed: {
    getAddress() {
      if (this.profile) {
        return `${this.profile.address_street} ${this.profile.address_houseno}, ${this.profile.address_postcode} ${this.profile.address_city}`;
      }
    },
    fullName() {
      if (this.profile && this.profile.first_name && this.profile.last_name) {
        return `${this.profile.first_name} ${this.profile.last_name}`;
      }
    },
    getCityCountry() {
      return `${
        this.company.company_address_city
          ? `${this.company.company_address_city}, `
          : ""
      }${
        this.company.company_address_country
          ? this.company.company_address_country
          : ""
      }`;
    },
    initialsName() {
      if (this.company && this.company.company_name) {
        return initialsName(this.company.company_name, 1);
      }
      return "";
    },

    // getAge() {
    //   return age(this.profile && this.profile.date_of_birth);
    // },
  },
  methods: {},
  async mounted() {},
  setup() {
    const i18n = useI18n();
    return {
      i18n,
    };
  },
};
</script>
