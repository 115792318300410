import axios from '../Axios';
import { COMPANYPROJECT, GETPRJECTBYUSER,GETPROJECTSHIFTBYUSER, CREATEPROJECT, DELETEPROJECT, CANCELEDPROJECT, GETPROJECTBYID, GETPROJECTBYLOCATION, UPDATEPROJECT, UPLOADPROJECTDOCUMENT, GETPROJECTDOCUMENTS, DELETEPROJECTDOCUMENT,GETUSERPROJECTMANAGER } from '../CONSTANTURL';

//for Admin and dispatch user
export const COMPANYPROJECTAPI = async (data) => {
  try {
    const { flag } = data;
    const flagLowerCase = flag.toLowerCase();
    const result = await axios.get(`${COMPANYPROJECT}/${flagLowerCase}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

//for other than Admin and dispatch user
export const GETPRJECTBYUSERAPI = async (data) => {
  try {
    const { user_id, flag } = data;
    const flagLowerCase = flag.toLowerCase();
    const result = await axios.get(`${GETPRJECTBYUSER}/${user_id}/${flagLowerCase}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const GETPROJECTSHIFTBYUSERAPI = async (data) => {
  try {
    const { user_id, flag } = data;
    const flagLowerCase = flag.toLowerCase();
    const result = await axios.get(`${GETPROJECTSHIFTBYUSER}/${user_id}/${flagLowerCase}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const CREATEPROJECTAPI = async (data) => {
  try {
    const result = await axios.post(`${CREATEPROJECT}`, data);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const DELETEPROJECTAPI = async (project_id) => {
  try {
    const result = await axios.delete(`${DELETEPROJECT}/${project_id}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const CANCELEDPROJECTAPI = async (project_id) => {
  try {
    const result = await axios.put(`${CANCELEDPROJECT}/${project_id}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const GETPROJECTBYIDAPI = async (project_id) => {
  try {
    const result = await axios.get(`${GETPROJECTBYID}/${project_id}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const UPDATEPROJECTAPI = async (data) => {
  try {
    const { project_id } = data;
    const result = await axios.put(`${UPDATEPROJECT}/${project_id}`, data);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const GETPROJECTBYLOCATIONAPI = async (location_id, data) => {
  try {
    const flag = data.toLowerCase();
    const result = await axios.get(`${GETPROJECTBYLOCATION}/${location_id}/${flag}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

// Document Upload API

export const CREATEPROJECTDOCUMENTAPI = async (formData) => {
  try {
    const result = await axios.post(`${UPLOADPROJECTDOCUMENT}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};


export const GETPROJECTDOCUMENTSAPI = async (documentId, isDeleted) => {
  try {
    const result = await axios.get(`${GETPROJECTDOCUMENTS}/${documentId}/deleted/${isDeleted}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const DELETEPROJECTDOCAPI = async (data) => {
  try {
    const result = await axios.delete(`${DELETEPROJECTDOCUMENT}/${data}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const GETUSERPROJECTMANAGERAPI = async (project_id) => {
  try {
    const result = await axios.get(`${GETUSERPROJECTMANAGER}/${project_id}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};





