<template>
  <div>
    <modal
      v-model:show="show"
      modal-classes="modal-white modal-lg"
      header-classes="align-items-center"
      body-classes="pt-0"
      @close="handleClose"
    >
      <template v-slot:header>
        <h6 class="modal-title text-capitalize text-default">
          {{ i18n.$t("project.createProject") }}
        </h6>
      </template>
      <div class="">
        <div class="row">
          <div class="col-12 col-md-9">
            <p class="text-muted text-sm mb-3">
              {{ i18n.$t("project.createProjectSubHeader") }}
            </p>
          </div>
        </div>
        <Form
          ref="form"
          class="pt-2"
          @submit="handleCreateProject"
          :validation-schema="schema"
          v-slot="{ errors }"
        >
          <div class="row">
            <div class="col-12 col-md-9 pr-md-1">
              <div class="form-group">
                <div
                  class="input-group"
                  :class="{ 'has-danger-border-red': errors.project_name }"
                >
                  <div class="input-group-prepend">
                    <span class="input-group-text font-weight-bolder">
                      <i class="fas fa-file-alt"></i>
                    </span>
                  </div>
                  <Field
                    class="form-control"
                    name="project_name"
                    type="text"
                    id="project_name"
                    :placeholder="i18n.$t('project.modal.form.project_name')"
                    v-model="formData.project_name"
                  ></Field>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-9 pr-md-1">
              <div class="form-group static-select-wrap">
                <div
                  class="input-group"
                  :class="{ 'has-danger-border-red': errors.location_id }"
                >
                  <div class="input-group-prepend">
                    <span class="input-group-text font-weight-bolder">
                      <i class="fas fa-map-marker-alt"></i>
                    </span>
                  </div>
                  <Field name="location_id" v-model="formData.location_id">
                    <Multiselect
                      :classes="{
                        optionSelectedPointed:
                          'bg-default is-pointed text-white',
                        optionSelected: 'bg-default text-white',
                        optionPointed: 'bg-green text-white',
                        containerOpen:
                          'is-open border border-primary shadow-none',
                      }"
                      v-model="formData.location_id"
                      :options="locations"
                      :placeholder="i18n.$t('project.modal.form.location')"
                      :searchable="true"
                      :close-on-select="true"
                    ></Multiselect>
                  </Field>
                </div>
              </div>
            </div>
            <div class="col-md-3 pl-md-1">
              <button
                type="button"
                class="btn btn-sm btn-portage my-2"
                @click="openLocationModal"
              >
                {{ i18n.$t("location.list.addLocation") }}
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-9 pr-md-1">
              <div
                class="form-group static-wrap"
                :class="{ 'has-danger-border-red': errors.start_date }"
              >
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text font-weight-bolder">
                      <i class="fas fa-calendar-alt"></i>
                    </span>
                  </div>
                  <Field name="start_date" v-model="formData.start_date">
                    <flat-picker
                      :config="start_date_config"
                      name="start_date"
                      class="form-control datepicker"
                      v-model="formData.start_date"
                      id="start_date"
                      :placeholder="i18n.$t('project.modal.form.start_date')"
                      @on-change="updateEndDate"
                    >
                    </flat-picker>
                  </Field>
                </div>
              </div>
            </div>
            <div class="col-md-3 pl-md-1">
              <div class="form-group static-time-wrap">
                <div
                  class="input-group"
                  :class="{ 'has-danger-border-red': errors.start_time }"
                >
                  <div class="input-group-prepend">
                    <span class="input-group-text font-weight-bolder">
                      <i class="fas fa-clock"></i>
                    </span>
                  </div>
                  <Field name="start_time" v-model="formData.start_time">
                    <flat-picker
                      :config="start_time_config"
                      name="start_time"
                      class="form-control datepicker"
                      v-model="formData.start_time"
                      id="start_time"
                      :placeholder="i18n.$t('project.modal.form.start_time')"
                      autocomplete="off"
                    >
                    </flat-picker>
                  </Field>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-9 pr-md-1">
              <div
                class="form-group static-wrap"
                :class="{ 'has-danger-border-red': errors.end_date }"
              >
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text font-weight-bolder">
                      <i class="fas fa-calendar-alt"></i>
                    </span>
                  </div>
                  <Field name="end_date" v-model="formData.end_date">
                    <flat-picker
                      :config="end_date_config"
                      name="end_date"
                      class="form-control datepicker"
                      v-model="formData.end_date"
                      id="end_date"
                      :placeholder="i18n.$t('project.modal.form.end_date')"
                      @on-change="updateEndTime"
                    ></flat-picker>
                  </Field>
                </div>
              </div>
            </div>
            <div class="col-md-3 pl-md-1">
              <div class="form-group static-time-wrap">
                <div
                  class="input-group"
                  :class="{ 'has-danger-border-red': errors.end_time }"
                >
                  <div class="input-group-prepend">
                    <span class="input-group-text font-weight-bolder">
                      <i class="fas fa-clock"></i>
                    </span>
                  </div>
                  <Field name="end_time" v-model="formData.end_time">
                    <flat-picker
                      :config="end_time_config"
                      name="end_time"
                      class="form-control datepicker"
                      v-model="formData.end_time"
                      id="end_time"
                      :placeholder="i18n.$t('project.modal.form.end_time')"
                      autocomplete="off"
                    >
                    </flat-picker>
                  </Field>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-ceter justify-content-between pt-4">
            <base-button
              native-type="submit"
              type="warning"
              class="text-white p-2 px-3"
            >
              <div class="d-flex align-items-center">
                <spinner v-show="loader" color="text-black" />
                <span>{{ i18n.$t("project.createProject") }}</span>
              </div>
            </base-button>
            <base-button
              type=""
              class="ml-auto text-default p-2 px-3"
              @click="handleClose"
            >
              {{ i18n.$t("common.cancel") }}
            </base-button>
          </div>
        </Form>
      </div>
    </modal>
    <add-location-modal
      :showModal="addLocationModalVisible"
      @close="closeLocationModal"
      @update="updateLocation"
    ></add-location-modal>
  </div>
</template>
<script>
import { Field, Form } from "vee-validate";
import Modal from "@/components/Modal";
import * as Yup from "yup";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { German } from "flatpickr/dist/l10n/de.js";
import Multiselect from "@vueform/multiselect";
import { GETALLLOCATIONSAPI } from "@/services/private/Location";
import date from "@/util/date";
import { useI18n } from "@/util/i18n";
import AddLocationModal from "../../Locations/AddLocationModal.vue";
import { useToast } from "vue-toastification";

export default {
  name: "create-project-modal",
  props: {
    showModal: Boolean,
    loader: Boolean,
  },
  components: {
    Field,
    Form,
    flatPicker,
    Modal,
    Multiselect,
    AddLocationModal,
  },
  data() {
    return {
      toast: useToast(),
      i18n: useI18n(),
      locations: [],
      showErrorMsg: false,
      addLocationModalVisible: false,
      formData: {
        project_name: "",
        location_id: "",
        start_date: new Date(),
        start_time: "00:00",
        end_date: "",
        end_time: "",
      },
      start_date_config: {
        position: "above",
        allowInput: true,
        altInput: true,
        dateFormat: "Y-m-d",
        altFormat: "d.m.Y",
        defaultDate: new Date(),
        disableMobile: "true",
        locale: German, // locale for this instance only
      },
      end_date_config: {
        position: "above",
        allowInput: true,
        altInput: true,
        dateFormat: "Y-m-d",
        altFormat: "d.m.Y",
        disableMobile: "true",
        locale: German, // locale for this instance only
      },
      start_time_config: {
        static: true,
        wrap: true,
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
        defaultDate: "00:00",
      },
      end_time_config: {
        static: true,
        wrap: true,
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
      },
      schema: Yup.object().shape({
        project_name: Yup.string().required(),
        location_id: Yup.string().required(),
        start_date: Yup.string().required(),
        start_time: Yup.string().required(),
      }),
    };
  },
  computed: {
    show() {
      return this.showModal;
    },
    showClose() {
      return false;
    },
  },
  watch: {
    showModal(new_value, old_value) {
      if (new_value != old_value) {
        this.$nextTick(() => {
          this.$refs.form.resetForm();
          this.formData = {
            project_name: "",
            location_id: "",
            start_date: new Date(),
            start_time: "00:00",
            end_date: "",
            end_time: "",
          };
        });
      }
    },
  },
  methods: {
    async getLocation() {
      const result = await GETALLLOCATIONSAPI(false);
      if (result && result.status === 200) {
        this.locations = result.data.data.map((l) => {
          return { value: l.location_id, label: l.location_name };
        });
      }
    },
    handleClose() {
      this.$refs.form.resetForm();
      this.$emit("close", false);
    },
    handleCreateProject() {
      this.$emit("create", this.formData);
    },
    updateEndDate(selectedDates, dateStr, instance) {
      if (dateStr) {
        this.end_date_config.minDate = new Date(dateStr);
        this.formData.end_date = new Date(dateStr);
      }
    },
    updateEndTime(selectedDates, dateStr, instance) {
      if (dateStr) {
        if (date().isDateToday(new Date(dateStr))) {
          this.end_time_config.minTime = date().formatDate(new Date(), "HH:mm");
        } else {
          this.end_time_config.minTime = "";
        }
      }
    },
    setEndMinTime(selectedDates, dateStr, instance) {
      if (dateStr) {
        const { start_date, end_date } = this.formData;
        if (start_date && end_date) {
          if (date().dateDiff(start_date, end_date) === 0) {
            this.end_time_config.minTime = this.start_time_config.minTime;
            this.formData.end_time = "";
          } else {
            this.end_time_config.minTime = "";
          }
        }
      }
    },
    openLocationModal() {
      this.addLocationModalVisible = true;
      // this.$emit("openLocation");
    },
    closeLocationModal() {
      this.addLocationModalVisible = false;
    },
    async updateLocation(result) {
      this.toast.success(this.i18n.$t("location.add.successMessage"));
      this.addLocationModalVisible = false;
      await this.getLocation();
      this.formData.location_id = result.data.data[0].location_id;
      let routeData = this.$router.resolve({
        name: "Locations",
      });
      window.open(routeData.href, "_blank");
    },
  },
  async mounted() {
    await this.getLocation();
  },
  setup() {
    const i18n = useI18n();
    return { i18n };
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
body.modal-open {
  overflow: hidden;
}
.modal {
  overflow-y: auto;
}
</style>
