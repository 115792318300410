/*!

=========================================================
* Vue Argon Dashboard PRO - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import ArgonDashboard from "./plugins/argon-dashboard";
import "element-plus/lib/theme-chalk/index.css";

import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import store from "./store";
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

const options = { containerClassName: "ct-notification", transition: "Vue-Toastification__fade", timeout: 3000, position: POSITION.BOTTOM_RIGHT };

const appInstance = createApp(App).use(store);
library.add(fas);
dom.watch();
appInstance.component('font-awesome-icon', FontAwesomeIcon);
appInstance.use(router);
appInstance.use(Toast, options);
appInstance.use(ArgonDashboard);
appInstance.mount("#app");

