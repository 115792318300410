<template>
  <modal
    v-model:show="show"
    modal-classes="modal-white"
    header-classes="align-items-center"
    body-classes="pt-0"
    :show-close="showClose"
  >
    <template v-slot:header>
      <h6 class="modal-title text-capitalize text-default">
        {{ i18n.$t("projectDetails.documents.listDocPopupTitle") }}
      </h6>
    </template>
    <div class="">
      <p class="text-default mb-3"></p>
      <div class="row document-content">
        <div
          class="col-12 col-sm-12 col-md-12 col-lg-12"
          v-for="doc in documents"
          :key="doc.document_name"
        >
          <div class="d-flex project-rows">
            <div
              class="col-8 project-col d-flex align-items-center"
              @click="openDocument(doc)"
            >
              <span class="d-flex" @click="openDocument(doc)">
                <i class="ni ni-single-copy-04"></i>
              </span>
              <span class="ml-2">
                {{ doc.document_name }}
              </span>
            </div>

            <div v-if="isAddAllowed" class="col-4 pr-0">
              <span class="d-flex align-items-center justify-content-end">
                <!-- <span style="margin-left: 5px;" @click="deleteDocument(doc.project_document_id)"
                  ><i class="ni ni-fat-remove"></i
                ></span> -->
                <el-dropdown
                  class="dropdown"
                  trigger="click"
                  placement="bottom-start"
                >
                  <span class="p-2 btn-icon-only text-light">
                    <i class="fas fa-ellipsis-v mt-2"></i>
                  </span>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <li
                        class="dropdown-item"
                        @click="deleteDocument(doc.document_id)"
                      >
                        {{ i18n.$t("common.remove") }}
                      </li>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-ceter justify-content-between pt-4">
        <button
          v-if="isAddAllowed"
          type="button"
          class="btn btn-white p-2 text-xs text-left"
          @click="addNewDocument"
        >
          <i class="fas fa-cloud-upload-alt text-gray"></i>
          <span class="">
            {{ i18n.$t("projectDetails.newDocument") }}
          </span>
        </button>

        <base-button
          type=""
          class="ml-auto text-default p-2 px-3"
          @click="handleClose"
        >
          {{ i18n.$t("logout.button.cancel") }}
        </base-button>
      </div>
      <delete-document-modal
        :showModal="models.removeDocumentModalVisible"
        :loader="loader"
        @close="closeDeleteDocumentModal"
        @confirm="showConfirmDocumentDeleteModal"
      ></delete-document-modal>

      <confirm-document-delete-modal
        :showModal="models.confirmDeleteDocumentModalVisible"
        :loader="loader"
        @close="closeConfirmDocumentDeleteModal"
        @remove="sendRemoveDocumentAPI"
      ></confirm-document-delete-modal>
    </div>
  </modal>
</template>
<script>
import Modal from "@/components/Modal";
import { useI18n } from "@/util/i18n";
import {  useRoute } from "vue-router";
import DeleteDocumentModal from "../../../Common/DeletePopup/DeleteDocumentModal.vue";
import ConfirmDocumentDeleteModal from "../../../Common/DeletePopup/ConfirmDocumentDeleteModal.vue";
import { ElDropdownMenu, ElDropdownItem, ElDropdown } from "element-plus";

export default {
  name: "list-documents-modal",
  props: {
    documents: Array,
    showModal: Boolean,
    loader: Boolean,
    isAddAllowed: Boolean,
  },
  components: {
    Modal,
    DeleteDocumentModal,
    ConfirmDocumentDeleteModal,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
  },
  data() {
    return {
      models: {
        removeDocumentModalVisible: false,
        confirmDeleteDocumentModalVisible: false,
        removeDocumentId: "",
        listDocumentModalVisible: false,
      },
      route: useRoute(),
    };
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
    addNewDocument() {
      this.$emit("addDoc");
    },
    openDocument(data) {
      window.open(data.document_path, "_blank");
    },
    deleteDocument(data) {
      this.models.removeDocumentId = data;
      this.models.removeDocumentModalVisible = true;
    },
    closeDeleteDocumentModal() {
      this.models.removeDocumentId = "";
      this.models.removeDocumentModalVisible = false;
    },
    showConfirmDocumentDeleteModal() {
      this.models.removeDocumentModalVisible = false;
      this.models.confirmDeleteDocumentModalVisible = true;
    },
    closeConfirmDocumentDeleteModal() {
      this.models.confirmDeleteDocumentModalVisible = false;
      this.closeDeleteDocumentModal();
    },
    sendRemoveDocumentAPI() {
      this.$emit("DeleteDocApi", this.models.removeDocumentId);
      this.closeConfirmDocumentDeleteModal();
    },
  },
  computed: {
    show() {
      return this.showModal;
    },
    showClose() {
      return true;
    },
    documentList() {
      return this.documents;
    },
  },
  mounted() {},
  setup() {
    const i18n = useI18n();

    return {
      i18n,
    };
  },
};
</script>
<style scoped>
.project-rows {
  margin-bottom: 10px;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 10px;
  margin-right: 0px;
  cursor: pointer;
}

.project-col {
  padding-left: 0px;
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
  font-size: 0.875rem;
}
.el-popper {
  z-index: 4000 !important;
}
.modal .modal-huge {
  max-width: 96%;
  width: 450px;
}
.document-content {
  overflow: auto;
  max-height: 284px;
}
</style>
