<template>
  <div class="card">
    <el-table
      class="table-responsive table-flush rounded"
      header-row-class-name="thead-light"
      :data="staffs"
      :default-sort="{ prop: 'last_name', order: 'ascending' }"
      @row-click="goToStaffPage" 
    > <!--@row-click="!checkBoxDisabled ?? goToStaffPage"-->
      <el-table-column min-width="84px" prop="icon">
        <template v-slot="{ row }">
          <div class="media justify-content-center align-items-center">
            <span
              class="avatar avatar-sm bg-success rounded-circle"
              translate="no"
            >
              {{ getInitialsName(row.first_name, row.last_name) }}
            </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :label="i18n.$t('editProfile.form.firstName')"
        min-width="150px"
        prop="first_name"
        sortable
      >
        <template v-slot="{ row }">
          <div class="media align-items-center">
            <div class="media-body">
              <span
                class="
                  font-weight-600
                  name
                  mb-0
                  text-sm text-default text-capitalize
                "
              >
                {{ row.first_name }}
              </span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="i18n.$t('editProfile.form.lastName')" min-width="150px" prop="last_name" sortable>
        <template v-slot="{ row }">
          <div class="media align-items-center">
            <div class="media-body">
              <router-link
                :to="`/staff/profile/${$store.state.user.company_id}/${row.version_no}/${row.user_id}`"
                class="
                  font-weight-600
                  name
                  mb-0
                  text-sm text-default text-capitalize
                "
              >
                {{ row.last_name }}
              </router-link>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :label="i18n.$t('common.role')"
        prop="role_code"
        min-width="140px"
        sortable
      >
        <template v-slot="{ row }">
          <span class="font-weight-600 name mb-0 text-capitalize">
            {{ getRoleI18n(row && row.role_name) }}
          </span>
        </template>
      </el-table-column>

      <el-table-column
        :label="i18n.$t('common.status')"
        min-width="170px"
        prop="is_active"
        sortable
      >
        <template v-slot="{ row }">
          <badge
            class="
              badge-dot
              text-sm-2
              font-weight-600
              badge-md
              d-flex
              align-items-center
            "
            type=""
          >
            <i :class="`bg-${row && row.is_active ? 'success' : 'danger'}`"></i>
            <span class="status text-capitalize font-weight-600">
              {{ getStatusI18n(row && row.is_active) }}
            </span>
          </badge>
        </template>
      </el-table-column>
       <!--PLEASE NOTE: Remove FALSE in the below column, if you want to implement Skills-Activation for a User-->
      <el-table-column v-if="false" :label="i18n.$t('skills.title')" min-width="150px" prop="last_name" sortable>
        <template v-slot="{ row }">
          <div class="media align-items-center">
            <div class="media-body">
              <input type="checkbox"  :disabled="checkboxDisabled" @click="()=> {alert('j')}"/>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="actions" min-width="100px" class-name="text-right">
        <template v-slot="{ row }">
          <el-dropdown
            trigger="click"
            class="dropdown"
            placement="bottom-start"
            v-if="
              row &&
              row.role_code &&
              row.role_code !== 'A' &&
              row.is_active == true &&
              isAccessible(permission.staff_list_action)
            "
          >
            <span class="btn btn-sm btn-icon-only text-light mr-1">
              <i class="fas fa-ellipsis-v mt-2"></i>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <li class="dropdown-item" @click="disconnectUser(row.user_id)">
                  {{ i18n.$t("common.removeUser.header") }}
                </li>
                <li class="dropdown-item" @click="changeRole(row)">
                  {{ i18n.$t("common.roleMng") }}
                </li>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>

    <!-- <div class="card-footer py-4 d-flex justify-content-end">
      <base-pagination v-modal="currentPage" :total="50"></base-pagination>
    </div> -->

    <!-- Remove user modal -->
    <remove-user-modal
      :showModal="models.removeUserModalVisible"
      :loader="loader"
      @close="closeRemoveUserModal"
      @remove="sendRemoveUserAPI"
    ></remove-user-modal>
    <change-role-modal
      v-if="models.changeRoleModalVisible"
      :showModal="models.changeRoleModalVisible"
      :loader="loader"
      :setSelectedRole="models.setSelectedRole"
      @close="closeChangeRoleModal"
      @update="openConfirmModal"
    ></change-role-modal>
    <confirm-change-role-modal
      :showModal="models.confirmChangeRoleModalVisible"
      :loader="loader"
      @close="closeConfirmChangeRoleModal"
      @confirm="sendChangeUserRoleAPI"
    ></confirm-change-role-modal>
  </div>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
} from "element-plus";
import { useToast } from "vue-toastification";
import initialsName from "@/util/initialsName";
import { useI18n } from "@/util/i18n";
import { CHANGEROLEAPI, DISCONNECTUSERAPI } from "@/services/private/Company";
import { has_permission } from "@/services/private/Role_Service";
import { permission_list } from "@/services/_helper/user_permission";
import RemoveUserModal from "./RemoveUserModal.vue";
import ChangeRoleModal from "./ChangeRoleModal.vue";
import ConfirmChangeRoleModal from "./ConfirmChangeRoleModal.vue";

export default {
  name: "light-table",
  props: {
    staffData: Array,
    checkboxDisabled: Boolean
  },
  components: {
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
    RemoveUserModal,
    ChangeRoleModal,
    ConfirmChangeRoleModal,
  },
  data() {
    return {
      loader: false,
      permission: permission_list,
      models: {
        removeUserModalVisible: false,
        removeUserId: "",
        changeRoleModalVisible: false,
        changeRoleUserId: "",
        setSelectedRole: "",
        confirmChangeRoleModalVisible: false,
      },
      currentPage: 1,
    };
  },
  computed: {
    staffs() {
      return this.staffData;
    },
  },
  methods: {
    isAccessible(permission) {
      return has_permission(permission.value);
    },
    getInitialsName(firstName, lastName) {
      return initialsName(`${firstName} ${lastName}`);
    },

    //Remove User functions
    disconnectUser(user_id) {
      this.models.removeUserId = user_id;
      this.models.removeUserModalVisible = true;
    },
    closeRemoveUserModal() {
      this.models.removeUserId = "";
      this.models.removeUserModalVisible = false;
    },
    async sendRemoveUserAPI() {
      const toast = useToast();
      this.models.removeUserModalVisible = false;
      //Call API
      const user_id = this.models.removeUserId;
      const company_id = this.$store.state.user.company_id;
      const data = { user_id, company_id };
      const result = await DISCONNECTUSERAPI(data);
      if (result && result.status === 200) {
        this.closeRemoveUserModal();
        toast.success(this.i18n.$t("staff.removeUser.success"));
        this.$emit("update");
      } else {
        toast.error(this.i18n.$t("staff.removeUser.failed"));
      }
    },

    //Change Role modal
    changeRole(row) {
      this.models.setSelectedRole = row && row.role_code;
      this.models.changeRoleUserId = row && row.user_id;
      this.models.changeRoleModalVisible = true;
    },
    closeChangeRoleModal() {
      this.models.changeRoleUserId = "";
      this.models.changeRoleModalVisible = false;
      this.models.setSelectedRole = "";
      this.models.changeSelectedRole = "";
    },
    openConfirmModal(data) {
      this.models.changeRoleModalVisible = false;
      this.models.setSelectedRole = data;
      this.models.confirmChangeRoleModalVisible = true;
    },

    //Confirm change role modal
    closeConfirmChangeRoleModal() {
      this.models.changeRoleUserId = "";
      this.models.confirmChangeRoleModalVisible = false;
      this.models.setSelectedRole = "";
    },
    async sendChangeUserRoleAPI() {
      const toast = useToast();
      const role_code = this.models.setSelectedRole;
      const user_id = this.models.changeRoleUserId;
      const company_id = this.$store.state.user.company_id;
      const data = { user_id, role_code, company_id };
      const result = await CHANGEROLEAPI(data);
      if (result && result.status === 200) {
        this.closeConfirmChangeRoleModal();
        toast.success(this.i18n.$t("staff.modal.changeRoleConfirm.success"));
        this.$emit("update");
      } else {
        toast.error(this.i18n.$t("staff.modal.changeRoleConfirm.failed"));
      }
    },
    goToStaffPage(row, column) {
      if (
        column &&
        column.property !== "actions" &&
        row &&
        row.company_id &&
        row.version_no &&
        row.user_id
      ) {
        this.$router.push(
          `/staff/profile/${row.company_id}/${row.version_no}/${row.user_id}`
        );
      }
    },
  },
  setup() {
    const i18n = useI18n();
    function getRoleI18n(type) {
      const roleName = type && type.toLowerCase();
      return i18n.$t(`role.${roleName}`);
    }
    function getStatusI18n(status) {
      const findStatus = status === true ? "connected" : "disconnected";
      return i18n.$t(`common.${findStatus}`);
    }
    return { i18n, getRoleI18n, getStatusI18n };
  },
};
</script>
