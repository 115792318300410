<template>
  <div>
    <div class="card card-profile">
      <div class="row justify-content-center">
        <div class="col-lg-3 order-lg-2">
          <div class="card-profile-image">
            <router-link to="/locations">
              <span translate="no">{{ setInitialsName }}</span>
            </router-link>
          </div>
        </div>
      </div>

      <div class="card-body pt-5">
        <div class="row">
          <div class="col">
            <div class="card-profile-stats d-flex justify-content-center">
              <div class="text-center">
                <h5 class="h3">
                  {{ locationData.location_name }}
                </h5>
                <div
                  class="
                    h5
                    font-weight-300
                    d-flex
                    align-items-center
                    justify-content-center
                  "
                  v-if="locationData.location_address_city !== ''"
                >
                  <i class="ni ni-pin-3 mr-1"></i>
                  {{ locationData.location_address_city }} , Deutschland
                </div>
              </div>
            </div>
          </div>
        </div>

        <Form
          @submit="onSubmit"
          :validation-schema="schema"
          v-slot="{ errors }"
        >
          <div class="mb-3">
            <Field
              as="textarea"
              class="form-control"
              :class="{ 'has-danger-border-red': errors.personal_info }"
              name="personal_info"
              :placeholder="i18n.$t('location.detail.form.about.placeholder')"
              v-model="locationData.location_text"
              :disabled="showEditBtn"
              rows="10"
              maxlength="500"
            ></Field>
          </div>
          <div class="text-right">
            <base-button
              type="warning"
              native-type="button"
              v-show="showEditBtn && locationData && !locationData.is_deleted"
              @click="editLocationText"
              class="btn-sm"
            >
              <div class="d-flex align-items-center">
                <span>{{ i18n.$t("editProfile.editInformationUser") }}</span>
              </div>
            </base-button>
            <div v-show="!showEditBtn">
              <base-button type="primary" native-type="submit" class="btn-sm">
                <div class="d-flex align-items-center">
                  <spinner v-show="loader" />
                  <span>{{ i18n.$t("editProfile.saveUserInfo") }}</span>
                </div>
              </base-button>
              <base-button
                type=""
                native-type="button"
                @click="cancelLocationTextEdit"
                class="btn-sm"
              >
                <div class="d-flex align-items-center">
                  <span>{{ i18n.$t("editProfile.discard") }}</span>
                </div>
              </base-button>
            </div>
          </div>
        </Form>
      </div>
    </div>

    <!-- Project Details -->
    <div class="card">
      <div class="card-body pt-5">
        <div class="row align-items-center">
          <div class="col-6">
            <h3 class="mb-0">{{ i18n.$t("location.project.title") }}</h3>
          </div>
          <div class="col-6"></div>
        </div>

        <hr class="my-3" />
        <div class="row">
          <div class="col-12 text-right">
            <div
              class="
                d-flex
                flex-column flex-sm-row
                justify-content-end
                align-items-end
              "
            >
              <base-button
                class="mr-0 mr-sm-2"
                size="sm"
                type="secondary"
                @click="togglePastList(!showPast)"
              >
                {{ displayPastListState }}
              </base-button>
              <base-button
                class="mr-0"
                size="sm"
                type="secondary"
                @click="toggleCanceledList(!showCanceled)"
              >
                {{ displayCanceledListState }}
              </base-button>
            </div>
          </div>

          <div
            class="col-12 project-item mt-3"
            v-for="proj in projectList"
            :key="proj.project_name"
          >
            <div class="row project-rows" @click="openProject(proj)">
              <div class="col-4 project-col">{{ proj.project_name }}</div>
              <div class="col-8">
                <span style="float: right"> {{ proj.start_date }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { useI18n } from "@/util/i18n";
import { useStore } from "vuex";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { useToast } from "vue-toastification";
import initialsName from "@/util/initialsName";
import { UPDATELOCATIONTEXTAPI } from "@/services/private/Location";
import { GETPROJECTBYLOCATIONAPI } from "@/services/private/Project";
import { useRoute, useRouter } from "vue-router";
const initialFilter = {
  is_active: true,
  is_canceled: false,
  is_deleted: false,
};
export default {
  props: {
    locationData: Object,
  },
  components: {
    Form,
    Field,
  },
  data() {
    return {
      projectList: [],
      route: useRoute(),
      router: useRouter(),
      i18n: useI18n(),
      displayPastListState: this.i18n.$t("project.list.showPast"),
      displayCanceledListState: this.i18n.$t("project.list.showCanceled"),
      displayDeletedListState: this.i18n.$t("project.list.showDeleted"),
      showPast: true,
      showCanceled: true,
      showDeleted: true,
      loader: false,
      filterProject: initialFilter,
      createProjectModalVisible: false,
      currentView: "CURRENT",
    };
  },
  computed: {
    setInitialsName() {
      if (this.locationData && this.locationData.location_name) {
        return initialsName(`${this.locationData.location_name}`);
      }
    },
  },
  methods: {
    async getProjectByLocation(view) {
      let location_id = this.route.params.location_id;
      let result = await GETPROJECTBYLOCATIONAPI(location_id, view);
      if (result && result.status == 200 && result.data) {
        this.projectList = result.data.data;
      }
    },
    async togglePastList(state) {
      this.displayCanceledListState = this.i18n.$t("project.list.showCanceled");
      this.displayDeletedListState = this.i18n.$t("project.list.showDeleted");
      this.currentView = state === false ? "PAST" : "CURRENT";
      this.showPast = state;
      this.showCanceled = true;
      this.filterProject = {
        is_active: state,
        is_canceled: false,
        is_deleted: false,
      };
      if (state) {
        this.displayPastListState = this.i18n.$t("project.list.showPast");
      } else {
        this.displayPastListState = this.i18n.$t("project.list.hidePast");
      }
      await this.getProjectByLocation(this.currentView);
    },

    async toggleCanceledList(state) {
      this.displayPastListState = this.i18n.$t("project.list.showPast");
      this.displayDeletedListState = this.i18n.$t("project.list.showDeleted");
      this.currentView = state === false ? "CANCELLED" : "CURRENT";
      this.showCanceled = state;
      this.showPast = true;
      this.filterProject = {
        is_active: !state ? false : true,
        is_canceled: !state ? true : false,
        is_deleted: false,
      };
      if (state) {
        this.displayCanceledListState = this.i18n.$t(
          "project.list.showCanceled"
        );
      } else {
        this.displayCanceledListState = this.i18n.$t(
          "project.list.hideCanceled"
        );
      }
      await this.getProjectByLocation(this.currentView);
    },
    async toggleDeletedList(state) {
      this.displayPastListState = this.i18n.$t("project.list.showPast");
      this.displayCanceledListState = this.i18n.$t("project.list.showCanceled");
      this.currentView = state === false ? "DELETED" : "CURRENT";
      this.showCanceled = state;
      this.showPast = true;
      this.filterProject = {
        is_active: !state ? false : true,
        is_canceled: false,
        is_deleted: !state ? true : false,
      };
      if (state) {
        this.displayDeletedListState = this.i18n.$t("project.list.showDeleted");
      } else {
        this.displayDeletedListState = this.i18n.$t("project.list.hideDeleted");
      }
      await getProjectByLocation(this.currentView);
    },
    openProject(data) {
      let routeData = this.router.resolve({
        name: "ProjectsDetails",
        params: { company_id: data.company_id, id: data.project_id },
      });
      window.open(routeData.href, "_blank");
    },
  },
  mounted() {
    this.getProjectByLocation("CURRENT");
  },
  setup(props, context) {
    const i18n = useI18n();
    const toast = useToast();
    const store = useStore();
    const route = useRoute();
    //let projectList = [];
    let location_id = route.params.location_id;

    let loader = ref(false);
    let showEditBtn = ref(true);
    let showProfileBtn = ref(false);
    let personal_info = ref("");

    // async function getProjectByLocation() {
    //   let result = await GETPROJECTBYLOCATIONAPI(location_id, "current");
    //   if (result && result.status == 200 && result.data) {
    //     projectList = result.data.data;
    //   }
    //   console.log(projectList);
    // }

    // onMounted(async () => {
    //   await getProjectByLocation();
    // });

    async function onSubmit() {
      loader.value = true;

      const data = {
        location_text: props.locationData.location_text,
      };
      const result = await UPDATELOCATIONTEXTAPI(location_id, data);
      loader.value = false;
      if (
        result.error &&
        result.response &&
        result.response.data &&
        result.response.data.status === 400
      ) {
        toast.error(i18n.$t("location.locationText.errorMessage"));
      } else if (result && result.status === 200) {
        toast.success(i18n.$t("location.locationText.savedMessage"));
        showEditBtn.value = true;
        context.emit("update");
      } else {
        toast.error(i18n.$t("location.locationText.errorMessage"));
      }
    }
    function editLocationText() {
      showEditBtn.value = false;
      //showProfileBtn.value = false;
    }
    function cancelLocationTextEdit() {
      showEditBtn.value = true;
      // showProfileBtn.value = true;
    }
    const schema = Yup.object().shape({
      personal_info: Yup.string().required().max(500),
    });

    return {
      i18n,
      schema,
      showEditBtn,
      showProfileBtn,
      loader,
      personal_info,
      onSubmit,
      editLocationText,
      cancelLocationTextEdit,
      // getProjectByLocation,
      //projectList,
    };
  },
};
</script>
<style scoped>
.project-rows {
  margin-bottom: 10px;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
}
.project-item {
  padding-right: 27px;
  padding-left: 27px;
}
.project-col {
  font-weight: bold;
}
</style>

