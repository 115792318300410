import axios from '../Axios';
import {
  GETUSERDEVICE,
} from '../CONSTANTURL';

export const GETUSERDEVICEAPI = async () => {
  try {
    const result = await axios.get(`${GETUSERDEVICE}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};