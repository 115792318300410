import * as axios from "axios";
import { BASEURL } from "./CONSTANTURL";

let instance = axios.create();

const APIPATH = `${BASEURL}`;
instance.defaults.baseURL = APIPATH;
instance.defaults.timeout = 20000;
instance.defaults.withCredentials = true;
instance.defaults.headers = {
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json",
};
instance.interceptors.request.use(async (config) => {
  config.headers = {
    "x-access-token": localStorage.getItem("access_token") || "",
  };
  return config;
});
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { instance as default };
