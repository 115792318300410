import axios from '../Axios';
import {
  GETALLSKILLS,
  ADDNEWSKILL,
  DELETESKILL,
  GETUSER,
  UNLOCKUSER,
  DELETEUSER,
  GETSKILLIDBYUSER,
  GETCLOUDIDBYSKILL,
  GETPUPLICCLOUDIDBYSKILL,
  GETSKILLWATCHED,
  SETSKILLWATCHED,
  GETSKILLUNWATCHED,
  GETSKILLIDBYCOMPANY
} from '../CONSTANTURL';

export const GETALLSKILLSAPI = async (company_id) => {
  try {
    const result = await axios.get(`${GETALLSKILLS}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const ADDNEWSKILLAPI = async (data) => {
  try {
    const result = await axios.post(`${ADDNEWSKILL}`, data);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const DELETESKILLAPI = async (data) => {
  try {
    const result = await axios.delete(`${DELETESKILL}/${data}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const GETUSERAPI = async (skill_id) => {
  try {
    const result = await axios.get(`${GETUSER}/${skill_id}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const UNLOCKUSERAPI = async (data) => {
  try {
    const result = await axios.post(`${UNLOCKUSER}`, data);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const DELETEUSERAPI = async (data) => {
  try {
    const result = await axios.delete(`${DELETEUSER}`, {data: data});
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const SETSKILLWATCHEDAPI = async (skill_id, user_id) => {
  try {
    const result = await axios.post(`${SETSKILLWATCHED}/${skill_id}/${user_id}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const GETSKILLWATCHEDAPI = async (user_id) => {
  try {
    const result = await axios.get(`${GETSKILLWATCHED}/${user_id}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const GETALLSKILLSWATCHEDAPI = async () => {
  try {
    const result = await axios.get(`${GETSKILLWATCHED}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const GETALLSKILLSUNWATCHEDAPI = async () => {
  try {
    const result = await axios.get(`${GETSKILLUNWATCHED}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const GETSKILLIDBYUSERAPI = async (data) => {
  try {
    const result = await axios.get(`${GETSKILLIDBYUSER}/${data}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const GETCLOUDIDBYSKILLAPI = async (data) => {
  try {
    const result = await axios.get(`${GETCLOUDIDBYSKILL}/${data}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const GETPUPLICCLOUDIDBYSKILLAPI = async (data) => {
  try {
    const result = await axios.get(`${GETPUPLICCLOUDIDBYSKILL}/${data}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const GETSKILLIDBYCOMPANYIDAPI = async (data) => {
  try {
    const result = await axios.get(`${GETSKILLIDBYCOMPANY}/${data}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
}