import axios from '../Axios';
import { SENDPUSHNOTIFICATION } from '../CONSTANTURL';
export const SENDPUSHNOTIFICATIONAPI = async (data) => {
  try {
    const result = await axios.post(`${SENDPUSHNOTIFICATION}`, data);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};
