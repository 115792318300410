export default {
  "message": {
    "wrong": "Something went wrong!",
    "userNotFound": "User not found",
    "pwdNote": "Your password must be at least 8 characters, a special character, one uppercase letter, and one lowercase letter.",
    "passwordChange": "Your password change successfully",
    "permission": "You don't have permission to access this system",
    "ConnectedCompanySuccess": "You are now connected to company",
    "fileRequired": "File is required",
    "cancel": "Abort",
    "invalidMobileNo": "Invalid phone number format",
    "noDeviceToken": "Cannot receive push notifications"
  },
  "modal": {
    "connectNetwork": {
      "title": "Connect to Company",
      "subTitle": "Would you like to connect to company",
      "NoPermissionMessage": "You don't have permission to connect with this company",
      "AlreadyConnectedMessage": "You are already connected with the company"
    }
  },
  "nav": {
    "search": "Search",
    "profile": "My profile",
    "settings": "Settings",
    "password": "Change Password",
    "logout": "Logout",
    "readAll": "Clear all"
  },
  "sidebarNav": {
    "dashboard": "Dashboard",
    "project": "Project",
    "network": "Network",
    "personal": "Employees",
    "location": "Location",
    "myskills": "My Skills",
    "skills": "Skills",
  },
  "role": {
    "admin": "Admin",
    "dispatch": "Dispatch",
    "worker": "Worker",
    "contractor": "Contractor",
    "freelancer": "Freelancer"
  },
  "notification": {
    "firstText": "You have",
    "secondText": "notifications",
  },
  "logout": {
    "header": "Signing off",
    "subHeader": "Do you really want to log off?",
    "smallText": "A small river named Duden flows by their place and supplies it with thy necessary regelialia",
    "button": {
      "logout": "Sign out",
      "cancel": "Abort"
    }
  },
  "common": {
    "status": "Status",
    "role": "Role",
    "email": "E-mail",
    "pwd": "Password",
    "loading": "Loading...",
    "checking": "Please Wait...",
    "sending": "Sending...",
    "date": "Date",
    "footer": {
      "data": "Data protection",
      "imprint": "Imprint",
      "cookie": "Cookie Directive (EU)"
    },
    "link": {
      "about": "About Us",
      "contact": "Contact"
    },
    "add": "Add",
    "connected": "Connected",
    "disconnected": "Disconnected",
    "removeUser": {
      "header": "User entfernen",
      "subHeader": "",
    },
    "disConnectUser": "Disconnect User",
    "remove": "Remove",
    "roleMng": "Role management",
    "accessUnAuthPageInAuth": {
      "header": "Please logout",
      "message": "You are trying to access public pages, please make sure you logout first to perform action"
    },
    "showActiveUsers": "Show Former Employees",
    "hideInactive": "Hide Former Employees",
    "ShowFormerEmployees": "Show Former Employees",
    "hideFormerEmployees": "Hide Former Employees",
    "canceledProject": "Canceled Project",
    "deleteProject": "Delete Project",
    "projects": "Projects",
    "information": "Information",
    "noStaff": "No Staff"
  },
  "registerOptions": {
    "header": "Register yourself",
    "subHeader": "Use these awesome forms to login or create new account in your project for free.",
    "form": {
      "header": "Register",
      "subHeader":"How would you like to register yourself?",
      "input": {
        "emailPassword": "Use E-Mail und Password",
        "code": "Use registration code",
      },
    }
  },
  "registerRegistrationCode": {
    "header": "Register yourself",
    "subHeader": "Use these awesome forms to login or create new account in your project for free.",
    "form": {
      "header": "Register",
      "subHeaderRequest":"Request register code",
      "subHeaderSendMail":"Your code has been send to your E-Mail",
      "subHeaderSendPhone":"Your code has been send to your Phone",
      "button":{
        "useEmail":"Use E-Mail",
        "usePhoneNumber":"Use Phonenumber",
        "requestCode":"Request Code",
        "register":"Register",
        "requestNewCode":"Request new Code",
      },
      "checkbox": {
        "textStart": "I agree with the",
        "link": "Privacy policy",
        "textAnd":"",
        "link2":"",
        "textEnd": ""
      },
      "input": {
        "emailPassword": "Use E-Mail und Password",
        "code": "Use registration code",
        "enterCode":"Enter code",
        "enterEmail":"Enter E-Mail",
        "phone": "Phone Number"
      },
    }
  },
  "registration": {
    "header": "Register yourself",
    "subHeader": "Use these awesome forms to login or create new account in your project for free.",
    "form": {
      "header": "Registration",
      "subHeader":"Create a Password",
      "input": {
        "firstName": "First Name",
        "lastName": "Last Name",
        "email": "E-Mail",
        "emailRepeat": "Repeat E-Mail",
        "pwd": "Password",
        "pwdRepeat": "Repeat Password",
        "checkbox": {
          "textStart": "I agree with the",
          "link": "Privacy policy",
          "textEnd": ""
        }
      },
      "sameEmail": "E-mail is already registered in the system",
      "sameMobileNo": "Phonenumber is already registered in the system",
      "submit": "Register"
    }
  },
  "registerSuccess": {
    "header": "E-mail sent",
    "subHeader": "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
  },
  "registerSuccessLinkRequired": {
    "header": "One last step!",
    "subHeader": "Your employer still needs to link your account. Then you're ready to go. See you soon!"
  },
  "registerSuccessNetwork": {
    "header": "E-mail versendet",
    "subHeader": "Please contact Administrator for appropriate rights and then Verify link."
  },
  "registerSuccessGoToLogin": {
    "header": "Register success",
    "subHeader": "Now you can log in to our platform on the login page with your telephone number and the verification code."
  },
  "activation": {
    "header": "Email successfully confirmed",
    "subHeader": "You will be automatically redirected to our login page. If this doesn't happen within 10 seconds, click",
    "subHeaderLink": "here",
    "conflict": "Your account is already activated"
  },
  "expireActivation": {
    "header": "Activation link is",
    "header2": "expired",
    "subHeader": "Unfortunately your activation link has expired. You can create a new activation link. Click",
    "subHeaderLink": "here"
  },
  "login": {
    "header": "Welcome",
    "subHeader": "Use these awesome forms to login or create new account in your project for free.",
    "form": {
      "header": "Login",
      "checkbox": "Remember me",
      "submit": "Log In"
    },
    "link": {
      "forgotPass": "Forgot password",
      "registration": "Register yourself"
    },
    "loginError": "Email and password do not match!",
    "activateAccount": "Your account is not yet activated or deactivated. Click on the activation email link to activate your account."
  },
  "forgotPassword": {
    "header": "Forgot Password",
    "subHeader": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    "form": {
      "header": "We will send you an email to reset your password",
      "submit": "Send e-mail"
    },
    "forgotPasswordSuccess": {
      "header": "Email sent",
      "subHeader": "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    }
  },
  "resetPassword": {
    "header": "New password",
    "subHeader": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    "form": {
      "header": "reset password",
      "submit": "Save new password",
      "button": "Request an email"
    },
    "expired": {
      "message": "Your password reset link has expired. Generate link by clicking below on forgot password link.",
    },
    "success": {
      "header": "E-Mail sent",
      "subHeader": "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },
    "modal": {
      "header": "Would you like to change your password?",
      "subText": "Click on new password, you will receive a message from us at your email address and you can set a new password",
      "button": "E-Mail anfordern"
    }
  },
  "editProfile": {
    "profile": "Profile",
    "personalInformation": "Personal Information",
    "hello": "Hello",
    "description": "This is your profile page. You can see the progress you've made with your work and manage your projects or assigned tasks",
    "form": {
      "firstName": "First Name",
      "lastName": "Last Name",
      "telePhoneNumber": "Phone Number",
      "birthDay": "Date of birth",
      "gender": "Gender",
      "married": "Marital Status",
      "street": "Street",
      "house": "House Number",
      "streetAndHouseNumber": "Street and House Number",
      "postCode": "Postal Code",
      "city": "City",
      "country": "Country",
      "error": {
        "mobile": "No special characters allowed. Please only use numbers."
      }
    },
    "address": "Address",
    "editInformation": "Edit Information",
    "editInformationUser": "Edit text",
    "saveUserInfo": "Save text",
    "save": "Save",
    "discard": "Discard",
    "infoSaved": "Profile information saved",
    "personalInfo": {
      "placeholder": "Tell me something about you!",
      "error": "Profile info is required"
    },
    "gender": {
      "header": "Select gender",
      "M": "Male",
      "F": "Female",
      "O": "Other",
    }
  },
  "staff": {
    "header": "Own Staff",
    "removeUser": {
      "success": "The user has been disconnected from company successfully",
      "failed": "Failed to a disconnected user from company"
    },
    "modal": {
      "changeRole": {
        "header": "Change role",
        "subHeader": "Lorem ipsum",
        "question": "What role would you like to give?",
        "confirm": "Assign role",
        "error": "Please select any one role",
      },
      "changeRoleConfirm": {
        "header": "Are you sure?",
        "subHeader": "Lorem ipsum",
        "success": "User role changed successfully",
        "failed": "Failed to change the role"
      },
      "invitationLink": {
        "header": "Create an invitation link",
        "subHeader": "invitation Link Lorem ipsum",
        "networkSubHeader": "invitation Link Lorem ipsum",
        "createLink": "Create link",
        "question": "Who would you like to invite?",
        "header1": "Invitation link for employees",
        "subHeader1": "invitation Link Lorem ipsum",
        "networkSubHeader1": "invitation Link Lorem ipsum",
        "linkCopy": "Copy link",
        "shareLink": "Share your link",
        "shareLinkContent": "Share your following link Todo",
        "link": "Link"
      },
      "sendMessage": {
        "header": "Send Notification",
        "continue": "Continue to Message",
        "title": "Title",
        "message": "Message",
        "send": "Send",
        "cancel": "Cancel",
        "success": "Message sent successfully",
        "failed": "Failed to send message",
        "titleRequired": "Title is required",
        "noUsersSelected": "At least one user must be selected",
        "back": "Back",
        "filter": {
          "project": "Project",
          "shift": "Shift",
          "planingStatus": "Planning Status",
          "skills": "Skills"
        }
      }

    }
  },
  "adminEmployee": {
    "header": "XY wants to connect with you",
    "subHeader": "Lorem",
    "newHere": "New here?",
    "accountYet": "You don't have a peeepl account yet?",
    "clickHere": "Then click here",
    "alreadyMember": "Already member?",
    "doYouAlreadyMember": "Do you already have a peeepl account?",
    "expire": "The link you trying to join a company is expired. Please contact a company admin to generate a link.",
    "roleCreated": "User role has been created successfully"
  },
  "network": {
    "pageTitle": "Network",
    "header": "XY wants to connect with you",
    "subHeader": "Lorem",
    "Table": {
      "Favorite": "Favorite",
      "Initials": "Initials",
      "Name": "Name",
      "Status": "Status"
    },
    "message": {
      "AddToFavorites": "Added to favorites",
      "RemovedFromFavorites": "Removed from favorites",
      "LinkCopySuccess": "Link copied successfully"
    },
    "showActiveNetwork": "Show Former Network",
    "hideInactive": "Hide Former Employees",
    "ShowFormerNetwork": "Show Former Network",
    "hideFormerNetwork": "Hide Former Network",
    "modal": {
      "disconnect": {
        "header": "Remove service provider",
        "subHeader": "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.",
      },
      "disconnectConfirm": {
        "header": "Are you sure",
        "subHeader": "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.",
      }
    },
    "companyProfile": {
      "title": "Company Profile",
      "basicInfo": "Basic information",
      "companyName": "Company Name",
      "companyType": "Company Type",
      "companyAddress": "Company Address",
      "companyEmail": "Company Email",
      "companyPhone": "Company Phone",
    }
  },
  "project": {
    "PageTitle": "Project overview",
    "createProject": "Create Project",
    "createProjectSubHeader": "There are many variations of passages of Lorem Ipsum available",
    "Table": {
      "Name": "Name",
      "company": "Company",
      "location": "Location"
    },
    "list": {
      "showPast": "Show completed projects",
      "hidePast": "Hide completed projects",
      "showCanceled": "Show canceled projects",
      "hideCanceled": "Hide canceled projects",
      "showDeleted": "Show deleted projects",
      "hideDeleted": "Hide deleted projects",
    },
    "projectManagerModal": {
      'title': 'Project Managenment',
      'noRecordError': 'Information is not available'
    },
    "status": {
      "created": "Project created",
      "planning": "In planning",
      "ready": "Personnel planning done",
      "ongoing": "Has begun",
      "completed": "Completed"
    },
    "modal": {
      "header": {
        "canceledProject": "Cancel Project",
        "deleteProject": "Delete Project",
        "deleteShift": "Delete Shift"
      },
      "subHeader": {
        "deleteShift": "Do you really want to delete the shift?"
      },
      "form": {
        "project_name": "Project name",
        "location": "Location",
        "start_date": "Date from",
        "end_date": "Date to",
        "start_time": "Time",
        "end_time": "Time",
      }
    },
    "response": {
      "created": "Project created successfully",
      "failed": "Project could not be created"
    }
  },
  "projectDetails": {
    "formLabel": {
      "start_date": "Start Date",
      "end_date": "End Date",
      "start_time": "Start Time",
      "end_time": "End Time",
      "totalPeople": "Number of visitors",
      "status": "Status",
      "notes": "Space for notes. Information written here can be viewed by everyone invited or booked to the project.",
    },
    "editProject": "Edit project",
    "people": "People",
    "document": "Documents",
    "newDocument": "New Document",
    "timeValidation": "Project start time should not be greater than shift time or end time can not be less than the shift end time",
    "infoSaved": "Project information saved successfully",
    "failedToSave": "Failed to update Project information",
    "SomethingWentWrong": "Something went wrong",
    "projectDeletedMsg": "Project is already Deleted",
    "projectNoAccessMsg": "You don't have access",
    "shifts": {
      "th": {
        "shift": "Shift",
        "date": "Date",
        "time": "Time",
        "discipline": "Discipline",
        "status": "Status",
        "personal": "Personal",
        "documents": "Documents",
        "information": "Information"
      },
      "heading": {
        "ownShift": "Own Shift",
        "personnelPlanning": "Personnel Planning"
      },
      "buttons": {
        "scheduleStaff": "Schedule Staff",
        "editShift": "Edit Shift",
        "addShift": "Add Shift",
        "addProjectManagement": "Add Project Management",
        "deleteShift": "Delete Shift"
      },
      "requestedUser": "Requested Staff",
      "availableUser": "Available Staff",
      "scheduledUser": "Scheduled Staff",
      "user_requested": "Requested",
      "user_assigned": "Assigned",
      "user_tentative": "Maybe",
      "user_perhaps": "Maybe",
      "user_available": "Accessible",
      "user_open": "Open",
      "user_unavailable": "Rejected",
      "user_rejected": "Rejected",
      "user_added": "Planned",
      "ic_request_open": "Requested",
      "ic_request_unavailable": "Rejected",
      "ic_request_tentative": "Maybe",
      "ic_user_requested": "Requested",
      "ic_user_assigned": "Assigned",
      "ic_user_tentative": "Maybe",
      "ic_user_perhaps": "Maybe",
      "ic_user_available": "Accessible",
      "ic_user_open": "Open",
      "ic_user_unavailable": "Rejected",
      "ic_user_rejected": "Rejected",
      "ic_user_added": "Planned",
      "ic_user_mapped": "Planned",
      "ic_open_perhaps": "Open Maybe",
      "ic_open_rejected": "Rejected",
      "company_requested": "Requested",
      "company_assigned": "Assigned",
      "company_tentative": "Maybe",
      "company_perhaps": "Maybe",
      "company_available": "Accessible",
      "company_open": "Open",
      "company_unavailable": "Rejected",
      "company_rejected": "Rejected",
      "company_added": "Planned",
      "notPlanned": "Not Planned",
      "showTheMessage": "Show The Message",
      "forms": {
        "createTitle": "Create Shift",
        "updateTitle": "Update Shift",
        "description": "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.",
        "shift_name": "Shift Name",
        "discipline": "Discipline",
        "noOfpeople": "No Of People",
        "information": "Information"
      },
      "response": {
        "created": "Project shift created successfully",
        "failed": "Project shift could not be created",
        "updated": "Project shift updated successfully",
        "failedUpdated": "Project shift could not be updated",
        "mayBeResponse": "Your feedback may have been sent successfully",
        "noPositiveResponse": "Please submit a response with YES before planning user",
        "multipleUpdateCheck": "Minimum one check is required to update multiple.",
        "shiftDeleted": "Shift successfully deleted",
        "shiftFailedDeleted": "Shift could not be deleted",
      },
      "actions": {
        "removeUser": "Remove",
        "rebook": "Re-book"
      },
      "modal": {
        "maybeHeader": "You are not sure yet",
        "maybeSubHeader": "Lorem ipsum",
        "maybeButton": "Send",
        "rejectedHeader": "Decline",
        "rejectedSubHeader": "Lorem ipsum",
        "rejectedButton": "Decline",
        "confirmRejectedHeader": "Are you sure?",
        "confirmRejectedSubHeader": "Lorem ipsum",
        "availableHeader": "Accept",
        "availableSubHeader": "Lorem ipsum",
        "availableButton": "Accept",
        "confirmAvailableHeader": "Are you sure?",
        "confirmAvailableSubHeader": "Lorem ipsum",
        "confirmRemoveHeader": "Are you sure?",
        "confirmRemoveSubHeader": "Lorem ipsum",
        "IC": {
          "maybeHeader": "You are not sure yet",
          "maybeSubHeader": "Lorem ipsum",
          "maybeButton": "Send",
          "rejectedHeader": "Decline",
          "rejectedSubHeader": "Lorem ipsum",
          "rejectedButton": "Decline",
          "confirmRejectedHeader": "Are you sure?",
          "confirmRejectedSubHeader": "Lorem ipsum",
          "availableHeader": "Accept",
          "availableSubHeader": "Lorem ipsum",
          "availableButton": "Accept",
          "confirmAvailableHeader": "Are you sure?",
          "confirmAvailableSubHeader": "Lorem ipsum",
          "confirmRemoveHeader": "Are you sure?",
          "confirmRemoveSubHeader": "Lorem ipsum",
        },
        "OC": {
          "maybeHeader": "You are not sure yet",
          "maybeSubHeader": "Lorem ipsum",
          "maybeButton": "Send",
          "rejectedHeader": "Decline",
          "rejectedSubHeader": "Lorem ipsum",
          "rejectedButton": "Decline",
          "confirmRejectedHeader": "Are you sure?",
          "confirmRejectedSubHeader": "Lorem ipsum",
          "availableHeader": "Accept",
          "availableSubHeader": "Lorem ipsum",
          "availableButton": "Accept",
          "confirmAvailableHeader": "Are you sure?",
          "confirmAvailableSubHeader": "Lorem ipsum",
          "confirmRemoveHeader": "Are you sure?",
          "confirmRemoveSubHeader": "Lorem ipsum",
        }
      },
      "swal": {
        "updateUserRequest": "Das {shift_name} wurde erfolgreich studiert! (updateUserRequest)", // for LightTableUser.vue
        "updateICShiftRequest": "Das {shift_name} wurde erfolgreich studiert! (updateICShiftRequest)", // for LightTableUser.vue
        "IC": {
          "updateUserRequest": "Die Rückmeldung Offen wurde übermittelt.", // for ICShiftStaff.vue & ICShiftDetails.vue
          "updateICShiftRequest": "Das {shift_name} wurde erfolgreich studiert! (IC.updateICShiftRequest)", // for ICShiftDetails.vue
          "updateConfirm": "Du hast Dich für die Schicht verfügbar gemeldet.",
          "updateRejected": "Du hast die Schicht abgelehnt.",
          "updateMaybe": "Die Rückmeldung vielleicht wurde übermittelt.",
          "updateUserAvailable": "Du hast Dich für die Schicht verfügbar gemeldet.",
          "updateUserMapped" : "Du hast den Nutzer erfolgreich eingeplant",
          "updateUserTentative" : "Die Rückmeldung vielleicht wurde übermittelt.",
          "updateUserUnavailable" : "Du hast die Schicht abgelehnt.",
          "updateUserRejected" : "Du hast den Nutzer abgelehnt.",
        },
        "OC": {
          "updateUserRequest": "Das {shift_name} wurde erfolgreich studiert! (OC.updateUserRequest)", // for ShiftDetails.vue
          "updateConfirmAvailableModalVisible": "Du hast Dich für die Schicht verfügbar gemeldet.",
          "updateConfirmRejectedModalVisible": "Du hast die Schicht abgelehnt.",
          "updateMaybeModalVisible": "Die Rückmeldung vielleicht wurde übermittelt.",
          "updateConfirm": "Du hast Dich für die Schicht verfügbar gemeldet.",
          "updateRejected": "Du hast die Schicht abgelehnt.",
          "updateMaybe": "Die Rückmeldung vielleicht wurde übermittelt.",
        },
      }
    },
    "documents": {
      "listDocPopupTitle": "Document List"
    },
    'shiftRequest': {
      "titleModal": "SCHEDULE PERSONNEL",
      "userTab": "Own staff",
      "networkTab": "Service Provider",
      "selectAll": "Select All",
      "selectNumber": "Select Number",
      "addressLabel": "Address",
      "assignBtn": "Assign",
      "requestBtn": "Request",
      "userRequestSuccess": "User request sent successfully",
      "uerAssignedSuccess": "User assigned successfully",
      "pleaseSelectUser": "Please select user",
      "status": {
        "user_requested": "Requested",
        "user_assigned": "Assigned",
        "user_tentative": "Maybe",
        "user_perhaps": "Maybe",
        "user_available": "Accessible",
        "user_open": "Open",
        "user_unavailable": "Rejected",
        "user_rejected": "Rejected",
        "user_added": "Planned",
        "ic_user_requested": "Requested",
        "ic_user_assigned": "Assigned",
        "ic_user_tentative": "Tentative",
        "ic_user_perhaps": "Perhaps",
        "ic_user_available": "Available",
        "ic_user_open": "Open",
        "ic_user_unavailable": "Unavailable",
        "ic_user_rejected": "Rejected",
        "ic_user_added": "Added",
        "ic_open_perhaps": "Open Maybe",
        "ic_open_rejected": "Rejected",
        "company_requested": "Requested",
        "company_assigned": "Assigned",
        "company_tentative": "Tentative",
        "company_perhaps": "Perhaps",
        "company_available": "Available",
        "company_unavailable": "Unavailable",
        "company_added": "Added",
        "company_open": "Open",
        "company_rejected": "Rejected",
      },
      "label": {
        "contactPerson": "Contact Person",
        "companyAddress": "Address",
        "companyEmail": "Email",
        "companyPhone": "Phone Number",
      },
      "shiftRequestSuccess": "Shift request status updated",
      "shiftRequestFailed": "Failed to update Shift request status",
      "moreRequestFailed": "You cannot request more users",
      "scheduleICRow": "You have not yet requested any staff. First react to the requested positions and ask your staff for it.",
      "noMoreAddUserToShift": "You cannot add more users to shift"
    },
    "removeBookedUser": {
      "removeHeader": "Are you sure?",
      "removeSubHeader": "Lorem ipsum",
      "responseHeader": "Removed user",
      "responseSuccess": "You have successfully remove booked user",
      "responseFailed": "Failed to remove booked user"
    }
  },
  "location": {
    "title": "Locations",
    "list": {
      "addLocation": "Add Location",
      "showDeletedLocation": "Show Deleted Location",
      "hideDeletedLocation": "Hide Deleted Location"
    },
    "table": {
      "name": "Name",
      "address": "Address"
    },
    "add": {
      "title": "Add Location",
      "subTitle": "Todo",
      "locationName": "Location Name",
      "street": "Street",
      "houseNumber": "House Number",
      "postalCode": "Postal Code",
      "city": "City",
      "addButton": "Add Location",
      "successMessage": "Added location successfully",
      "failMessage": "Something went wrong"
    },
    "delete": {
      "title": "Location Delete?",
      "subTitle": "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.",
      "confirmDeleteTitle": "Are you sure?",
      "confirmDeleteSubTitle": "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.",
      "successDeleteMessage": "Document deleted successfully",
      "failDeleteMessage": "Deleting Document failed",
      "successDeleteLocationMessage": "Location deleted successfully",
      "failDeleteLocationMessage": "Deleting location failed",
    },
    "detail": {
      "titlePage": "Location",
      "subHeader": "Location profile sub header text field is the same as user profile.",
      "locationInformation": "Location Information",
      "updateSuccessMsg": "Location Updated Successfully",
      "editButton": "Edit Location",
      "form": {
        "name": "Name",
        "website": "Website",
        "street": "Street",
        "houseno": "House No",
        "postalcode": "Postal Code",
        "city": "City",
        "contactPerson": "Contact Person",
        "contactName": "Name",
        "contactTeleNo": "Telephone No",
        "contactEmail": "E-Mail",
        "alternateTelNo": "Alternate Telephone No",
        "about": {
          "placeholder": "Tell us something about location!",
        },
      }
    },
    "locationDocument": {
      "titleInformation": "Information",
      "newDocumentBtn": "New Document",
      "docDeleteTitle": "Document Delete?",
      "docDeleteSubHeader": "Lorem ipsum",
      "newDocumentTitle": "New Document",
      "documentName": "Document Name",
      "uploadModalBtn": "Document Upload",
      "uploadDocSuccess": "Document uploaded successfully",
      "errorPdfAllow": "Only pdf file are allowed"
    },
    "locationText": {
      "savedMessage": "Location text saved successfully",
      "errorMessage": "Speichern des Standorttexts fehlgeschlagen"
    },
    "project": {
      "title": "Project"
    }

  },
  "skills": {
    "title": "Skills",
    "titleModal": "Unlock Skill",
    "list": {
      "addSkills": "Hinzufügen",
      "showDeletedSkills": "Archiv",
      "hideDeletedSkills": "Aktive anzeigen",
      "editSkills": "Edit skills"
    },
    "table": {
      "name": "Name",
      "address": "Adresse"
    },
    "add": {
      "title": "Hinzufügen",
      "subTitle": "Erstelle eine neue Skills um ein Projekt damit zu verknüpfen",
      "SkillsName": "Name",
      "street": "Straße",
      "houseNumber": "Hausnummer",
      "postalCode": "Postleitzahl",
      "city": "Stadt",
      "addButton": "Hinzufügen",
      "successMessage": "Skills erfolgreich hinzugefügt",
      "failMessage": "Etwas hat nicht geklappt. Bitte versuche es erneut!"
    },
    "delete": {
      "title": "Archivieren?",
      "subTitle": "Wenn Du die Skills entfernst kannst Du die darin gespeicherten Informationen nicht mehr bearbeiten. Kommende Projekte die mit der Location verknüpft sind müssen einer neuen Location zugewiesen werden.",
      "confirmDeleteTitle": "Bist Du sicher?",
      "confirmDeleteSubTitle": "Diese Aktion kann nicht widerrufen werden.",
      "successDeleteMessage": "Dokument wurde erfolgreich gelöscht",
      "failDeleteMessage": "Dokument konnte nicht gelöscht werden",
      "successDeleteSkillsMessage": "Skills wurde erfolgreich gelöscht",
      "failDeleteSkillsMessage": "Löschen der Skills fehlgeschlagen"
    },
    "detail": {
      "titlePage": "Profil",
      "subHeader": "Hier kannst Du Deine Skills verwalten und bearbeiten.",
      "skillsInformation": "Informationen",
      "updateSuccessMsg": "Skills erfolgreich aktualisiert",
      "editButton": "Informationen bearbeiten",
      "form": {
        "name": "Name",
        "website": "Website",
        "street": "Straße",
        "houseno": "Hausnummer",
        "postalcode": "Postleitzahl",
        "city": "Stadt",
        "contactPerson": "Ansprechpartner",
        "contactName": "Name",
        "contactTeleNo": "Telefonnummer",
        "contactEmail": "E-Mail",
        "alternateTelNo": "Alternative Telefonnummer",
        "about": {
          "placeholder": "Hier ist Platz für Deine Anmerkungen!",
        },
      }
    },
    "SkillsDocument": {
      "titleInformation": "Dokumente",
      "newDocumentBtn": "Neues Dokument",
      "docDeleteTitle": "Dokument löschen?",
      "docDeleteSubHeader": "Bist Du sicher, dass Du das Dokument entfernen möchtest?",
      "newDocumentTitle": "Neues Dokument",
      "documentName": "Dokumentname",
      "uploadModalBtn": "Dokument hochladen",
      "uploadDocSuccess": "Dokument erfolgreich hochgeladen",
      "errorPdfAllow": "Nur PDF-Dateien sind erlaubt"
    },
    "SkillsText": {
      "savedMessage": "Skills Beschreibung erfolgreich gespeichert",
      "errorMessage": "Speichern der Skills Beschreibung fehlgeschlagen",
    },
    "SkillsView": {
      "swipeDeclined": "Please watch the video to the end.",
      "swipeDeclinedLastVideo": "This is the last video!"
    },
    "project": {
      "title": ""
    }
  }
};
