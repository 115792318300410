<template>
  <div class="wrapper">
    <!-- <notifications></notifications> -->
    <side-bar>
      <template v-slot:links>
        <sidebar-item
          v-for="item in menu_item_permiited"
          :key="item.hasPermission"
          :link="item.item"
        />

        <!-- <div class="copyright text-center text-lg-left text-muted">
          © {{ year }}
          <a
            href="https://www.creative-tim.com"
            class="font-weight-bold ml-1 "
            target="_blank"
            >peeepl UG(haftungsbeschrankt)</a
          >
        </div> -->
        <!-- <div>
          <ul
            class="nav nav-footer justify-content-center justify-content-lg-end"
          >
            <li class="nav-item">
              <a
                href="#"
                style="text-align: right"
                class="font-weight-bold nav-link"
                target="_blank"
                >© {{ year }} peeepl UG (haftungsbeschränkt)
              </a>
            </li>
            <li class="nav-item">
              <a href="#" class="nav-link" target="_blank">peeepl</a>
            </li>
            <li class="nav-item">
              <a href="#" class="nav-link" target="_blank">{{
                i18n.$t("common.footer.data")
              }}</a>
            </li>
            <li class="nav-item">
              <a href="#" class="nav-link" target="_blank">{{
                i18n.$t("common.footer.imprint")
              }}</a>
            </li>
            <li class="nav-item">
              <a href="#" class="nav-link" target="_blank">{{
                i18n.$t("common.footer.cookie")
              }}</a>
            </li>
          </ul>
        </div> -->
        <!-- <sidebar-item
          v-if="isAccessible('menu_dashboard')"
          :link="{
            name: getLinkName('dashboard'),
            icon: 'ni ni-shop text-green',
            path: '/dashboard',
          }"
        />
        <sidebar-item
          v-if="isAccessible('menu_projects')"
          :link="{
            name: getLinkName('project'),
            icon: 'ni ni-folder-17 text-green',
            path: '/projects',
          }"
        />
        <sidebar-item
          v-if="isAccessible('menu_network')"
          :link="{
            name: getLinkName('network'),
            icon: 'ni ni-world text-green',
            path: '/network',
          }"
        />
        <sidebar-item
          v-if="isAccessible('menu_staff')"
          :link="{
            name: getLinkName('personal'),
            icon: 'ni ni-single-02 text-green',
            path: '/staff',
          }"
        />
        <sidebar-item
          v-if="isAccessible('menu_locations')"
          :link="{
            name: getLinkName('location'),
            icon: 'ni ni-map-big text-green',
            path: '/locations',
          }"
        /> -->
      </template>

      <template v-slot:links-after>
        <div id="footer-links" class="footer-links-show navbar-inner">
          <ul class="nav navbar-nav nav-footer justify-content-center">
            <li class="nav-item footer-align">
              <a href="https://peeepl.de" class="nav-link py-1" target="_blank">
                © {{ year }} peeepl UG (haftungsbeschränkt)
              </a>
            </li>
            <li class="nav-item footer-align">
              <a
                href="https://peeepl.de/datenschutzerklaerung"
                class="nav-link py-1"
                target="_blank"
              >
                {{ i18n.$t("common.footer.data") }}
              </a>
            </li>
            <li class="nav-item footer-align">
              <a
                href="https://peeepl.de/impressum"
                class="nav-link py-1"
                target="_blank"
              >
                {{ i18n.$t("common.footer.imprint") }}
              </a>
            </li>
            <li class="nav-item footer-align">
              <a
                href="https://peeepl.de/cookie-richtlinie-eu"
                class="nav-link py-1"
                target="_blank"
              >
                {{ i18n.$t("common.footer.cookie") }}
              </a>
            </li>
            <li style="display: none" class="nav-item footer-align">
              <a href="#" class="nav-link py-1" target="_blank">
                {{ i18n.$t("common.footer.cookie") }}
              </a>
            </li>
          </ul>
        </div>
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <router-view></router-view>
      </div>
      <!-- <content-footer v-if="!$route.meta.hideFooter"></content-footer> -->
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import dayjs from "dayjs";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import { useI18n } from "@/util/i18n";
import {
  has_permission,
  PermissionService,
  setPermission,
} from "@/services/private/Role_Service";
import { GETUSERPERMISSIONS } from "@/services/private/Profile";
import store from "../../store";
import storage from "@/util/storage";
import { REFRESHTOKENAPI } from "@/services/public/Auth";
import { GETSKILLIDBYUSERAPI } from "@/services/private/Skills";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
  },
  data() {
    return {
      timer: null,
      expireTime: 0,
      year: new Date().getFullYear(),
      menu_item_permiited: [],
      menu_items: [
        {
          hasPermission: "menu_dashboard",
          item: {
            name: this.getLinkName("dashboard"),
            icon: "ni ni-shop text-green",
            path: "/dashboard",
          },
        },
        {
          hasPermission: "menu_projects",
          item: {
            name: this.getLinkName("project"),
            icon: "ni ni-folder-17 text-green",
            path: "/projects",
          },
        },
        {
          hasPermission: "menu_network",
          item: {
            name: this.getLinkName("network"),
            icon: "ni ni-world text-green",
            path: "/network",
          },
        },
        {
          hasPermission: "menu_staff",
          item: {
            name: this.getLinkName("personal"),
            icon: "ni ni-single-02 text-green",
            path: "/staff",
          },
        },
        {
          hasPermission: "menu_locations",
          item: {
            name: this.getLinkName("location"),
            icon: "ni ni-map-big text-green",
            path: "/locations",
          },
        },
        {
          hasPermission: "menu_myskills",
          item: {
            name: this.getLinkName("myskills"),
            icon: "ni ni-mobile-button text-green",
            path: "/skillsview",
            target: "_blank"
          },
        },
        {
          hasPermission: "menu_skills",
          item: {
            name: this.getLinkName("skills"),
            icon: "ni ni-laptop text-green",
            path: "/skills",
          },
        },
      ],
    };
  },
  computed: {
    // isAccessible: function(permission) {
    //    var data =  PermissionService.currentUserValue
    //     return data && data.length && data.includes(permission)
    //     // console.log('Access permission');
    //     // return has_permission(permission);
    //   },
    getExpireTime() {
      return store.state.expiredAt;
    },
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
    isAccessible(permission) {
      //  var data =  PermissionService.currentUserValue
      //   return data && data.length && data.includes(permission)
      // console.log('Access permission');

      return has_permission(permission);
    },
    async silentRefreshToken() {
      const user = storage().getUser();
      const refresh_token = storage().getRefreshToken();
      const rememberMe = storage().getRememberMe();
      const data = {
        user_id: user && user.user_id,
        refresh_token,
        rememberMe,
      };
      const result = await REFRESHTOKENAPI(data);
      if (result.status === 200) {
        const { extraDetails, data } = result.data;
        const user = data[0];
        storage().setAccessToken(extraDetails.access_token);
        storage().setRefreshToken(extraDetails.refresh_token);
        storage().setUser({
          user_id: user.user_id,
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          role: user.role,
          company_id: user && user.company_id,
          current_version: user && user.current_version,
        });
        setPermission(user.permission, user.role);
        store.commit("authSuccess", {
          access_token: extraDetails.access_token,
          expired_at: extraDetails.expired_at,
          user: user,
        });
        this.expireTime = dayjs().add(10800000, "ms").valueOf();
      }
    },
    async checkSkillsData() {
      const result = await GETSKILLIDBYUSERAPI(storage().getUser().user_id);
      console.log(result);
      return result.data.data;
    }
  },
  mounted() {
    async function refreshUserPermission() {
      if (store.getters.isLoggedIn) {
        const user = storage().getUser();
        const result = await GETUSERPERMISSIONS(user.user_id);
        if (result && result.status === 200) {
          const { data } = result.data;
          setPermission(data[0].permission, data[0].role);
        }
      }
    }
    refreshUserPermission();
    // this.initScrollbar();
  },

  created() {

    PermissionService.currentUser.subscribe((x) => {
      this.menu_item_permiited = [];
      this.menu_items.forEach((element) => {
        if (x.includes(element.hasPermission)) {
            this.menu_item_permiited.push(element);
        }
      });
    });
    this.expireTime = dayjs().add(10800000, "ms").valueOf();
    this.timer = setInterval(() => {
      this.silentRefreshToken();
    }, dayjs(this.expireTime).diff() - 10 * 10000);
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
  setup() {
    const i18n = useI18n();
    function getLinkName(type) {
      return i18n.$t(`sidebarNav.${type}`);
    }
    return { i18n, getLinkName };
  },
};
</script>
<style lang="scss">
.footer-align {
  width: 100%;
  text-align: left;
  padding-right: none !important;
}
</style>
