<template>
    <div class="skill-tile">
        <div class="cropped">
            <img class="responsive"
                :src="skill.videos.length === 1 ? 'https://customer-czy559gndrdvu19x.cloudflarestream.com/' + skill.videos[0].cloud_id + '/thumbnails/thumbnail.jpg?time=1s' :
                    'https://customer-czy559gndrdvu19x.cloudflarestream.com/' + skill.videos[1].cloud_id + '/thumbnails/thumbnail.jpg?time=4s'"
                alt="Hello World">
        </div>
        <div class="data-section">
            <p class="title">{{ skill.name }}</p>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import Skill from "./Skill";


export default defineComponent({
    name: "SkillTile",
    props: {
        skill: Skill
    },
    data() {
        return {
            skill: this.skill,
        }
    },
});
</script>


<style scoped>
.skill-tile {
    position: relative;
    margin-bottom: -50px;
}

.title {
    position: absolute;
    bottom: 30%;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    text-align: center;
    font-size: 0.7em;
    font-weight: bold;
    width: fit-content;
    cursor: pointer;
}

.data-section {
    border-radius: 5px;
}

.cropped {
    height: auto;
    clip-path: inset(0px 0px 70px 0 round 5px);
    margin-bottom: -70px;
}

.responsive {
    width: 100%;
    cursor: pointer;
    transition: 0.3s;
    border-radius: 5px;
}

@media screen and (min-width: 700px) {

    .responsive {
        width: 60%;
        box-shadow: 0 8px 12px 0 rgba(0,0,0,0.5);
    }
}

@media screen and (min-width: 1200px) {

    .responsive {
        width: 40%;
        box-shadow: 0 8px 12px 0 rgba(0,0,0,0.5);
       
    }
}
</style>