<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-warning py-7 py-lg-6 pt-lg-6">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="text-white">
                {{ i18n.$t("login.header") }}
              </h1>
              <p class="text-lead text-white">
                {{ i18n.$t("login.subHeader") }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
          xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-header bg-transparent">
              <div class="text-muted text-center">
                <small>{{ i18n.$t("login.form.header") }}</small>
              </div>
            </div>
            <div class="card-body px-lg-5 py-lg-5">
              <Form @submit="onSubmitPhone" :validation-schema="schemaPhone">
                <base-input alternative name="phoneNumber" addon-left-icon="ni ni-mobile-button"
                  :placeholder="i18n.$t('registerRegistrationCode.form.input.phone')">
                </base-input>
                <base-input v-if="codeRequested" alternative name="code" addon-left-icon="ni ni-key-25"
                  :placeholder="i18n.$t('registerRegistrationCode.form.input.enterCode')">
                </base-input>
                <div class="text-center">
                  <base-button type="warning" native-type="submit" class="my-4">
                    <div class="d-flex align-items-center">
                      <spinner v-show="loader" />
                      <span>{{ !codeRequested ? i18n.$t("registerRegistrationCode.form.button.requestCode") :
                        i18n.$t("login.form.submit") }}</span>
                    </div>
                  </base-button>
                </div>

              </Form>

              <table width="100%" v-if="!codeRequested">
                <tr>
                  <td>
                    <hr />
                  </td>
                  <td style="width:1px; padding: 0 10px; white-space: nowrap; font-size: 15px;">oder</td>
                  <td>
                    <hr />
                  </td>
                </tr>
              </table>
              <Form @submit="onSubmit" :validation-schema="schema" v-if="!codeRequested">

                <base-input alternative name="email" addon-left-icon="ni ni-email-83"
                  :placeholder="i18n.$t('common.email')" @keyup="removeLoginError">
                </base-input>

                <base-input alternative name="pwd" addon-left-icon="ni ni-lock-circle-open" type="password"
                  :placeholder="i18n.$t('common.pwd')" @keyup="removeLoginError" autocomplete="current-password">
                </base-input>
                <base-checkbox name="rememberMe" v-model="rememberMe">
                  {{ i18n.$t("login.form.checkbox") }}
                </base-checkbox>
                <h4 class="text-orange login-validation" v-show="showError">
                  {{ loginError }}
                </h4>
                <div class="text-center">
                  <base-button type="warning" native-type="submit" class="my-4">
                    <div class="d-flex align-items-center">
                      <spinner v-show="loader" />
                      <span>{{ i18n.$t("login.form.submit") }}</span>
                    </div>
                  </base-button>
                </div>
              </Form>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6">
              <router-link to="/forgot-password" class="text-light">
                <small>{{ i18n.$t("login.link.forgotPass") }}?</small>
              </router-link>
            </div>
            <!---
            <div class="col-6 text-right">
              <router-link to="/register" class="text-light">
                <small>{{ i18n.$t("login.link.registration") }}</small>
              </router-link>
            </div>
            -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "@/util/i18n";
import { useRouter, useRoute } from "vue-router";
import { Form } from "vee-validate";
import { useToast } from "vue-toastification";
import * as Yup from "yup";
import { LOGINAPI, LOGINBYPHONE, SENDCODELOGIN } from "@/services/public/Auth";
import { GETSKILLIDBYUSERAPI, GETCLOUDIDBYSKILLAPI } from "@/services/private/Skills";
import { setPermission, has_permission } from "@/services/private/Role_Service";
import { permission_list } from "@/services/_helper/user_permission";
import storage from "@/util/storage";
import { VERIFYINVITEAPI } from "@/services/private/Network";
import Skill from "../SkillsView/Skill";
const md5 = require("md5");

export default {
  components: {
    Form,
  },
  data() {
    return {
      model: {
        email: "",
        password: "",
        phoneNumber: "",
        code: ""
      },
    };
  },
  setup() {
    const store = useStore();
    const i18n = useI18n();
    const router = useRouter();
    const route = useRoute();
    const toast = useToast();
    const rememberMe = ref(false);
    const usesPhonenumber = ref(true);
    const codeRequested = ref(false);
    let loader = ref(false);
    let loginError = ref("");
    let showError = ref(false);
    storage().clear();

    function validateMobileNo(mobile_no) {
      mobile_no = mobile_no.trim();
      mobile_no = mobile_no.replace(/\s/g, '');

      if (mobile_no.startsWith('0')) {
        mobile_no = '+49' + mobile_no.slice(1);
      }
      const phoneNumberRegex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
      if (!phoneNumberRegex.test(mobile_no) || mobile_no.length < 11) {
        invalidMobileNo.value = true;
        return null;
      }

      return mobile_no;
    }


    async function onSubmit(values) {
      const { email, pwd } = values;

      var data = {};
      usesPhonenumber.value = false;
      if (route.params.invite_token) {
        data = {
          email: email.toLowerCase(),
          pwd: md5(pwd),
          rememberMe: rememberMe.value,
          invite_token: route.params.invite_token,
        };
      } else {
        data = {
          email: email.toLowerCase(),
          pwd: md5(pwd),
          rememberMe: rememberMe.value,
          network_token: route.params.network_token,
        };
      }

      loader.value = true;
      const result = await LOGINAPI(data);
      console.log(result);
      loader.value = false;

      await loginProceed(result);

    }

    async function onSubmitPhone(values) {
      var { phoneNumber, code } = values;
      console.log(phoneNumber);
      phoneNumber = validateMobileNo(phoneNumber);
      if (phoneNumber === null) return;
      var data = {};
      if (route.params.invite_token) {
        data = {
          phoneNumber: phoneNumber,
          code: code,
          invite_token: route.params.invite_token,
        };
      } else {
        data = {
          phoneNumber: phoneNumber,
          code: code
          //network_token: route.params.network_token,
        };
      }

      if (codeRequested.value) {
        await onSubmitLogin(data);
      } else {
        await onSubmitRequestCode(data);
      }
    }

    async function onSubmitRequestCode(data, actions) {
      var requestData = { mphone: data.phoneNumber }
      loader.value = true;
      const result = await SENDCODELOGIN(requestData);
      console.log(result);
      loader.value = false;

      if (
        result.error &&
        result.response &&
        result.response.data &&
        result.response.data.status === 409
      ) {
        // sameEmail.value = true;
      } else if (result && result.status === 200) {
        console.log(result);
        codeRequested.value = true;
        //actions.resetForm();
        //router.push({ name: "RegisterSuccess", params: { success: true } });
      } else {
        toast.error(i18n.$t("message.wrong"));
      }
    }

    async function onSubmitLogin(data) {
      var requestData = { mphone: data.phoneNumber, user_code: data.code }
      loader.value = true;
      const result = await LOGINBYPHONE(requestData);
      console.log(result);
      loader.value = false;



      await loginProceed(result);

    }

    async function loginProceed(result) {
      if (
        result.error &&
        result.response &&
        result.response.data &&
        (result.response.data.status === 401 ||
          result.response.data.status === 404)
      ) {
        const { data } = result.response.data;
        showError.value = true;
        if (data && data[0] && data[0].is_active === true) {
          loginError.value = i18n.$t("login.activateAccount");
        } else {
          loginError.value = i18n.$t("login.loginError");
        }
      } else if (
        result.response &&
        result.response.data &&
        result.response.data.status === 403
      ) {
        toast.error(i18n.$t("message.permission") || i18n.$t("message.wrong"));
      } else if (result && result.status === 200) {
        const { extraDetails, data } = result.data;
        const user = data[0];
        storage().setAccessToken(extraDetails.access_token);
        storage().setRefreshToken(extraDetails.refresh_token);
        storage().setRememberMe(rememberMe.value);
        storage().setUser({
          user_id: user.user_id,
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          role: user.role,
          company_id: user && user.company_id,
          current_version: user && user.current_version,
        });

        if (!usesPhonenumber) {
          localStorage.setItem("hashPwd", md5(pwd));
        }


        setPermission(user.permission, user.role);

        store.commit("authSuccess", {
          access_token: extraDetails.access_token,
          expired_at: extraDetails.expired_at,
          user: user,
        });

        if (
          !user.date_of_birth ||
          !user.address_street ||
          !user.address_postcode ||
          !user.address_city ||
          !has_permission(permission_list.menu_dashboard.value)
        ) {
          if (route.params.network_token) {
            const data = { network_token: route.params.network_token };
            const verificationResult = await VERIFYINVITEAPI(
              route.params.network_token
            );
            if (
              verificationResult.error &&
              verificationResult.response &&
              verificationResult.response.data &&
              verificationResult.response.data.status === 410
            ) {
              toast.error(
                i18n.$t("adminEmployee.expire") || i18n.$t("message.wrong")
              );
              //isLinkActive.value = 1;
            } else if (
              verificationResult &&
              verificationResult.status === 200
            ) {
              router.push({
                name: "Profile",
                params: {
                  network_token: route.params.network_token,
                  company: verificationResult.data.data[0].company_name,
                },
              });
            }
          } else {
            if (user.role.role_code === "S") {
              router.push({
                name: "Skills View",
              });
            } else {
              router.push({
                name: "Profile",
              });
            }
          }
        } else {
          if (route.params.network_token) {
            const data = { network_token: route.params.network_token };
            const verificationResult = await VERIFYINVITEAPI(
              route.params.network_token
            );
            if (
              verificationResult.error &&
              verificationResult.response &&
              verificationResult.response.data &&
              verificationResult.response.data.status === 410
            ) {
              toast.error(
                i18n.$t("adminEmployee.expire") || i18n.$t("message.wrong")
              );
              //isLinkActive.value = 1;
            } else if (
              verificationResult &&
              verificationResult.status === 200
            ) {
              router.push({
                name: "Projects",
                params: {
                  network_token: route.params.network_token,
                  company: verificationResult.data.data[0].company_name,
                },
              });
            }
          } else {
            if (user.role.role_code === "S") {
              router.push({
                name: "Skills View",
              });
            } else {
              router.push({
                name: "Projects",
              });
            }
          }
        }
      } else {
        store.commit("authError", i18n.$t("message.wrong"));
        toast.error(i18n.$t("message.wrong"));
      }
    }


    function removeLoginError() {
      showError.value = false;
    }

    const schema = Yup.object().shape({
      email: Yup.string().email().required(),
      pwd: Yup.string().required(),
    });

    const schemaPhone = Yup.object().shape({
      phoneNumber: Yup.string().required()
    });

    return {
      loader,
      i18n,
      schema,
      schemaPhone,
      showError,
      loginError,
      rememberMe,
      onSubmit,
      removeLoginError,
      usesPhonenumber,
      onSubmitPhone,
      codeRequested,
      loginProceed

    };
  },
};
</script>

<style>
.divider {
  width: 500px;
  text-align: center;
}

.divider hr {
  margin-left: auto;
  margin-right: auto;
  width: 40%;

}

.left {
  float: left;
}

.right {
  float: right;
}
</style>