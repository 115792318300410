import axios from '../Axios';
import { REGISTER, CODECHECKLOGIN,VERIFICATIONREGISTER, VERIFICATIONLOGIN, LOGIN, CHECKACTIVATION, REFRESHTOKEN, GENERATENEWACTIVATION, FORGOTPASSWORD, RESETPASSWORD, VERIFYFORGOTPASSWORD, LOGOUT, CODECHECKREGISTER } from '../CONSTANTURL';



export const REGISTERAPI = async (data) => {
  try {
    const result = await axios.post(`${REGISTER}`, data);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const SENDCODEREGISTER = async (data) => {
  console.log("API CALL, ",  data);
  try {
    //Loginphone ist für existierende user. Korrekter Endpunkt kommt noch. Abwarten
    const result = await axios.post(`${VERIFICATIONREGISTER}`, data);
    console.log(result);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const REGISTERBYPHONE = async (data) => { 
  console.log("API CALL, ",  data);
  try {
    //Loginphone ist für existierende user. Korrekter Endpunkt kommt noch. Abwarten
    const result = await axios.post(`${CODECHECKREGISTER}`, data);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};
export const LOGINBYPHONE = async (data) => { 
  console.log("API CALL, ",  data);
  try {
    //Loginphone ist für existierende user. Korrekter Endpunkt kommt noch. Abwarten
    const result = await axios.post(`${CODECHECKLOGIN}`, data);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const SENDCODELOGIN = async (data) => {
  console.log("API CALL, ",  data);
  try {
    const result = await axios.post(`${VERIFICATIONLOGIN}`, data);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};
export const LOGINAPI = async (data) => {
  try {
    const result = await axios.post(`${LOGIN}`, data);

    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};
export const CHECKACTIVATIONAPI = async (data) => {
  try {
    const { token, user_id } = data;
    const result = await axios.get(`${CHECKACTIVATION}/${user_id}/${token}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};
export const GENERATENEWACTIVATIONAPI = async (data) => {
  try {
    const { user_id } = data;
    const result = await axios.get(`${GENERATENEWACTIVATION}/${user_id}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};
export const FORGOTPASSWORDAPI = async (data) => {
  try {
    const result = await axios.post(`${FORGOTPASSWORD}`, data);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};
export const VERIFYFORGOTPASSWORDAPI = async (data) => {
  try {
    const { token, user_id } = data;
    const result = await axios.get(`${VERIFYFORGOTPASSWORD}/${user_id}/${token}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};
export const RESETPASSWORDAPI = async (data) => {
  try {
    const result = await axios.put(`${RESETPASSWORD}`, data);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};
export const REFRESHTOKENAPI = async (data) => {
  try {
    const result = await axios.put(`${REFRESHTOKEN}`, data);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};


export const hasAccess = (name) => {
  var permissions = localStorage.getItem("permissions");

  switch (name) {

    case "home":
      return true;

    case "users":
      return permissions.includes("View All Users");

    case "permissions":
      return permissions.includes("View All Permissions");

    case "roles":
      return permissions.includes("View All Roles");

    default:
      return false;
  }
};
export const LOGOUTAPI = async (data) => {
  try {
    const result = await axios.put(`${LOGOUT}`, data);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};
