<template>
  <card>
    <Form @submit="onSubmit" :validation-schema="schema">
      <div class="row align-items-center">
        <div class="col-12 col-md-7">
          <div>
            <h3 class="mb-0 text-uppercase">
              {{ i18n.$t("common.projects") }} {{ projectData.project_name }}
            </h3>
          </div>
        </div>
      </div>
      <hr class="mt-3 mb-0" />
      <h6 class="heading-small text-muted mb-4">
        {{ i18n.$t("common.information") }}
      </h6>
      <div class="row no-gutters">
        <div class="col-12 col-md-8">
          <div class="row no-gutters">
            <div class="col-w-2">
              <div class="d-block mr-2 mb-2">
                <span class="p-2 text-xs text-left">
                  <i class="fas fa-calendar-alt text-gray"></i>
                  <span class="margin-left">
                    {{
                      formatDate(
                        projectData.start_date,
                        i18n.$t("projectDetails.start_date")
                      )
                    }}
                  </span>
                </span>
              </div>
              <div class="d-none d-sm-block mr-2 mb-2">
                <span class="p-2 text-xs text-left">
                  <i class="fas fa-clock text-gray"></i>
                  <span class="margin-left">
                    {{
                      formatTime(
                        projectData && projectData.start_time,
                        i18n.$t("projectDetails.start_time")
                      )
                    }}
                  </span>
                </span>
              </div>
              <div class="d-block d-sm-none mr-2 mb-2">
                <span class="p-2 text-xs text-left">
                  <i class="fas fa-calendar-alt text-gray"></i>
                  <span class="margin-left">
                    {{
                      formatDate(
                        projectData.end_date,
                        i18n.$t("project.modal.form.end_date")
                      )
                    }}
                  </span>
                </span>
              </div>

              <div class="d-block mr-2 mb-2">
                <button
                  v-if="userBookedForShift()"
                  class="btn btn-white px-2 py-2 m-1 text-xs mr-2 text-left"
                  type="button"
                  @click="openDocumentModal()"
                >
                  <i class="fas fa-file-alt text-gray"></i>
                  <span class="">
                    {{ i18n.$t("projectDetails.document") }}
                  </span>
                </button>
              </div>
            </div>
            <div class="col-w-2">
              <div class="d-block d-sm-none mr-2 mb-2">
                <span class="p-2 text-xs text-left">
                  <i class="fas fa-clock text-gray"></i>
                  <span class="margin-left">
                    {{
                      formatTime(
                        projectData && projectData.start_time,
                        i18n.$t("projectDetails.start_time")
                      )
                    }}
                  </span>
                </span>
              </div>
              <div class="d-none d-sm-block mr-2 mb-2">
                <span class="p-2 text-xs text-left">
                  <i class="fas fa-calendar-alt text-gray"></i>
                  <span class="margin-left">
                    {{
                      formatDate(
                        projectData.end_date,
                        i18n.$t("project.modal.form.end_date")
                      )
                    }}
                  </span>
                </span>
              </div>
              <div class="d-block mr-2 mb-2">
                <span class="p-2 text-xs text-left">
                  <i class="fas fa-clock text-gray"></i>
                  <span class="margin-left">
                    {{
                      formatTime(
                        projectData.end_time,
                        i18n.$t("project.modal.form.end_time")
                      )
                    }}
                  </span>
                </span>
              </div>
              <div class="d-block d-sm-none mr-2 mb-2">
                <span class="p-2 text-xs text-left">
                  <i class="fas fa-user text-gray"></i>
                  <span class="margin-left">
                    {{ projectData.no_of_visitors }}
                    {{ i18n.$t("projectDetails.people") }}
                  </span>
                </span>
              </div>
              <div class="d-block d-sm-none mr-2 mb-2">
                <span class="p-2 text-xs text-left">
                  <i class="fas fa-map-marker text-gray"></i>
                  <span
                    @click="openLocationProfile(projectData.location_id)"
                    class="margin-left"
                  >
                    {{ projectData.location_name }}
                  </span>
                </span>
              </div>
            </div>
            <div class="col-w-2">
              <div class="d-none d-sm-block mr-2 mb-2">
                <span class="p-2 text-xs text-left">
                  <i class="fas fa-user text-gray"></i>
                  <span class="margin-left">
                    {{ projectData.no_of_visitors }}
                    {{ i18n.$t("projectDetails.people") }}
                  </span>
                </span>
              </div>
              <div class="d-none d-sm-block mr-2 mb-2">
                <span class="p-2 text-xs text-left">
                  <i class="fas fa-map-marker text-gray"></i>
                  <span
                    @click="openLocationProfile(projectData.location_id)"
                    class="margin-left"
                  >
                    {{ projectData.location_name }}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4 pt-2 pt-sm-0">
          <Field
            as="textarea"
            class="form-control"
            name="project_description"
            :placeholder="i18n.$t('projectDetails.formLabel.notes')"
            v-model="projectData.project_description"
            :rows="showEditBtn ? 7 : 3"
            maxlength="500"
            :disabled="!showEditBtn"
          ></Field>
        </div>
      </div>

      <list-documents-modal
        :documents="documents"
        :showModal="models.listDocumentModalVisible"
        :isAddAllowed="isRoleAdminOrDispatch"
        @close="closeListDocumentModal"
      >
      </list-documents-modal>
    </Form>
  </card>
</template>
<script>
import { computed, reactive, ref, watch } from "vue";
import { Form, Field } from "vee-validate";
import { useToast } from "vue-toastification";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Multiselect from "@vueform/multiselect";
import * as Yup from "yup";
import {
  UPDATEPROJECTAPI,
  GETPROJECTDOCUMENTSAPI,
} from "@/services/private/Project";
import { ElDropdownMenu, ElDropdownItem, ElDropdown } from "element-plus";
import date from "@/util/date";
import { useI18n } from "@/util/i18n";
import storage from "@/util/storage";
import AddDocumentModal from "./Document/AddDocumentModal.vue";
import { useRoute, useRouter } from "vue-router";
import DeleteDocumentModal from "../../Common/DeletePopup/DeleteDocumentModal.vue";
import ConfirmDocumentDeleteModal from "../../Common/DeletePopup/ConfirmDocumentDeleteModal.vue";
import ListDocumentsModal from "./Document/ListDocumentsModal.vue";

export default {
  props: {
    project: Object,
    shiftStartDateTime: String,
    shiftEndDateTime: String,
    projectStartDateTime: String,
    projectEndDateTime: String,
    isOCProject: Boolean,
    shiftData: Object,
  },

  components: {
    Form,
    Field,
    flatPicker,
    Multiselect,
    AddDocumentModal,
    DeleteDocumentModal,
    ConfirmDocumentDeleteModal,
    ListDocumentsModal,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
  },
  data() {
    return {
      locations: [],
      documents: [],
      models: {
        addDocumentModalVisible: false,
        removeDocumentModalVisible: false,
        confirmDeleteDocumentModalVisible: false,
        removeDocumentId: "",
        listDocumentModalVisible: false,
      },
      route: useRoute(),
      router: useRouter(),
    };
  },
  computed: {
    isRoleAdminOrDispatch() {
      const user = storage().getUser();
      const role = user && user.role;
      return role && (role.role_code === "A" || role.role_code === "D");
    },
  },
  methods: {
    userBookedForShift() {
      let isUserBooked = false;
      if (this.shiftData) {
        this.shiftData.forEach((element) => {
          if (
            element.shift_request_status == "user_added" ||
            element.shift_request_status == "ic_user_added"
          ) {
            isUserBooked = true;
          }
        });
      }
      return isUserBooked;
    },
    openDocumentModal() {
      this.models.listDocumentModalVisible = true;
    },
    closeListDocumentModal() {
      this.models.listDocumentModalVisible = false;
    },
    getTextTrim(data, length) {
      if (data.length > length) {
        return data.substring(0, length) + "...";
      } else {
        return data;
      }
    },
    openDocument(data) {
      window.open(data.project_document_path, "_blank");
    },
    async getDocuments() {
      let id_Document = this.route.params.id;
      let is_Deleted = false;
      var result = await GETPROJECTDOCUMENTSAPI(id_Document, is_Deleted);
      if (result && result.status == 200) {
        this.documents = result.data.data.map((a) => ({
          document_id: a.project_document_id,
          document_name: a.project_document_name,
          document_path: a.project_document_path,
        }));
      }
    },
    updateDocument() {
      this.getDocuments();
      this.models.addDocumentModalVisible = false;
    },
    openLocationProfile(data) {
      let routeData = this.router.resolve({
        name: "LocationDetails",
        params: {
          location_id: data,
        },
      });
      window.open(routeData.href, "_blank");
    },
  },
  mounted() {
    this.getDocuments();
  },
  setup(props, context) {
    const project = props && props.project;
    const projectStartDateTime = ref(props.projectStartDateTime);
    const projectEndDateTime = ref(props.projectEndDateTime);
    const isOCProject = ref(props.isOCProject);

    const i18n = useI18n();
    const toast = useToast();
    let projectData = reactive({
      company_id: project.company_id,
      end_date: project.end_date,
      end_time: project.end_time,
      location_id: project.location_id,
      location_name: project.location_name,
      project_description: project.project_description,
      project_id: project.project_id,
      project_name: project.project_name,
      project_status: i18n.$t(`project.status.${project.project_status}`),
      project_status_en: project.project_status,
      start_date: project.start_date,
      start_time: project.start_time,
      no_of_visitors: 0,
    });

    let loader = ref(false);
    let showEditBtn = ref(false);
    let showProjectBtn = ref(true);

    watch(
      () => props.project,
      (currentProject, prevProject) => {
        projectData.start_date = currentProject.start_date;
        projectData.start_time = currentProject.start_time;
        projectData.end_date = currentProject.end_date;
        projectData.end_time = currentProject.end_time;
        projectData.location_name = currentProject.location_name;
        projectData.location_id = currentProject.location_id;
      },
      { deep: true, immediate: true }
    );
    // watch(
    //   () => props.shiftStartTime,
    //   (currentTime, prevTime) => {
    //     // shiftStartTime.value = currentTime;
    //   },
    //   { immediate: true }
    // );
    // watch(
    //   () => props.shiftEndTime,
    //   (currentTime, prevTime) => {
    //     // shiftEndTime.value = currentTime;
    //   },
    //   { immediate: true }
    // );

    const shiftEndDateTime = computed(() => props.shiftEndDateTime);
    const shiftStartDateTime = computed(() => props.shiftStartDateTime);
    function editProject() {
      showEditBtn.value = true;
      showProjectBtn.value = false;
    }
    function showProject() {
      showEditBtn.value = false;
      showProjectBtn.value = true;
    }
    function getProjectStatus(status) {
      return i18n.$t(`project.status.${status}`);
    }
    function formatDate(projectDate, message) {
      if (projectDate !== null) {
        return date().formatDate(projectDate, "DD.MM.YYYY");
      }
      return message;
    }
    function formatTime(projectTime, message) {
      if (projectTime !== null) {
        const time = `${date().formatTime(`${projectTime}`, "HH:mm")}`;
        return `${time} Uhr`;
      }
      return message;
    }
    async function onSubmit(values) {
      try {
        loader.value = true;
        const {
          end_date,
          end_time,
          project_description,
          start_date,
          start_time,
          no_of_visitors,
        } = values;
        const data = {
          project_id: projectData.project_id,
          project_name: projectData.project_name,
          location_id: projectData.location_id,
          project_status: projectData.project_status_en,
          end_date,
          end_time,
          project_description,
          start_date,
          start_time,
          no_of_visitors,
        };
        const enddate =
          end_date !== null
            ? `${end_date}`
            : `${date().formatDate(shiftEndDateTime.value, "YYYY-MM-DD")}`;
        const endtime =
          end_time !== null
            ? `${date().formatTime(end_time, "HH:mm")}`
            : `${date().formatDate(shiftEndDateTime.value, "HH:mm")}`;
        //check if project start time is greater than shift start time and check for end time is less than with project endtime
        const isAfterStartDate = date().isSameOrAfter(
          `${shiftStartDateTime.value}`,
          `${start_date} ${date().formatTime(start_time, "HH:mm")}`
        );
        const isBeforeEndDate = date().isSameOrBefore(
          `${shiftEndDateTime.value}`,
          `${enddate} ${endtime}`
        );
        if (
          props.shiftStartTime !== null &&
          props.shiftEndTime !== null &&
          (!isAfterStartDate || !isBeforeEndDate)
        ) {
          loader.value = false;
          toast.error(i18n.$t("projectDetails.timeValidation"));
        } else {
          const result = await UPDATEPROJECTAPI(data);
          loader.value = false;
          if (result && result.status === 200) {
            toast.success(i18n.$t("projectDetails.infoSaved"));
            showProject();
            context.emit("update");
          } else {
            toast.error(i18n.$t("projectDetails.failedToSave"));
          }
        }
      } catch (err) {
        loader.value = false;
        toast.error(i18n.$t("projectDetails.failedToSave"));
      }
    }
    const schema = Yup.object().shape({
      project_name: Yup.string().required(),
      location_id: Yup.string().required(),
      start_date: Yup.string().required(),
      start_time: Yup.string().required(),
    });
    return {
      i18n,
      projectData,
      showEditBtn,
      showProjectBtn,
      loader,
      project,
      projectStartDateTime,
      projectEndDateTime,
      shiftStartDateTime,
      shiftEndDateTime,
      isOCProject,
      schema,
      editProject,
      showProject,
      getProjectStatus,
      formatDate,
      formatTime,
      onSubmit,
    };
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped lang="scss">
.project_status {
  &:disabled,
  &[readonly] {
    background-color: #fff !important;
  }
}
.datepicker {
  &:disabled,
  &[readonly] {
    background-color: #fff !important;
  }
}
.project-rows {
  margin-bottom: 10px;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 10px;
  margin-right: 0px;
  cursor: pointer;
}
.project-col {
  padding-left: 0px;
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
  font-size: 0.875rem;
}
.margin-left {
  margin-left: 5px;
}
</style>
