<template>
  <div class="row">
    <div v-bind:class="classObject">
      <Field
        class="form-control"
        style="border: none"
        name="search"
        type="text"
        label="search"
        placeholder="Search"
        v-model="search"
      >
      </Field>

      <el-table
        class="table-responsive table-flush rounded"
        ref="userTable"
        header-row-class-name="thead-light"
        :show-header="false"
        :current-row-key="1"
        :data="
          allNetworks.value.filter(
            (data) =>
              !search ||
              data.company_name.toLowerCase().includes(search.toLowerCase())
          )
        "
        highlight-current-row
      >
        <el-table-column min-width="50px" prop="icon">
          <template v-slot="{ row }">
            <div class="media align-items-center">
              <div>
                <span
                  class="avatar avatar-sm bg-success color-grey rounded-circle"
                  translate="no"
                >
                  {{ getInitialsName(row.company_name) }}
                </span>
              </div>
              <div style="margin-left: 15px" @click="handleCurrentChange(row)">
                <div class="media-body font-weight-600">
                  {{ row.company_name }}
                </div>
                <span class="status text-capitalize">
                  {{ getStatus(row.request_status) }}
                </span>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column width="100px">
          <template v-slot="{ row }">
            <base-checkbox
              v-model="row.isSelected"
              :disabled="row.isDisable"
              @change="check(row)"
              class="mb-3"
            >
            </base-checkbox>
          </template>
        </el-table-column>
      </el-table>

      <div id="block_container">
        <div id="bloc1">
          <label style="color: #8898aa">{{
            i18n.$t("projectDetails.shiftRequest.selectAll")
          }}</label>
        </div>
        <div id="bloc2">
          <base-checkbox
            @change="selectUnSelectAll"
            label="search"
            class="mb-3"
          >
          </base-checkbox>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-ceter pt-4" style="position: sticky; bottom: 0; float: left;">
        <base-button
          type="primary"
          style="margin-left: auto"
          class="text-white p-2 px-3"
          :disabled="!users.value || users.value <= 0"
          @click="generateShiftRequest"
        >
          {{ i18n.$t("projectDetails.shiftRequest.requestBtn") }}
        </base-button>
      </div>
    <div v-if="company.value" class="col-6">
      <div class="row">
        <div class="col-xl-12 order-xl-2">
          <company-profile-view :company="company.value">
          </company-profile-view>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, reactive, onMounted, computed } from "vue";
import Modal from "@/components/Modal";
import { useI18n } from "@/util/i18n";
import { GETCOMPANYPROFILEAPI } from "@/services/private/Company";
import { GETALLNETWORKAPI } from "@/services/private/Network";
import {
  GETALLBYPARTNERSHIFTAPI,
  CREATESHIFTPARTNERREQUESTAPI,
} from "@/services/private/ProjectShift";
import { useToast } from "vue-toastification";
import CompanyProfileView from "./CompanyProfileView.vue";
import initialsName from "@/util/initialsName";
import { Field, Form } from "vee-validate";
import {
  ElTable,
  ElTableColumn,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
} from "element-plus";
export default {
  name: "user-network-view",
  props: {
    shiftData: {
      type: Object,
      required: true,
    },
    shiftRequestData: Object,
  },
  components: {
    Modal,
    Field,
    CompanyProfileView,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
  },
  data() {
    return {};
  },
  created: function () {},
  computed: {},
  methods: {},

  setup(props, context) {
    const i18n = useI18n();
    const toast = useToast();
    let allNetworks = reactive({ value: [] });
    let currentRow = null;
    let profileData = null;
    let search = "";
    let users = reactive({ value: [] });
    // let shiftDataObject = this.shiftData;
    let existingShiftUsers = [];
    let availableShiftUser = [];
    let scheduleUserList = [];
    let selectAll = false;
    let company = reactive({ value: null });

    function getRoleI18n(type) {
      const roleName = type && type.toLowerCase();
      return i18n.$t(`role.${roleName}`);
    }

    function getInitialsName(company_name) {
      return initialsName(company_name, 1);
    }

    async function getNetworkList(isActive) {
      isActive = isActive;
      const result = await GETALLNETWORKAPI(isActive);
      if (result && result.status === 200) {
        allNetworks.value = result.data.data;
      }
      getAllByShift();
    }

    function getAllByShift() {
      let shift = props.shiftData; //this.shiftDataAll();
      if (shift) {
        // var result = await GETALLSHIFTREQUESTAPI({
        //   //await GETALLBYPARTNERSHIFTAPI({
        //   shift_id: shift.shift_id,
        //   flag: false,
        // });
        if (props.shiftRequestData) {
          //&& result && result.status === 200
          const data = props.shiftRequestData; //result.data;
          if (data) {
            existingShiftUsers = data.requestedUserList;
            availableShiftUser = data.availableUserList;
            scheduleUserList = data.scheduleUserList;

            allNetworks.value.forEach((element) => {
              var existData = scheduleUserList.find(
                (o) => o.partner_company_id === element.partner_company_id
              );
              if (existData) {
                element.isSelected = true;
                element.isDisable = true;
                element.shift_request_status = existData.shift_request_status;
              }
            });

            allNetworks.value.forEach((element) => {
              var existData = existingShiftUsers.find(
                (o) => o.partner_company_id === element.partner_company_id
              );
              if (existData) {
                element.isSelected = true;
                element.isDisable = true;
                element.shift_request_status = existData.shift_request_status;
              }
            });

            allNetworks.value.forEach((element) => {
              var existData = availableShiftUser.find(
                (o) => o.partner_company_id === element.partner_company_id
              );
              if (existData) {
                element.isSelected = true;
                element.isDisable = true;
                element.shift_request_status = existData.shift_request_status;
              }
            });
          }
          // const { data } = result.data;
          // if (data && data.length) {
          //   allNetworks.value.forEach((element) => {
          //     var existData = data.find(
          //       (o) => o.partner_company_id === element.partner_company_id
          //     );
          //     if (existData) {
          //       element.isSelected = true;
          //       element.isDisable = true;
          //       element.request_status = existData.request_status;
          //     }
          //   });
          // }
        }
      }
    }

    async function generateShiftRequest() {
      var shift = props.shiftData;
      if (shift) {
        if (users.value && users.value.length > 0) {
          let data = {
            shift_id: shift.shift_id,
            partner_companies: users.value,
            request_status: "company_requested",
            requested_user_count: props.shiftData.shift_count,
          };

          var result = await CREATESHIFTPARTNERREQUESTAPI(data);
          if (result && result.status === 200) {
            const { data } = result.data;
            users.value = [];
            allNetworks.value.forEach((element) => {
              var existData = data.find(
                (o) => o.partner_company_id === element.partner_company_id
              );
              if (existData) {
                element.isSelected = true;
                element.isDisable = true;
                element.request_status = existData.request_status;
              }
            });

            toast.success(
              i18n.$t("projectDetails.shiftRequest.userRequestSuccess")
            );
            context.emit("update", props.shiftData.shift_id);
            // getAllByShift();
          }
        } else {
          toast.error(i18n.$t("projectDetails.shiftRequest.pleaseSelectUser"));
        }
      }
    }

    function check(val) {
      if (val.isSelected == true) {
        users.value.push({
          partner_company_id: val.partner_company_id,
          partner_company_name: val.company_name,
        });
      } else {
        const index = users.value
          .map((item) => item.partner_company_id)
          .indexOf(val.partner_company_id);
        if (index > -1) {
          users.value.splice(index, 1);
        }
      }
    }

    function selectUnSelectAll() {
      if (selectAll) {
        selectAll = false;
        users.value = [];
        allNetworks.value.forEach((element) => {
          if (!element.isDisable) {
            element.isSelected = false;
          }
        });
      } else {
        selectAll = true;
        allNetworks.value.forEach((element) => {
          if (!element.isDisable && !element.isSelected) {
            element.isSelected = true;
            users.value.push({
              partner_company_id: element.partner_company_id,
              partner_company_name: element.company_name,
            });
          }
        });
      }
    }

    function handleCurrentChange(val) {
      currentRow = val;
      loadCompanyProfile(val.partner_company_id);
    }

    async function loadCompanyProfile(company_id) {
      const result = await GETCOMPANYPROFILEAPI(company_id);
      if (result && result.status === 200 && result.data) {
        company.value = result.data.data[0];
      }
    }

    function getStatus(status) {
      return i18n.$t(`projectDetails.shiftRequest.status.${status}`);
    }

    onMounted(async () => {
      await getNetworkList(true);
    });

    const classObject = computed(() => {
      if (company.value) {
        return "col-6";
      } else {
        return "col-12";
      }
    });

    return {
      i18n,
      getRoleI18n,
      allNetworks,
      search,
      selectAll,
      classObject,
      getInitialsName,
      generateShiftRequest,
      check,
      selectUnSelectAll,
      loadCompanyProfile,
      handleCurrentChange,
      company,
      users,
      getStatus,
    };
  },
};
</script>
<style>
.modal .modal-huge {
  max-width: 96%;
  width: 1000px !important;
}

div.el-table .el-table__row:hover {
  background: #f5f7fa !important;
}
.el-table__body tr.current-row > td {
  background: #e5e5e5 !important;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: black !important;
  background-color: #fff !important;
}
.nav-pills .nav-link {
  color: black !important;
  background-color: #b8b7b7 !important;
}
</style>
<style scoped>
.color-grey {
  background-color: #b8b7b7 !important;
}

#block_container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

#bloc1 {
  margin-right: 39px;
}

#bloc2 {
  margin-right: 50px;
}

.el-table__row {
  cursor: pointer;
}
</style>


