<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-warning py-7 py-lg-8 pt-lg-9">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="text-white">
                {{ i18n.$t("forgotPassword.forgotPasswordSuccess.header") }}
              </h1>
              <p class="text-lead text-white">
                {{ i18n.$t("forgotPassword.forgotPasswordSuccess.subHeader") }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
  </div>
</template>
<script>
import { useI18n } from "@/util/i18n";
import { onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
export default {
  setup() {
    const i18n = useI18n();
    const router = useRouter();
    const route = useRoute();
    onMounted(async () => {
      await checkParams();
    });
    async function checkParams() {
      if (!(route && route.params && route.params.success)) {
        router.push({ name: "ForgotPassword" });
      }
    }
    return {
      i18n,
      checkParams,
    };
  },
};
</script>
