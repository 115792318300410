<template>
  <modal
    v-model:show="show"
    modal-classes="modal-white"
    header-classes="align-items-center"
    body-classes="pt-0"
  >
    <template v-slot:header>
      <h6 class="modal-title text-capitalize text-default">
        {{ i18n.$t("staff.modal.invitationLink.shareLink") }}
      </h6>
    </template>
    <div class="">
      <p class="text-default mb-3">
        {{ i18n.$t("staff.modal.invitationLink.shareLinkContent") }}
      </p>
      <div>
        <div>
          <form>
            <a
              :href="invite_link"
              class="btn btn-secondary btn-lg disabled linkCSS"
              role="button"
              aria-disabled="true"
              ref="mylink"
              id="myInput"
              >{{ invite_link }}</a
            >
            <div v-if="showErrorMsg">
              <span class="text-danger font-weight-bold">
                {{ i18n.$t("staff.modal.changeRole.error") }}
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import Modal from "@/components/Modal";
import { useI18n } from "@/util/i18n";
export default {
  name: "invitation-link-copy-modal",
  props: {
    showModal: Boolean,
    invitationLink: String,
  },
  components: {
    Modal,
  },
  data() {
    return {
      showErrorMsg: false,
    };
  },
  computed: {
    show() {
      return this.showModal;
    },
    showClose() {
      return false;
    },
    invite_link() {
      return this.invitationLink;
    },
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
  },
  setup() {
    const i18n = useI18n();
    return { i18n };
  },
};
</script>
