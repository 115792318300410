class Skill {
    constructor(skill_id, lectures, status, name, videos) {
        this.skill_id = skill_id
        this.lectures = lectures;
        this.status = status;
        this.name = name;
        this.videos = videos;
    }

    toString() {
        return this.name + " " + this.skill_id;
    }
}

export default Skill;