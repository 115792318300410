<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-warning py-7 py-lg-6 pt-lg-6">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-7 col-lg-6 col-md-8 px-5">
              <h1 class="text-white">
                {{ i18n.$t("adminEmployee.header") }}
              </h1>
              <p class="text-lead text-white">
                {{ i18n.$t("adminEmployee.subHeader") }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body bg-transparent" v-if="isLinkActive === 2">
              <div class="text-muted text-center">
                <h4 class="display-4">
                  {{ i18n.$t("adminEmployee.newHere") }}
                </h4>
              </div>
              <div class="text-muted text-center pt-4">
                <h4>{{ i18n.$t("adminEmployee.accountYet") }}</h4>
              </div>
              <div class="text-muted text-center pt-2">
                <span class="text-blue pointer" @click="onSubmit('RegisterPhone')">
                  {{ i18n.$t("adminEmployee.clickHere") }}
                </span>
              </div>
              <div class="text-muted text-center pt-6">
                <h4 class="display-4">
                  {{ i18n.$t("adminEmployee.alreadyMember") }}
                </h4>
              </div>
              <div class="text-muted text-center pt-4">
                <h4>{{ i18n.$t("adminEmployee.doYouAlreadyMember") }}</h4>
              </div>
              <div class="text-muted text-center pt-2">
                <span class="text-blue pointer" @click="onSubmit('Login')">
                  {{ i18n.$t("adminEmployee.clickHere") }}
                </span>
              </div>
            </div>
            <div class="card-body bg-transparent" v-if="isLinkActive === 1">
              <p class="text-default font-weight-bold mb-0">
                {{ i18n.$t("adminEmployee.expire") }}
              </p>
            </div>
            <div class="card-body bg-transparent" v-if="isLinkActive === 0">
              <h2 class="text-default text-center mb-0">
                <spinner color="text-default" />{{ i18n.$t("common.checking") }}
              </h2>
            </div>
            <div class="card-body bg-transparent" v-if="isLinkActive === 3">
              <h2 class="text-default text-center mb-0">
                {{ i18n.$t("adminEmployee.roleCreated") }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { setPermission } from "@/services/private/Role_Service";
import { CHECKJOINCOMPANYAPI, CREATEROLEAPI } from "@/services/private/Company";
import { useI18n } from "@/util/i18n";
import storage from "@/util/storage";

export default {
  setup() {
    const store = useStore();
    const i18n = useI18n();
    const router = useRouter();
    const route = useRoute();
    // isLinkActive: 0 - initial, 1 - false, 2 - true, 3 - created roles if already logged in
    let isLinkActive = ref(0);
    const invite_token = route.params.token;
    async function onSubmit(type) {
      router.push({ name: `${type}`, params: { invite_token: invite_token } });
    }
    async function checkJoinCompanyLink() {
      const data = { invite_token: invite_token };
      const result = await CHECKJOINCOMPANYAPI(data);
      if (
        result.error &&
        result.response &&
        result.response.data &&
        result.response.data.status === 410
      ) {
        isLinkActive.value = 1;
      } else if (result && result.status === 200) {
        if (store.getters.isLoggedIn) {
          const rememberMe = storage().getRememberMe();
          const roleResult = await CREATEROLEAPI({
            invite_token,
            rememberMe,
          });
          if (
            roleResult.error &&
            roleResult.response &&
            roleResult.response.data &&
            roleResult.response.data.status === 410
          ) {
            isLinkActive.value = 1;
          } else if (roleResult && roleResult.status === 200) {
            const { extraDetails, data } = roleResult.data;
            const user = data[0];
            storage().setAccessToken(extraDetails.access_token);
            storage().setRefreshToken(extraDetails.access_token);
            storage().setUser({
              user_id: user.user_id,
              first_name: user.first_name,
              last_name: user.last_name,
              email: user.email,
              role: user.role,
              company_id: user && user.company_id,
              current_version: user && user.current_version,
            });

            setPermission(data[0].permission, data[0].role);

            store.commit("authSuccess", {
              access_token: extraDetails.access_token,
              user: user,
            });
            isLinkActive.value = 3;
            setTimeout(() => {
              if (
                !user.date_of_birth ||
                !user.address_street ||
                !user.address_postcode ||
                !user.address_city
              ) {
                router.push({ name: "Profile" });
              } else {
                router.push({ name: "Dashboard" });
              }
            }, 2000);
          }
        } else {
          isLinkActive.value = 2;
        }
      } else {
        isLinkActive.value = 1;
      }
    }
    onMounted(async () => {
      await checkJoinCompanyLink();
    });

    return {
      i18n,
      onSubmit,
      isLinkActive,
    };
  },
};
</script>
