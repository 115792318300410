import { BehaviorSubject } from 'rxjs';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('permissions')));


export const PermissionService = {
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() { return currentUserSubject.value; }
};

// const PERMISSION = {
//     menu_staff: { value: "Administration_CategoryManagement" },
//     menu_dashboard: { value: "Administration_ClusterManagement" },
//     menu_personal_info: { value: "Administration_ClusterManagement" },
// }


// function setAllPermissionOfUser(data, role) {
//     var permissions = data.filter(function (obj) {
//         return obj.role_code == role_code;
//     }).map(function (obj) {
//         return obj.permission_code;
//     });
// }

export const has_permission = (name) => {
  var permissions = PermissionService.currentUserValue;   //localStorage.getItem("permissions")
  return permissions && permissions.length && permissions.includes(name);
};

export const currentuser_role = () => {
  return JSON.parse(localStorage.getItem("currentUser"));
};

export const setPermission = (data, role) => {
  var permissions = data.filter(function (obj) {
    return obj.role_code == role.role_code;
  }).map(function (obj) {
    return obj.permission_code;
  });
  localStorage.setItem("permissions", JSON.stringify(permissions));
  localStorage.setItem('currentUser', JSON.stringify(role));
  // component A
  currentUserSubject.next(permissions);
};

export function getUserPermissions() {
  return JSON.parse(localStorage.getItem("permissions"));
}
