<template>
  <div>
    <base-header type="default" class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Netzwerk</h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6 xs-mt--3">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <edit-profile-form></edit-profile-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useI18n } from "@/util/i18n";
import EditProfileForm from "./CompanyProfile/EditProfileForm.vue";

export default {
  components: {
    EditProfileForm,
  },
  computed: {
    firstName() {
      return `${this.$store.getters.firstName}`;
    },
  },
  setup() {
    const i18n = useI18n();
    return { i18n };
  },
};
</script>
<style>
.profile-header {
  background-size: cover;
  background-position: center top;
  min-height: 500px;
}
.profile-background {
  background: linear-gradient(
    90deg,
    rgba(124, 123, 159, 1) 0%,
    rgba(29, 26, 80, 1) 67%
  ) !important;
}
</style>
