<template>
<card>
  <p>UnAuthorised Access</p>
</card>
</template>
<script>

export default {
 
};
</script>
<style>

</style>
