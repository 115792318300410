<template>
  <modal
    v-model:show="show"
    modal-classes="modal-white"
    header-classes="align-items-center"
    body-classes="pt-0"
    size="lg"
    :show-close="showClose"
  >
    <template v-slot:header>
      <h6 class="modal-title text-capitalize text-default">
        {{ i18n.$t("location.locationDocument.newDocumentTitle") }}
      </h6>
    </template>
    <div class="">
      <p class="text-default mb-3"></p>
      <div>
        <Form
          ref="form"
          class="pt-2"
          @submit="createLocationDocument"
          :validation-schema="schema"
          v-slot="{ errors }"
        >
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="ni ni-single-copy-04"></i>
                    </span>
                  </div>

                  <Field
                    class="form-control"
                    :class="{
                      'has-danger-border-red': errors.document_name,
                    }"
                    name="document_name"
                    type="text"
                    id="document_name"
                    v-model="documentName"
                    :placeholder="
                      i18n.$t('location.locationDocument.documentName')
                    "
                    maxlength="30"
                  ></Field>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"
                      ><i class="ni ni-single-copy-04"></i
                    ></span>
                  </div>

                  <Field
                    class="form-control"
                    :class="{
                      'has-danger-border-red': errors.file_upload,
                    }"
                    ref="file_upload_ref"
                    v-if="uploadReady"
                    accept="application/pdf"
                    name="file_upload"
                    type="file"
                    id="file_upload"
                    @change="handleFileUploads"
                  ></Field>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex align-items-ceter justify-content-between pt-4">
            <base-button
              type="warning"
              native-type="submit"
              class="text-white p-2 px-3"
            >
              <div class="d-flex align-items-center">
                <spinner v-show="loader" />
                <span>{{
                  i18n.$t("location.locationDocument.uploadModalBtn")
                }}</span>
              </div>
            </base-button>
            <base-button
              type=""
              class="ml-auto text-default p-2 px-3"
              @click="handleClose"
            >
              {{ i18n.$t("logout.button.cancel") }}
            </base-button>
          </div>
        </Form>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/Modal";
import { useI18n } from "@/util/i18n";
import { UPLOADLOCATIONDOCUMENTAPI } from "@/services/private/Location";
import { onMounted, reactive, ref, watch } from "vue";
import { Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useRouter, useRoute } from "vue-router";
import { useToast } from "vue-toastification";

export default {
  name: "add-document-modal",
  props: {
    showModal: Boolean,
    setSelectedRole: String,
    locationId: String,
  },
  components: {
    Modal,
    Field,
    Form,
  },
  data() {
    return {
      loader: false,
      invite_link: "",
      file: "",
      documentName: "",
      file_upload: {},
      location_Id: this.locationId,
      route: useRoute(),
      toast: useToast(),
      uploadReady: true,
      addDocumentModel: {
        documentName: "",
      },
      schema: Yup.object().shape({
        document_name: Yup.string().required(),
        file_upload: Yup.mixed().required(this.i18n.$t("message.fileRequired")),
      }),
    };
  },
  computed: {
    show() {
      return this.showModal;
    },
    showClose() {
      return true;
    },
    company_id() {
      return this.$store.state.user.company_id;
    },
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
    clear() {
      this.uploadReady = false;
      this.$nextTick(() => {
        this.uploadReady = true;
      });
    },
    async createLocationDocument() {
      this.loader = true;
      let formData = new FormData();
      let id_Location = this.route.params.location_id;
      formData.append("file", this.file);
      formData.append("location_id", id_Location);
      formData.append("document_name", this.documentName);
      if (this.file.type != "application/pdf") {
        this.toast.error(
          this.i18n.$t("location.locationDocument.errorPdfAllow")
        );
        this.loader = false;
        return;
      }

      let result = await UPLOADLOCATIONDOCUMENTAPI(formData);
      this.loader = false;
      if (result && result.status == 200) {
        this.$emit("update");
      }
    },
    handleFileUploads(event) {
      let file = event.target.files[0];
      if (file.type == "application/pdf") {
        this.file = event.target.files[0];
      } else {
        this.clear();
        //this.$refs.file_upload_ref.value = null;
        this.toast.error(
          this.i18n.$t("location.locationDocument.errorPdfAllow")
        );
      }
    },
  },
  watch: {
    showModal(new_value, old_value) {
      if (new_value != old_value) {
        this.$nextTick(() => {
          this.$refs.form.resetForm();
          this.clear();
          this.documentName = "";
          this.document = "";
          this.$refs.file_upload_ref.value = null;
        });
      }
    },
  },
  setup(props, { emit }) {
    const i18n = useI18n();

    return {
      i18n,
    };
  },
};
</script>

<style>
</style>
