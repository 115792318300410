<template>
  <card>
    <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
      <div class="row align-items-center">
        <div class="col-12 col-md-7">
          <div v-if="!showEditBtn">
            <h3 class="mb-0 text-uppercase">
              {{ i18n.$t("common.projects") }} {{ projectData.project_name }}
            </h3>
          </div>
          <div v-else>
            <div class="row align-items-center">
              <div class="col">
                <Field
                  :class="{ 'has-danger-border-red': errors.project_name }"
                  class="form-control"
                  name="project_name"
                  type="text"
                  id="project_name"
                  :placeholder="i18n.$t('project.modal.form.project_name')"
                  v-model="projectData.project_name"
                ></Field>
              </div>
              <div class="col">
                <Field name="location_id" v-model="projectData.location_id">
                  <Multiselect
                    :classes="{
                      container: 'multiselect border-0 multiselect-height',
                      search:
                        'multiselect-search multiselect-form-control shadow-none',
                      optionSelectedPointed: 'bg-default is-pointed text-white',
                      optionSelected: 'bg-default text-white',
                      optionPointed: 'bg-green text-white',
                      containerOpen:
                        'is-open border border-primary shadow-none',
                    }"
                    v-model="projectData.location_id"
                    :options="locations"
                    :placeholder="i18n.$t('project.modal.form.location')"
                    :searchable="true"
                    :close-on-select="true"
                    :class="{ 'has-danger-border-red': errors.location_id }"
                  ></Multiselect>
                </Field>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-12 col-md-5 text-right pt-2 pt-sm-0"
          v-if="isRoleAdminOrDispatch && company_id === projectData.company_id"
        >
          <base-button
            type="button"
            class="btn-sm"
            v-if="showProjectBtn"
            @click="downloadPdf"
          >
            <div class="d-flex align-items-center">
              <span>Einsatzplan drucken</span>
            </div>
          </base-button>
          <base-button
            type="warning"
            class="btn-sm"
            v-if="showProjectBtn"
            @click="editProject"
          >
            <div class="d-flex align-items-center">
              <span>{{ i18n.$t("projectDetails.editProject") }}</span>
            </div>
          </base-button>
          <div v-if="showEditBtn">
            <button type="submit" class="btn btn-primary btn-sm">
              <div class="d-flex align-items-center">
                <spinner v-show="loaderBar" />
                <span>{{ i18n.$t("editProfile.save") }}</span>
              </div>
            </button>
            <base-button type="" @click="showProject" class="btn-sm">
              <div class="d-flex align-items-center">
                <span>{{ i18n.$t("editProfile.discard") }}</span>
              </div>
            </base-button>
          </div>
        </div>
      </div>
      <hr class="mt-3 mb-0" />
      <h6 class="heading-small text-muted mb-4">
        {{ i18n.$t("common.information") }}
      </h6>
      <div class="row" :class="{ 'no-gutters': !showEditBtn }">
        <div class="col-12 col-md-8">
          <div class="row no-gutters" v-if="!showEditBtn">
            <div class="col-w-2">
              <div class="d-block mr-2 mb-2">
                <span class="p-2 text-xs text-left">
                  <i class="fas fa-calendar-alt text-gray"></i>
                  <span class="margin-left">
                    {{
                      formatDate(
                        projectData.start_date,
                        i18n.$t("projectDetails.start_date")
                      )
                    }}
                  </span>
                </span>
              </div>
              <div class="d-none d-sm-block mr-2 mb-2">
                <span class="p-2 text-xs text-left">
                  <i class="fas fa-clock text-gray"></i>
                  <span class="margin-left">
                    {{
                      formatTime(
                        projectData && projectData.start_time,
                        i18n.$t("projectDetails.start_time")
                      )
                    }}
                  </span>
                </span>
              </div>
              <div class="d-block d-sm-none mr-2 mb-2">
                <span class="p-2 text-xs text-left">
                  <i class="fas fa-calendar-alt text-gray"></i>
                  <span class="margin-left">
                    {{
                      formatDate(
                        projectData.end_date,
                        i18n.$t("project.modal.form.end_date")
                      )
                    }}
                  </span>
                </span>
              </div>
              <div class="d-block mr-2 mb-2">
                <button
                  class="btn btn-white px-2 py-2 m-1 text-xs mr-2 text-left"
                  type="button"
                  @click="openDocumentModal()"
                >
                  <i class="fas fa-file-alt text-gray"></i>
                  <span class="">
                    {{ i18n.$t("projectDetails.document") }}
                  </span>
                </button>
              </div>
            </div>
            <div class="col-w-2">
              <div class="d-block d-sm-none mr-2 mb-2">
                <span class="p-2 text-xs text-left">
                  <i class="fas fa-clock text-gray"></i>
                  <span class="margin-left">
                    {{
                      formatTime(
                        projectData && projectData.start_time,
                        i18n.$t("projectDetails.start_time")
                      )
                    }}
                  </span>
                </span>
              </div>
              <div class="d-none d-sm-block mr-2 mb-2">
                <span class="p-2 text-xs text-left">
                  <i class="fas fa-calendar-alt text-gray"></i>
                  <span class="margin-left">
                    {{
                      formatDate(
                        projectData.end_date,
                        i18n.$t("project.modal.form.end_date")
                      )
                    }}
                  </span>
                </span>
              </div>
              <div class="d-block mr-2 mb-2">
                <span class="p-2 text-xs text-left">
                  <i class="fas fa-clock text-gray"></i>
                  <span class="margin-left">
                    {{
                      formatTime(
                        projectData.end_time,
                        i18n.$t("project.modal.form.end_time")
                      )
                    }}
                  </span>
                </span>
              </div>
              <div class="d-block d-sm-none mr-2 mb-2">
                <span class="p-2 text-xs text-left">
                  <i class="fas fa-user text-gray"></i>
                  <span class="margin-left">
                    {{ projectData.no_of_visitors }}
                    {{ i18n.$t("projectDetails.people") }}
                  </span>
                </span>
              </div>
              <div class="d-block d-sm-none mr-2 mb-2">
                <span class="p-2 text-xs text-left">
                  <i class="fas fa-map-marker text-gray"></i>
                  <span
                    @click="openLocationProfile(projectData.location_id)"
                    class="margin-left"
                  >
                    {{ projectData.location_name }}
                  </span>
                </span>
              </div>
            </div>
            <div class="col-w-2">
              <div class="d-none d-sm-block mr-2 mb-2">
                <span class="p-2 text-xs text-left">
                  <i class="fas fa-user text-gray"></i>
                  <span class="margin-left">
                    {{ projectData.no_of_visitors }}
                    {{ i18n.$t("projectDetails.people") }}
                  </span>
                </span>
              </div>
              <div class="d-none d-sm-block mr-2 mb-2">
                <span class="p-2 text-xs text-left">
                  <i class="fas fa-map-marker text-gray"></i>
                  <span
                    @click="openLocationProfile(projectData.location_id)"
                    class="margin-left"
                  >
                    {{ projectData.location_name }}
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div class="row" v-else>
            <div class="col">
              <div class="d-block">
                <label for="start_date" class="font-weight-bolder text-sm">
                  {{ i18n.$t("projectDetails.formLabel.start_date") }}
                </label>
                <div
                  class="form-group mb-2 static-wrap"
                  :class="{ 'has-danger-border-red': errors.start_date }"
                >
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text font-weight-bolder">
                        <i class="fas fa-calendar-alt"></i>
                      </span>
                    </div>
                    <Field name="start_date" v-model="projectData.start_date">
                      <flat-picker
                        :config="start_date_config"
                        name="start_date"
                        class="datepicker"
                        v-model="projectData.start_date"
                        id="start_date"
                        :placeholder="
                          i18n.$t('projectDetails.formLabel.start_date')
                        "
                        @on-change="updateEndDate"
                        autocomplete="off"
                      >
                      </flat-picker>
                    </Field>
                  </div>
                </div>
              </div>
              <div class="d-block">
                <label for="start_time" class="font-weight-bolder text-sm">
                  {{ i18n.$t("projectDetails.formLabel.start_time") }}
                </label>
                <div class="form-group mb-2 static-time-wrap">
                  <div
                    class="input-group"
                    :class="{ 'has-danger-border-red': errors.start_time }"
                  >
                    <div class="input-group-prepend">
                      <span class="input-group-text font-weight-bolder">
                        <i class="fas fa-clock"></i>
                      </span>
                    </div>
                    <Field name="start_time" v-model="projectData.start_time">
                      <flat-picker
                        :config="start_time_config"
                        name="start_time"
                        class="form-control datepicker"
                        v-model="projectData.start_time"
                        id="start_time"
                        :placeholder="
                          i18n.$t('projectDetails.formLabel.start_time')
                        "
                        autocomplete="off"
                      >
                      </flat-picker>
                    </Field>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="d-block">
                <label for="end_date" class="font-weight-bolder text-sm">
                  {{ i18n.$t("projectDetails.formLabel.end_date") }}
                </label>
                <div
                  class="form-group mb-2 static-wrap"
                  :class="{ 'has-danger-border-red': errors.end_date }"
                >
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text font-weight-bolder">
                        <i class="fas fa-calendar-alt"></i>
                      </span>
                    </div>
                    <Field name="end_date" v-model="projectData.end_date">
                      <flat-picker
                        :config="end_date_config"
                        name="end_date"
                        class="datepicker"
                        v-model="projectData.end_date"
                        id="end_date"
                        :placeholder="
                          i18n.$t('projectDetails.formLabel.end_date')
                        "
                      >
                      </flat-picker>
                    </Field>
                  </div>
                </div>
              </div>
              <div class="d-block">
                <label for="end_time" class="font-weight-bolder text-sm">
                  {{ i18n.$t("projectDetails.formLabel.end_time") }}
                </label>
                <div class="form-group mb-2 static-time-wrap">
                  <div
                    class="input-group"
                    :class="{ 'has-danger-border-red': errors.end_time }"
                  >
                    <div class="input-group-prepend">
                      <span class="input-group-text font-weight-bolder">
                        <i class="fas fa-clock"></i>
                      </span>
                    </div>
                    <Field name="end_time" v-model="projectData.end_time">
                      <flat-picker
                        :config="end_time_config"
                        name="end_time"
                        class="form-control datepicker"
                        v-model="projectData.end_time"
                        id="end_time"
                        :placeholder="
                          i18n.$t('projectDetails.formLabel.end_time')
                        "
                        autocomplete="off"
                      >
                      </flat-picker>
                    </Field>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="d-block">
                <label for="totalPeople" class="font-weight-bolder text-sm">
                  {{ i18n.$t("projectDetails.formLabel.totalPeople") }}
                </label>
                <div class="form-group mb-2">
                  <div
                    class="input-group"
                    :class="{
                      'has-danger-border-red': errors.no_of_visitors,
                    }"
                  >
                    <div class="input-group-prepend">
                      <span class="input-group-text font-weight-bolder">
                        <i class="fas fa-user"></i>
                      </span>
                    </div>
                    <Field
                      class="form-control"
                      name="no_of_visitors"
                      type="number"
                      id="no_of_visitors"
                      :placeholder="
                        i18n.$t('projectDetails.formLabel.totalPeople')
                      "
                      v-model="projectData.no_of_visitors"
                    ></Field>
                  </div>
                </div>
              </div>
              <div class="d-block">
                <div class="form-group mb-2">
                  <label
                    for="project_status"
                    class="font-weight-bolder text-sm"
                  >
                    {{ i18n.$t("projectDetails.formLabel.status") }}
                  </label>
                  <div class="form-group mb-2">
                    <div
                      class="input-group"
                      :class="{
                        'has-danger-border-red': errors.project_status,
                      }"
                    >
                      <div class="input-group-prepend">
                        <span class="input-group-text font-weight-bolder">
                          <i class="fas fa-archive"></i>
                        </span>
                      </div>
                      <Field
                        name="project_status"
                        type="text"
                        v-model="projectData.project_status"
                        class="form-control project_status"
                        id="project_status"
                        disabled="true"
                      >
                      </Field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4 pt-2 pt-sm-0">
          <Field
            as="textarea"
            class="form-control"
            name="project_description"
            :placeholder="i18n.$t('projectDetails.formLabel.notes')"
            v-model="projectData.project_description"
            :rows="showEditBtn ? 7 : 3"
            maxlength="500"
            :disabled="!showEditBtn"
          ></Field>
        </div>
      </div>
      <div v-if="showEditBtn">
        <hr class="mt-3 mb-0" />
        <h6 class="heading-small text-muted mb-3">
          {{ i18n.$t("projectDetails.document") }}
        </h6>
        <div class="row">
          <div
            class="col-3 col-sm-3 col-md-4 col-lg-3"
            v-for="doc in documents"
            :key="doc.project_document_name"
          >
            <div class="d-flex project-rows align-items-center">
              <div class="col-8 project-col">
                {{ doc && doc.document_name }}
              </div>

              <div class="col-4">
                <span class="d-flex align-items-center justify-content-end">
                  <span class="mr-1 d-flex" @click="openDocument(doc)">
                    <i class="ni ni-single-copy-04"></i>
                  </span>
                  <el-dropdown
                    class="dropdown"
                    trigger="click"
                    placement="bottom-start"
                  >
                    <span class="pl-2 btn-icon-only text-light">
                      <i class="fas fa-ellipsis-v mt-0"></i>
                    </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <li
                          class="dropdown-item"
                          @click="deleteDocument(doc.document_id)"
                        >
                          {{ i18n.$t("common.remove") }}
                        </li>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <button
            class="btn btn-white p-2 text-xs text-left"
            @click="addNewDocument"
            type="button"
          >
            <i class="fas fa-cloud-upload-alt text-gray"></i>
            <span class="">
              {{ i18n.$t("projectDetails.newDocument") }}
            </span>
          </button>
        </div>
      </div>

      <add-document-modal
        v-if="models.addDocumentModalVisible"
        :loader="loader"
        :id="projectData.projectId"
        :showModal="models.addDocumentModalVisible"
        @close="closeModal"
        @add="createDocument"
      ></add-document-modal>

      <!-- Remove document modal -->
      <delete-document-modal
        :showModal="models.removeDocumentModalVisible"
        :loader="loader"
        @close="closeDeleteDocumentModal"
        @confirm="showConfirmDocumentDeleteModal"
      ></delete-document-modal>

      <confirm-document-delete-modal
        :showModal="models.confirmDeleteDocumentModalVisible"
        :loader="loader"
        @close="closeConfirmDocumentDeleteModal"
        @remove="sendRemoveDocumentAPI"
      ></confirm-document-delete-modal>

      <list-documents-modal
        :documents="documents"
        :showModal="models.listDocumentModalVisible"
        :isAddAllowed="models.isAddAllowed"
        @close="closeListDocumentModal"
        @DeleteDocApi="removeDocumentAPI"
        @addDoc="addNewDocument"
      >
      </list-documents-modal>
    </Form>
  </card>
</template>
<script>
import jspdf from "jspdf";
import { computed, reactive, ref, watch } from "vue";
import { Form, Field } from "vee-validate";
import { useToast } from "vue-toastification";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { German } from "flatpickr/dist/l10n/de.js";
import Multiselect from "@vueform/multiselect";
import * as Yup from "yup";
import { GETALLLOCATIONSAPI } from "@/services/private/Location";
import {
  UPDATEPROJECTAPI,
  GETPROJECTDOCUMENTSAPI,
  DELETEPROJECTDOCAPI,
  CREATEPROJECTDOCUMENTAPI,
} from "@/services/private/Project";
import { GETALLSHIFTREQUESTAPI } from "@/services/private/ProjectShift";
import { ElDropdownMenu, ElDropdownItem, ElDropdown } from "element-plus";
import date from "@/util/date";
import { useI18n } from "@/util/i18n";
import storage from "@/util/storage";
import { useRoute, useRouter } from "vue-router";

import AddDocumentModal from "./Document/AddDocumentModal.vue";
import DeleteDocumentModal from "../../Common/DeletePopup/DeleteDocumentModal.vue";
import ConfirmDocumentDeleteModal from "../../Common/DeletePopup/ConfirmDocumentDeleteModal.vue";
import ListDocumentsModal from "./Document/ListDocumentsModal.vue";

export default {
  props: {
    project: Object,
    shiftData: Object,
    shiftStartDateTime: String,
    shiftEndDateTime: String,
    projectStartDateTime: String,
    projectEndDateTime: String,
    isOCProject: Boolean,
  },

  components: {
    Form,
    Field,
    flatPicker,
    Multiselect,
    AddDocumentModal,
    DeleteDocumentModal,
    ConfirmDocumentDeleteModal,
    ListDocumentsModal,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
  },
  data() {
    return {
      locations: [],
      documents: [],
      loader: false,
      models: {
        addDocumentModalVisible: false,
        removeDocumentModalVisible: false,
        confirmDeleteDocumentModalVisible: false,
        removeDocumentId: "",
        listDocumentModalVisible: false,
        isAddAllowed: true,
      },
      route: useRoute(),
      router: useRouter(),
    };
  },
  computed: {
    isRoleAdminOrDispatch() {
      const user = storage().getUser();
      const role = user && user.role;
      return role && (role.role_code === "A" || role.role_code === "D");
    },
    company_id() {
      const user = storage().getUser();
      return user && user.company_id;
    },
  },
  methods: {
    async downloadPdf() {
      const doc = new jspdf({
        orientation: "p",
        unit: "mm",
        format: "a4",
        putOnlyUsedFonts: true,
        floatPrecision: 16, // or "smart", default is 16
      });
      doc.setFont("helvetica");
      doc.setFontSize(9);

      //console.log(this.shiftData);

      let html = "<h1>Einsatzplan</h1><br><br>";
      // iterate over all shifts
      for (const shift of this.shiftData) {
        html += `<table class="el-table__body" style="width: 700px;" border="1">`;
        html += `<tr>`;
        html += `<th><div class="media-body text-uppercase font-weight-600 text-sm text-default">${shift.shift_name}</div></th>`;
        html += `<th><div class="media-body text-uppercase font-weight-600 text-sm text-default">Telefonnummer</div></th>`;
        html += `<th><div class="media-body text-uppercase font-weight-600 text-sm text-default">Beginn ${date().formatTime(
          `${shift.shift_start_time}`,
          "HH:mm"
        )}</div></th>`;
        html += `<th><div class="media-body text-uppercase font-weight-600 text-sm text-default">Ende ${date().formatTime(
          `${shift.shift_end_time}`,
          "HH:mm"
        )}</div></th>`;
        html += `</tr>`;
        let shift_count = shift.shift_count;
        // use GETALLSHIFTREQUESTAPI to get all shift requests for this shift
        const resultShiftRequest = await GETALLSHIFTREQUESTAPI({
          shift_id: shift.shift_id,
          flag: false,
        });
        if (resultShiftRequest && resultShiftRequest.status === 200) {
          const { data } = resultShiftRequest.data;
          //console.log(data);
          if (data.length > 0 && data[0].scheduleUserList.length > 0) {
            console.log(data[0].scheduleUserList);
            for (const user of data[0].scheduleUserList) {
              //console.log(user);
              html += `<tr>`;
              if (user.user_id === null) {
                html += `<td><div class="text-sm">${user.partner_company_name}</div></td>`;
              } else {
                html += `<td><div class="text-sm">${user.first_name} ${user.last_name}</div></td>`;
              }
              if (user.mobile_no === null) {
                html += `<td></td>`;
              } else {
                html += `<td>${user.mobile_no}</td>`;
              }
              html += `<td></td>`;
              html += `<td></td>`;
              html += `</tr>`;
            }
          }
          for (
            let i = 0;
            i < shift_count - data[0].scheduleUserList.length;
            i++
          ) {
            html += `<tr>`;
            html += `<td><div style="color: white;">.</div></td>`;
            html += `<td></td>`;
            html += `<td></td>`;
            html += `<td></td>`;
            html += `</tr>`;
          }
        }
        html += `</table><br><br>`;
      }

      // save pdf
      doc.html(html, {
        callback: function (doc) {
          doc.save("Einsatzplan.pdf");
        },
        width: 1000,
        windowWidth: 4000,
      });
    },
    openDocumentModal() {
      this.models.listDocumentModalVisible = true;
      this.models.isAddAllowed = this.isOCProject;
    },
    closeListDocumentModal() {
      this.models.listDocumentModalVisible = false;
    },
    getTextTrim(data, length) {
      if (data.length > length) {
        return data.substring(0, length) + "...";
      } else {
        return data;
      }
    },
    async getLocation() {
      const result = await GETALLLOCATIONSAPI(false);
      if (result && result.status === 200) {
        this.locations = result.data.data.map((l) => {
          return { value: l.location_id, label: l.location_name };
        });
      }
    },
    addNewDocument() {
      this.models.addDocumentModalVisible = true;
    },
    closeModal() {
      this.models.addDocumentModalVisible = false;
    },
    deleteDocument(data) {
      this.models.removeDocumentId = data;
      this.models.removeDocumentModalVisible = true;
    },
    closeDeleteDocumentModal() {
      this.models.removeDocumentId = "";
      this.models.removeDocumentModalVisible = false;
    },
    showConfirmDocumentDeleteModal() {
      this.models.removeDocumentModalVisible = false;
      this.models.confirmDeleteDocumentModalVisible = true;
    },
    closeConfirmDocumentDeleteModal() {
      this.models.confirmDeleteDocumentModalVisible = false;
      this.closeDeleteDocumentModal();
    },
    async sendRemoveDocumentAPI() {
      const toast = useToast();
      //Call API
      const doc_id = this.models.removeDocumentId;
      const result = await DELETEPROJECTDOCAPI(doc_id);

      if (result && result.status === 200) {
        this.closeConfirmDocumentDeleteModal();
        this.getDocuments();
        //this.$emit("update");
        toast.success(this.i18n.$t("location.delete.successDeleteMessage"));
      } else {
        toast.error(this.i18n.$t("location.delete.failDeleteMessage"));
      }
    },
    async removeDocumentAPI(id) {
      const toast = useToast();
      //Call API
      const doc_id = id;
      const result = await DELETEPROJECTDOCAPI(doc_id);

      if (result && result.status === 200) {
        this.closeConfirmDocumentDeleteModal();
        this.getDocuments();
        //this.$emit("update");
        toast.success(this.i18n.$t("location.delete.successDeleteMessage"));
      } else {
        toast.error(this.i18n.$t("location.delete.failDeleteMessage"));
      }
    },
    openDocument(data) {
      window.open(data.document_path, "_blank");
    },
    async getDocuments() {
      let id_Document = this.route.params.id;
      let is_Deleted = false;
      var result = await GETPROJECTDOCUMENTSAPI(id_Document, is_Deleted);
      if (result && result.status == 200) {
        this.documents = result.data.data.map((a) => ({
          document_id: a.project_document_id,
          document_name: a.project_document_name,
          document_path: a.project_document_path,
        }));
      }
    },
    async createDocument(value) {
      const toast = useToast();
      this.loader = true;
      let formData = new FormData();
      let projectid = this.route.params.id;
      formData.append("file", value.file);
      formData.append("project_id", projectid);
      formData.append("project_document_name", value.documentName);
      if (value.file.type != "application/pdf") {
        this.toast.error(
          this.i18n.$t("location.locationDocument.errorPdfAllow")
        );
        this.loader = false;
        return;
      }

      let result = await CREATEPROJECTDOCUMENTAPI(formData);
      this.loader = false;
      if (result && result.status == 200) {
        toast.success(
          this.i18n.$t("location.locationDocument.uploadDocSuccess")
        );
        this.updateDocument();
      }
    },
    updateDocument() {
      this.getDocuments();
      this.models.addDocumentModalVisible = false;
    },
    openLocationProfile(data) {
      let routeData = this.router.resolve({
        name: "LocationDetails",
        params: {
          location_id: data,
        },
      });
      window.open(routeData.href, "_blank");
    },
  },
  mounted() {
    this.getLocation();
    this.getDocuments();
  },
  setup(props, context) {
    const propsProject = props && props.project;
    const propsProjectStartDateTime = ref(props.projectStartDateTime);
    const propsProjectEndDateTime = ref(props.projectEndDateTime);
    const propsIsOCProject = ref(props.isOCProject);

    const i18n = useI18n();
    const toast = useToast();
    const start_date_config = reactive({
      static: true,
      wrap: true,
      altInput: true,
      allowInput: true,
      dateFormat: "Y-m-d",
      altFormat: "d.m.Y",
      defaultDate: new Date(),
      disableMobile: "true",
      locale: German, // locale for this instance only
    });
    const end_date_config = reactive({
      static: true,
      wrap: true,
      altInput: true,
      allowInput: true,
      dateFormat: "Y-m-d",
      altFormat: "d.m.Y",
      disableMobile: "true",
      locale: German, // locale for this instance only
    });
    const start_time_config = reactive({
      static: true,
      wrap: true,
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
      defaultDate: "00:00",
    });
    const end_time_config = reactive({
      static: true,
      wrap: true,
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
    });
    let projectData = reactive({
      company_id: propsProject.company_id,
      end_date: propsProject.end_date,
      end_time: propsProject.end_time,
      location_id: propsProject.location_id,
      location_name: propsProject.location_name,
      project_description: propsProject.project_description,
      project_id: propsProject.project_id,
      project_name: propsProject.project_name,
      project_status: i18n.$t(`project.status.${propsProject.project_status}`),
      project_status_en: propsProject.project_status,
      start_date: propsProject.start_date,
      start_time: propsProject.start_time,
      no_of_visitors: propsProject.no_of_visitors,
    });

    let loaderBar = ref(false);
    let showEditBtn = ref(false);
    let showProjectBtn = ref(true);

    watch(
      () => props.project,
      (currentProject, prevProject) => {
        projectData.start_date = currentProject.start_date;
        projectData.start_time = currentProject.start_time;
        projectData.end_date = currentProject.end_date;
        projectData.end_time = currentProject.end_time;
        projectData.location_name = currentProject.location_name;
        projectData.location_id = currentProject.location_id;

        if (currentProject && currentProject.end_date !== null) {
          end_date_config.minDate = currentProject.end_date;
        } else {
          end_date_config.minDate = currentProject.start_date;
        }
      },
      { deep: true, immediate: true }
    );

    const propsShiftEndDateTime = computed(() => props.shiftEndDateTime);
    const propsShiftStartDateTime = computed(() => props.shiftStartDateTime);

    function editProject() {
      showEditBtn.value = true;
      showProjectBtn.value = false;
    }
    function showProject() {
      showEditBtn.value = false;
      showProjectBtn.value = true;
    }
    function getProjectStatus(status) {
      return i18n.$t(`project.status.${status}`);
    }
    function formatDate(projectDate, message) {
      if (projectDate !== null) {
        return date().formatDate(projectDate, "DD.MM.YYYY");
      }
      return message;
    }
    function formatTime(projectTime, message) {
      if (projectTime !== null) {
        const time = `${date().formatTime(`${projectTime}`, "HH:mm")}`;
        return `${time} Uhr`;
      }
      return message;
    }
    function updateEndDate(selectedDates, dateStr, instance) {
      if (dateStr) {
        end_date_config.minDate = new Date(dateStr);
      }
    }
    function updateEndTime(selectedDates, dateStr, instance) {
      if (dateStr) {
        if (date().isDateToday(new Date(dateStr))) {
          end_time_config.minTime = date().formatDate(new Date(), "HH:mm");
        } else {
          end_time_config.minTime = "";
        }
      }
    }
    function setEndMinTime(selectedDates, dateStr, instance) {
      if (dateStr) {
        const { start_date, end_date } = projectData;
        if (start_date && end_date) {
          if (date().dateDiff(start_date, end_date) === 0) {
            end_time_config.minTime = start_time_config.minTime;
            projectData.end_time = null;
          } else {
            end_time_config.minTime = "";
          }
        }
      }
    }
    async function onSubmit(values) {
      try {
        loaderBar.value = true;
        const {
          end_date,
          end_time,
          project_description,
          start_date,
          start_time,
          no_of_visitors,
        } = values;
        const data = {
          project_id: projectData.project_id,
          project_name: projectData.project_name,
          location_id: projectData.location_id,
          project_status: projectData.project_status_en,
          end_date,
          end_time,
          project_description,
          start_date,
          start_time,
          no_of_visitors,
        };
        const enddate =
          end_date !== null
            ? `${end_date}`
            : `${date().formatDate(propsShiftEndDateTime.value, "YYYY-MM-DD")}`;
        const endtime =
          end_time !== null
            ? `${date().formatTime(end_time, "HH:mm")}`
            : `${date().formatDate(propsShiftEndDateTime.value, "HH:mm")}`;
        //check if project start time is greater than shift start time and check for end time is less than with project endtime
        const isAfterStartDate = date().isSameOrAfter(
          `${propsShiftStartDateTime.value}`,
          `${start_date} ${date().formatTime(start_time, "HH:mm")}`
        );
        const isBeforeEndDate = date().isSameOrBefore(
          `${propsShiftEndDateTime.value}`,
          `${enddate} ${endtime}`
        );
        if (
          props.shiftStartDateTime !== null &&
          props.shiftEndDateTime !== null &&
          (!isAfterStartDate || !isBeforeEndDate)
        ) {
          loaderBar.value = false;
          toast.error(i18n.$t("projectDetails.timeValidation"));
        } else {
          const result = await UPDATEPROJECTAPI(data);
          loaderBar.value = false;
          if (result && result.status === 200) {
            toast.success(i18n.$t("projectDetails.infoSaved"));
            showProject();
            context.emit("update");
          } else {
            toast.error(i18n.$t("projectDetails.failedToSave"));
          }
        }
      } catch (err) {
        loaderBar.value = false;
        toast.error(i18n.$t("projectDetails.failedToSave"));
      }
    }
    const schema = Yup.object().shape({
      project_name: Yup.string().required(),
      location_id: Yup.string().required(),
      start_date: Yup.string().required(),
      start_time: Yup.string().required(),
    });
    return {
      i18n,
      projectData,
      showEditBtn,
      showProjectBtn,
      start_date_config,
      end_date_config,
      start_time_config,
      end_time_config,
      loaderBar,
      propsProject,
      propsProjectStartDateTime,
      propsProjectEndDateTime,
      propsShiftStartDateTime,
      propsShiftEndDateTime,
      propsIsOCProject,
      schema,
      editProject,
      showProject,
      getProjectStatus,
      formatDate,
      formatTime,
      updateEndDate,
      updateEndTime,
      setEndMinTime,
      onSubmit,
    };
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped lang="scss">
.project_status {
  &:disabled,
  &[readonly] {
    background-color: #fff !important;
  }
}
.datepicker {
  &:disabled,
  &[readonly] {
    background-color: #fff !important;
  }
}
.project-rows {
  margin-bottom: 10px;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 10px;
  margin-right: 0px;
  cursor: pointer;
}
.project-col {
  padding-left: 0px;
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
  font-size: 0.875rem;
}

.margin-left {
  margin-left: 5px;
}
</style>
