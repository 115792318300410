import axios from '../Axios';
import { GETALLROLE } from '../CONSTANTURL';
export const GETALLROLEAPI = async () => {
  try {
    const result = await axios.get(`${GETALLROLE}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};
