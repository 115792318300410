<template>
  <div>
    <router-view v-if="allDataLoaded" :key="$route.fullPath"></router-view>

    <add-network-modal :showModal="models.invitationLinkModalVisible" :loader="loader"
      :invitationLink="models.invitationLink" :company="models.company" @close="closeModal"
      @update="openGeneratedLinkModal"></add-network-modal>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import en from "@/locales/en";
import de from "@/locales/de";
import { REFRESHTOKENAPI } from "@/services/public/Auth";
import { setPermission, has_permission } from "@/services/private/Role_Service";
import { messageService } from "@/services/_helper/message";
import { permission_list } from "@/services/_helper/user_permission";
import { provideI18n } from "@/util/i18n";
import storage from "@/util/storage";
import AddNetworkModal from "@/views/Network/AddNetworkModal.vue";
import InvitationLinkModal from "@/views/Network/InvitationLinkModal.vue";
import { useToast } from "vue-toastification";
import { GETPUPLICCLOUDIDBYSKILLAPI } from "@/services/private/Skills";
import Skill from "./views/SkillsView/Skill";

export default {
  components: {
    AddNetworkModal,
    InvitationLinkModal,
  },
  data() {
    return {
      loader: false,
      route: useRoute(),
      models: {
        invitationLinkModalVisible: false,
        invitationLinkGenerateModalVisible: false,
        invitationLinkCopyModalVisible: false,
        invitationLink: "",
        company: "",
      },
      messages: [],
    };
  },
  created() {
    // subscribe to home component messages
    this.subscription = messageService.getMessage().subscribe((message) => {
      if (message) {
        this.models.invitationLink = message.network_token.network_token;
        this.models.company = message.network_token.company;
        this.models.invitationLinkModalVisible = true;
        // add message to local state if not empty
        this.messages.push(message);
      } else {
        // clear messages when empty message received
        this.messages = [];
      }
    });
  },
  beforeUnmount() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  },
  methods: {
    generateInviteLink() {
      this.models.invitationLinkModalVisible = true;
    },
    closeModal() {
      this.models.invitationLinkModalVisible = false;
      // this.models.invitationLinkGenerateModalVisible = false;
      // this.models.invitationLinkCopyModalVisible = false;
    },
    openGeneratedLinkModal(company) {
      this.models.invitationLinkModalVisible = false;
      //this.models.invitationLinkGenerateModalVisible = true;
      //this.models.invitationLink = inviteLink;
    },
  },
  mounted() {
    // if (this.route.params.network_token) {
    //   this.generateInviteLink();
    // }
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    let allDataLoaded = ref(false);

    //i18n setup
    provideI18n({
      locale: process.env.VUE_APP_I18N_LOCALE || "de",
      messages: {
        en: en,
        de: de,
      },
    });

    function extractSkillIdFromUrl(url) {
      var id = url.match(/\/([^\/]+)$/)[1];
      return id;
    }


    async function fetchCloudIdsBySkill(skill_id) {
            const cloudIds = await GETPUPLICCLOUDIDBYSKILLAPI(skill_id);
            return cloudIds.data.data;
        }

    function createVideoListOfCloudIds(cloud_ids) {
            const videos = [];
            for (let i = 0; i < cloud_ids.length; i++) {
                videos.push({
                    "cloud_id": cloud_ids[i].cloud_id,
                    "url": `https://customer-czy559gndrdvu19x.cloudflarestream.com/${cloud_ids[i].cloud_id}/downloads/default.mp4`
                    , "watched": false
                })
            }
            return videos;
        }

     async function getSkill (skillId) {
            console.log("getSkill");
            console.log(skillId);
            const skill = [];
            const cloudIds = await fetchCloudIdsBySkill(skillId);
            const videos = createVideoListOfCloudIds(cloudIds);
            skill.push(new Skill(skillId, [], "", "test", videos));
            console.log(skill);
            store.commit("setSkills", skill);
            return skill;
        };

    async function checkRefreshToken() {
      const user = storage().getUser();
      const refresh_token = storage().getRefreshToken();
      const rememberMe = storage().getRememberMe();

      const data = {
        user_id: user.user_id,
        refresh_token,
        rememberMe,
      };
      const result = await REFRESHTOKENAPI(data);
      if (result.status === 200) {
        const { extraDetails, data } = result.data;
        const user = data[0];
        storage().setAccessToken(extraDetails.access_token);
        storage().setRefreshToken(extraDetails.refresh_token);
        storage().setUser({
          user_id: user.user_id,
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          role: user.role,
          company_id: user && user.company_id,
          current_version: user && user.current_version,
        });
        setPermission(user.permission, user.role);
        store.commit("authSuccess", {
          access_token: extraDetails.access_token,
          expired_at: extraDetails.expired_at,
          user: user,
        });
        allDataLoaded.value = true;
        if (has_permission(permission_list.skills_view.value) ||
          has_permission(permission_list.menu_myskills.value) && window.location.href.includes("skillsview")) {
          router.push({ name: "Skills View" })
        } else if (window.location.href.includes("viewstream") && has_permission(permission_list.menu_myskills.value)) {
          router.push({name: "Redirect Page", params: { skill_id: extractSkillIdFromUrl(window.location.href) } })
         }
        else if (
          user.date_of_birth == null ||
          user.address_street == null ||
          user.address_postcode == null ||
          user.address_city == null ||
          !has_permission(permission_list.menu_dashboard.value)
        ) {
          router.push({ name: "Profile" });
        }
      } else {
        allDataLoaded.value = true;
        storage().clear();
        store.commit("logout");
        router.push({ name: "Login" });
      }
    }
    onMounted(async () => {
      const refresh_token = localStorage.getItem("refresh_token");
      if (refresh_token) {
        checkRefreshToken();
      } else {
        if(window.location.href.includes("publicskills")) {
          const skillId = extractSkillIdFromUrl(window.location.href);
          await getSkill(skillId);
        }
        allDataLoaded.value = true; 
    }});

    return {
      allDataLoaded,
      getSkill,
      fetchCloudIdsBySkill,
      createVideoListOfCloudIds,
    };
  },
};
</script>
