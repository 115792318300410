<template>
  <!--<p v-if="showGoBackBtn" class="back-arrow ni ni-bold-left" @click="goBack"></p>-->
  <p class="logout" @click="logout()">Ausloggen</p>
  <div>
    <div class="overview">
      <DataSection />
      <p style="text-align: center;">Meine Skills</p>
      <StateBar />
    </div>
    <div class="skills-section">
      <ul class="card-list">
        <li @click="handleClick(skill)" class="card-item"
          v-for="(skill, index) in store.getters.skills.filter(skill => skill.status === store.getters.statebar)"
          :key="index">
          <SkillTile :skill="skill" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
//import SkillsView from './SkillsView.vue'
import { useRouter } from 'vue-router'
import DataSection from './DataSection.vue';
import StateBar from './StateBar.vue';
import SkillTile from './SkillTile.vue';
import { useStore } from 'vuex';
import { onMounted, ref } from 'vue';
import storage from "@/util/storage";
import Skill from './Skill';
import { GETSKILLIDBYUSERAPI, GETCLOUDIDBYSKILLAPI, GETSKILLWATCHEDAPI } from "@/services/private/Skills";
import { LOGOUTAPI } from "@/services/public/Auth"


const store = useStore();
const router = useRouter();
const skillStatus = ref("todo");
const showGoBackBtn = ref(true);

onMounted(async () => {
  await getSkillsList(storage().getUser().user_id)
  storage().getUser().role.role_code === "S" ? showGoBackBtn.value = false : showGoBackBtn.value === true;
})

function goBack() {
  router.push({ name: "Profile" })
}

const logout = async () => {
  const user = storage().getUser();
  await LOGOUTAPI({ user_id: user && user.user_id });
  storage().clear();
  store.commit("logout");
  window.location = "/#/login";
}

async function fetchSkillsByUser(user_id) {
  const skills = await GETSKILLIDBYUSERAPI(user_id);
  return skills.data.data;
}

async function fetchCloudIdsBySkill(skill_id) {
  const cloudIds = await GETCLOUDIDBYSKILLAPI(skill_id);
  return cloudIds.data.data;
}

async function getSkillsList(user_id) {
  const skillsList = [];
  const skills = await fetchSkillsByUser(user_id);
  for (let i = 0; i < skills.length; i++) {
    await getSkillStatus(user_id, skills[i].skill_id)
    const cloudIds = await fetchCloudIdsBySkill(skills[i].skill_id);
    const videos = createVideoListOfCloudIds(cloudIds);
   
    skillsList.push(new Skill(skills[i].skill_id, [], skillStatus.value, skills[i].skill_name, videos))
  }
  store.commit("setSkills", skillsList);
  return skillsList;
}

function createVideoListOfCloudIds(cloud_ids) {
  const videos = [];
  for (let i = 0; i < cloud_ids.length; i++) {
    videos.push({
      "cloud_id": cloud_ids[i].cloud_id,
      "url": `https://customer-czy559gndrdvu19x.cloudflarestream.com/${cloud_ids[i].cloud_id}/downloads/default.mp4`
      , "watched": false
    })
  }
  return videos;
}

async function getSkillStatus(user_id, skill_id) {
  const result = await GETSKILLWATCHEDAPI(user_id);
  if (result.data.data.unwatchedSkills.some(e => e.skill_id === skill_id)) { 
    skillStatus.value = "todo"
  }
  else if (result.data.data.watchedSkills.some(e => e.skill_id === skill_id)) { 
    skillStatus.value = "done"
  }
}


const handleClick = (skill) => {
  //store.commit("setActiveSkill", skill);
  router.push({ name: "View Stream", params: { skill_id: skill.skill_id } })
}

</script>

<style scoped>
ul {
  list-style-type: none;
  grid-template-columns: repeat(3, minmax(100px, 1fr));
  text-align: center;
}

.card-item {
  background-color: transparent;
 

}

.card-list {
  display: grid;
  grid-gap: 0.1em;
  padding: 1px;
}

.skills-section {
  transition: all 0.2s;
  margin-top: 8px;
}

.back-arrow {
  position: absolute;
  z-index: 999;
  font-size: 1.4rem;
  top: 4%;
  left: 4%;
  transform: translateY(-50%) translateX(-4%);
  color: black;
  cursor: pointer;
}

.logout {
  position: absolute;
  z-index: 999;
  font-size: 1.0rem;
  top: 4%;
  right: 4%;
  transform: translateY(-50%) translateX(-4%);
  color: black;
  cursor: pointer;
}
</style>
