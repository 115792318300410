<template>
    <div>
        <!-- Header -->
        <div class="header bg-gradient-warning py-7 py-lg-6 pt-lg-6">
            <div class="container">
                <div class="header-body text-center mb-7">
                    <div class="row justify-content-center">
                        <div class="col-xl-5 col-lg-6 col-md-8 px-5">
                            <h1 class="text-white">
                                {{ i18n.$t("registration.header") }}
                            </h1>
                            <p class="text-lead text-white">
                                {{ i18n.$t("registration.subHeader") }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="separator separator-bottom separator-skew zindex-100">
                <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
                    xmlns="http://www.w3.org/2000/svg">
                    <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
                </svg>
            </div>
        </div>
        <!-- Page content -->
        <div class="container mt--8 pb-5">
            <!-- Table -->
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-8">
                    <div class="card bg-secondary border-0">
                        <div class="card-header bg-transparent">
                            <div class="text-muted text-center">
                                <small>{{ i18n.$t("registration.form.header") }}</small>
                            </div>
                        </div>
                        <div class="card-body px-lg-5 py-lg-5">
                            <Form v-slot="{ meta, errors }" @submit="onSubmit" :validation-schema="schema">
                                <base-input alternative class="mb-3" addon-left-icon="ni ni-hat-3"
                                    :placeholder="i18n.$t('registration.form.input.firstName')" name="firstName">
                                </base-input>
                                <base-input alternative class="mb-3" addon-left-icon="ni ni-hat-3"
                                    :placeholder="i18n.$t('registration.form.input.lastName')" name="lastName">
                                </base-input>
                                <base-input alternative class="mb-3" addon-left-icon="ni ni-mobile-button"
                                    :placeholder="i18n.$t('registerRegistrationCode.form.input.phone')" name="phoneNumber">
                                </base-input>
                                <div v-if="invalidMobileNo" class="text-danger small mb-3 block">
                                    {{ i18n.$t("message.invalidMobileNo") }}
                                </div>

                                <!-- <div class="form-group">
                                        <div class="input-group mb-3"
                                            :class="{ 'has-danger-border-red': errors.mobile_no }">
                                            <div class="input-group-prepend" style="font-size: 3em; cursor: pointer;" @click="countryCodeClick">
                                                <span class="input-group-text font-weight-normal" >+49</span>
                                            </div>
                                            
                                            <Field class="form-control" name="mobile_no" type="text" id="mobile_no"
                                                :placeholder="i18n.$t('editProfile.form.telePhoneNumber')"
                                                maxlength="25"></Field>
                                        </div>
                                        <div class="text-danger mt-2 small" v-show="errors.mobile_no">
                                            {{ i18n.$t("registerRegistrationCode.form.input.phone") }}
                                        </div>
                                    </div>-->





                                <div v-if="!showCodeInput" class="row mt-4">
                                    <div class="col-12">
                                        <base-checkbox name="agree" v-model="model.agree">
                                            <span class="text-muted">{{
                                                i18n.$t("registration.form.input.checkbox.textStart")
                                            }}
                                                <a href="https://peeepl.de/datenschutzerklaerung-peeeplapp" target="_blank">
                                                    {{ i18n.$t("registration.form.input.checkbox.link1") }}
                                                </a>
                                                {{
                                                    i18n.$t("registration.form.input.checkbox.textAnd")
                                                }}
                                                <a href="https://peeepl.de/nutzungsbedingungen-peeeplapp" target="_blank">
                                                    {{ i18n.$t("registration.form.input.checkbox.link2") }}
                                                </a>
                                                {{
                                                    i18n.$t("registration.form.input.checkbox.textEnd")
                                                }}.
                                            </span>
                                        </base-checkbox>
                                    </div>
                                </div>
                                <div v-if="!showCodeInput" class="text-center">
                                    <button type="submit" :disabled="!(model.agree) || loader" class="btn btn-warning mt-4">
                                        <div class="d-flex align-items-center">
                                            <spinner v-show="loader" />
                                            <span>{{ i18n.$t("registerRegistrationCode.form.button.requestCode") }}</span>
                                        </div>
                                    </button>
                                </div>
                                <base-input id="inputCode" v-if="showCodeInput" alternative type="phone" class="mt-5"
                                    addon-left-icon="ni ni-key-25"
                                    :placeholder="i18n.$t('registerRegistrationCode.form.input.enterCode')" name="code">
                                </base-input>
                                <div v-if="showCodeInput" class="text-center">
                                    <button @click="onClickRegister" class="btn btn-warning mt-4">
                                        <div class="d-flex align-items-center">
                                            <spinner v-show="loader" />
                                            <span>{{ i18n.$t("registerRegistrationCode.form.button.register") }}</span>
                                        </div>
                                    </button>
                                </div>
                            </Form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "@/util/i18n";
import { useToast } from "vue-toastification";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { REGISTERBYPHONE, REGISTERAPI, SENDCODEREGISTER } from "@/services/public/Auth";
const md5 = require("md5");
const phoneNumberRegex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
const phoneNumberRegexwithZero = /^(?:\+|0)(?:[0-9] ?){6,14}[0-9]$/;
export default {
    name: "registerPhone",
    components: {
        Form,
        Field
    },
    data() {
        return {
            model: {
                firstName: "",
                lastName: "",
                phoneNumber: "",
                code: "",
                agree: false,
            },
        };
    },
    methods: {},
    setup() {
        const i18n = useI18n();
        const toast = useToast();
        const router = useRouter();
        const route = useRoute();
        const inputCodeField = ref("");
        let loader = ref(false);
        const showCodeInput = ref(false);
        const invalidMobileNo = ref(false);

        function validateMobileNo(mobile_no) {
            mobile_no = mobile_no.trim();
            mobile_no = mobile_no.replace(/\s/g, '');

            if (mobile_no.startsWith('0')) {
                mobile_no = '+49' + mobile_no.slice(1);
            }
            
            if (!phoneNumberRegex.test(mobile_no) || mobile_no.length < 11) {
                invalidMobileNo.value = true;
                return null;
            }

            return mobile_no;
        }

        async function onSubmit(values, actions) {
            //mobile_no.value = document.getElementById('mobile_no').value;

            var { firstName, lastName, phoneNumber, code } = values;
            phoneNumber = validateMobileNo(phoneNumber);
            if (phoneNumber === null) return;
            var data = {};
            if (route.params.invite_token) {
                data = {
                    first_name: firstName,
                    last_name: lastName,
                    phoneNumber: phoneNumber,//mobile_no.value,
                    code: code,
                    invite_token: route.params.invite_token,
                };
            } else {
                data = {
                    first_name: firstName,
                    last_name: lastName,
                    phoneNumber: phoneNumber, //mobile_no.value,
                    code: code
                    //network_token: route.params.network_token,
                };
            }

            if (showCodeInput.value) {
                await onSubmitRegister(data, actions);
            } else {
                await onSubmitRequestCode(data);
            }


        }

        async function onSubmitRequestCode(data) {
            var requestData = { mphone: data.phoneNumber }
            loader.value = true;
            const result = await SENDCODEREGISTER(requestData);
            console.log(result);
            loader.value = false;

            if (
                result.error &&
                result.response &&
                result.response.data &&
                result.response.data.status === 409
            ) {
                // sameEmail.value = true;
            } else if (result && result.status === 200) {
                console.log(result);
                showCodeInput.value = true;
                //actions.resetForm();
                //router.push({ name: "RegisterSuccess", params: { success: true } });
            } else {
                toast.error(i18n.$t("message.wrong"));
            }
        }

        async function onSubmitRegister(data, actions) {
            if (data.invite_token) {
                var requestData = { mphone: data.phoneNumber, user_code: data.code, first_name: data.first_name, last_name: data.last_name, invite_token: data.invite_token }
            } else {
                var requestData = { mphone: data.phoneNumber, user_code: data.code, first_name: data.first_name, last_name: data.last_name }
            }
            loader.value = true;
            const result = await REGISTERBYPHONE(requestData);
            console.log(result);
            loader.value = false;

            if (
                result.error &&
                result.response &&
                result.response.data &&
                result.response.data.status === 409
            ) {
                toast.error(i18n.$t("registration.form.sameMobileNo"));
            } else if (result && result.status === 200) {
                console.log(result);
                //router.push({ name: "RegisterSuccessLinkRequired", params: { success: true } });
                setTimeout(() => {
                    router.push("/login");
                }, 1000);
                actions.resetForm();
            } else {
                toast.error(i18n.$t("message.wrong"));
            }
        }

        async function register(data) {
            var requestData = { first_name: data.first_name, last_name: data.last_name, mphone: data.phoneNumber };
            loader.value = true;
            const result = await REGISTERAPI(requestData);
            console.log(result);
            loader.value = false;
        }

        function countryCodeClick() {
            alert("COUNTRY ROOOOOAAAAD; TAKE ME HOOOOME")
        }


        const schema = Yup.object().shape({
            firstName: Yup.string().required(),
            lastName: Yup.string().required(),
            phoneNumber: Yup.string().matches(phoneNumberRegexwithZero, 'Phone number is not valid').required(i18n.$t("message.invalidMobileNo"))
        });

        return {
            i18n,
            schema,
            loader,
            onSubmit,
            onSubmitRequestCode,
            onSubmitRegister,
            showCodeInput,
            inputCodeField,
            countryCodeClick,
            invalidMobileNo,
        };
    },
};
</script>
<style>
.phoneInput {
    position: relative;
    display: flex;

}

.field {
    display: flex;
    align-items: center;
}

.phoneIcon {
    position: relative;
    cursor: pointer;

}

.inputField {
    width: 100%;
}
</style>