<template>
  <div class="card card-profile">
    <div class="row justify-content-center">
      <div class="col-lg-3 order-lg-2">
        <div class="card-profile-image">
          <router-link to="/dashboard">
            <span translate="no">{{ setInitialsName }}</span>
          </router-link>
        </div>
      </div>
    </div>

    <!-- <div class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
        <div class="d-flex justify-content-between">
        </div>
      </div> -->

    <div class="card-body pt-5">
      <div class="row">
        <div class="col">
          <div class="card-profile-stats d-flex justify-content-center">
            <div class="text-center">
              <h5 class="h3 text-capitalize">
                {{ fullName }}
                <span class="font-weight-light">
                  {{ getAge !== "" ? `, ${getAge}` : "" }}
                </span>
              </h5>
              <div
                class="
                  h5
                  font-weight-300
                  d-flex
                  align-items-center
                  justify-content-center
                "
                v-if="getCityCountry !== ''"
              >
                <i class="ni ni-pin-3 mr-1"></i>
                <span>{{ getCityCountry }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
        <div class="mb-3">
          <Field
            as="textarea"
            class="form-control"
            :class="{ 'has-danger-border-red': errors.personal_info }"
            name="personal_info"
            :placeholder="i18n.$t('editProfile.personalInfo.placeholder')"
            v-model="personal_info"
            :disabled="!showEditBtn"
            rows="10"
            maxlength="500"
          ></Field>
        </div>
        <div class="text-right">
          <base-button
            type="warning"
            native-type="button"
            v-show="showProfileBtn"
            @click="editProfile"
            class="btn-sm"
          >
            <div class="d-flex align-items-center">
              <span>{{ i18n.$t("editProfile.editInformationUser") }}</span>
            </div>
          </base-button>
          <div v-show="showEditBtn">
            <base-button type="primary" native-type="submit" class="btn-sm">
              <div class="d-flex align-items-center">
                <spinner v-show="loader" />
                <span>{{ i18n.$t("editProfile.saveUserInfo") }}</span>
              </div>
            </base-button>
            <base-button
              type=""
              native-type="button"
              @click="showProfile"
              class="btn-sm"
            >
              <div class="d-flex align-items-center">
                <span>{{ i18n.$t("editProfile.discard") }}</span>
              </div>
            </base-button>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>
<script>
import { computed, ref, reactive, watch } from "vue";
import { useI18n } from "@/util/i18n";
import { useStore } from "vuex";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { useToast } from "vue-toastification";
import age from "@/util/age";
import { UPDATEPERSONALINFORAPI } from "@/services/private/Profile";
export default {
  props: {
    profile: Object,
  },
  components: {
    Form,
    Field,
  },
  data() {
    return {};
  },
  computed: {
    fullName() {
      return `${this.$store.getters.fullName}`;
    },
    setInitialsName() {
      return `${this.$store.getters.initialsName}`;
    },
    getAge() {
      const user = this.$store.state.user;
      return age(user && user.date_of_birth);
    },
  },
  methods: {},
  setup(props, context) {
    const i18n = useI18n();
    const toast = useToast();
    const store = useStore();
    let loader = ref(false);
    let showEditBtn = ref(false);
    let showProfileBtn = ref(true);
    let personal_info = ref("");

    const getCityCountry = computed(
      () =>
        `${
          props.profile.address_city ? `${props.profile.address_city}, ` : ""
        }${props.profile.address_country ? props.profile.address_country : ""}`
    );
    watch(
      () => props.profile,
      (currentValue, oldValue) => {
        personal_info.value = currentValue.personal_info;
      },
      { deep: true }
    );

    async function onSubmit() {
      loader.value = true;
      const user_id = store.state.user.user_id;
      const modified_by = user_id;
      const data = {
        user_id,
        personal_info: personal_info.value,
        modified_by,
      };
      const result = await UPDATEPERSONALINFORAPI(data);
      loader.value = false;
      if (
        result.error &&
        result.response &&
        result.response.data &&
        result.response.data.status === 400
      ) {
        toast.error(i18n.$t("editProfile.personalInfo.error"));
      } else if (result && result.status === 200) {
        const { data } = result.data;
        store.commit("setUser", data[0]);
        toast.success(i18n.$t("editProfile.infoSaved"));
        showEditBtn.value = false;
        showProfileBtn.value = true;
        context.emit("update");
      } else {
        toast.error(i18n.$t("message.wrong"));
      }
    }
    function editProfile() {
      showEditBtn.value = true;
      showProfileBtn.value = false;
    }
    function showProfile() {
      showEditBtn.value = false;
      showProfileBtn.value = true;
      personal_info.value = props.profile.personal_info;
    }
    const schema = Yup.object().shape({
      personal_info: Yup.string().required().max(500),
    });

    return {
      i18n,
      schema,
      showEditBtn,
      showProfileBtn,
      loader,
      personal_info,
      getCityCountry,
      onSubmit,
      editProfile,
      showProfile,
    };
  },
};
</script>
<style></style>

