<template>
    <div>
        <!-- Header -->
        <div class="header bg-gradient-warning py-7 py-lg-6 pt-lg-6">
            <div class="container">
                <div class="header-body text-center mb-7">
                    <div class="row justify-content-center">
                        <div class="col-xl-5 col-lg-6 col-md-8 px-5">
                            <h1 class="text-white">
                                {{ i18n.$t("registration.header") }}
                            </h1>
                            <p class="text-lead text-white">
                                {{ i18n.$t("registration.subHeader") }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="separator separator-bottom separator-skew zindex-100">
                <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
                    xmlns="http://www.w3.org/2000/svg">
                    <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
                </svg>
            </div>
        </div>
        <!-- Page content -->
        <div class="container mt--8 pb-5">
            <!-- Table -->
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-8">
                    <div class="card bg-secondary border-0">
                        <div class="card-header bg-transparent">
                            <div class="text-center">
                                <small>{{ i18n.$t("registerRegistrationCode.form.header") }}</small>
                            </div>
                        </div>
                        <div class="text-muted text-center py-2">
                            <small>{{ lastSubHeader }}</small>
                        </div>


                        <div class="card-body " v-if="showChoiceCard">
                            <Form>
                                <div class="row justify-content-center">
                                    <button class=" btn btn-white px-2 py-2 m-1 text-xs mr-2" name="emailPassword"
                                        @click="useEmail">

                                        <div class="d-flex align-items-center">
                                            <spinner v-show="loader" />
                                            <span class="text-muted text-center" addon-left-icon="ni ni-email-83">{{
                                                i18n.$t("registerRegistrationCode.form.button.useEmail") }}</span>
                                        </div>
                                    </button>
                                </div>

                                <div class="row justify-content-center">
                                    <button alternative class="btn btn-white px-2 py-2 m-1 text-xs mr-2" name="registerCode"
                                        @click="usePhone">

                                        <div class="d-flex align-items-center">
                                            <spinner v-show="loader" />
                                            <span class="text-muted text-center">{{
                                                i18n.$t("registerRegistrationCode.form.button.usePhoneNumber") }}</span>
                                        </div>

                                    </button>
                                </div>
                            </Form>
                        </div>

                        <div class="card-body" v-if="showEmailInput">
                            <Form>
                                <div class="row justify-content-center">
                                    <base-input alternative type="email" class="mb-3" addon-left-icon="ni ni-email-83"
                                        :placeholder="i18n.$t('registerRegistrationCode.form.input.enterEmail')"
                                        name="email">
                                    </base-input>
                                </div>


                                <div class="text-center"><button @click="requestCodeClicked" type="submit"
                                        class="btn base-button btn-warning my-4"><!---->
                                        <div class="d-flex align-items-center">
                                            <div class="spinner-border mr-2 text-white" role="status"
                                                style="display: none;"><span class="sr-only">Loading...</span></div>
                                            <span>{{
                                                i18n.$t("registerRegistrationCode.form.button.requestCode") }}</span>
                                        </div>
                                    </button></div>

                            </Form>
                        </div>

                        <div class="card-body " v-if="showPhoneInput">
                            <Form>
                                <div class="row justify-content-center">
                                    <base-input alternative type="phone" class="mb-3" addon-left-icon="ni ni-email-83"
                                        :placeholder="+49" name="phoneNumber">
                                    </base-input>
                                </div>

                                <div class="text-center"><button @click="requestCodeClicked" type="submit"
                                        class="btn base-button btn-warning my-4"><!---->
                                        <div class="d-flex align-items-center">
                                            <div class="spinner-border mr-2 text-white" role="status"
                                                style="display: none;"><span class="sr-only">Loading...</span></div>
                                            <span>{{
                                                i18n.$t("registerRegistrationCode.form.button.requestCode") }}</span>
                                        </div>
                                    </button></div>
                            </Form>
                        </div>

                        <div class="card-body " v-if="showCodeInput">
                            <Form>
                                <div class="row justify-content-center">
                                    <base-input alternative type="phone" class="mb-3" addon-left-icon="ni ni-email-83"
                                        :placeholder="i18n.$t('registerRegistrationCode.form.input.enterCode')"
                                        name="phoneNumber">
                                    </base-input>
                                </div>
                                    <div class="copyright  text-center text-muted" style="cursor: pointer;">
                                        <a @click="requestNewCode" class="font-weight-bold ml-1">
                                            {{ i18n.$t("registerRegistrationCode.form.button.requestNewCode") }}</a>
                                    </div>
                                <div class="row mt-4">
                                    <div class="col-12">
                                        <base-checkbox name="agree" v-model="model.agree">
                                            <span class="text-muted">{{
                                                i18n.$t("registerRegistrationCode.form.checkbox.textStart")
                                            }}
                                                <a href="https://peeepl.de/datenschutzerklaerung-peeeplapp" target="_blank">
                                                    {{ i18n.$t("registerRegistrationCode.form.checkbox.link1") }}
                                                </a>
                                                {{
                                                    i18n.$t("registerRegistrationCode.form.checkbox.textAnd")
                                                }}
                                                <a href="https://peeepl.de/nutzungsbedingungen-peeeplapp" target="_blank">
                                                    {{ i18n.$t("registerRegistrationCode.form.checkbox.link2") }}
                                                </a>
                                                {{
                                                    i18n.$t("registerRegistrationCode.form.checkbox.textEnd")
                                                }}.
                                            </span>
                                        </base-checkbox>
                                    </div>
                                </div>
                                <div class="text-center"><button @click="registrationCodeRouting" type="submit"
                                        class="btn base-button btn-warning my-4"><!---->
                                        <div class="d-flex align-items-center">
                                            <div class="spinner-border mr-2 text-white" role="status"
                                                style="display: none;"><span class="sr-only">Loading...</span></div>
                                            <span>{{
                                                i18n.$t("registerRegistrationCode.form.button.register") }}</span>
                                        </div>
                                    </button></div>
                            </Form>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "@/util/i18n";
import { useToast } from "vue-toastification";
import { Form } from "vee-validate";
import * as Yup from "yup";
import { REGISTERAPI } from "@/services/public/Auth";
const md5 = require("md5");

export default {
    name: "registerRegistrationCode",
    components: {
        Form,
    },
    data() {
        return {
            model: {
                email: "",
                pwd: "",
                agree: false,
            },
        };
    },
    methods: {},
    setup() {
        const i18n = useI18n();
        const toast = useToast();
        const router = useRouter();
        const route = useRoute();
        let loader = ref(false);

        const showEmailInput = ref(false);
        const showPhoneInput = ref(false);
        const showChoiceCard = ref(true);
        const showCodeInput = ref(false);
        const lastSubHeader = ref(i18n.$t("registerRegistrationCode.form.subHeaderRequest"));

        function useEmail() {
            showEmailInput.value = true;
            showChoiceCard.value = false;
        }

        function usePhone() {
            showPhoneInput.value = true;
            showChoiceCard.value = false;
        }

        function requestCodeClicked() {
            if (showPhoneInput.value === true) {
                lastSubHeader.value = i18n.$t("registerRegistrationCode.form.subHeaderSendPhone")
            } else {
                lastSubHeader.value = i18n.$t("registerRegistrationCode.form.subHeaderSendMail")
            }
            showEmailInput.value = false;
            showPhoneInput.value = false;
            showCodeInput.value = true;
        }

        function requestNewCode(){
            alert("PIMMMEL");
        }

        async function onSubmit(values, actions) {
            const { email, pwd } = values;
            var data = {};
            if (route.params.invite_token) {
                data = {
                    email,
                    pwd: md5(pwd),
                    invite_token: route.params.invite_token,
                };
            } else {
                data = {
                    email,
                    pwd: md5(pwd),
                    //network_token: route.params.network_token,
                };
            }
            loader.value = true;
            const result = await REGISTERAPI(data);
            loader.value = false;
            if (
                result.error &&
                result.response &&
                result.response.data &&
                result.response.data.status === 409
            ) {
                sameEmail.value = true;
            } else if (
                result &&
                result.status === 200 &&
                route.params.network_token
            ) {
                actions.resetForm();
                router.push({
                    name: "RegisterSuccessNetwork",
                    params: { success: true },
                });
            } else if (result && result.status === 200) {
                actions.resetForm();
                router.push({ name: "RegisterSuccess", params: { success: true } });
            } else {
                toast.error(i18n.$t("message.wrong"));
            }
        }
        function removeSameErrorMsg() {
            sameEmail.value = false;
        }

        const schema = Yup.object().shape({
            email: Yup.string().email().required(),
            pwd: Yup.string()
                .min(8)
                .required()
                .matches(
                    "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-\/§()=<>;+,:']).{8,}$",
                    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
                ),
        });

        return {
            i18n,
            schema,
            loader,
            onSubmit,
            removeSameErrorMsg,
            showChoiceCard,
            showCodeInput,
            showEmailInput,
            showPhoneInput,
            useEmail,
            usePhone,
            requestCodeClicked,
            lastSubHeader,
            requestNewCode
        };
    },
};
</script>
<style></style>
  