<template>
  <div>
    <base-header type="default" class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-12">
          <h6 class="h2 text-white d-inline-block mb-0">
            {{ i18n.$t("staff.header") }}
          </h6>
        </div>
        <div class="col-lg-6 col-12 text-right">
          <div
            class="
              d-flex
              flex-column flex-sm-row
              justify-content-end
              align-items-end
            "
          >
          <base-button
              class="mb-2 mr-0 mr-sm-2"
              v-if="isAccessible(permission.staff_add) && !models.editSkillsClicked"
              size="sm"
              type="warning"
              @click="openSendNotificationSelectStaffModal()"
            >
              Mitteilung senden
            </base-button>
            <base-button
              class="mb-2 mr-0 mr-sm-2"
              v-if="isAccessible(permission.staff_add) && !models.editSkillsClicked"
              size="sm"
              type="warning"
              @click="generateInviteLink()"
            >
              {{ i18n.$t("common.add") }}
            </base-button>
            <!--PLEASE NOTE: Remove FALSE in the below 3 base-buttons, if you want to implement Skills-Activation for a User-->
            <base-button
              class="mb-2 mr-0 mr-sm-2"
              v-if="isAccessible(permission.staff_add) && !models.editSkillsClicked &&false"
              size="sm"
              type="warning"
              @click="editSkills()"
            >
              {{ i18n.$t("skills.list.editSkills") }}
            </base-button>
            <base-button
              class="mb-2 mr-0 mr-sm-2"
              v-if="models.editSkillsClicked &&false"
              size="sm"
              type="warning"
              @click=""
            >
            {{ i18n.$t("editProfile.save") }}
            </base-button>
            <base-button
              class="mb-2 mr-0 mr-sm-2"
              v-if="models.editSkillsClicked && false"
              size="sm"
              type="warning"
              @click="models.editSkillsClicked = false"
            >
            {{ i18n.$t("editProfile.discard") }}
            </base-button>
            <base-button
              class="mb-2 mr-0 mr-sm-2"
              v-if="isAccessible(permission.staff_showalluser) && !models.editSkillsClicked"
              size="sm"
              type="secondary"
              @click="toggleUserList(!showDelete)"
            >
              {{ displayUserListState }}
            </base-button>
          </div>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <light-table :staffData="staffs.value" :checkboxDisabled="!models.editSkillsClicked" @update="getUpdatedStaff" />
        </div>
      </div>
    </div>
    <invitation-link-modal
      :showModal="models.invitationLinkModalVisible"
      :loader="loader"
      :setSelectedRole="models.setSelectedRole"
      @close="closeModal"
      @update="openGeneratedLinkModal"
    ></invitation-link-modal>
    <invitation-link-generate-modal
      :showModal="models.invitationLinkGenerateModalVisible"
      :loader="loader"
      :invitationLink="models.invitationLink"
      @close="closeModal"
      @update="openCopyLinkModal"
    ></invitation-link-generate-modal>
    <invitation-link-copy-modal
      :showModal="models.invitationLinkCopyModalVisible"
      :invitationLink="models.invitationLink"
      @close="closeModal"
    ></invitation-link-copy-modal>
    <send-notification-select-staff-modal
      :showModal="models.sendNotificationSelectStaffModalVisible"
      :loader="loader"
      @close="closeModal">
    </send-notification-select-staff-modal>
  </div>
</template>
<script>
import { ref, reactive, onMounted } from "vue";
import { useI18n } from "@/util/i18n";
import LightTable from "./LightTable.vue";
import InvitationLinkModal from "./InvitationLinkModal.vue";
import InvitationLinkGenerateModal from "./InvitationLinkGenerateModal.vue";
import InvitationLinkCopyModal from "./InvitationLinkCopyModal.vue";
import SendNotificationSelectStaffModal from "./SendNotificationSelectStaffModal.vue";
import { COMPANYUSERAPI } from "@/services/private/Company";
import { has_permission } from "@/services/private/Role_Service";
import { permission_list } from "@/services/_helper/user_permission";
import { UNLOCKUSERAPI, DELETEUSERAPI } from "@/services/private/Skills";

export default {
  components: {
    LightTable,
    InvitationLinkModal,
    InvitationLinkGenerateModal,
    InvitationLinkCopyModal,
    SendNotificationSelectStaffModal
  },
  data() {
    return {
      loader: false,
      permission: permission_list,
      models: {
        invitationLinkModalVisible: false,
        invitationLinkGenerateModalVisible: false,
        invitationLinkCopyModalVisible: false,
        sendNotificationSelectStaffModalVisible: false,
        invitationLink: "",
        editSkillsClicked: false
      },
    };
  },
  methods: {
    isAccessible(permission) {
      return has_permission(permission.value);
    },
    generateInviteLink() {
      this.models.invitationLinkModalVisible = true;
    },
    editSkills() {
      this.models.editSkillsClicked = true;
    },
    openGeneratedLinkModal(inviteLink) {
      this.models.invitationLinkModalVisible = false;
      this.models.invitationLinkGenerateModalVisible = true;
      this.models.invitationLink = inviteLink;
    },
    openCopyLinkModal() {
      this.models.invitationLinkGenerateModalVisible = false;
      this.models.invitationLinkCopyModalVisible = true;
    },
    openSendNotificationSelectStaffModal() {
      this.models.invitationLinkModalVisible = false;
      this.models.invitationLinkGenerateModalVisible = false;
      this.models.invitationLinkCopyModalVisible = false;
      this.models.sendNotificationSelectStaffModalVisible = true;
    },
    closeModal() {
      this.models.invitationLinkModalVisible = false;
      this.models.invitationLinkGenerateModalVisible = false;
      this.models.invitationLinkCopyModalVisible = false;
      this.models.sendNotificationSelectStaffModalVisible = false;
    },
  },
  setup() {
    const i18n = useI18n();
    let displayUserListState = ref(i18n.$t("common.showActiveUsers"));
    let showDelete = ref(false);
    let staffs = reactive({ value: [] });
    async function toggleUserList(state) {
      showDelete.value = state;
      if (state) {
        displayUserListState.value = i18n.$t("common.hideInactive");
      } else {
        displayUserListState.value = i18n.$t("common.showActiveUsers");
      }
      await getCompanyStaff(!state);
    }
    async function getCompanyStaff(state) {
      const result = await COMPANYUSERAPI({ is_active: state });
      if (result && result.status === 200) {
        staffs.value = result.data.data.map((r) => {
          return {
            company_id: r.company_id,
            email: r.email,
            first_name: r.first_name,
            is_active: r.role.is_active,
            last_name: r.last_name,
            role_name: r.role.role_name,
            user_id: r.user_id,
            role_code: r.role.role_code,
            version_no: r.version_no,
          };
        });
      }
    }
    async function getUpdatedStaff() {
      await getCompanyStaff(true);
    }
    onMounted(async () => {
      await getCompanyStaff(true);
    });
    return {
      i18n,
      displayUserListState,
      showDelete,
      toggleUserList,
      staffs,
      getUpdatedStaff,
    };
  },
};
</script>
