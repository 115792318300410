<template>
  <div class="card card-profile">
    <div style="margin-top: 80px" class="row justify-content-center">
      <div class="col-lg-3 order-lg-2">
        <div class="card-profile-image">
          <router-link to="/dashboard">
            <span translate="no">{{ initialsName }}</span>
          </router-link>
        </div>
      </div>
    </div>

    <div class="card-body pt-5">
      <div class="row">
        <div class="col">
          <div class="card-profile-stats d-flex justify-content-center">
            <div class="text-center">
              <h5 class="h3">
                {{ fullName }}
                <span class="font-weight-light">
                  {{ getAge !== "" ? `, ${getAge}` : "" }}
                </span>
              </h5>
              <div
                class="
                  h5
                  font-weight-300
                  d-flex
                  align-items-center
                  justify-content-center
                "
                v-if="getCityCountry !== ''"
              >
                <i class="ni ni-pin-3 mr-1"></i>
                <span>{{ getCityCountry }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <Form>
            <div class="pl-lg-4">
              <div class="row">
                <div class="col-lg-12">
                  <label>{{ i18n.$t("editProfile.form.birthDay") }}</label>
                  <Field
                    class="form-control"
                    name="birthday"
                    type="text"
                    :placeholder="i18n.$t('editProfile.form.birthDay')"
                    v-model="profile.date_of_birth"
                    disabled
                  >
                  </Field>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <label>{{
                    i18n.$t("projectDetails.shiftRequest.addressLabel")
                  }}</label>
                  <Field
                    class="form-control"
                    name="address"
                    type="text"
                    :placeholder="
                      i18n.$t('projectDetails.shiftRequest.addressLabel')
                    "
                    v-model="getAddress"
                    disabled
                  >
                  </Field>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <label>{{
                    i18n.$t("editProfile.form.telePhoneNumber")
                  }}</label>
                  <Field
                    class="form-control"
                    name="mobile_no"
                    type="text"
                    id="mobile_no"
                    :placeholder="i18n.$t('editProfile.form.telePhoneNumber')"
                    v-model="profile.mobile_no"
                    disabled="true"
                    maxlength="25"
                  ></Field>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <label>Email</label>
                  <Field
                    class="form-control"
                    name="email"
                    type="email"
                    label="E-mail"
                    placeholder="E-mail"
                    v-model="profile.email"
                    disabled
                  >
                  </Field>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useI18n } from "@/util/i18n";
import { Field, Form } from "vee-validate";
import age from "@/util/age";
import initialsName from "@/util/initialsName";

export default {
  props: {
    profile: Object,
  },
  components: {
    Field,
    Form,
  },
  data() {
    return {
      //user: this.profile,
    };
  },
  computed: {
    getAddress() {
      if (this.profile) {
        let data = "";
        if (this.profile.address_street) {
          data = data + " " + this.profile.address_street;
        }
        if (this.profile.address_houseno) {
          data = data + " " + this.profile.address_houseno;
        }
        if (this.profile.address_postcode) {
          data = data + " " + this.profile.address_postcode;
        }
        return data;
      }
    },
    fullName() {
      if (this.profile && this.profile.first_name && this.profile.last_name) {
        return `${this.profile.first_name} ${this.profile.last_name}`;
      }
    },
    getCityCountry() {
      return `${
        this.profile.address_city ? `${this.profile.address_city}, ` : ""
      }${this.profile.address_country ? this.profile.address_country : ""}`;
    },
    initialsName() {
      if (this.profile && this.profile.first_name && this.profile.last_name) {
        return initialsName(
          `${this.profile.first_name} ${this.profile.last_name}`
        );
      }
      return "";
    },

    getAge() {
      return age(this.profile && this.profile.date_of_birth);
    },
  },
  methods: {},
  async mounted() {},
  setup() {
    const i18n = useI18n();
    return {
      i18n,
    };
  },
};
</script>
