<template>
  <div>
    <div class="mr-3">
      <span
        class="border-bottom py-3 px-0 d-block"
        v-for="(n, index) in staffs"
        :key="index"
        :class="getStatusClass(n)"
      >
        <div class="d-flex align-items-center">
          <div class="col-auto px-1">
            <span
              @click="openProfile(n)"
              class="avatar avatar-sm rounded-circle cursor-pointer"
            >
              {{ getInitialNames(n) }}
            </span>
          </div>
          <div class="col pl-2 pr-2">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h4 class="mb-0 text-sm font-weight-bolder text-capitalize">
                      {{ getName(n) }}
                    </h4>
                  </div>
                </div>
                <p class="text-sm-2 mb-0">
                  <badge
                    class="
                      badge-dot
                      text-sm-2
                      badge-md
                      p-0
                      d-flex
                      align-items-center
                    "
                    :type="getStatusColor(n)"
                    v-if="status !== 'scheduled'"
                  >
                    <i :class="`bg-${getStatusColor(n)}`"></i>
                    <span class="status text-gray text-capitalize">
                      {{ getStaffStatus(n) }}
                    </span>
                  </badge>
                  <badge
                    class="
                      badge-dot
                      text-sm-2
                      badge-md
                      p-0
                      d-flex
                      align-items-center
                    "
                    v-else
                  >
                    <span class="status text-gray text-capitalize">
                      {{ getStaffStatus(n) }}
                    </span>
                  </badge>
                </p>
                <div v-if="n.request_feedback">
                  <el-popover
                    placement="bottom"
                    :width="250"
                    trigger="click"
                    :content="n.request_feedback"
                  >
                    <template #reference>
                      <span
                        class="
                          d-block
                          mt-2
                          font-weight-500
                          text-sm-2 text-left text-gray-dark
                          cursor-pointer
                        "
                      >
                        {{ i18n.$t("projectDetails.shifts.showTheMessage") }}
                      </span>
                    </template>
                  </el-popover>
                </div>
              </div>
              <div
                class="px-2 d-flex align-items-center justify-content-center"
                v-if="status === 'requested'"
              >
                <i
                  class="fas fa-question text-warning"
                  v-if="
                    n.shift_request_status === shiftStatus.userTentative ||
                    n.shift_request_status === shiftStatus.icRequestTentative
                  "
                ></i>
                <i
                  class="fas fa-times text-danger"
                  v-if="
                    n.shift_request_status === shiftStatus.userUnavailable ||
                    n.shift_request_status === shiftStatus.icRequestUnavailable
                  "
                ></i>
                <h4
                  class="font-weight-bolder mb-0 ml-3"
                  v-if="n && n.shift_partner_request_id"
                >
                  {{ n.count }}
                </h4>
              </div>
              <div class="action-options" v-else-if="status === 'available'">
                <div class="d-flex align-items-center justify-content-center">
                  <div class="form-check d-flex">
                    <input
                      type="checkbox"
                      class="form-check-input position-static mr-3"
                      v-model="availableResponse"
                      :value="index"
                      number
                    />
                  </div>
                  <button
                    class="btn btn-outline-dark btn-sm"
                    @click="updateRequest(n, shiftStatus.userPerhaps)"
                  >
                    <i class="fas fa-question"></i>
                  </button>
                  <button
                    class="btn btn-outline-dark btn-sm"
                    @click="updateRequest(n, shiftStatus.userAdded)"
                  >
                    <i class="fas fa-check"></i>
                  </button>
                  <button
                    class="btn btn-outline-dark btn-sm"
                    @click="updateRequest(n, shiftStatus.userRejected)"
                  >
                    <i class="fas fa-times"></i>
                  </button>
                </div>
              </div>
              <div v-else-if="status === 'scheduled'">
                <el-dropdown
                  placement="bottom-start"
                  trigger="click"
                  class="dropdown"
                >
                  <span class="btn btn-sm btn-icon-only text-default mr-1">
                    <i class="fas fa-ellipsis-v mt-2"></i>
                  </span>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <li class="dropdown-item" @click="removeBookedUser(n)">
                        {{
                          i18n.$t(`projectDetails.shifts.actions.removeUser`)
                        }}
                      </li>
                      <!-- <li class="dropdown-item">
                        {{ i18n.$t(`projectDetails.shifts.actions.rebook`) }}
                      </li> -->
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </div>
            </div>
          </div>
        </div>
      </span>
    </div>
    <div class="px-2 py-4" v-if="status === 'available' && staffs.length > 0">
      <div class="d-flex align-items-center justify-content-end">
        <span class="mr-3">
          {{ i18n.$t("projectDetails.shiftRequest.selectAll") }}
        </span>
        <div class="form-check d-flex">
          <input
            type="checkbox"
            class="form-check-input position-static mr-3"
            v-model="checkAll"
            aria-label="Check all"
          />
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <div class="pr-3 py-0">
            <button
              class="btn btn-outline-dark btn-sm"
              @click="updateMultiple(shiftStatus.userPerhaps)"
            >
              <i class="fas fa-question"></i>
            </button>
            <button
              class="btn btn-outline-dark btn-sm"
              @click="updateMultiple(shiftStatus.userAdded)"
            >
              <i class="fas fa-check"></i>
            </button>
            <button
              class="btn btn-outline-dark btn-sm"
              @click="updateMultiple(shiftStatus.userRejected)"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, ref, reactive } from "vue";
import {
  ElDropdownMenu,
  ElDropdown,
  ElPopover,
  ElCheckbox,
} from "element-plus";
import { useToast } from "vue-toastification";
import {
  SHIFTSTATUS,
  SHIFTSTATUSCOLOR,
  SHIFTSTATUSCOLORDOT,
  PARTNERSHIFTSTATUS,
} from "@/util/constant";
import { useI18n } from "@/util/i18n";
import initialsName from "@/util/initialsName";
import { useRouter } from "vue-router";

export default {
  props: {
    type: String,
    shiftName: String,
    staffData: Array,
    isModalAbort: Boolean,
  },
  data() {
    return {
      router: useRouter(),
    };
  },
  components: {
    [ElDropdown.name]: ElDropdown,
    [ElDropdownMenu.name]: ElDropdownMenu,
    [ElPopover.name]: ElPopover,
    [ElCheckbox.name]: ElCheckbox,
  },
  methods: {
    getInitialNames(staff) {
      if (staff && staff.user_id === null) {
        return initialsName(staff && staff.partner_company_name, 1);
      }
      return initialsName(
        `${staff && staff.first_name} ${staff && staff.last_name}`
      );
    },
    openProfile(staff) {
      if (staff.shift_request_status != "ic_user_open" && staff.user_id) {
        let routeData = this.router.resolve({
          name: "StaffProfile",
          params: {
            company_id: staff.company_id,
            version: staff.current_version,
            user_id: staff.user_id,
          },
        });
        window.open(routeData.href, "_blank");
      } else {
        let routeData = this.router.resolve({
          name: "CompanyProfile",
          params: {
            company_id: staff.partner_company_id,
          },
        });
        window.open(routeData.href, "_blank");
      }
    },
    getName(staff) {
      if (staff && staff.user_id === null) {
        return staff && staff.partner_company_name;
      }
      return `${staff && staff.first_name} ${staff && staff.last_name}`;
    },
  },
  setup(props, context) {
    const i18n = useI18n();
    const toast = useToast();
    const shiftStatus = reactive(SHIFTSTATUS);
    const partnerShiftStatus = reactive(PARTNERSHIFTSTATUS);
    const status = props && props.type;
    const shiftName = computed(() => props && props.shiftName);
    const availableResponse = ref([]);
    const staffs = computed(() => props && props.staffData);
    const checkAll = computed({
      get: () => {
        return staffs && staffs.value
          ? availableResponse.value.length == staffs.value.length
          : false;
      },
      set: (value) => {
        var checked = [];
        if (value) {
          staffs.value.forEach((s, index) => {
            checked.push(index);
          });
        }
        availableResponse.value = checked;
      },
    });

    function getStaffStatus(n) {
      const { shift_request_status, request_status } = n;
      if (status === "scheduled" && n.partner_company_id) {
        return n.partner_company_name;
      } else if (
        status === "available" &&
        n.shift_partner_request_id &&
        n.user_id !== null
      ) {
        return n.partner_company_name;
      }
      if (shift_request_status) {
        return i18n.$t(`projectDetails.shifts.${shift_request_status}`);
      } else {
        return i18n.$t(`projectDetails.shifts.${request_status}`);
      }
    }
    function getStatusClass(n) {
      if (status === "scheduled") {
        if (n && n.shift_partner_request_id) {
          return "bg-spindle";
        }
        return "bg-light-orange";
      } else {
        const { shift_request_status, request_status } = n;
        if (
          status === "available" &&
          n.shift_partner_request_id &&
          n.user_id !== null
        ) {
          return "bg-spindle";
        } else if (shift_request_status) {
          return SHIFTSTATUSCOLOR[shift_request_status];
        } else {
          return SHIFTSTATUSCOLOR[request_status];
        }
      }
    }
    function getStatusColor(n) {
      const { shift_request_status, request_status } = n;
      if (shift_request_status) {
        return SHIFTSTATUSCOLORDOT[shift_request_status];
      } else {
        return SHIFTSTATUSCOLORDOT[request_status];
      }
    }
    async function updateRequest(row, status) {
      let updatedStatus = status;
      if (row.partner_company_id && status === SHIFTSTATUS.userPerhaps) {
        updatedStatus = SHIFTSTATUS.icOpenPerhaps;
      } else if (
        row.partner_company_id &&
        status === SHIFTSTATUS.userRejected
      ) {
        updatedStatus = SHIFTSTATUS.icOpenRejected;
      } else if (row.partner_company_id && status === SHIFTSTATUS.userAdded) {
        updatedStatus = SHIFTSTATUS.icUserAdded;
      }
      const data = {
        shift_request_id: row.shift_request_id,
        shift_id: row.shift_id,
        shift_name: shiftName.value,
        shift_request_status: updatedStatus,
        user_name: `${row.first_name} ${row.last_name}`,
        user_id: row.user_id,
        partner_company_id: row.partner_company_id
          ? row.partner_company_id
          : "",
      };
      availableResponse.value = [];
      context.emit("showConfirmation", { data, type: "SINGLE" });
    }
    function updateMultiple(status) {
      if (availableResponse.value.length === 0) {
        toast.error(
          i18n.$t("projectDetails.shifts.response.multipleUpdateCheck")
        );
      } else {
        let updatedStatus = status;
        const data = availableResponse.value.map((avail) => {
          const a = staffs.value[avail];
          if (a.partner_company_id && status === SHIFTSTATUS.userPerhaps) {
            updatedStatus = SHIFTSTATUS.icOpenPerhaps;
          } else if (
            a.partner_company_id &&
            status === SHIFTSTATUS.userRejected
          ) {
            updatedStatus = SHIFTSTATUS.icOpenRejected;
          } else if (a.partner_company_id && status === SHIFTSTATUS.userAdded) {
            updatedStatus = SHIFTSTATUS.icUserAdded;
          }
          return {
            ...a,
            shift_request_status: updatedStatus,
            user_name: `${a.first_name} ${a.last_name}`,
            user_id: a.user_id,
            partner_company_id: a.partner_company_id
              ? a.partner_company_id
              : "",
          };
        });
        availableResponse.value = [];
        context.emit("showConfirmation", { data, type: "BATCH" });
      }
    }
    function removeBookedUser(row) {
      context.emit("remove", row);
    }
    return {
      i18n,
      toast,
      shiftStatus,
      partnerShiftStatus,
      status,
      shiftName,
      staffs,
      availableResponse,
      checkAll,
      getStaffStatus,
      getStatusClass,
      getStatusColor,
      updateRequest,
      updateMultiple,
      removeBookedUser,
    };
  },
};
</script>
<style>
.cursor-pointer {
  cursor: pointer;
}
</style>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
