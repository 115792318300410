<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-warning py-7 py-lg-6 pt-lg-6">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="text-white">
                {{ i18n.$t("resetPassword.header") }}
              </h1>
              <p class="text-lead text-white">
                {{ i18n.$t("resetPassword.subHeader") }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-header bg-transparent">
              <div class="text-muted text-center">
                <small>{{ i18n.$t("resetPassword.form.header") }}</small>
              </div>
            </div>
            <div class="card-body px-lg-5 py-lg-5" v-if="isLinkActive === null">
              <h1 class="text-default text-center">
                <spinner color="text-default" />{{ i18n.$t("common.checking") }}
              </h1>
            </div>
            <div class="card-body px-lg-5 py-lg-5" v-if="isLinkActive === true">
              <Form
                v-slot="{ errors }"
                @submit="onSubmit"
                :validation-schema="schema"
              >
                <base-input
                  alternative
                  class="mb-3"
                  addon-left-icon="ni ni-lock-circle-open"
                  :placeholder="i18n.$t('common.pwd')"
                  type="password"
                  name="pwd"
                >
                </base-input>
                <base-input
                  alternative
                  class="mb-3"
                  addon-left-icon="ni ni-lock-circle-open"
                  :placeholder="i18n.$t('registration.form.input.pwdRepeat')"
                  type="password"
                  name="pwdConfirmation"
                  :validate="errors && errors.pwd ? true : false"
                >
                </base-input>
                <div class="row mt-4">
                  <div class="col-12">
                    <span
                      class="small"
                      :class="{
                        'text-danger': errors['pwd'],
                        'text-muted': !errors['pwd'],
                      }"
                    >
                      {{ i18n.$t("message.pwdNote") }}
                    </span>
                  </div>
                </div>
                <div class="text-center">
                  <base-button type="warning" native-type="submit" class="my-4">
                    <div class="d-flex align-items-center">
                      <spinner v-show="loader" />
                      <span>{{ i18n.$t("resetPassword.form.submit") }}</span>
                    </div>
                  </base-button>
                </div>
              </Form>
            </div>
            <div
              class="card-body px-lg-5 py-lg-5"
              v-if="isLinkActive === false"
            >
              <h4>
                {{ i18n.$t("resetPassword.expired.message") }}
              </h4>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6">
              <router-link to="/forgot-password" class="text-light">
                <small> {{ i18n.$t("login.link.forgotPass") }}?</small>
              </router-link>
            </div>
            <div class="col-6 text-right">
              <router-link to="/register" class="text-light">
                <small> {{ i18n.$t("login.link.registration") }}</small>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onMounted } from "vue";
import { Form } from "vee-validate";
import * as Yup from "yup";
import { useI18n } from "@/util/i18n";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import {
  RESETPASSWORDAPI,
  VERIFYFORGOTPASSWORDAPI,
} from "@/services/public/Auth";
const md5 = require("md5");

export default {
  components: {
    Form,
  },
  data() {
    return {
      model: {
        pwd: "",
        pwdConfirmation: "",
      },
      visible: false,
    };
  },
  setup() {
    const toast = useToast();
    const i18n = useI18n();
    const route = useRoute();
    const router = useRouter();
    let loader = ref(false);
    let isLinkActive = ref(null);

    onMounted(async () => {
      await validateLink();
    });
    async function validateLink() {
      const data = { user_id: route.params.userId, token: route.params.token };
      loader.value = true;
      const result = await VERIFYFORGOTPASSWORDAPI(data);
      loader.value = false;
      if (
        result.error &&
        result.response &&
        result.response.data &&
        (result.response.data.status === 410 ||
          result.response.data.status === 404)
      ) {
        isLinkActive.value = false;
      } else if (result && result.status === 200) {
        isLinkActive.value = true;
      } else {
        isLinkActive.value = false;
      }
    }
    async function onSubmit(values) {
      const { pwd } = values;
      const data = {
        user_id: route.params.userId,
        pwd: md5(pwd),
      };
      loader.value = true;
      const result = await RESETPASSWORDAPI(data);
      loader.value = false;
      if (
        result.error &&
        result.response &&
        result.response.data &&
        result.response.data.status === 404
      ) {
        toast.error(i18n.$t("message.userNotFound"));
      } else if (result && result.status === 200) {
        toast.success(i18n.$t("message.passwordChange"));
        setTimeout(() => {
          router.push("/login");
        }, 3000);
      }
    }
    const schema = Yup.object().shape({
      pwd: Yup.string()
        .min(8)
        .required()
        .matches(
          "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        ),
      pwdConfirmation: Yup.string()
        .required()
        .oneOf([Yup.ref("pwd"), null], "Password must match"),
    });

    return {
      loader,
      isLinkActive,
      validateLink,
      i18n,
      onSubmit,
      schema,
    };
  },
};
</script>
