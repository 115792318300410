<template>
  <card>
    <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
      <div class="row align-items-center">
        <div class="col-4">
          <h3 class="mb-0">{{ i18n.$t("editProfile.profile") }}</h3>
        </div>
        <div class="col-8 text-right">
          <base-button
            type="warning"
            class="btn-sm"
            v-show="showProfileBtn"
            @click="editProfile"
          >
            <div class="d-flex align-items-center">
              <span>{{ i18n.$t("editProfile.editInformation") }}</span>
            </div>
          </base-button>
          <div v-show="showEditBtn">
            <base-button type="primary" native-type="submit" class="btn-sm">
              <div class="d-flex align-items-center">
                <spinner v-show="loader" />
                <span>{{ i18n.$t("editProfile.save") }}</span>
              </div>
            </base-button>
            <base-button type="" @click="showProfile" class="btn-sm">
              <div class="d-flex align-items-center">
                <span>{{ i18n.$t("editProfile.discard") }}</span>
              </div>
            </base-button>
          </div>
        </div>
      </div>
      <hr class="my-3" />
      <h6 class="heading-small text-muted mb-4">
        {{ i18n.$t("editProfile.personalInformation") }}
      </h6>
      <div class="pl-lg-4">
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label>{{ i18n.$t("editProfile.form.firstName") }}</label>
              <Field
                class="form-control"
                name="first_name"
                type="text"
                id="first_name"
                :class="{ 'has-danger-border-red': errors.first_name }"
                :placeholder="i18n.$t('editProfile.form.firstName')"
                v-model="user.first_name"
                :disabled="!showEditBtn"
              ></Field>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label>{{ i18n.$t("editProfile.form.lastName") }}</label>
              <Field
                class="form-control"
                name="last_name"
                type="text"
                id="last_name"
                :class="{ 'has-danger-border-red': errors.last_name }"
                :placeholder="i18n.$t('editProfile.form.lastName')"
                v-model="user.last_name"
                :disabled="!showEditBtn"
              ></Field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label>{{ i18n.$t("common.email") }}</label>
              <Field
                class="form-control"
                name="email"
                type="email"
                id="email"
                :class="{ 'has-danger-border-red': errors.email }"
                placeholder="E-mail"
                v-model="user.email"
                :disabled="!showEditBtn"
              ></Field>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label>{{ i18n.$t("editProfile.form.telePhoneNumber") }}</label>
              <div
                class="input-group mb-3"
                :class="{ 'has-danger-border-red': errors.mobile_no }"
              >
                <Field
                  class="form-control"
                  name="mobile_no"
                  type="text"
                  id="mobile_no"
                  :placeholder="i18n.$t('editProfile.form.telePhoneNumber')"
                  v-model="extraProfileData.mobile_no"
                  :disabled="!showEditBtn"
                  maxlength="25"
                ></Field>
              </div>
              <div class="text-danger mt-2 small" v-show="errors.mobile_no">
                {{ i18n.$t("editProfile.form.error.mobile") }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div
              class="form-group"
              :class="{
                'has-danger-border-red-group': !extraProfileData.date_of_birth,
              }"
            >
              <label>{{ i18n.$t("editProfile.form.birthDay") }}</label>
              <flat-picker
                :config="config"
                name="date_of_birth"
                class="datepicker"
                v-model="extraProfileData.date_of_birth"
                :disabled="!showEditBtn"
                id="date_of_birth"
                placeholder="DD.MM.YYYY"
              >
              </flat-picker>

              <!-- <Field
              class="form-control"
              :class="{ 'has-danger-border-red': errors.date_of_birth }"
              name="date_of_birth"
              type="date"
              :placeholder="i18n.$t('editProfile.form.birthDay')"
              v-model="user.date_of_birth"
              :disabled="!showEditBtn"
            >
            </Field> -->
            </div>
          </div>
          <div class="col-lg-6">
            <label for="gender">
              {{ i18n.$t("editProfile.form.gender") }}
            </label>
            <Field
              name="gender"
              as="select"
              :disabled="!showEditBtn"
              v-model="extraProfileData.gender"
              class="form-control"
              id="gender"
              :class="{ 'has-danger-border-red': errors.gender }"
            >
              <option disabled value="">
                {{ i18n.$t("editProfile.gender.header") }}
              </option>
              <option value="M">{{ i18n.$t("editProfile.gender.M") }}</option>
              <option value="F">{{ i18n.$t("editProfile.gender.F") }}</option>
              <option value="O">{{ i18n.$t("editProfile.gender.O") }}</option>
            </Field>
          </div>
        </div>
      </div>
      <hr class="my-4" />

      <!-- Address -->
      <h6 class="heading-small text-muted mb-4">
        {{ i18n.$t("editProfile.address") }}
      </h6>

      <div class="pl-lg-4">
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label>{{ i18n.$t("editProfile.form.street") }}</label>
              <Field
                class="form-control"
                :class="{ 'has-danger-border-red': errors.address_street }"
                name="address_street"
                type="text"
                :placeholder="i18n.$t('editProfile.form.street')"
                v-model="extraProfileData.address_street"
                :disabled="!showEditBtn"
              >
              </Field>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label>{{ i18n.$t("editProfile.form.house") }}</label>
              <Field
                class="form-control"
                :class="{ 'has-danger-border-red': errors.address_houseno }"
                name="address_houseno"
                type="text"
                :placeholder="i18n.$t('editProfile.form.house')"
                v-model="extraProfileData.address_houseno"
                :disabled="!showEditBtn"
              >
              </Field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <div class="form-group">
              <label>{{ i18n.$t("editProfile.form.postCode") }}</label>
              <Field
                class="form-control"
                :class="{ 'has-danger-border-red': errors.address_postcode }"
                name="address_postcode"
                type="text"
                :placeholder="i18n.$t('editProfile.form.postCode')"
                v-model="extraProfileData.address_postcode"
                :disabled="!showEditBtn"
              >
              </Field>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="form-group">
              <label>{{ i18n.$t("editProfile.form.city") }}</label>
              <Field
                class="form-control"
                :class="{ 'has-danger-border-red': errors.address_city }"
                name="address_city"
                type="text"
                :placeholder="i18n.$t('editProfile.form.city')"
                v-model="extraProfileData.address_city"
                :disabled="!showEditBtn"
              >
              </Field>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="form-group">
              <label>{{ i18n.$t("editProfile.form.country") }}</label>
              <Field
                class="form-control"
                :class="{ 'has-danger-border-red': errors.address_country }"
                name="address_country"
                type="text"
                :placeholder="i18n.$t('editProfile.form.country')"
                v-model="extraProfileData.address_country"
                :disabled="!showEditBtn"
              >
              </Field>
            </div>
          </div>
        </div>
      </div>
    </Form>
  </card>
</template>
<script>
import { reactive, ref, watch } from "vue";
import * as Yup from "yup";
import { Form, Field } from "vee-validate";
import { useToast } from "vue-toastification";
import { useStore } from "vuex";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { German } from "flatpickr/dist/l10n/de.js";
import { useI18n } from "@/util/i18n";
import storage from "@/util/storage";
import { UPDATEPROFILEAPI } from "@/services/private/Profile";
const PHONE_NO_REGEX = `^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,8}$`;
const phoneNumberRegex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
const phoneNumberRegexwithZero = /^(?:\+|0)(?:[0-9] ?){6,14}[0-9]$/;
export default {
  props: {
    profile: Object,
  },
  components: {
    Form,
    Field,
    flatPicker,
  },
  data() {
    return {
      // user: this.$store.state.user || {},
      config: {
        wrap: true,
        allowInput: true,
        altInput: true,
        maxDate: new Date(),
        dateFormat: "Y.m.d",
        altFormat: "d.m.Y",
        disableMobile: "true",
        locale: German, // locale for this instance only
      },
    };
  },
  methods: {},

  setup(props, context) {
    const i18n = useI18n();
    const toast = useToast();
    const store = useStore();
    let user = reactive({
      ...JSON.parse(localStorage.getItem("user")),
    });
    let extraProfileData = reactive({
      address_city: props.profile.address_city,
      address_country: props.profile.address_country,
      address_houseno: props.profile.address_houseno,
      address_postcode: props.profile.address_postcode,
      address_street: props.profile.address_street,
      date_of_birth: props.profile.date_of_birth || null,
      gender: props.profile.gender,
      mobile_no: props.profile.mobile_no,
    });
    let loader = ref(false);
    let showEditBtn = ref(false);
    let showProfileBtn = ref(true);

    watch(
      () => props.profile,
      (currentValue, oldValue) => {
        extraProfileData.address_city = currentValue.address_city;
        extraProfileData.address_country = currentValue.address_country;
        extraProfileData.address_houseno = currentValue.address_houseno;
        extraProfileData.address_postcode = currentValue.address_postcode;
        extraProfileData.address_street = currentValue.address_street;
        extraProfileData.date_of_birth = currentValue.date_of_birth || null;
        extraProfileData.gender = currentValue.gender || null;
        extraProfileData.mobile_no = currentValue.mobile_no;
      },
      { deep: true }
    );

    async function onSubmit(values) {
      try {
        loader.value = true;
        const {
          first_name,
          last_name,
          email,
          address_houseno,
          address_street,
          address_city,
          address_postcode,
          address_country,
        } = values;
        const user = JSON.parse(localStorage.getItem("user"));
        const user_id = user.user_id;
        const modified_by = user.user_id;
        const gender = document.getElementById("gender").value;
        const mobile_no = document.getElementById("mobile_no").value;
        const date_of_birth = document.getElementById("date_of_birth").value;
        const data = {
          user_id,
          first_name,
          last_name,
          email,
          mobile_no,
          gender,
          date_of_birth,
          address_houseno,
          address_street,
          address_city,
          address_postcode,
          address_country,
          modified_by,
        };
        const result = await UPDATEPROFILEAPI(data);
        loader.value = false;
        if (
          result.error &&
          result.response &&
          result.response.data &&
          (result.response.data.status === 400 ||
            result.response.data.status === 409)
        ) {
          toast.error(i18n.$t("message.wrong"));
        } else if (result && result.status === 200) {
          const { data } = result.data;
          storage().setUser({
            user_id: data[0].user_id,
            first_name: data[0].first_name,
            last_name: data[0].last_name,
            email: data[0].email,
            role: data[0].role,
            company_id: user && data[0].company_id,
            current_version: user && data[0].current_version,
          });
          store.commit("setUser", data[0]);
          toast.success(i18n.$t("editProfile.infoSaved"));
          showEditBtn.value = false;
          showProfileBtn.value = true;
          context.emit("update");
        } else {
          toast.error(i18n.$t("message.wrong"));
        }
      } catch (err) {
        loader.value = false;
        toast.error(i18n.$t("message.wrong"));
      }
    }
    function editProfile() {
      showEditBtn.value = true;
      showProfileBtn.value = false;
    }

    function showProfile() {
      showEditBtn.value = false;
      showProfileBtn.value = true;
      resetFormData();
    }

    function resetFormData() {
      user.first_name = props.profile.first_name;
      user.last_name = props.profile.last_name;
      user.email = props.profile.email;
      extraProfileData.address_city = props.profile.address_city;
      extraProfileData.address_country = props.profile.address_country;
      extraProfileData.address_houseno = props.profile.address_houseno;
      extraProfileData.address_postcode = props.profile.address_postcode;
      extraProfileData.address_street = props.profile.address_street;
      extraProfileData.date_of_birth = props.profile.date_of_birth || null;
      extraProfileData.gender = props.profile.gender;
      extraProfileData.mobile_no = props.profile.mobile_no;
    }

    const schema = Yup.object().shape({
      first_name: Yup.string().required(),
      last_name: Yup.string().required(),
      email: Yup.string().required().email(),
      mobile_no: Yup.string().matches(phoneNumberRegexwithZero, 'Phone number is not valid').required(),
      gender: Yup.string().required(),
      address_postcode: Yup.number().required(),
      date_of_birth: Yup.date().required(),
      address_houseno: Yup.string().required(),
      address_street: Yup.string().required(),
      address_city: Yup.string().required(),
      address_country: Yup.string().required(),
    });

    return {
      i18n,
      schema,
      user,
      extraProfileData,
      loader,
      showEditBtn,
      showProfileBtn,
      showProfile,
      onSubmit,
      editProfile,
    };
  },
};
</script>
<style></style>
