<template>
  <div class="card">
    <el-table class="table-responsive table-flush rounded" header-row-class-name="thead-light" :data="skills"
      :default-sort="{ prop: 'name', order: 'ascending' }" @row-click="goToLocationPage">
      <el-table-column :label="i18n.$t('network.Table.Name')" min-width="120px" prop="name" sortable>
        <template v-slot="{ row }">
          <div class="media align-items-center">
            <span class="
                font-weight-600
                name
                mb-0
                text-sm text-capitalize
                cursor-pointer
              ">
              {{ row.skill_name }}
            </span>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="Mitarbeiter freischalten" min-width="140px" class-name="text-right">
        <template v-slot="{ row }">
          <span @click="addUserToSkills(row)">
            <i class="fa fa-user-plus"></i>
          </span>
        </template>
      </el-table-column>

      <!--
      <el-table-column min-width="100px" prop="actions" class-name="text-right">
        <template v-slot="{ row }">
          <el-dropdown v-if="!row.is_deleted && isAccessible(permission.network_list_action)
            " class="dropdown" trigger="click" placement="bottom-start">
            <span class="p-2 btn-icon-only text-light">
              <i class="fas fa-ellipsis-v mt-2"></i>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <li class="dropdown-item" @click="deleteLocation(row.location_id)">
                  {{ i18n.$t("common.remove") }}
                </li>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </el-table-column>
    -->
    </el-table>

    <!-- Add Users to Skills popup modal -->
    <user-to-skills-modal :showModal="models.addUserToSkillsModelVisible" :skill-id="skillsRowData.skill_id" :skill_name="skillsRowData.skill_name" @close="closeAddUserToSkillsModel">
    </user-to-skills-modal>

  </div>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
} from "element-plus";
import { useToast } from "vue-toastification";
import { useI18n } from "@/util/i18n";
import { useRouter } from "vue-router";
import { has_permission } from "@/services/private/Role_Service";
import { permission_list } from "@/services/_helper/user_permission";
import DeleteLocationModal from "../Locations/DeleteLocationModal.vue";
import ConfirmDeleteModal from "../Locations/ConfirmDeleteModal.vue";
import { DELETELOCATIONAPI } from "@/services/private/Location";
import UserToSkillsModal from "./UserToSkillsModal.vue";

export default {
  name: "light-table",
  components: {
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
    DeleteLocationModal,
    ConfirmDeleteModal,
    UserToSkillsModal,
  },
  props: {
    skills: Array,
  },
  data() {
    return {
      loader: false,
      router: useRouter(),
      i18n: useI18n(),
      permission: permission_list,
      currentPage: 1,
      models: {
        addUserToSkillsModelVisible: false,
        removeLocationModalVisible: false,
        removeLocationId: "",
        confirmDeleteModalVisible: false,
      },
      skillsRowData: {},
    };
  },
  methods: {
    isAccessible(permission) {
      return has_permission(permission.value);
    },
    redirectToDetails(raw) {
      this.router.push({
        name: "LocationDetails",
        params: { location_id: raw.location_id },
      });
    },
    deleteLocation(locationId) {
      this.models.removeLocationId = locationId;
      this.models.removeLocationModalVisible = true;
    },
    closeDeleteLocationModal() {
      this.models.removeLocationId = "";
      this.models.removeLocationModalVisible = false;
    },
    showConfirmDeleteModal() {
      this.models.removeLocationModalVisible = false;
      this.models.confirmDeleteModalVisible = true;
    },
    closeConfirmDeleteModal() {
      this.models.confirmDeleteModalVisible = false;
      this.closeDeleteLocationModal();
    },
    closeAddUserToSkillsModel() {
      this.models.addUserToSkillsModelVisible = false;
    },
    async addUserToSkills(data) {
      document.body.classList.add("modal-open");
      console.log(data.skill_name);
      this.skillsRowData = data;
      this.models.addUserToSkillsModelVisible = true;
    },
  },
  setup() {
    const i18n = useI18n();
    function getStatusI18n(status) {
      const findStatus = status === true ? "connected" : "disconnected";
      return i18n.$t(`common.${findStatus}`);
    }
    return { i18n, getStatusI18n };
  },
  mounted() {
    //this.getAllNetwork();
  },
};
</script>
