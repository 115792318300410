<template>
  <div class="card card-profile">
    <div class="row justify-content-center">
      <div class="col-lg-3 order-lg-2">
        <div class="card-profile-image">
          <router-link to="/dashboard">
            <span translate="no">{{ setInitialsName }}</span>
          </router-link>
        </div>
      </div>
    </div>

    <div class="card-body pt-5">
      <div class="row">
        <div class="col">
          <div class="card-profile-stats d-flex justify-content-center">
            <div class="text-center">
              <h5 class="h3">
                {{ fullName }}
                <span class="font-weight-light">
                  {{ getAge !== "" ? `, ${getAge}` : "" }}
                </span>
              </h5>
              <div
                class="
                  h5
                  font-weight-300
                  d-flex
                  align-items-center
                  justify-content-center
                "
                v-if="getCityCountry !== ''"
              >
                <i class="ni ni-pin-3 mr-1"></i>
                <span>{{ getCityCountry }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Form onsubmit="return false;">
        <div class="mb-3">
          <Field
            as="textarea"
            class="form-control"
            name="personal_info"
            :placeholder="i18n.$t('editProfile.personalInfo.placeholder')"
            v-model="user.personal_info"
            :disabled="!showEditBtn"
            rows="10"
            maxlength="500"
          ></Field>
        </div>
      </Form>
    </div>
  </div>
</template>
<script>
import { Form, Field } from "vee-validate";
import { useI18n } from "@/util/i18n";
import initialsName from "@/util/initialsName";
import age from "@/util/age";

export default {
  components: {
    Form,
    Field,
  },
  props: ["user"],
  data() {
    return {
      showEditBtn: false,
    };
  },
  computed: {
    fullName() {
      const userDetails = this.user;
      if (userDetails && userDetails.first_name && userDetails.last_name) {
        return `${userDetails.first_name} ${userDetails.last_name}`;
      }
      return "";
    },
    setInitialsName() {
      const userDetails = this.user;
      if (userDetails && userDetails.first_name && userDetails.last_name) {
        return initialsName(
          `${userDetails.first_name} ${userDetails.last_name}`
        );
      }
      return "";
    },
    getAge() {
      const userAge = this.user;
      return age(userAge && userAge.date_of_birth);
    },
    getCityCountry() {
      return `${this.user.address_city ? `${this.user.address_city}, ` : ""}${
        this.user.address_country ? this.user.address_country : ""
      }`;
    },
  },
  setup() {
    const i18n = useI18n();

    return {
      i18n,
    };
  },
};
</script>
<style></style>

