<template>
  <div>
    <modal
      v-model:show="show"
      modal-classes="modal-white"
      header-classes="align-items-center"
      :show-close="showClose"
      body-classes="pt-0"
    >
      <template v-slot:header>
        <h6 class="modal-title text-capitalize text-default">
          {{ i18n.$t("projectDetails.shifts.modal.rejectedHeader") }}
        </h6>
      </template>
      <div class="">
        <p class="text-light mb-0 text-wrap">
          {{ i18n.$t("projectDetails.shifts.modal.rejectedSubHeader") }}
        </p>
        <Form ref="form" class="pt-2">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <Field
                  as="textarea"
                  class="form-control"
                  name="request_feedback"
                  :placeholder="
                    i18n.$t('projectDetails.shifts.forms.information')
                  "
                  rows="3"
                  maxlength="100"
                  v-model="formData.request_feedback"
                ></Field>
              </div>
            </div>
          </div>
        </Form>
      </div>
      <template v-slot:footer>
        <base-button
          type="warning"
          class="text-white p-2 px-3"
          @click="handleClose"
        >
          {{ i18n.$t("logout.button.cancel") }}
        </base-button>
        <base-button
          native-type="button"
          type=""
          class="ml-auto text-default p-2 px-3"
          @click.stop="updateUserRequest"
        >
          <div class="d-flex align-items-center">
            <spinner v-show="loader" color="text-black" />
            <span>
              {{ i18n.$t("projectDetails.shifts.modal.rejectedButton") }}
            </span>
          </div>
        </base-button>
      </template>
    </modal>
  </div>
</template>
<script>
import { reactive } from "vue";
import { Field, Form } from "vee-validate";
import Modal from "@/components/Modal";
import { SHIFTSTATUS } from "@/util/constant";
import { useI18n } from "@/util/i18n";
export default {
  name: "rejected-modal",
  props: {
    showModal: Boolean,
    loader: Boolean,
    requestType: String,
  },
  components: {
    Field,
    Form,
    Modal,
  },
  data() {
    return {
      formData: {
        request_feedback: "",
      },
    };
  },
  computed: {
    show() {
      return this.showModal;
    },
    showClose() {
      return false;
    },
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
    updateUserRequest() {
      const request_feedback = this.formData.request_feedback;
      this.formData.request_feedback = "";
      this.$emit("rejected", { request_feedback });
    },
  },
  setup() {
    const i18n = useI18n();
    const shiftStatus = reactive(SHIFTSTATUS);
    return { i18n, shiftStatus };
  },
};
</script>
