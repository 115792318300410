<template>
  <modal
    v-model:show="show"
    modal-classes="modal-white"
    header-classes="align-items-center"
    body-classes="pt-0"
    :show-close="showClose"
  >
    <template v-slot:header>
      <h6 class="modal-title text-capitalize text-default">
        {{ i18n.$t("staff.modal.invitationLink.header") }}
      </h6>
    </template>
    <div class="">
      <p class="text-default mb-3">
        {{ i18n.$t("staff.modal.invitationLink.subHeader") }}
      </p>
      <div>
        <h3>{{ i18n.$t("staff.modal.invitationLink.question") }}</h3>
        <div>
          <form class="pl-3 pt-2">
            <base-radio
              v-for="role in roles"
              :key="role.role_code"
              :name="role.role_code"
              class="mb-3"
              v-model="selectedRole"
              @change="onChangeRole($event)"
            >
              {{ getRoleI18n(role && role.role_name) }}
            </base-radio>
            <div v-if="showErrorMsg">
              <span class="text-danger font-weight-bold">
                {{ i18n.$t("staff.modal.changeRole.error") }}
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
    <template v-slot:footer>
      <base-button
        native-type="button"
        type="warning"
        class="text-white p-2 px-3"
        @click="generateInviteLink"
      >
        <div class="d-flex align-items-center">
          <spinner v-show="loader" color="text-black" />
          <span>{{ i18n.$t("staff.modal.invitationLink.createLink") }}</span>
        </div>
      </base-button>
      <base-button
        type=""
        class="ml-auto text-default p-2 px-3"
        @click="handleClose"
      >
        {{ i18n.$t("logout.button.cancel") }}
      </base-button>
    </template>
  </modal>
</template>
<script>
import Modal from "@/components/Modal";
import { useI18n } from "@/util/i18n";
import { GETALLROLEAPI } from "@/services/private/Role";
import { INVITATIONLINKAPI } from "@/services/private/Company";

export default {
  name: "invitation-link-modal",
  props: {
    showModal: Boolean,
    loader: Boolean,
    setSelectedRole: String,
  },
  components: {
    Modal,
  },
  data() {
    return {
      roles: [],
      selectedRole: "",
      showErrorMsg: false,
      invite_link: "",
    };
  },
  computed: {
    show() {
      return this.showModal;
    },
    showClose() {
      return false;
    },
    company_id() {
      return this.$store.state.user.company_id;
    },
  },
  methods: {
    async getRole() {
      const result = await GETALLROLEAPI();
      if (result && result.status === 200) {
        this.roles = result.data.data.filter(
          (item) => item.role_code !== "A" && item.role_code !== "F"
        );
      }
    },
    handleClose() {
      this.selectedRole = "";
      this.invite_link = "";
      this.$emit("close");
    },
    async generateInviteLink() {
      if (this.selectedRole !== "") {
        const role_code = this.selectedRole;
        const company_id = this.company_id;
        const data = {
          role_code,
          company_id,
        };
        const result = await INVITATIONLINKAPI(data);
        if (result.status == 200) {
          this.selectedRole = "";
          this.invite_link = result.data.data.invite_link;
          this.$emit("update", this.invite_link);
        }
      } else {
        this.showErrorMsg = true;
      }
    },
    onChangeRole(event) {
      this.selectedRole = event.target.value;
      this.showErrorMsg = false;
    },
  },
  setup() {
    const i18n = useI18n();

    function getRoleI18n(type) {
      const roleName = type && type.toLowerCase();
      return i18n.$t(`role.${roleName}`);
    }
    return { i18n, getRoleI18n };
  },
  mounted() {
    this.getRole();
  },
};
</script>
