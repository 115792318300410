<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-warning py-7 py-lg-8 pt-lg-9">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="text-white">
                {{ i18n.$t("forgotPassword.header") }}
              </h1>
              <p class="text-lead text-white">
                {{ i18n.$t("forgotPassword.subHeader") }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-header bg-transparent">
              <div class="text-black text-center text-lg font-weight-bold">
                {{ i18n.$t("forgotPassword.form.header") }}
              </div>
            </div>
            <div class="card-body px-lg-5 py-lg-5">
              <Form @submit="onSubmit" :validation-schema="schema">
                <base-input
                  alternative
                  name="email"
                  addon-left-icon="ni ni-email-83"
                  :placeholder="i18n.$t('common.email')"
                >
                </base-input>
                <div class="text-center">
                  <base-button type="warning" native-type="submit" class="my-4">
                    <div class="d-flex align-items-center">
                      <spinner v-show="loader" />
                      <span>{{ i18n.$t("forgotPassword.form.submit") }}</span>
                    </div>
                  </base-button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { useI18n } from "@/util/i18n";
import { Form } from "vee-validate";
import { useToast } from "vue-toastification";
import * as Yup from "yup";
import { FORGOTPASSWORDAPI } from "@/services/public/Auth";
import { useRouter } from "vue-router";
export default {
  components: {
    Form,
  },
  data() {
    return {
      model: {
        email: "",
      },
      visible: false,
    };
  },
  setup() {
    const i18n = useI18n();
    const toast = useToast();
    const router = useRouter();
    let loader = ref(false);
    async function onSubmit(values, actions) {
      const { email } = values;
      const data = {
        email,
      };
      loader.value = true;
      const result = await FORGOTPASSWORDAPI(data);
      loader.value = false;
      if (
        result.error &&
        result.response &&
        result.response.data &&
        result.response.data.status === 404
      ) {
        toast.error(i18n.$t("message.userNotFound"));
      } else if (result && result.status === 200) {
        actions.resetForm();
        router.push({
          name: "ForgotPasswordSuccess",
          params: { success: true },
        });
      } else {
        toast.error(i18n.$t("message.wrong"));
      }
    }
    const schema = Yup.object().shape({
      email: Yup.string().email().required(),
    });

    return {
      loader,
      i18n,
      onSubmit,
      schema,
    };
  },
};
</script>
