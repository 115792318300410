<template>
    <div class="bar">
        <!--  <div class="active"></div>-->
        <div class="field" @click="changeState('todo')">Neu</div>
        <!--<div class="field" @click="changeState('inProgress')">Angefangen</div>-->
        <div class="field" @click="changeState('done')">Fertig</div>
    </div>
</template>

<script setup>
import { defineComponent, onMounted } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const changeState = (state) => {

    const fields = document.getElementsByClassName('field');
    for (let i = 0; i < fields.length; i++) {
        fields[i].classList.remove('active');

    }
    if (state == 'todo') {
        fields[0].classList.add('active');
        store.commit("setStateBar", "todo")
    }/** else if (state == 'inProgress') {
        fields[1].classList.add('active');
        store.commit("setStateBar", "inProgress")
    }**/ else if (state == 'done') {
        fields[1].classList.add('active');
        store.commit("setStateBar", "done")
    }
    //this.$emit('currentState', state)
};

onMounted(() => {
    const state = store.getters.statebar
    const fields = document.getElementsByClassName('field');
    if (state === "todo") fields[0].classList.add('active');
   /** if (state === "inProgress") fields[1].classList.add('active');*/
    if (state === "done") fields[1].classList.add('active');

})

</script>

<style>
.bar {
    position: relative;
    width: 40%;
    height: 40px;
    background-color: #000041;
    display: flex;
    text-align: center;
    margin-bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;
}

.bar .active {
    flex-grow: 3;
    background: #0b0b9b;
    border-radius: 10px;
    color: white;
}

.field {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    cursor: pointer;
    transition: all 0.2s ease-out;
    font-size: small;
}

.active {
    font-weight: bolder;
    font-size: medium;
}

@media screen and (max-width: 720px) {
    .bar {
        width: 70%;
    }
}

@media screen and (max-width: 450px) {
    .bar {
        width: 90%;
    }
}


/*.active {
    position: absolute;
    font-weight: bolder;
    background-color: #6565bc;
    height: 40px;
    width: 30%;
    border-radius: 10px;
    align-self: center;
    
}*/
</style>