const storage = () => {
  const setUser = (data) => {
    localStorage.setItem("user", JSON.stringify(data));
  };
  const getUser = () => {
    return JSON.parse(localStorage.getItem("user"));
  };
  const setAccessToken = (data) => {
    localStorage.setItem("access_token", data);
  };
  const getAccessToken = () => {
    return localStorage.getItem("access_token");
  };
  const setRefreshToken = (data) => {
    localStorage.setItem("refresh_token", data);
  };
  const getRefreshToken = () => {
    return localStorage.getItem("refresh_token");
  };
  const setRememberMe = (data) => {
    localStorage.setItem("rememberMe", data);
  };
  const getRememberMe = () => {
    return localStorage.getItem("rememberMe");
  };
  const setSideBar =  (data) =>{
    localStorage.setItem("sideBar",data);
  };
  const getSideBar = () => {
    return localStorage.getItem("sideBar");
  };
  const clear = () => {
    return localStorage.clear();
  };
  return { setUser, getUser, setAccessToken, getAccessToken, setRefreshToken, getRefreshToken, setRememberMe, getRememberMe, clear,setSideBar,getSideBar };
};
export default storage;
