<template>
  <div>
    <modal
      v-model:show="show"
      modal-classes="modal-white"
      header-classes="align-items-center"
      :show-close="showClose"
      body-classes="pt-0"
    >
      <template v-slot:header>
        <h6 class="modal-title text-capitalize text-default">
          {{ i18n.$t("projectDetails.shifts.modal.OC.confirmRejectedHeader") }}
        </h6>
      </template>
      <div class="">
        <p class="text-light mb-0">
          {{
            i18n.$t("projectDetails.shifts.modal.OC.confirmRejectedSubHeader")
          }}
        </p>
      </div>
      <template v-slot:footer>
        <base-button
          type="warning"
          class="text-white p-2 px-3"
          @click="handleClose"
        >
          {{ i18n.$t("logout.button.cancel") }}
        </base-button>
        <base-button
          native-type="button"
          type=""
          class="ml-auto text-default p-2 px-3"
          @click.stop="removeBookedUser"
        >
          <div class="d-flex align-items-center">
            <spinner v-show="loader" color="text-black" />
            <span>
              {{ i18n.$t("projectDetails.shifts.modal.OC.rejectedButton") }}
            </span>
          </div>
        </base-button>
      </template>
    </modal>
  </div>
</template>
<script>
import Modal from "@/components/Modal";
import { useI18n } from "@/util/i18n";
export default {
  name: "remove-booked-user-modal",
  props: {
    showModal: Boolean,
    loader: Boolean,
  },
  components: {
    Modal,
  },
  computed: {
    show() {
      return this.showModal;
    },
    showClose() {
      return false;
    },
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
    removeBookedUser() {
      this.$emit("remove");
    },
  },
  setup() {
    const i18n = useI18n();
    return { i18n };
  },
};
</script>
