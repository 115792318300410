export const PROJECTSTATUSCOLOR = {
  created: "yellow",
  planning: "blue",
  ready: "orange",
  ongoing: "green",
  completed: "red"
};
export const SHIFTSTATUS = {
  userRequested: "user_requested",
  userAssigned: "user_assigned",
  userTentative: "user_tentative",
  userPerhaps: "user_perhaps",
  userAvailable: "user_available",
  userOpen: "user_open",
  userUnavailable: "user_unavailable",
  userRejected: "user_rejected",
  userAdded: "user_added",
  icRequestOpen: "ic_request_open",
  icRequestUnavailable: "ic_request_unavailable",
  icRequestTentative: "ic_request_tentative",
  icUserOpen: "ic_user_open",
  icUserRequested: "ic_user_requested",
  icUserAssigned: "ic_user_assigned",
  icUserTentative: "ic_user_tentative",
  icUserPerhaps: "ic_user_perhaps",
  icUserAvailable: "ic_user_available",
  icUserUnavailable: "ic_user_unavailable",
  icUserRejected: "ic_user_rejected",
  icUserAdded: "ic_user_added",
  icUserMapped: "ic_user_mapped",
  icOpenPerhaps: "ic_open_perhaps",
  icOpenRejected: "ic_open_rejected"
};
export const SHIFTSTATUSCOLOR = {
  user_requested: "",
  user_assigned: "",
  user_tentative: "",
  user_perhaps: "",
  user_available: "",
  user_open: "",
  user_unavailable: "bg-rejected-unavailable",
  user_rejected: "bg-rejected-unavailable",
  user_added: "",
  ic_request_open: "",
  ic_request_unavailable: "bg-rejected-unavailable",
  ic_request_tentative: "bg-light-orange",
  ic_user_requested: "",
  ic_user_assigned: "",
  ic_user_tentative: "",
  ic_user_perhaps: "",
  ic_user_available: "",
  ic_user_open: "",
  ic_user_unavailable: "bg-rejected-unavailable",
  ic_user_rejected: "bg-rejected-unavailable",
  ic_user_mapped: "",
  ic_open_perhaps: "",
  ic_open_rejected: "bg-rejected-unavailable",
  company_open: "",
  ic_user_added: "",
  company_requested: "",
  company_assigned: "",
  company_tentative: "",
  company_perhaps: "",
  company_available: "",
  company_unavailable: "bg-rejected-unavailable",
  company_rejected: "bg-rejected-unavailable",
  company_added: "",
};
export const SHIFTSTATUSCOLORDOT = {
  user_requested: "warning",
  user_assigned: "warning",
  user_tentative: "warning",
  user_perhaps: "warning",
  user_available: "primary",
  user_open: "primary",
  user_unavailable: "danger",
  user_rejected: "danger",
  user_added: "success",
  ic_request_open: "warning",
  ic_request_unavailable: "danger",
  ic_request_tentative: "warning",
  ic_user_open: "primary",
  ic_user_requested: "warning",
  ic_user_assigned: "warning",
  ic_user_tentative: "warning",
  ic_user_perhaps: "warning",
  ic_user_available: "primary",
  ic_user_unavailable: "danger",
  ic_user_rejected: "danger",
  ic_user_added: "success",
  ic_user_mapped: "success",
  ic_open_perhaps: "warning",
  ic_open_rejected: "danger",
  company_requested: "warning",
  company_assigned: "warning",
  company_tentative: "warning",
  company_perhaps: "warning",
  company_available: "primary",
  company_open: "primary",
  company_unavailable: "danger",
  company_rejected: "danger",
  company_added: "success",
};


export const PARTNERSHIFTSTATUS = {
  companyRequested: "company_requested",
  companyAssigned: "company_assigned",
  companyTentative: "company_tentative",
  companyPerhaps: "company_perhaps",
  companyAvailable: "company_available",
  companyUnavailable: "company_unavailable",
  companyAdded: "company_added",
  companyOpen: "company_open",
  companyRejected: "company_rejected",
};
