export const permission_list = {
  menu_staff: { value: "menu_staff" },
  menu_dashboard: { value: "menu_dashboard" },
  menu_personal_info: { value: "menu_personal_info" },
  menu_projects: { value: "menu_projects" },
  menu_network: { value: "menu_network" },
  menu_locations: { value: "menu_locations" },
  menu_skills: { value: "menu_skills" },
  menu_myskills: {value: "menu_myskills"},
  network_add: { value: "network_add" },
  network_list_action: { value: "network_list_action" },
  network_list_showalluser: { value: "network_list_showalluser" },
  staff_add: { value: "staff_add" },
  staff_showalluser: { value: "staff_showalluser" },
  staff_list_action: { value: "staff_list_action" },
  location_add: { value: "location_add" },
  location_list_action: { value: "location_list_action" },
  location_show_deleted: { value: "location_show_deleted" },
  skills_view: {value: "skills_view"}
};
