export default {
  "message": {
    "wrong": "Entschuldigung, ein Fehler ist aufgetreten!",
    "userNotFound": "Benutzer nicht gefunden.",
    "pwdNote": "Dein Passwort muss aus mindestens 8 Zeichen, einem Großbuchstaben und einem Kleinbuchstaben bestehen. Zusätzlich muss mindestens eines der folgenden Sonderzeichen verwendet werden: ! $ % & # - ? @ / * . § ( ) = + ' : , ; < > ",
    "passwordChange": "Deine Passwortänderung war erfolgreich.",
    "permission": "Du bist nicht berechtigt, auf dieses System zuzugreifen",
    "ConnectedCompanySuccess": "Du wurdest erfolgreich verbunden",
    "fileRequired": "Datei ist erforderlich",
    "cancel": "Abbrechen",
    "invalidMobileNo": "Ungültiges Format der Telefonnummer",
    "noDeviceToken": "Kann keine Push-Benachrichtigungen empfangen"
  },
  "modal": {
    "connectNetwork": {
      "title": "Mit Unternehmen verbinden",
      "subTitle": "Möchtest Du Dich mit dem Unternehmen verbinden",
      "NoPermissionMessage": "Du bist nicht berechtigt, Dich mit diesem Unternehmen zu verbinden",
      "AlreadyConnectedMessage": "Du bist bereits mit dem Unternehmen verbunden"
    }
  },
  "nav": {
    "search": "Suche",
    "profile": "Mein Profil",
    "settings": "Einstellungen",
    "password": "Passwort ändern",
    "logout": "Ausloggen",
    "readAll": "Alle löschen"
  },
  "sidebarNav": {
    "dashboard": "Dashboard",
    "project": "Projekte",
    "network": "Netzwerk",
    "personal": "Mitarbeiter",
    "location": "Locations",
    "myskills": "Meine Skills",
    "skills": "Skills"
  },
  "role": {
    "admin": "Admin",
    "dispatch": "Projektleiter",
    "worker": "Mitarbeiter",
    "contractor": "Leiharbeiter",
    "freelancer": "Freelancer"
  },
  "notification": {
    "firstText": "Du hast",
    "secondText": "Benachrichtigungen",
  },
  "logout": {
    "header": "Abmeldung",
    "subHeader": "Möchtest Du Dich wirklich Abmelden?",
    "smallText": "Wenn Du Dich vom System abmeldest, hast Du keinen Zugriff mehr auf die Funktionen bis Du Dich erneut anmeldest.",
    "button": {
      "logout": "Abmelden",
      "cancel": "Abbrechen"
    }
  },
  "common": {
    "status": "Status",
    "role": "Rolle",
    "email": "E-Mail",
    "pwd": "Passwort",
    "loading": "Wird geladen...",
    "checking": "Bitte warten...",
    "sending": "senden...",
    "date": "Datum",
    "footer": {
      "data": "Datenschutzerklärung",
      "imprint": "Impressum",
      "cookie": "Nutzungsbedingungen"
    },
    "link": {
      "about": "Über uns",
      "contact": "Kontakt"
    },
    "add": "Hinzufügen",
    "cancel": "Abbrechen",
    "connected": "aktiv",
    "disconnected": "inaktiv",
    "removeUser": {
      "header": "User entfernen",
      "subHeader": "",
    },
    "disConnectUser": "User entfernen",
    "remove": "Entfernen",
    "roleMng": "Rollenmanagement",
    "accessUnAuthPageInAuth": {
      "header": "Bitte abmelden",
      "message": "Du versuchst auf eine öffentliche Seiten zuzugreifen. Bitte melde Dich erst ab, um die Aktion auszuführen"
    },
    "showActiveUsers": " Inaktive",
    "hideInactive": "Aktive",
    "ShowFormerEmployees": "Inaktive",
    "hideFormerEmployees": "Aktive",
    "canceledProject": "Projekt stornieren",
    "deleteProject": "Projekt löschen",
    "projects": "Projekte",
    "information": "Information",
    "noStaff": "Kein Personal"
  },
  "registerOptions": {
    "header": "Registriere Dich",
    "subHeader": "Willkommen bei peeepl! Hier kannst Du Dich kostenlos Registrieren. Dein Account und die darin gespeicherten Daten gehört ausschließlich Dir. Du entscheidest, mit wem Du welche Informationen teilst. Wir empfehlen Dir eine E-Mail-Adresse zu verwenden auf die ausschließlich Du Zugriff hast.",
    "form": {
      "header": "Registrierung",
      "subHeader":"Wie möchtest Du Dich registrieren",
      "input": {
        "emailPassword": "E-Mail und Passwort nutzen",
        "code": "Registrierungscode nutzen",
      },
    }
  },
  "registerRegistrationCode": {
    "header": "Registriere Dich",
    "subHeader": "Willkommen bei peeepl! Hier kannst Du Dich kostenlos Registrieren. Dein Account und die darin gespeicherten Daten gehört ausschließlich Dir. Du entscheidest, mit wem Du welche Informationen teilst. Wir empfehlen Dir eine E-Mail-Adresse zu verwenden auf die ausschließlich Du Zugriff hast.",
    "form": {
      "header": "Registrierung",
      "subHeaderRequest":"Registrierungscode anfordern",
      "subHeaderSendMail":"Code wurde an Deine Mail gesendet",
      "subHeaderSendPhone":"Code wurde an Deine Telefonnummer gesendet",
      "button":{
        "useEmail":"E-Mail verwenden",
        "usePhoneNumber":"Telefonnummer verwenden",
        "requestCode":"Code Anfordern",
        "register":"Registrieren",
        "requestNewCode":"Neuen Code anfordern",
      },
      "checkbox": {
        "textStart": "Ich stimme den",
        "link1": "Datenschutzbestimmungen",
        "textAnd": "und",
        "link2": "Nutzungsbedingungen",
        "textEnd": "zu"
      },
      "input": {
        "emailPassword": "E-Mail und Passwort nutzen",
        "code": "Registrierungscode nutzen",
        "enterCode":"Code eingeben",
        "enterEmail":"E-Mail Adresse eingeben",
        "phone": "Telefonnummer"
      },
    }
  },
  "registration": {
    "header": "Registriere Dich",
    "subHeader": "Willkommen bei peeepl! Hier kannst Du Dich kostenlos Registrieren. Dein Account und die darin gespeicherten Daten gehört ausschließlich Dir. Du entscheidest, mit wem Du welche Informationen teilst. Wir empfehlen Dir eine E-Mail-Adresse zu verwenden auf die ausschließlich Du Zugriff hast.",
    "form": {
      "header": "Registrierung",
      "subHeader":"Passwort erstellen",
      "input": {
        "firstName": "Vorname",
        "lastName": "Nachname",
        "email": "E-Mail",
        "emailRepeat": "E-Mail wiederholen",
        "pwd": "Passwort",
        "pwdRepeat": "Passwort wiederholen",
        "checkbox": {
          "textStart": "Ich stimme den",
          "link1": "Datenschutzbestimmungen",
          "textAnd": "und",
          "link2": "Nutzungsbedingungen",
          "textEnd": "zu"
        }
      },
      "sameEmail": "E-Mail ist bereits im System registriert",
      "sameMobileNo": "Telefonnummer ist bereits im System registriert",
      "submit": "Registrieren"
    }
  },
  "registerSuccess": {
    "header": "E-Mail versendet",
    "subHeader": "Bitte bestätige Deine E-Mail Adresse. Hierfür haben wir Dir einen Link in Dein Postfach gesendet. Gegebenenfalls musst Du in Deinem Spam Ordner schauen."
  },
  "registerSuccessLinkRequired": {
    "header": "Ein letzter Schritt!",
    "subHeader": "Dein Arbeitgeber muss Deinen Account noch verknüpfen. Dann kann es los gehen. Bis gleich!"
  },
  "registerSuccessNetwork": {
    "header": "E-Mail versendet",
    "subHeader": "Du hast nicht die Rechte diesen Link zu öffnen. Bitte wende Dich an Deinen Unternehmens Administrator."
  },
  "registerSuccessGoToLogin": {
    "header": "Registrierung erfolgreich",
    "subHeader": "Nun kannst du dich auf der Login-Seite mit deiner Telefonnummer und dem Verifizierungscode in unsere Plattform einloggen."
  },
  "activation": {
    "header": "E-Mail erfolgreich bestätigt",
    "subHeader": "Du wirst gleich automatisch auf unsere Login Seite weitergeleitet. Wenn dies nicht innerhalb von 10 Sekunden geschieht klicke",
    "subHeaderLink": "hier",
    "conflict": "Dein Benutzerkonto ist bereits aktiviert. Du kannst Dich anmelden."
  },
  "expireActivation": {
    "header": "Aktivierungslink ist",
    "header2": "abgelaufen",
    "subHeader": "Leider ist Dein Aktivierungslink abgelaufen. Du kannst Dir einen neuen Aktivierungslink erstellen. Klicke dafür",
    "subHeaderLink": "hier"
  },
  "login": {
    "header": "Herzlich willkommen",
    "subHeader": "Hier kannst Du Dich in Deinem Account anmelden oder Dich kostenlos registrieren.",
    "form": {
      "header": "Anmeldung",
      "checkbox": "Passwort speichern",
      "submit": "Anmelden"
    },
    "link": {
      "forgotPass": "Passwort vergessen",
      "registration": "Registrieren"
    },
    "loginError": "E-Mail und Passwort stimmen nicht überein!",
    "activateAccount": "Dein Konto ist noch nicht aktiviert. Klicke auf den Aktivierungs-E-Mail-Link, um Dein Konto zu aktivieren."
  },
  "forgotPassword": {
    "header": "Passwort vergessen",
    "subHeader": "Hast Du Dein Passwort vergessen? Kein Problem wir helfen Dir ein neues Passwort zu erstellen.",
    "form": {
      "header": "Wir senden Dir eine E-Mail um dein Passwort zurückzusetzen",
      "submit": "E-Mail senden"
    },
    "forgotPasswordSuccess": {
      "header": "E-Mail versendet",
      "subHeader": "Bitte bestätige Deine E-Mail-Adresse. Hierfür haben wir Dir einen Link in Dein Postfach gesendet. Gegebenenfalls musst Du in Deinem Spam-Ordner schauen."
    }
  },
  "resetPassword": {
    "header": "Neues Passwort",
    "subHeader": "Du kannst nun ein neues Passwort für Deinen Account erstellen.",
    "form": {
      "header": "Passwort zurückzusetzen",
      "submit": "Neues Passwort speichern",
      "button": "E-Mail anfordern"
    },
    "expired": {
      "message": "Dein Link zum Zurücksetzen des Passworts ist abgelaufen. Generiere einen neuen Link, indem Du unten auf den Link Passwort vergessen klickst.",
    },
    "success": {
      "header": "E-Mail versendet",
      "subHeader": "Bitte bestätige Deine E-Mail-Adresse. Hierfür haben wir Dir einen Link in Dein Postfach gesendet. Gegebenenfalls musst Du in Deinem Spam-Ordner schauen."
    },
    "modal": {
      "header": "Möchtest Du Dich Passwort ändern?",
      "subText": "Klicke auf neues Passwort, Du bekommst von uns eine Nachricht an Deine Mail Adresse und kannst ein neues Passwort setzten",
      "button": "E-Mail anfordern"
    }
  },
  "editProfile": {
    "profile": "Profil",
    "personalInformation": "PERSÖNLICHE INFORMATIONEN",
    "hello": "Hallo",
    "description": "Das ist Dein peeepl Profil. Alle Deine Informationen werden sicher auf einem Server in Deutschland und DSGVO-konform gespeichert. Für die optimale Nutzung unserer Plattform empfehlen wir Dir Dein Profil zu vervollständigen.",
    "form": {
      "firstName": "Vorname",
      "lastName": "Nachname",
      "telePhoneNumber": "Telefonnummer",
      "birthDay": "Geburtstag",
      "gender": "Geschlecht",
      "married": "Familienstand",
      "street": "Straße",
      "house": "Hausnummer",
      "streetAndHouseNumber": "Straße und Hausnummer",
      "postCode": "Postleitzahl",
      "city": "Stadt",
      "country": "Land",
      "error": {
        "mobile": "Keine Sonderzeichen erlaubt. Bitte nur Nummern verwenden."
      }
    },
    "address": "Adresse",
    "editInformation": "Informationen bearbeiten",
    "editInformationUser": "Text bearbeiten",
    "saveUserInfo": " Text speichern",
    "save": "Speichern",
    "discard": "Verwerfen",
    "infoSaved": "Profilinformationen erfolgreich gespeichert",
    "personalInfo": {
      "placeholder": "Erzähle etwas über dich!",
      "error": "Profilinformationen sind erforderlich"
    },
    "gender": {
      "header": "Geschlecht auswählen",
      "M": "Männlich",
      "F": "Weiblich",
      "O": "Divers",
    }
  },
  "staff": {
    "header": "Mitarbeiter",
    "removeUser": {
      "success": "Der Benutzer wurde erfolgreich vom Unternehmen getrennt",
      "failed": "Fehler bei einem vom Unternehmen getrennten Benutzer"
    },
    "modal": {
      "changeRole": {
        "header": "Rolle ändern",
        "subHeader": "Du kannst die Rolle des Users ändern.",
        "question": "Welche Rolle möchtest Du vergeben?",
        "confirm": "Rolle vergeben",
        "error": "Bitte wähle eine beliebige Rolle aus"
      },
      "changeRoleConfirm": {
        "header": "Bist Du sicher?",
        "subHeader": "Eine Änderung der Rolle geschieht sofort. Du kannst diese Aktion nicht widerrufen.",
        "success": "Benutzerrolle erfolgreich geändert",
        "failed": "Die Rolle konnte nicht geändert werden"
      },
      "invitationLink": {
        "header": "Einladungslink erstellen",
        "subHeader": "Erstelle einen Link und lade jemanden dazu ein, sich auf peeepl mit Dir zu verknüpfen",
        "networkSubHeader": "Lade deine Geschäftspartner und Dienstleister ein, sich mit Dir auf peeepl zu verknüpfen.",
        "createLink": "Link erstellen",
        "question": "In welche Rolle möchtest Du Deinen Mitarbeiter einladen?",
        "header1": "Einladungslink für Mitarbeiter",
        "subHeader1": "Hier kannst Du festlegen, in welcher Rolle Deine Mitarbeiter eingeladen werden sollen",
        "networkSubHeader1": "Lade Deine Geschäftspartner und Dienstleister ein, sich mit Dir auf peeepl zu verknüpfen.",
        "linkCopy": "Link kopieren",
        "shareLink": "Teile den Link",
        "shareLinkContent": "Versende den Link an Deine Mitarbeiter. Du kannst den Link per E-Mail, SMS oder ähnliches versenden",
        "link": "Verknüpfung"
      },
      "sendMessage":{
        "header": "Mitteilung senden",
        "continue": "Weiter zur Nachricht",
        "title": "Titel",
        "message": "Nachricht",
        "send": "Absenden",
        "cancel": "Abbrechen",
        "success": "Nachricht erfolgreich gesendet",
        "failed": "Nachricht konnte nicht gesendet werden",
        "titleRequired": "Titel ist erforderlich",
        "noUsersSelected": "Bitte wählen Sie mindestens einen Benutzer aus, um eine Nachricht zu senden",
        "back": "Zurück",
        "filter": {
          "project": "Projekt",
          "shift": "Schicht",
          "planingStatus": "Planungsstatus",
          "skills": "Skills"
        }
      }
    }
  },
  "adminEmployee": {
    "header": "Du wurdest eingeladen Dich mit einem Unternehmen zu verbinden",
    "subHeader": "Nachdem Du Dich verbunden hast, kannst Deinen Arbeitsalltag einfach und zentral planen und organisieren",
    "newHere": "Neu hier?",
    "accountYet": "Du hast noch keinen peeepl Account?",
    "clickHere": "Dann klick hier",
    "alreadyMember": "Bereits Mitglied?",
    "doYouAlreadyMember": "Du hast bereits einen peeepl Account?",
    "expire": "Der Link ist abgelaufen. Bitte wende Dich an einen Unternehmens Administrator, um einen neuen Link zu erhalten.",
    "roleCreated": "Benutzerrolle wurde erfolgreich erstellt"
  },
  "network": {
    "pageTitle": "Netzwerk",
    "header": "Du wurdest eingeladen Dich mit einem Unternehmen zu verbinden",
    "subHeader": "Nachdem Du Dich verbunden hast kannst Deinen Arbeitsalltag einfach und zentral planen und organisieren",
    "Table": {
      "Favorite": "Favoriten",
      "Initials": "Initialen",
      "Name": "Name",
      "Status": "Status"
    },
    "message": {
      "AddToFavorites": "Zu Favoriten hinzugefügt",
      "RemovedFromFavorites": "Aus Favoriten entfernt",
      "LinkCopySuccess": "Link erfolgreich kopiert"
    },
    "showActiveNetwork": "Inaktive",
    "hideInactive": "Aktive",
    "ShowFormerNetwork": "Inaktive",
    "hideFormerNetwork": "Aktive",
    "modal": {
      "disconnect": {
        "header": "Aus Netzwerk entfernen",
        "subHeader": "Du kannst dieses Unternehmen aus Deinem Netzwerk entfernen. Anfragen für kommende Projekte werden storniert. Eine Übersicht über vergangene Projekte bleibt erhalten, kann aber nicht mehr bearbeitet werden.",
      },
      "disconnectConfirm": {
        "header": "Bist Du sicher?",
        "subHeader": "Du kannst die Aktion nicht widerrufen. Wenn Du zu einem späteren Zeitpunkt dieses Unternehmen wieder in Dein Netzwerk aufnehmen möchtest, musst Du Dich erneut verbinden.",
      }
    },
    "companyProfile": {
      "title": "Firmenprofil",
      "basicInfo": "Informationen",
      "companyName": "Name der Firma",
      "companyType": "Unternehmenstyp",
      "companyAddress": "Firmenanschrift"
    }
  },
  "project": {
    "PageTitle": "Projektübersicht",
    "createProject": "Projekt erstellen",
    "createProjectSubHeader": "",
    "Table": {
      "Name": "Name",
      "company": "Firma",
      "location": "Standort"
    },
    "list": {
      "showPast": "Beendete Projekte",
      "hidePast": "Projektübersicht",
      "showCanceled": "Stornierte Projekte",
      "hideCanceled": "Projektübersicht",
      "showDeleted": "Gelöschte Projekte",
      "hideDeleted": "Gelöschte Projekte ausblenden",
    },
    "projectManagerModal": {
      'title': 'Projektleitung',
      'noRecordError': 'Informationen sind nicht verfügbar'
    },
    "status": {
      "created": "Projekt erstellt", //yellow
      "planning": "In Planung", //blue
      "ready": "Personalplanung abgeschlossen", //orange
      "ongoing": "Hat begonnen", //green
      "completed": "Abgeschlossen" //red
    },
    "modal": {
      "header": {
        "canceledProject": "Projekt Stornieren",
        "deleteProject": "Projekt löschen",
        "deleteShift": "Schicht löschen"
      },
      "subHeader": {
        "deleteShift": "Möchtest Du die Schicht wirklich löschen?"
      },
      "form": {
        "project_name": "Projektname",
        "location": "Location",
        "start_date": "Datum, von",
        "end_date": "Datum, bis",
        "start_time": "Uhrzeit",
        "end_time": "Uhrzeit",
      },
      "canceled": {
        "title": "Storniert",
        "message1": "Das",
        "message2": "wurde erfolgreich storniert!",
      },
      "deleted": {
        "title": "Gelöscht",
        "message1": "Das",
        "message2": "wurde erfolgreich gelöscht!",
      }
    },
    "response": {
      "created": "Projekt erfolgreich erstellt",
      "failed": "Projekt konnte nicht erstellt werden"
    },
  },
  "projectDetails": {
    "formLabel": {
      "start_date": "Start Datum",
      "end_date": "End Datum",
      "start_time": "Start Uhrzeit",
      "end_time": "End Uhrzeit",
      "totalPeople": "Gästeanzahl",
      "status": "Status",
      "notes": "Platz für Notizen. Hier geschriebene Infos sind für alle auf das Projekt eingeladene oder gebuchten Personen einsehbar.",
    },
    "editProject": "Projekt bearbeiten",
    "people": "Personen",
    "document": "Dokumente",
    "newDocument": "Neues Dokument",
    "timeValidation": "Der Projektzeitraum muss gleich oder größer sein als die Zeiträume der Projekt Schichten",
    "infoSaved": "Projektinformationen erfolgreich gespeichert",
    "failedToSave": "Projektinformationen konnten nicht aktualisiert werden",
    "SomethingWentWrong": "Etwas ist schief gelaufen",
    "projectDeletedMsg": "Projekt ist bereits gelöscht",
    "projectNoAccessMsg": "Sie haben keinen Zugriff",
    "shifts": {
      "th": {
        "shift": "Schicht",
        "date": "Datum",
        "time": "Uhrzeit",
        "discipline": "Gewerk",
        "status": "Status",
        "personal": "Personal",
        "documents": "Dokumente",
        "information": "Informationen"
      },
      "heading": {
        "ownShift": "Eigene Schicht",
        "personnelPlanning": "Personalplanung"
      },
      "buttons": {
        "scheduleStaff": "Personal einplanen",
        "editShift": "Schicht bearbeiten",
        "addShift": "Schicht hinzufügen",
        "addProjectManagement": "Projektmanagement hinzufügen",
        "deleteShift": "Schicht löschen"
      },
      "requestedUser": "Angefragtes Personal",
      "availableUser": "Verfügbares personal",
      "scheduledUser": "Eingeplantes personal",
      "user_requested": "Angefragt",
      "user_assigned": "Eingeteilt",
      "user_tentative": "Vielleicht",
      "user_perhaps": "Vielleicht",
      "user_available": "Verfügbar",
      "user_open": "Verfügbar",
      "user_unavailable": "Abgelehnt",
      "user_rejected": "Abgelehnt",
      "user_added": "Eingeplant",
      "ic_request_open": "Angefragt",
      "ic_request_unavailable": "Abgelehnt",
      "ic_request_tentative": "Vielleicht",
      "ic_user_requested": "Angefragt",
      "ic_user_assigned": "Eingeteilt",
      "ic_user_tentative": "Vielleicht",
      "ic_user_perhaps": "Vielleicht",
      "ic_user_available": "Verfügbar",
      "ic_user_open": "Offen",
      "ic_user_unavailable": "Abgelehnt",
      "ic_user_rejected": "Abgelehnt",
      "ic_user_added": "Eingeplant",
      "ic_user_mapped" :"Eingeplant",
      "ic_open_perhaps": "Öffnen Vielleicht",
      "ic_open_rejected": "Abgelehnt",
      "company_requested": "Angefragt",
      "company_assigned": "Zugewiesen",
      "company_tentative": "Vielleicht",
      "company_perhaps": "Vielleicht",
      "company_available": "Verfügbar",
      "company_open": "Offen",
      "company_unavailable": "Abgelehnt",
      "company_rejected": "Abgelehnt",
      "company_added": "Eingeplant",
      "notPlanned": "Nicht eingeplant",
      "showTheMessage": "Nachricht anzeigen",
      "forms": {
        "createTitle": "Schicht erstellen",
        "updateTitle": "Schicht aktualisieren",
        "description": "",
        "shift_name": "Schicht Name",
        "discipline": "Gewerk",
        "noOfpeople": "Personenanzahl",
        "information": "Informationen",
      },
      "response": {
        "created": "Projektschicht erfolgreich erstellt",
        "failed": "Projektschicht konnte nicht erstellt werden",
        "updated": "Projektschicht erfolgreich aktualisiert",
        "failedUpdated": "Projektschicht konnte nicht aktualisiert werden",
        "mayBeResponse": "Deine Rückmeldung Vielleicht wurde erfolgreich übersendet",
        "noPositiveResponse": "Bitte senden Sie eine Antwort mit JA, bevor Sie den Benutzer planen",
        "multipleUpdateCheck": "Du muss mindestens einen Nutzer auswählen um diese Aktion zu nutzen.",
        "shiftDeleted": "Schicht erfolgreich gelöscht",
        "shiftFailedDeleted": "Shift konnte nicht gelöscht werden",
      },
      "actions": {
        "removeUser": "Entfernen",
        "rebook": "Umbuchen"
      },
      "modal": {
        "maybeHeader": "Du bist Dir noch nicht sicher",
        "maybeSubHeader": "Wenn Du mit VIELLEICHT antwortest hast Du die Möglichkeit eine Nachricht zu hinterlassen und zu einem späteren Zeitpunkt zu- oder abzusagen.",
        "maybeButton": "Absenden",
        "rejectedHeader": "Ablehnen",
        "rejectedSubHeader": "Wenn Du mit ABLEHNEN antwortest wirst Du nicht weiter für die Planung berücksichtigt und kannst nicht auf diesem Projekt arbeiten",
        "rejectedButton": "Entfernen",
        "confirmRejectedHeader": "Bist Du sicher?",
        "confirmRejectedSubHeader": "Diese Aktion kann nicht widerrufen werden!",
        "availableHeader": "Annehmen",
        "availableSubHeader": "Wenn Du mit ANNEHMEN antwortest wirst Du für die weitere Planung berücksichtigt",
        "availableButton": "Annehmen",
        "confirmAvailableHeader": "Bist Du sicher?",
        "confirmAvailableSubHeader": "Diese Aktion kann nicht widerrufen werden!",
        "confirmRemoveHeader": "Bist Du sicher?",
        "confirmRemoveSubHeader": "Diese Aktion kann nicht widerrufen werden!",
        "IC": {
          "maybeHeader": "Du bist Dir noch nicht sicher",
          "maybeSubHeader": "Wenn Du mit VIELLEICHT antwortest hast Du die Möglichkeit eine Nachricht zu hinterlassen und zu einem späteren Zeitpunkt zu- oder abzusagen.",
          "maybeButton": "Absenden",
          "rejectedHeader": "Ablehnen",
          "rejectedSubHeader": "Wenn Du mit ABLEHNEN antwortest wirst Du nicht weiter für die Planung berücksichtigt und kannst nicht auf diesem Projekt arbeiten",
          "rejectedButton": "Entfernen",
          "confirmRejectedHeader": "Bist Du sicher?",
          "confirmRejectedSubHeader": "Diese Aktion kann nicht widerrufen werden!",
          "availableHeader": "Annehmen",
          "availableSubHeader": "Wenn Du mit ANNEHMEN antwortest wirst Du für die weitere Planung berücksichtigt",
          "availableButton": "Annehmen",
          "confirmAvailableHeader": "Bist Du sicher?",
          "confirmAvailableSubHeader": "Diese Aktion kann nicht widerrufen werden!",
          "confirmAvailableSubHeaderLegal": "Ich stimme zu, dass mit der finalen Bestätigung meines Einsatzes durch {name_owning_company} meine Profilinformationen an die Administratoren, Disponenten und Projektleiter von {name_owning_company} innerhalb dieses Projektes weitergegeben werden.",
          "confirmRemoveHeader": "Bist Du sicher?",
          "confirmRemoveSubHeader": "Diese Aktion kann nicht widerrufen werden!",
        },
        "OC": {
          "maybeHeader": "Du bist dir noch nicht sicher",
          "maybeSubHeader": "Wenn Du mit VIELLEICHT antwortest hast Du die Möglichkeit eine Nachricht zu hinterlassen und zu einem späteren Zeitpunkt zu- oder abzusagen.",
          "maybeButton": "Absenden",
          "rejectedHeader": "Ablehnen",
          "rejectedSubHeader": "Wenn Du mit ABLEHNEN antwortest wirst Du nicht weiter für die Planung berücksichtigt und kannst nicht auf diesem Projekt arbeiten",
          "rejectedButton": "Entfernen",
          "confirmRejectedHeader": "Bist Du sicher?",
          "confirmRejectedSubHeader": "Diese Aktion kann nicht widerrufen werden!",
          "availableHeader": "Annehmen",
          "availableSubHeader": "Wenn Du mit ANNEHMEN antwortest wirst Du für die weitere Planung berücksichtigt",
          "availableButton": "Annehmen",
          "confirmAvailableHeader": "Bist Du sicher?",
          "confirmAvailableSubHeader": "Diese Aktion kann nicht widerrufen werden!",
          "confirmRemoveHeader": "Bist Du sicher?",
          "confirmRemoveSubHeader": "Diese Aktion kann nicht widerrufen werden!",
        },
      },
      "swal": {
        "updateUserRequest": "Du hast den Nutzer erfolgreich eingeplant.", // for LightTableUser.vue & ShiftDetails.vue
        "updateICShiftRequest": "Das {shift_name} wurde erfolgreich studiert! (updateICShiftRequest)", // for LightTableUser.vue
        "IC": {
          "updateUserRequest": "Die Rückmeldung Offen wurde übermittelt.", // for ICShiftStaff.vue & ICShiftDetails.vue
          "updateICShiftRequest": "Das {shift_name} wurde erfolgreich studiert! (IC.updateICShiftRequest)", // for ICShiftDetails.vue
          "updateConfirm": "Du hast Dich für die Schicht verfügbar gemeldet.",
          "updateRejected": "Du hast die Schicht abgelehnt.",
          "updateMaybe": "Die Rückmeldung vielleicht wurde übermittelt.",
          "updateUserAvailable": "Du hast Dich für die Schicht verfügbar gemeldet.",
          "updateUserMapped" : "Du hast den Nutzer erfolgreich eingeplant",
          "updateUserTentative" : "Die Rückmeldung vielleicht wurde übermittelt.",
          "updateUserUnavailable" : "Du hast die Schicht abgelehnt.",
          "updateUserRejected" : "Du hast den Nutzer abgelehnt.",
          "updateUserPerhaps" : "Die Rückmeldung vielleicht wurde übermittelt.",
        },
        "OC": {
          "updateUserRequest": "Das {shift_name} wurde erfolgreich studiert! (OC.updateUserRequest)", // for ShiftDetails.vue
          "updateConfirmAvailableModalVisible": "Du hast Dich für die Schicht verfügbar gemeldet.",
          "updateConfirmRejectedModalVisible": "Du hast die Schicht abgelehnt.",
          "updateMaybeModalVisible": "Die Rückmeldung vielleicht wurde übermittelt.",
          "updateConfirm": "Du hast den Nutzer erfolgreich eingeplant.",
          "updateRejected": "Du hast den Nutzer abgelehnt.",
          "updateMaybe": "Du hast die Rückmeldung vielleicht.",
        },
      }
    },
    "documents": {
      "listDocPopupTitle": "Dokumentenliste"
    },
    'shiftRequest': {
      "titleModal": "PERSONAL EINPLANEN",
      "userTab": "Eigenes Personal",
      "networkTab": "Netzwerk",
      "selectAll": "Alle auswählen",
      "selectNumber": "Anzahl auswählen",
      "addressLabel": "Adresse",
      "assignBtn": "Einteilen",
      "requestBtn": "Anfragen",
      "userRequestSuccess": "Benutzeranfrage erfolgreich gesendet",
      "uerAssignedSuccess": "Benutzer erfolgreich eingeteilt",
      "pleaseSelectUser": "Bitte Benutzer auswählen",
      "status": {
        "user_requested": "Angefragt",
        "user_assigned": "Eingeteilt",
        "user_tentative": "Vielleicht",
        "user_perhaps": "Vielleicht",
        "user_available": "Verfügbar",
        "user_open": "Verfügbar",
        "user_unavailable": "Abgelehnt",
        "user_rejected": "Abgelehnt",
        "user_added": "Eingeplant",
        "company_requested": "Requested",
        "company_assigned": "Assigned",
        "company_tentative": "Tentative",
        "company_perhaps": "Perhaps",
        "company_available": "Available",
        "company_unavailable": "Unavailable",
        "company_added": "Added",
        "company_open": "Open",
        "company_rejected": "Rejected",
        "ic_request_open": "Angefragt",
        "ic_request_unavailable": "Vielleicht",
        "ic_request_tentative": "Abgelehnt",
        "ic_user_requested": "Angefragt",
        "ic_user_assigned": "Zugewiesen",
        "ic_user_tentative": "Vielleicht",
        "ic_user_perhaps": "Vielleicht",
        "ic_user_available": "Verfügbar",
        "ic_user_open": "Offen",
        "ic_user_unavailable": "Abgelehnt",
        "ic_user_rejected": "Abgelehnt",
        "ic_user_added": "Eingeplant",
        "ic_open_perhaps": "Öffnen vielleicht",
        "ic_open_rejected": "Abgelehnt",
      },
      "label": {
        "contactPerson": "Ansprechpartner",
        "companyAddress": "Adresse",
        "companyEmail": "E-Mail",
        "companyPhone": "Telefonnummer",
      },
      "shiftRequestSuccess": "Status der Schichtanfrage aktualisiert",
      "shiftRequestFailed": "Status der Schichtanfrage konnte nicht aktualisiert werden",
      "moreRequestFailed": "Du kannst keine weiteren Benutzer anfordern",
      "scheduleICRow": "Du hast bisher noch kein Personal angefragt. Du musst erst eine Rückmeldung zur Anfrage geben bevor Du Personal einplanen kannst.",
      "noMoreAddUserToShift": "Du kannst der Schicht keine weiteren Benutzer hinzufügen"
    },
    "removeBookedUser": {
      "removeHeader": "Bist Du sicher?",
      "removeSubHeader": "Diese Aktion kann nicht widerrufen werden!",
      "responseHeader": "Benutzer entfernt",
      "responseSuccess": "Sie haben den gebuchten Benutzer erfolgreich entfernt",
      "responseFailed": "Gebuchter Benutzer konnte nicht entfernt werden"
    }
  },
  "location": {
    "title": "Locations",
    "list": {
      "addLocation": "Hinzufügen",
      "showDeletedLocation": "Archiv",
      "hideDeletedLocation": "Aktive anzeigen"
    },
    "table": {
      "name": "Name",
      "address": "Adresse"
    },
    "add": {
      "title": "Hinzufügen",
      "subTitle": "Erstelle eine neue Location um ein Projekt damit zu verknüpfen",
      "locationName": "Name",
      "street": "Straße",
      "houseNumber": "Hausnummer",
      "postalCode": "Postleitzahl",
      "city": "Stadt",
      "addButton": "Hinzufügen",
      "successMessage": "Location erfolgreich hinzugefügt",
      "failMessage": "Etwas hat nicht geklappt. Bitte versuche es erneut!"
    },
    "delete": {
      "title": "Archivieren?",
      "subTitle": "Wenn Du die Location entfernst kannst Du die darin gespeicherten Informationen nicht mehr bearbeiten. Kommende Projekte die mit der Location verknüpft sind müssen einer neuen Location zugewiesen werden.",
      "confirmDeleteTitle": "Bist Du sicher?",
      "confirmDeleteSubTitle": "Diese Aktion kann nicht widerrufen werden.",
      "successDeleteMessage": "Dokument wurde erfolgreich gelöscht",
      "failDeleteMessage": "Dokument konnte nicht gelöscht werden",
      "successDeleteLocationMessage": "Location wurde erfolgreich gelöscht",
      "failDeleteLocationMessage": "Löschen der Location fehlgeschlagen"
    },
    "detail": {
      "titlePage": "Profil",
      "subHeader": "Hier kannst Du Deine Location verwalten und bearbeiten.",
      "locationInformation": "Informationen",
      "updateSuccessMsg": "Location erfolgreich aktualisiert",
      "editButton": "Informationen bearbeiten",
      "form": {
        "name": "Name",
        "website": "Website",
        "street": "Straße",
        "houseno": "Hausnummer",
        "postalcode": "Postleitzahl",
        "city": "Stadt",
        "contactPerson": "Ansprechpartner",
        "contactName": "Name",
        "contactTeleNo": "Telefonnummer",
        "contactEmail": "E-Mail",
        "alternateTelNo": "Alternative Telefonnummer",
        "about": {
          "placeholder": "Hier ist Platz für Deine Anmerkungen!",
        },
      }
    },
    "locationDocument": {
      "titleInformation": "Dokumente",
      "newDocumentBtn": "Neues Dokument",
      "docDeleteTitle": "Dokument löschen?",
      "docDeleteSubHeader": "Bist Du sicher, dass Du das Dokument entfernen möchtest?",
      "newDocumentTitle": "Neues Dokument",
      "documentName": "Dokumentname",
      "uploadModalBtn": "Dokument hochladen",
      "uploadDocSuccess": "Dokument erfolgreich hochgeladen",
      "errorPdfAllow": "Nur PDF-Dateien sind erlaubt"
    },
    "locationText": {
      "savedMessage": "Location Beschreibung erfolgreich gespeichert",
      "errorMessage": "Speichern der Location Beschreibung fehlgeschlagen",
    },
    "project": {
      "title": ""
    }
  },
  "skills": {
    "title": "Skills",
    "titleModal": "Skill freischalten",
    "list": {
      "addSkills": "Hinzufügen",
      "showDeletedSkills": "Archiv",
      "hideDeletedSkills": "Aktive anzeigen",
      "editSkills": "Skills bearbeiten"
    },
    "table": {
      "name": "Name",
      "address": "Adresse"
    },
    "add": {
      "title": "Hinzufügen",
      "subTitle": "Erstelle eine neue Skills um ein Projekt damit zu verknüpfen",
      "SkillsName": "Name",
      "street": "Straße",
      "houseNumber": "Hausnummer",
      "postalCode": "Postleitzahl",
      "city": "Stadt",
      "addButton": "Hinzufügen",
      "successMessage": "Skill Zuweisung erfolgreich geändert",
      "failMessage": "Etwas hat nicht geklappt. Bitte versuche es erneut!"
    },
    "delete": {
      "title": "Archivieren?",
      "subTitle": "Wenn Du die Skills entfernst kannst Du die darin gespeicherten Informationen nicht mehr bearbeiten. Kommende Projekte die mit der Location verknüpft sind müssen einer neuen Location zugewiesen werden.",
      "confirmDeleteTitle": "Bist Du sicher?",
      "confirmDeleteSubTitle": "Diese Aktion kann nicht widerrufen werden.",
      "successDeleteMessage": "Dokument wurde erfolgreich gelöscht",
      "failDeleteMessage": "Dokument konnte nicht gelöscht werden",
      "successDeleteSkillsMessage": "Skills wurde erfolgreich gelöscht",
      "failDeleteSkillsMessage": "Löschen der Skills fehlgeschlagen"
    },
    "detail": {
      "titlePage": "Profil",
      "subHeader": "Hier kannst Du Deine Skills verwalten und bearbeiten.",
      "skillsInformation": "Informationen",
      "updateSuccessMsg": "Skills erfolgreich aktualisiert",
      "editButton": "Informationen bearbeiten",
      "form": {
        "name": "Name",
        "website": "Website",
        "street": "Straße",
        "houseno": "Hausnummer",
        "postalcode": "Postleitzahl",
        "city": "Stadt",
        "contactPerson": "Ansprechpartner",
        "contactName": "Name",
        "contactTeleNo": "Telefonnummer",
        "contactEmail": "E-Mail",
        "alternateTelNo": "Alternative Telefonnummer",
        "about": {
          "placeholder": "Hier ist Platz für Deine Anmerkungen!",
        },
      }
    },
    "SkillsDocument": {
      "titleInformation": "Dokumente",
      "newDocumentBtn": "Neues Dokument",
      "docDeleteTitle": "Dokument löschen?",
      "docDeleteSubHeader": "Bist Du sicher, dass Du das Dokument entfernen möchtest?",
      "newDocumentTitle": "Neues Dokument",
      "documentName": "Dokumentname",
      "uploadModalBtn": "Dokument hochladen",
      "uploadDocSuccess": "Dokument erfolgreich hochgeladen",
      "errorPdfAllow": "Nur PDF-Dateien sind erlaubt"
    },
    "SkillsText": {
      "savedMessage": "Skills Beschreibung erfolgreich gespeichert",
      "errorMessage": "Speichern der Skills Beschreibung fehlgeschlagen",
    },
    "SkillsView": {
      "swipeDeclined": "Bitte schaue das Video bis zum Ende.",
      "swipeDeclinedLastVideo": "Das ist das letzte Video!"
    },
    "project": {
      "title": ""
    }
  }
};
