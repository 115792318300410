<template>
  <div class="card">
    <div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0">{{title}}</h3>
        </div>
      </div>
    </div>

    <el-table
      class="table-responsive table"
      :data="tableData"
      :default-sort="{ prop: 'watched_dt', order: 'descending' }"
      header-row-class-name="thead-light"
    >
      <el-table-column label="Name" sortable min-width="130px" prop="page">
        <template v-slot="{ row }">
          <div class="font-weight-600">{{ row.first_name }} {{ row.last_name }}</div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableData[0]?.created_dt !== undefined"
        label="Erstellt am"
        sortable
        min-width="90px"
        prop="created_dt"
      >
        <template v-slot="{ row }">
          <span class="font-weight-600">{{ formatDate(row.created_dt) }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { ElTable, ElTableColumn } from "element-plus";
import date from "@/util/date";
export default {
  name: "page-visits-table",
  components: {
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
  },
  props: {
    tableData: Array,
    title: String,
  },
  data() {
    return {
    };
  },
  methods: {
    formatDate(projectDate) {
      return date().formatDate(projectDate, "DD.MM.YYYY HH:mm");
    },
  },
};
</script>
<style></style>
