import dayjs from "dayjs";
const age = (date) => {
  if (date) {
    const today = dayjs(new Date());
    const fomateDate = date.split(".").join("-");
    const dob = dayjs(fomateDate).format('YYYY-MM-DD');
    const year = today.diff(dob, "year");
    return isNaN(year) ? "" : year;
  }
  return "";
};
export default age;
