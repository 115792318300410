import dayjs from "dayjs";
const relativeTime = require('dayjs/plugin/relativeTime');
const customParseFormat = require('dayjs/plugin/customParseFormat');
const isToday = require('dayjs/plugin/isToday');
const isSameOrBefore = require('dayjs/plugin/isSameOrBefore');
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
const isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(customParseFormat);
dayjs.extend(isToday);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);
require('dayjs/locale/en');
require('dayjs/locale/de');
const locale = process.env.VUE_APP_I18N_LOCALE || "de";

const date = () => {
  const fromNow = (payload) => {
    dayjs.extend(relativeTime);
    return dayjs(payload).locale(locale).fromNow();
  };
  const formatDate = (date, unit) => {
    return dayjs(date).format(unit);
  };
  const formatTime = (time, unit) => {
    return dayjs(time, unit).format(unit);

  };
  const isSame = (firstDate, secondDate, unit) => {
    return dayjs(firstDate).isSame(secondDate, unit);
  };
  const isDateToday = (date) => {
    return dayjs(date).isToday();
  };
  const dateDiff = (first, two) => {
    const date1 = dayjs(first);
    const date2 = dayjs(two);
    return date1.diff(date2);
  };
  const isSameOrAfter = (first, two) => {
    return dayjs(first).isSameOrAfter(dayjs(two));
  };
  const isSameOrBefore = (first, two) => {
    return dayjs(first).isSameOrBefore(dayjs(two));
  };
  const isBetween = (currentDate, first, two, unit) => {
    return dayjs(currentDate).isBetween(first, dayjs(two), unit);
  };
  const isAfter = (first, second) => {
    return dayjs(first).isAfter(dayjs(second));
  };
  const isBefore = (first, second) => {
    return dayjs(first).isBefore(second, dayjs(second));
  };
  return { fromNow, formatDate, formatTime, isSame, isDateToday, dateDiff, isSameOrAfter, isSameOrBefore, isBetween, isAfter, isBefore };
};
export default date;
