<template>
  <footer class="footer px-4 mx-2">
    <div class="row align-items-center justify-content-lg-between">
      <div class="col-lg-6">
        <div class="copyright text-center text-lg-left text-muted">
          © {{ year }}
          <a href="/" class="font-weight-bold ml-1" target="_blank">
            peeepl UG(haftungsbeschrankt)
          </a>
        </div>
      </div>
      <div class="col-lg-6">
        <ul
          class="nav nav-footer justify-content-center justify-content-lg-end"
        >
          <li class="nav-item">
            <a href="/" class="nav-link" target="_blank">peeepl</a>
          </li>
          <li class="nav-item">
            <a href="/" class="nav-link" target="_blank">
              {{ i18n.$t("common.footer.data") }}
            </a>
          </li>
          <li class="nav-item">
            <a href="/" class="nav-link" target="_blank">
              {{ i18n.$t("common.footer.imprint") }}
            </a>
          </li>
          <li class="nav-item">
            <a href="/" class="nav-link" target="_blank">
              {{ i18n.$t("common.footer.cookie") }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>
<script>
import { useI18n } from "@/util/i18n";
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
  setup() {
    const i18n = useI18n();
    return {
      i18n,
    };
  },
};
</script>
<style></style>
