const initialsName = (name, limit) => {
  limit = limit || 2;
  return name
    // split string into array of strings (example: "fiRsT reSPoNsE AcAdEmY" ==> ["fiRsT", "reSPoNsE", "AcAdEmY"])
    .split(" ")
    // map over words and return a capitalized first letter of each word (example: ["fiRsT", "reSPoNsE", "AcAdEmY"] ==> ["F", "R", "A"])
    .map(c => c.charAt(0).toUpperCase())
    // join letters to single string (example: ["F", "R", "A"] ==> "FRA")
    .join("")
    // append second letter of first word to this new string (example: "FRA" ==> "FRAI")
    .concat(name.charAt(1).toUpperCase())
    // limit this new string to 2 characters (example: "FRAI" ==> "FR")
    .substring(0, limit);
};
export default initialsName;
