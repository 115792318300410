<template>
  <div>
    <div class="header pb-6 d-flex align-items-center pt-4">
      <!-- Mask -->
      <span class="mask profile-background opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-7 col-md-10">
            <h1 class="display-2 text-white">
              {{ locationForm.location_name }}
            </h1>
            <p class="text-white mt-0 mb-5">
              {{ i18n.$t("location.detail.subHeader") }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid mt--6 xs-mt--3">
      <div class="row">
        <div class="col-xl-5 order-xl-2">
          <location-detail-right-card
            :locationData="locationForm"
          ></location-detail-right-card>
        </div>

        <div class="col-xl-7 order-xl-1">
          <div class="row">
            <div class="col-lg-6"></div>
            <div class="col-lg-6"></div>
          </div>
          <location-details-form
            :locationData="locationForm"
            @update="saveLocationDetail"
          >
          </location-details-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted } from "vue";
import { useI18n } from "@/util/i18n";
import { useToast } from "vue-toastification";
import { Form, Field } from "vee-validate";
import LocationDetailRightCard from "./LocationDetailRightCard.vue";
import LocationDetailsForm from "./LocationDetailsForm.vue";
import AddDocumentModal from "./AddDocumentModal.vue";
import {
  GETLOCATIONBYIDAPI,
  UPDATELOCATIONAPI,
} from "@/services/private/Location";
import { useRoute } from "vue-router";

export default {
  components: {
    Form,
    Field,
    LocationDetailRightCard,
    LocationDetailsForm,
    AddDocumentModal,
  },
  methods: {},
  setup() {
    const i18n = useI18n();
    const route = useRoute();
    const toast = useToast();

    let location_id = "";
    let showDetailsBtn = ref(false);
    let locationForm = reactive({
      location_id: "",
      location_name: "",
      location_website: "",
      location_image_path: "",
      location_text: "",
      location_address_houseno: "",
      location_address_street: "",
      location_address_city: "",
      location_address_country: "",
      location_address_postcode: "",
      contact_name: "",
      contact_email: "",
      contact_phone: "",
      contact_address_houseno: "",
      contact_address_street: "",
      contact_address_city: "",
      contact_address_country: "",
      contact_address_postcode: "",
      alternate_contact_phone: "",
      is_deleted: true,
    });

    async function showLocationDetail() {
      location_id = route.params.location_id;
      const result = await GETLOCATIONBYIDAPI(location_id);
      if (
        result &&
        result.status === 200 &&
        result.data.data &&
        result.data.data.length
      ) {
        let locationData = result.data.data[0];
        locationForm.location_id = locationData.location_id;
        locationForm.location_name = locationData.location_name;
        locationForm.location_website =
          locationData.location_website == null
            ? ""
            : locationData.location_website;
        locationForm.location_image_path = locationData.location_image_path;
        locationForm.location_text = locationData.location_text;
        locationForm.location_address_houseno =
          locationData.location_address_houseno;
        locationForm.location_address_street =
          locationData.location_address_street;
        locationForm.location_address_city = locationData.location_address_city;
        locationForm.location_address_country =
          locationData.location_address_country;
        locationForm.location_address_postcode =
          locationData.location_address_postcode;
        locationForm.contact_name =
          locationData.contact_name == null ? "" : locationData.contact_name;
        locationForm.contact_email =
          locationData.contact_email == null ? "" : locationData.contact_email;
        locationForm.contact_phone =
          locationData.contact_phone == null ? "" : locationData.contact_phone;
        locationForm.contact_address_houseno = "";
        locationForm.contact_address_street = "";
        locationForm.contact_address_city = "";
        locationForm.contact_address_country = "";
        locationForm.contact_address_postcode = "";
        locationForm.alternate_contact_phone =
          locationData.alternate_contact_phone == null
            ? ""
            : locationData.alternate_contact_phone;
        locationForm.is_deleted =
          locationData.is_deleted !== null ? locationData.is_deleted : true;
      }
    }
    function editLocation() {
      showDetailsBtn.value = true;
    }
    async function saveLocationDetail() {
      const result = await UPDATELOCATIONAPI(location_id, locationForm);
      if (result && result.status == 200) {
        toast.success(i18n.$t("location.detail.updateSuccessMsg"));
      } else {
        toast.error(i18n.$t("location.detail.updateSuccessMsg"));
      }
    }

    onMounted(async () => {
      await showLocationDetail();
    });

    return {
      showDetailsBtn,
      locationForm,
      i18n,
      editLocation,
      showLocationDetail,
      saveLocationDetail,
    };
  },
};
</script>
<style scoped>
.page-list__image {
  max-width: 230px;
  max-height: 137px;
  width: auto;
  height: auto;
}
</style>
