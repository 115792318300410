import axios from '../Axios';
import { COMPANYUSER, INVITELINK, CHANGEROLE, DISCONNECTUSER, CHECKJOINCOMPANY, CREATEROLE, GETEMPLOYEEPROFILE, GETCOMPANYPROFILE } from '../CONSTANTURL';
export const COMPANYUSERAPI = async (data) => {
  try {
    const { is_active } = data;
    const result = await axios.get(`${COMPANYUSER}/${is_active}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};
export const INVITATIONLINKAPI = async (data) => {
  try {
    const { role_code } = data;
    const result = await axios.get(`${INVITELINK}/${role_code}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};
export const CHECKJOINCOMPANYAPI = async (data) => {
  try {
    const { invite_token } = data;
    const result = await axios.get(`${CHECKJOINCOMPANY}/${invite_token}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};
export const CHANGEROLEAPI = async (data) => {
  try {
    const { user_id } = data;
    const result = await axios.put(`${CHANGEROLE}/${user_id}`, data);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};
export const DISCONNECTUSERAPI = async (data) => {
  try {
    const { user_id } = data;
    const result = await axios.put(`${DISCONNECTUSER}/${user_id}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};
export const CREATEROLEAPI = async (data) => {
  try {
    const result = await axios.post(`${CREATEROLE}`, data);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const GETEMPLOYEEPROFILEAPI = async (data) => {
  try {
    const { version_no, user_id } = data;
    const result = await axios.get(`${GETEMPLOYEEPROFILE}/${user_id}/${version_no}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};
export const GETCOMPANYPROFILEAPI = async (company_id) => {
  try {
    const result = await axios.get(`${GETCOMPANYPROFILE}/${company_id}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};
