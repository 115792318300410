<template>
  <modal
    v-model:show="show"
    modal-classes="modal-white"
    header-classes="align-items-center"
    body-classes="pt-0"
    :show-close="showClose"
  >
    <template v-slot:header>
      <h6 class="modal-title text-capitalize text-default">
        {{ i18n.$t("staff.modal.invitationLink.header1") }}
      </h6>
    </template>
    <div class="">
      <p class="text-default mb-3">
        {{ i18n.$t("staff.modal.invitationLink.subHeader1") }}
      </p>
      <div>
        <div>
          <form>
            <a
              :href="invite_link"
              class="btn btn-secondary btn-lg disabled linkCSS"
              role="button"
              aria-disabled="true"
              ref="mylink"
              id="myInput"
              >{{ invite_link }}</a
            >
            <div v-if="showErrorMsg">
              <span class="text-danger font-weight-bold">
                {{ i18n.$t("staff.modal.changeRole.error") }}
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
    <template v-slot:footer>
      <base-button
        native-type="button"
        type="warning"
        class="text-white p-2 px-3"
        @click="copyInvitationLink"
      >
        <div class="d-flex align-items-center">
          <spinner v-show="loader" color="text-black" />
          <span>{{ i18n.$t("staff.modal.invitationLink.linkCopy") }}</span>
        </div>
      </base-button>
      <base-button
        type=""
        class="ml-auto text-default p-2 px-3"
        @click="handleClose"
      >
        {{ i18n.$t("logout.button.cancel") }}
      </base-button>
    </template>
  </modal>
</template>
<script>
import Modal from "@/components/Modal";
import { useI18n } from "@/util/i18n";
import { useToast } from "vue-toastification";
export default {
  name: "invitation-link-generate-modal",
  props: {
    showModal: Boolean,
    loader: Boolean,
    invitationLink: String,
  },
  components: {
    Modal,
  },
  data() {
    return {
      roles: [],
      selectedRole: this.setSelectedRole,
      showErrorMsg: false,
    };
  },
  computed: {
    show() {
      return this.showModal;
    },
    showClose() {
      return false;
    },
    invite_link() {
      return this.invitationLink;
    },
  },
  methods: {
    handleClose() {
      this.selectedRole = "";
      this.$emit("close");
    },
    copyInvitationLink() {
      const cb = navigator.clipboard;
      const toast = useToast();
      toast.success(this.i18n.$t("network.message.LinkCopySuccess"));
      cb.writeText(this.invitationLink).then(() => this.$emit("update"));
    },
  },
  setup() {
    const i18n = useI18n();
    return { i18n };
  },
};
</script>
<style scoped>
</style>
