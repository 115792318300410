<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-warning py-7 py-lg-8 pt-lg-9">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div
              class="col-xl-5 col-lg-6 col-md-8 px-5"
              v-if="isLinkActive === true"
            >
              <h1 class="text-white">
                {{ i18n.$t("activation.header") }}
              </h1>
              <p class="text-lead text-white">
                {{ i18n.$t("activation.subHeader") }}
                <router-link to="/login" class="btn btn-link p-0 underline">
                  {{ i18n.$t("activation.subHeaderLink") }}
                </router-link>
              </p>
            </div>
            <div
              class="col-xl-5 col-lg-6 col-md-8 px-5"
              v-if="isLinkActive === false"
            >
              <h1 class="text-white">
                {{ i18n.$t("expireActivation.header") }}
              </h1>
              <h1 class="text-white">
                {{ i18n.$t("expireActivation.header2") }}
              </h1>
              <p class="text-lead text-white">
                <span class="pr-1">
                  {{ i18n.$t("expireActivation.subHeader") }}
                </span>
                <button class="p-0 btn btn-link" @click="reSendLink">
                  {{ i18n.$t("expireActivation.subHeaderLink") }}
                </button>
              </p>
              <span
                class="d-flex align-items-center justify-content-center"
                v-if="loader"
              >
                <spinner />
                <span class="pl-2 text-white font-weight-bold">
                  {{ i18n.$t("common.sending") }}
                </span>
              </span>
            </div>
            <div
              class="col-xl-5 col-lg-6 col-md-8 px-5"
              v-if="isLinkActive === null"
            >
              <h1 class="text-white">
                <spinner />{{ i18n.$t("common.checking") }}
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useToast } from "vue-toastification";
import { useI18n } from "@/util/i18n";
import {
  CHECKACTIVATIONAPI,
  GENERATENEWACTIVATIONAPI,
} from "@/services/public/Auth";

export default {
  setup() {
    const i18n = useI18n();
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();
    let loader = ref(false);
    let isLinkActive = ref(null);
    onMounted(async () => {
      await checkActiveLink();
    });
    async function checkActiveLink() {
      const data = { user_id: route.params.userId, token: route.params.token };
      const result = await CHECKACTIVATIONAPI(data);
      console.log(result);
      if (result && result.status === 200) {
        isLinkActive.value = true;
        setTimeout(() => {
          router.push("/login");
        }, 10000);
      } else {
        isLinkActive.value = false;
      }
    }
    async function reSendLink() {
      loader.value = true;
      const data = { user_id: route.params.userId };
      const result = await GENERATENEWACTIVATIONAPI(data);
      loader.value = false;
      if (
        result.error &&
        result.response &&
        result.response.data &&
        result.response.data.status === 409
      ) {
        toast.error(i18n.$t("activation.conflict"));
      } else if (result && result.status === 200) {
        router.push({ name: "RegisterSuccess", params: { success: true } });
      } else {
        toast.error(i18n.$t("message.wrong"));
      }
    }

    return {
      i18n,
      checkActiveLink,
      reSendLink,
      isLinkActive,
      loader,
    };
  },
};
</script>
