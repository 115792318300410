<template>
  <div class="spinner-border mr-2" :class="color" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</template>
<script>
export default {
  name: "spinner",
  props: {
    color: {
      type: String,
      default: "text-white",
    },
  },
};
</script>
<style>
.ct-example .btn {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
</style>
