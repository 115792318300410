<template>
    <div class="tut-video">
        <video ref="vidRef" id="video" @ended="videoEnded" :poster="poster" controls autoplay muted playsinline   disablePictureInPicture 
        controlsList="nodownload nofullscreen">
          <source :src="video" type="video/mp4">
        </video>
    </div>
  </template>
    
  <script>
  import { defineComponent, ref, reactive, watch } from "vue";
  
  
  export default defineComponent({
    name: "player",
    components: {
      
    },
    props: {
      video: String,
      poster: String,
      onVideoEnd: Function,
      onSwipe: Function,
      id: String
    },
    setup(props) {
      const vidRef = ref(null);
  
  
  
  
      const state = reactive({
        playing: false,
      });
  
      const cloudFlareScript = document.createElement("script");
  
      cloudFlareScript.setAttribute("src", "https://cdn.dashjs.org/latest/dash.all.min.js");
      document.head.appendChild(cloudFlareScript);
  
  
      const videoEnded = () => {
       // const node = document.createAttribute("controls")
       // document.getElementById("video").attributes.setNamedItem(node)
        props.onVideoEnd();
      }
  
      const swiped = (direction) => {
        props.onSwipe(direction);
      }
  
      const play = () => {
        vidRef.value.play();
        state.playing = true;
      };
  
      const pause = () => {
        vidRef.value.pause();
        state.playing = false;
      };
  
      const togglePlay = () => {
        if (state.playing) {
          pause();
        } else {
          play();
        }
      };
  
      return {
        vidRef,
        videoEnded,
        swiped,
        play,
        pause,
        togglePlay,
        state,
      };
    },
  });
  </script>
    
  <style scoped>

  :root {
    --viewport-height: 100vh;
  }
  
  .tut-video {
    position: relative;
    width: 100%;
    height: 100vh;
    z-index: 999;
    background-color: #000041;
  
  
  }
  
  #video {
    width: 100%;
    height: 100dvh;
  
  }
  
  svg {
    position: absolute;
    top: calc(50% - 35px);
    left: calc(50% - 35px);
    width: 90px;
    height: 90px;
    z-index: 10;
  }
  </style>