<template>
  <div>
    <base-header type="default" class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">
            {{ i18n.$t("network.pageTitle") }}
          </h6>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button
            v-if="isAccessible(permission.network_add)"
            size="sm"
            type="warning"
            @click="generateInviteLink()"
          >
            {{ i18n.$t("common.add") }}
          </base-button>

          <base-button
            v-if="isAccessible(permission.network_list_showalluser)"
            size="sm"
            type="warning"
            @click="toggleNetworkList(!showAll)"
          >
            {{ displayNetworkListState }}
          </base-button>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <light-table
            :networks="allNetworks.value"
            @update="getUpdatedNetwork"
          />
        </div>
      </div>
    </div>
    <invitation-link-modal
      :showModal="models.invitationLinkModalVisible"
      :loader="loader"
      :invitationLink="models.invitationLink"
      @close="closeModal"
      @update="openGeneratedLinkModal"
    ></invitation-link-modal>
    <invitation-link-generate-modal
      :showModal="models.invitationLinkGenerateModalVisible"
      :loader="loader"
      :invitationLink="models.invitationLink"
      @close="closeModal"
      @update="openCopyLinkModal"
    ></invitation-link-generate-modal>
    <invitation-link-copy-modal
      :showModal="models.invitationLinkCopyModalVisible"
      :invitationLink="models.invitationLink"
      @close="closeModal"
    ></invitation-link-copy-modal>
  </div>
</template>
<script>
import { ref, reactive, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "@/util/i18n";
import { has_permission } from "@/services/private/Role_Service";
import { permission_list } from "@/services/_helper/user_permission";
import { GETALLNETWORKAPI } from "@/services/private/Network";
import LightTable from "./LightTable";
import InvitationLinkModal from "./InvitationLinkModal.vue";
import InvitationLinkGenerateModal from "./InvitationLinkGenerateModal.vue";
import InvitationLinkCopyModal from "./InvitationLinkCopyModal.vue";

export default {
  components: {
    LightTable,
    InvitationLinkModal,
    InvitationLinkGenerateModal,
    InvitationLinkCopyModal,
  },
  data() {
    return {
      route: useRoute(),
      loader: false,
      permission: permission_list,
      models: {
        invitationLinkModalVisible: false,
        invitationLinkGenerateModalVisible: false,
        invitationLinkCopyModalVisible: false,
        invitationLink: "",
      },
    };
  },
  methods: {
    generateInviteLink() {
      this.models.invitationLinkModalVisible = true;
    },
    openGeneratedLinkModal(inviteLink) {
      this.models.invitationLinkModalVisible = false;
      this.models.invitationLinkGenerateModalVisible = true;
      this.models.invitationLink = inviteLink;
    },
    openCopyLinkModal() {
      this.models.invitationLinkGenerateModalVisible = false;
      this.models.invitationLinkCopyModalVisible = true;
    },
    closeModal() {
      this.models.invitationLinkModalVisible = false;
      this.models.invitationLinkGenerateModalVisible = false;
      this.models.invitationLinkCopyModalVisible = false;
    },
    isAccessible(permission) {
      return has_permission(permission.value);
    },
  },
  setup() {
    const i18n = useI18n();
    let displayNetworkListState = ref(i18n.$t("network.ShowFormerNetwork"));
    let showAll = ref(false);
    let isActive = true;
    let networks = reactive({ value: [] });
    let allNetworks = reactive({ value: [] });
    function toggleNetworkList(state) {
      showAll.value = state;
      if (!state) {
        getNetworkList(true);
        displayNetworkListState.value = i18n.$t("network.hideFormerNetwork");
      } else {
        getNetworkList(false);
        displayNetworkListState.value = i18n.$t("network.ShowFormerNetwork");
      }
    }
    async function getNetworkList(isActive) {
      isActive = isActive;
      const result = await GETALLNETWORKAPI(isActive);
      if (result && result.status === 200) {
        allNetworks.value = result.data.data;
      }
    }
    async function getUpdatedNetwork() {
      await getNetworkList(isActive);
    }
    onMounted(async () => {
      await getNetworkList(true);
    });
    return {
      i18n,
      displayNetworkListState,
      showAll,
      toggleNetworkList,
      allNetworks,
      getUpdatedNetwork,
      networks,
    };
  },
};
</script>
<style scoped>
.btn-sm {
  margin-bottom: 15px;
}
</style>
