<template>
  <card>
    <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
      <div class="row align-items-center">
        <div class="col-4">
          <h3 class="mb-0">{{ i18n.$t("location.detail.titlePage") }}</h3>
        </div>
        <div class="col-8 text-right">
          <base-button
            type="warning"
            class="btn-sm"
            v-show="showEditBtn && locationData && !locationData.is_deleted"
            @click="editLocation"
          >
            <div class="d-flex align-items-center">
              <span>{{ i18n.$t("location.detail.editButton") }}</span>
            </div>
          </base-button>
          <div v-show="!showEditBtn">
            <base-button type="primary" native-type="submit" class="btn-sm">
              <div class="d-flex align-items-center">
                <spinner v-show="loader" />
                <span>{{ i18n.$t("editProfile.save") }}</span>
              </div>
            </base-button>
            <base-button type="" @click="cancelEdit" class="btn-sm">
              <div class="d-flex align-items-center">
                <span>{{ i18n.$t("editProfile.discard") }}</span>
              </div>
            </base-button>
          </div>
        </div>
      </div>
      <hr class="my-3" />
      <h6 class="heading-small text-muted mb-4">
        {{ i18n.$t("location.detail.locationInformation") }}
      </h6>
      <div class="pl-lg-4">
        <div class="row">
          <div class="col-lg-6">
            <label>{{ i18n.$t("location.detail.form.name") }}</label>
            <Field
              class="form-control"
              :class="{ 'has-danger-border-red': errors.location_name }"
              name="location_name"
              type="text"
              :placeholder="i18n.$t('location.detail.form.name')"
              v-model="locationData.location_name"
              :disabled="showEditBtn"
            >
            </Field>
            <small class="text-danger" v-show="errors.location_name">
              {{ errors.location_name }}
            </small>
          </div>
          <div class="col-lg-6">
            <label>{{ i18n.$t("location.detail.form.website") }}</label>
            <Field
              class="form-control"
              name="location_website"
              type="text"
              :placeholder="i18n.$t('location.detail.form.website')"
              v-model="locationData.location_website"
              :disabled="showEditBtn"
            >
            </Field>
          </div>
        </div>

        <div class="row top-margin-row">
          <div class="col-lg-6">
            <label>{{ i18n.$t("location.detail.form.street") }}</label>
            <Field
              class="form-control"
              :class="{
                'has-danger-border-red': errors.location_address_street,
              }"
              name="location_address_street"
              type="text"
              :placeholder="i18n.$t('location.detail.form.street')"
              v-model="locationData.location_address_street"
              :disabled="showEditBtn"
            >
            </Field>
            <small class="text-danger" v-show="errors.location_address_street">
              {{ errors.location_address_street }}
            </small>
          </div>

          <div class="col-lg-6">
            <label>{{ i18n.$t("location.detail.form.houseno") }}</label>
            <Field
              class="form-control"
              name="location_address_houseno"
              type="text"
              :class="{
                'has-danger-border-red': errors.location_address_houseno,
              }"
              :placeholder="i18n.$t('location.detail.form.houseno')"
              v-model="locationData.location_address_houseno"
              :disabled="showEditBtn"
            >
            </Field>
            <small class="text-danger" v-show="errors.location_address_houseno">
              {{ errors.location_address_houseno }}
            </small>
          </div>
        </div>

        <div class="row top-margin-row">
          <div class="col-lg-6">
            <label>{{ i18n.$t("location.detail.form.postalcode") }}</label>
            <Field
              class="form-control"
              name="location_address_postcode"
              type="text"
              :class="{
                'has-danger-border-red': errors.location_address_postcode,
              }"
              :placeholder="i18n.$t('location.detail.form.postalcode')"
              maxlength="5"
              @keypress="isNumber($event)"
              v-model="locationData.location_address_postcode"
              :disabled="showEditBtn"
            >
            </Field>
            <small
              class="text-danger"
              v-show="errors.location_address_postcode"
            >
              {{ errors.location_address_postcode }}
            </small>
          </div>

          <div class="col-lg-6">
            <label>{{ i18n.$t("location.detail.form.city") }}</label>
            <Field
              class="form-control"
              name="location_address_city"
              type="text"
              :class="{ 'has-danger-border-red': errors.location_address_city }"
              :placeholder="i18n.$t('location.detail.form.city')"
              v-model="locationData.location_address_city"
              :disabled="showEditBtn"
            >
            </Field>
            <small class="text-danger" v-show="errors.location_address_city">
              {{ errors.location_address_city }}
            </small>
          </div>
        </div>
      </div>
      <hr class="my-4" />

      <!-- Address -->
      <h6 class="heading-small text-muted mb-4">
        {{ i18n.$t("location.detail.form.contactPerson") }}
      </h6>

      <div class="pl-lg-4">
        <div class="row">
          <div class="col-lg-6">
            <label>{{ i18n.$t("location.detail.form.contactName") }}</label>
            <Field
              class="form-control"
              name="contact_name"
              type="text"
              :placeholder="i18n.$t('location.detail.form.contactName')"
              v-model="locationData.contact_name"
              :disabled="showEditBtn"
            >
            </Field>
          </div>

          <div class="col-lg-6">
            <label>{{ i18n.$t("location.detail.form.contactTeleNo") }}</label>
            <Field
              class="form-control"
              name="contact_phone"
              type="text"
              maxlength="10"
              @keypress="isNumber($event)"
              :placeholder="i18n.$t('location.detail.form.contactTeleNo')"
              v-model="locationData.contact_phone"
              :disabled="showEditBtn"
            >
            </Field>
          </div>
        </div>

        <div class="row top-margin-row">
          <div class="col-lg-6">
            <label>{{ i18n.$t("location.detail.form.contactEmail") }}</label>
            <Field
              class="form-control"
              name="contact_email"
              :class="{ 'has-danger-border-red': errors.contact_email }"
              type="text"
              :placeholder="i18n.$t('location.detail.form.contactEmail')"
              v-model="locationData.contact_email"
              :disabled="showEditBtn"
            >
            </Field>
            <small class="text-danger" v-show="errors.contact_email">
              {{ errors.contact_email }}
            </small>
            <!-- <span>{{ errors.contact_email }}</span> -->
          </div>

          <div class="col-lg-6">
            <label>{{ i18n.$t("location.detail.form.alternateTelNo") }}</label>
            <Field
              class="form-control"
              name="alternate_contact_phone"
              type="text"
              maxlength="10"
              @keypress="isNumber($event)"
              :placeholder="i18n.$t('location.detail.form.alternateTelNo')"
              v-model="locationData.alternate_contact_phone"
              :disabled="showEditBtn"
            >
            </Field>
          </div>
        </div>
      </div>

      <hr class="my-4" />
    </Form>

    <!-- Information -->

    <div
      class="row align-items-center"
      v-if="locationData && !locationData.is_deleted"
    >
      <div class="col-4">
        <h6 class="heading-small text-muted mb-0">
          {{ i18n.$t("location.locationDocument.titleInformation") }}
        </h6>
      </div>
      <div class="col-8 text-right">
        <base-button @click="uploadDocument" type="warning" class="btn-sm">
          <div class="d-flex align-items-center">
            <span class="btn-inner--icon">
              <i class="ni ni-cloud-upload-96"></i>
            </span>
            <spinner v-show="loader" />
            <span>
              {{ i18n.$t("location.locationDocument.newDocumentBtn") }}
            </span>
          </div>
        </base-button>
      </div>
    </div>
    <div class="row" style="padding-left: 27px">
      <div
        class="col-6 project-item"
        v-for="doc in documents"
        :key="doc.document_name"
      >
        <div class="row">
          <div class="col-12 location-doc-label">
            <label> {{ doc.document_name }}</label>
          </div>
        </div>
        <div class="row project-rows project-rows align-items-center">
          <div class="col-8 location-col">{{ doc.document_original_name }}</div>
          <div class="col-4">
            <span class="d-flex align-items-center justify-content-end">
              <span class="mr-1 d-flex" @click="openDocument(doc)">
                <i class="ni ni-single-copy-04"></i>
              </span>
              <el-dropdown
                class="dropdown"
                trigger="click"
                placement="bottom-start"
              >
                <span class="pl-2 btn-icon-only text-light">
                  <i class="fas fa-ellipsis-v mt-0"></i>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <li
                      class="dropdown-item"
                      @click="deleteLocationDoc(doc.location_document_id)"
                    >
                      {{ i18n.$t("common.remove") }}
                    </li>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </span>
          </div>
        </div>
      </div>
    </div>

    <add-document-modal
      :locationId="locationId"
      :showModal="models.addLocationModalVisible"
      @close="closeModal"
      @update="updateLocationDocument"
    ></add-document-modal>

    <!-- Remove user modal -->
    <delete-location-doc-modal
      :showModal="models.removeLocationDocModalVisible"
      :loader="loader"
      @close="closeDeleteLocationDocModal"
      @confirm="showConfirmLocationDocDeleteModal"
    ></delete-location-doc-modal>

    <confirm-location-doc-delete-modal
      :showModal="models.confirmDeleteLocationDocModalVisible"
      :loader="loader"
      @close="closeConfirmLocationDocDeleteModal"
      @remove="sendRemoveLocationAPI"
    ></confirm-location-doc-delete-modal>
  </card>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
} from "element-plus";
import { onMounted, reactive, ref, watch } from "vue";
import * as Yup from "yup";
import { Form, Field } from "vee-validate";
import { useToast } from "vue-toastification";
import { useStore } from "vuex";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { German } from "flatpickr/dist/l10n/de.js";
import { useI18n } from "@/util/i18n";
import AddDocumentModal from "./AddDocumentModal.vue";
import {
  GETLOCATIONDOCUMENTSAPI,
  DELETELOCATIONDOCAPI,
} from "@/services/private/Location";
import { useRouter, useRoute } from "vue-router";
import DeleteLocationDocModal from "./DeleteLocationDocModal.vue";
import ConfirmLocationDocDeleteModal from "./ConfirmLocationDocDeleteModal.vue";

export default {
  props: {
    locationData: Object,
  },
  components: {
    Form,
    Field,
    flatPicker,
    AddDocumentModal,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
    DeleteLocationDocModal,
    ConfirmLocationDocDeleteModal,
  },
  data() {
    return {
      config: {
        wrap: true,
        allowInput: true,
        altInput: true,
        maxDate: new Date(),
        dateFormat: "Y.m.d",
        altFormat: "d.m.Y",
        disableMobile: "true",
        locationId: "",
        locale: German, // locale for this instance only
      },
      models: {
        addLocationModalVisible: false,
        removeLocationDocModalVisible: false,
        confirmDeleteLocationDocModalVisible: false,
        removeLocationDocId: "",
      },
      location_data: this.locationData,
      locationId: this.locationData.location_id,
      route: useRoute(),
      documents: [],
    };
  },
  methods: {
    uploadDocument() {
      this.models.addLocationModalVisible = true;
    },
    closeModal() {
      this.models.addLocationModalVisible = false;
    },
    deleteLocationDoc(data) {
      this.models.removeLocationDocId = data;
      this.models.removeLocationDocModalVisible = true;
    },
    closeDeleteLocationDocModal() {
      this.models.removeLocationDocId = "";
      this.models.removeLocationDocModalVisible = false;
    },
    showConfirmLocationDocDeleteModal() {
      this.models.removeLocationDocModalVisible = false;
      this.models.confirmDeleteLocationDocModalVisible = true;
    },
    closeConfirmLocationDocDeleteModal() {
      this.models.confirmDeleteLocationDocModalVisible = false;
      this.closeDeleteLocationDocModal();
    },
    async sendRemoveLocationAPI() {
      const toast = useToast();
      //Call API
      const location_doc_id = this.models.removeLocationDocId;
      const result = await DELETELOCATIONDOCAPI(location_doc_id);

      if (result && result.status === 200) {
        this.closeConfirmLocationDocDeleteModal();
        this.getDocuments();
        //this.$emit("update");
        toast.success(this.i18n.$t("location.delete.successDeleteMessage"));
      } else {
        toast.error(this.i18n.$t("location.delete.failDeleteMessage"));
      }
    },
    async getDocuments() {
      let id_Location = this.route.params.location_id;
      let is_Deleted = false;
      var result = await GETLOCATIONDOCUMENTSAPI(id_Location, is_Deleted);
      if (result && result.status == 200) {
        this.documents = result.data.data;
      }
    },
    updateLocationDocument() {
      this.getDocuments();
      this.models.addLocationModalVisible = false;
    },
    openDocument(data) {
      window.open(data.document_path, "_blank");
    },
  },
  mounted() {
    this.getDocuments();
  },
  setup(props, { emit }) {
    const i18n = useI18n();
    const toast = useToast();
    const store = useStore();

    const schema = Yup.object().shape({
      location_name: Yup.string().required("Name is required"),
      location_address_street: Yup.string().required("Street is required"),
      location_address_postcode: Yup.string().required(
        "Postal Code is required"
      ),
      location_address_city: Yup.string().required("City is required"),
      location_address_houseno: Yup.string().required("House No is required"),
      contact_email: Yup.string().nullable().email("Invalid email"),
    });

    let loader = ref(false);
    let showEditBtn = ref(true);
    //let showProfileBtn = ref(false);

    async function onSubmit() {
      loader.value = true;
      emit("update");
      loader.value = false;
      showEditBtn.value = true;
    }

    function editLocation() {
      showEditBtn.value = false;
    }

    function cancelEdit() {
      showEditBtn.value = true;
    }

    function isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    }

    return {
      i18n,
      loader,
      showEditBtn,
      schema,
      cancelEdit,
      onSubmit,
      editLocation,
      isNumber,
    };
  },
};
</script>
<style scoped>
.project-rows {
  margin-bottom: 10px;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
}
.project-item {
  padding-right: 27px;
  padding-left: 27px;
}
.location-col {
  padding-left: 0px;
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
  font-size: 0.875rem;
}
.location-doc-label {
  padding-left: 2px;
}
.document-list {
  margin-top: 10px;
}
.top-margin-row {
  margin-top: 10px;
}
</style>
