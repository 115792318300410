<template>
  <div
    class="
      sidenav
      navbar navbar-vertical
      fixed-left
      navbar-expand-xs navbar-light
      bg-white
      overflow-x-hidden
    "
    @mouseenter="$sidebar.onMouseEnter()"
    @mouseleave="$sidebar.onMouseLeave()"
  >
    <div class="scrollbar-inner" ref="sidebarScrollArea">
      <div class="sidenav-header d-flex align-items-center">
        <router-link class="navbar-brand" to="/projects">
          <img :src="logo" class="navbar-brand-img" alt="Peeepl" />
        </router-link>
        <div class="ml-auto">
          <!-- Sidenav toggler -->
          <div
            class="sidenav-toggler d-none d-xl-block"
            :class="{ active: !$sidebar.isMinimized }"
            @click="minimizeSidebar"
          >
            <div class="sidenav-toggler-inner">
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
            </div>
          </div>
        </div>
      </div>
      <slot></slot>
      <div class="navbar-inner">
        <ul class="navbar-nav">
          <slot name="links">
            <sidebar-item
              v-for="(link, index) in sidebarLinks"
              :key="link.name + index"
              :link="link"
            >
              <sidebar-item
                v-for="(subLink, index) in link.children"
                :key="subLink.name + index"
                :link="subLink"
              >
              </sidebar-item>
            </sidebar-item>
          </slot>
        </ul>
      </div>
      <slot name="links-after"> </slot>
    </div>
  </div>
</template>
<script>
import storage from "@/util/storage";
export default {
  name: "sidebar",
  props: {
    title: {
      type: String,
      default: "Creative Tim",
      description: "Sidebar title",
    },
    shortTitle: {
      type: String,
      default: "CT",
      description: "Sidebar short title",
    },
    logo: {
      type: String,
      default: "img/brand/PEEEPL_LOGO_dark.png",
      description: "Sidebar app logo",
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
      description:
        "List of sidebar links as an array if you don't want to use components for these.",
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        "Whether sidebar should autoclose on mobile when clicking an item",
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  methods: {
    minimizeSidebar() {
      if (this.$sidebar) {
        //this.$sidebar.displaySidebar(false);
        this.$sidebar.isMinimized = !this.$sidebar.isMinimized;
        this.$sidebar.toggleMinimize();
        storage().setSideBar(this.$sidebar.isMinimized);
      }
    },
  },
  mounted() {
    if (storage().getSideBar() === null) {
      this.$sidebar.isMinimized = this.$sidebar.breakpoint < window.innerWidth;
      storage().setSideBar(this.$sidebar.isMinimized);
    } else if (storage().getSideBar() === "true") {
      this.$sidebar.isMinimized = storage().getSideBar() === "true";
    }
    if (this.$sidebar) {
      this.$sidebar.toggleMinimize();
    }
  },
  // beforeUnmount() {
  //   if (this.$sidebar.showSidebar) {
  //     this.$sidebar.showSidebar = false;
  //   }
  // },
};
</script>
