<template>
  <modal
    v-model:show="show"
    modal-classes="modal-white"
    header-classes="align-items-center"
    body-classes="pt-0"
    @close="handleClose"
    size=""
  >
    <template v-slot:header>
      <h6 class="modal-title text-capitalize text-default">
        {{
          mode === "CREATE"
            ? i18n.$t("projectDetails.shifts.forms.createTitle")
            : i18n.$t("projectDetails.shifts.forms.updateTitle")
        }}
      </h6>
    </template>
    <div class="">
      <div class="row">
        <div class="col-12">
          <p class="text-muted text-sm mb-3">
            {{ i18n.$t("projectDetails.shifts.forms.shift_discipline") }}
          </p>
        </div>
      </div>
      <Form
        ref="form"
        class="pt-2"
        @submit="handleAddShift"
        :validation-schema="schema"
        v-slot="{ errors }"
      >
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <div
                class="input-group"
                :class="{ 'has-danger-border-red': errors.shift_name }"
              >
                <div class="input-group-prepend">
                  <span class="input-group-text font-weight-bolder">
                    <i class="fas fa-file-alt"></i>
                  </span>
                </div>
                <Field
                  class="form-control"
                  name="shift_name"
                  type="text"
                  id="shift_name"
                  maxlength="50"
                  :placeholder="
                    i18n.$t('projectDetails.shifts.forms.shift_name')
                  "
                  v-model="formData.shift_name"
                ></Field>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <div
                class="input-group"
                :class="{ 'has-danger-border-red': errors.shift_discipline }"
              >
                <div class="input-group-prepend">
                  <span class="input-group-text font-weight-bolder">
                    <i class="fas fa-file-alt"></i>
                  </span>
                </div>
                <Field
                  class="form-control"
                  name="shift_discipline"
                  type="text"
                  maxlength="50"
                  id="shift_discipline"
                  :placeholder="
                    i18n.$t('projectDetails.shifts.forms.discipline')
                  "
                  v-model="formData.shift_discipline"
                ></Field>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8 pr-md-1">
            <div
              class="form-group static-wrap"
              :class="{ 'has-danger-border-red': errors.shift_date }"
            >
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text font-weight-bolder">
                    <i class="fas fa-calendar-alt"></i>
                  </span>
                </div>
                <Field name="shift_date" v-model="formData.shift_date">
                  <flat-picker
                    :config="shift_date_config"
                    name="shift_date"
                    class="form-control datepicker"
                    v-model="formData.shift_date"
                    id="shift_date"
                    :placeholder="i18n.$t('project.modal.form.start_date')"
                    @on-change="updateEndDate"
                  >
                  </flat-picker>
                </Field>
              </div>
            </div>
          </div>
          <div class="col-md-4 pl-md-1">
            <div class="form-group static-time-wrap">
              <div
                class="input-group"
                :class="{ 'has-danger-border-red': errors.shift_start_time }"
              >
                <div class="input-group-prepend">
                  <span class="input-group-text font-weight-bolder">
                    <i class="fas fa-clock"></i>
                  </span>
                </div>
                <Field
                  name="shift_start_time"
                  v-model="formData.shift_start_time"
                >
                  <flat-picker
                    :config="shift_start_time_config"
                    name="shift_start_time"
                    class="form-control datepicker"
                    v-model="formData.shift_start_time"
                    id="shift_start_time"
                    :placeholder="i18n.$t('project.modal.form.start_time')"
                    autocomplete="off"
                    @on-change="checkIfEndDateIsSet"
                  >
                  </flat-picker>
                </Field>
              </div>
            </div>
          </div>
          <div class="col-md-8 pr-md-1">
            <div
              class="form-group static-wrap"
              :class="{ 'has-danger-border-red': errors.shift_date }"
            >
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text font-weight-bolder">
                    <i class="fas fa-calendar-alt"></i>
                  </span>
                </div>
                <Field name="shift_end_date" v-model="formData.shift_end_date">
                  <flat-picker
                    :config="shift_end_date_config"
                    name="shift_end_date"
                    class="form-control datepicker"
                    v-model="formData.shift_end_date"
                    id="shift_end_date"
                    :placeholder="i18n.$t('project.modal.form.end_date')"
                    @on-change="setEndDateTime"
                  >
                  </flat-picker>
                </Field>
              </div>
            </div>
          </div>
          <div class="col-md-4 pl-md-1">
            <div
              class="form-group static-wrap"
              :class="{ 'has-danger-border-red': errors.shift_end_time }"
            >
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text font-weight-bolder">
                    <i class="fas fa-clock"></i>
                  </span>
                </div>
                <Field name="shift_end_time" v-model="formData.shift_end_time">
                  <flat-picker
                    :config="shift_end_time_config"
                    name="shift_end_time"
                    class="form-control datepicker"
                    v-model="formData.shift_end_time"
                    id="shift_end_time"
                    :placeholder="i18n.$t('project.modal.form.start_time')"
                  ></flat-picker>
                </Field>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <div
                class="input-group"
                :class="{ 'has-danger-border-red': errors.shift_count }"
              >
                <div class="input-group-prepend">
                  <span class="input-group-text font-weight-bolder">
                    <i class="fas fa-user"></i>
                  </span>
                </div>
                <Field
                  class="form-control"
                  name="shift_count"
                  type="number"
                  id="shift_count"
                  min="0"
                  max="500"
                  :disabled="isAddManager"
                  :placeholder="
                    i18n.$t('projectDetails.shifts.forms.noOfpeople')
                  "
                  v-model="formData.shift_count"
                ></Field>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <Field
                as="textarea"
                class="form-control"
                name="shift_description"
                :placeholder="
                  i18n.$t('projectDetails.shifts.forms.information')
                "
                rows="5"
                maxlength="500"
                v-model="formData.shift_description"
              ></Field>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-ceter justify-content-between pt-4">
          <base-button
            native-type="submit"
            type="warning"
            class="text-white p-2 px-3"
          >
            <div class="d-flex align-items-center">
              <spinner v-show="loader" color="text-black" />
              <span>
                {{
                  mode === "CREATE"
                    ? i18n.$t("projectDetails.shifts.forms.createTitle")
                    : i18n.$t("projectDetails.shifts.forms.updateTitle")
                }}
              </span>
            </div>
          </base-button>
          <base-button
            type=""
            class="ml-auto text-default p-2 px-3"
            @click="handleClose"
          >
            {{ i18n.$t("common.cancel") }}
          </base-button>
        </div>
      </Form>
    </div>
  </modal>
</template>
<script>
import { Field, Form } from "vee-validate";
import Modal from "@/components/Modal";
import * as Yup from "yup";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { German } from "flatpickr/dist/l10n/de.js";
import date from "@/util/date";
import { useI18n } from "@/util/i18n";

export default {
  name: "add-shift-modal",
  props: {
    details: Object,
    showModal: Boolean,
    loader: Boolean,
    mode: String,
    shiftData: Object,
  },
  components: {
    Field,
    Form,
    flatPicker,
    Modal,
  },
  data() {
    return {
      formData: {
        shift_id: "",
        project_id: (this.details && this.details.project_id) || "",
        shift_name: "",
        shift_discipline: "",
        shift_date: (this.details && this.details.start_date) || "",
        shift_end_date: (this.details && this.details.start_date) || "",
        shift_start_time: "",
        shift_end_time: "",
        shift_count: "",
        shift_status: (this.details && this.details.project_status) || "",
        shift_description: "",
      },
      shift_date_config: {
        static: true,
        wrap: true,
        allowInput: true,
        altInput: true,
        dateFormat: "Y-m-d",
        altFormat: "d.m.Y",
        disableMobile: "true",
        locale: German, // locale for this instance only
      },
      shift_end_date_config: {
        static: true,
        wrap: true,
        allowInput: true,
        altInput: true,
        dateFormat: "Y-m-d",
        altFormat: "d.m.Y",
        disableMobile: "true",
        locale: German, // locale for this instance only
      },
      shift_start_time_config: {
        static: true,
        wrap: true,
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
        defaultDate: "00:00",
      },
      shift_end_time_config: {
        static: true,
        wrap: true,
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
      },
      schema: Yup.object().shape({
        shift_name: Yup.string().required(),
        shift_discipline: Yup.string().required(),
        shift_date: Yup.string().required(),
        shift_end_date: Yup.string().required(),
        shift_start_time: Yup.string().required(),
        shift_end_time: Yup.string().required(),
        shift_count: Yup.number().max(500).required(),
      }),
    };
  },
  computed: {
    show() {
      return this.showModal;
    },
    showClose() {
      return false;
    },
    isAddManager() {
      if (this.mode == "ADDMANAGER") {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    mode(new_value, old_value) {
      if (new_value === "EDIT" || new_value === "ADDMANAGER") {
        this.$nextTick(() => {
          this.formData = {
            ...this.formData,
            project_id: this.shiftData && this.shiftData.project_id,
            shift_id: this.shiftData && this.shiftData.shift_id,
            shift_name: this.shiftData && this.shiftData.shift_name,
            shift_discipline: this.shiftData && this.shiftData.shift_discipline,
            shift_date: this.shiftData && this.shiftData.shift_date,
            shift_end_date: this.shiftData && this.shiftData.shift_end_date,
            shift_start_time: this.shiftData && this.shiftData.shift_start_time,
            shift_end_time: this.shiftData && this.shiftData.shift_end_time,
            shift_count: this.shiftData && this.shiftData.shift_count,
            shift_status: this.shiftData && this.shiftData.shift_status_en,
            shift_description:
              this.shiftData && this.shiftData.shift_description,
          };
        });
      } else if (new_value === "CREATE" || new_value === "ADDMANAGER") {
        this.$nextTick(() => {
          this.formData.project_id = this.details && this.details.project_id;
          this.formData.shift_date = this.details && this.details.start_date;
          this.formData.shift_start_time = "00:00";
          this.formData.shift_end_date =
            this.details && this.details.start_date;
          this.formData.shift_status =
            this.details && this.details.project_status;
        });
      }
    },
  },
  methods: {
    resetForm() {
      this.$nextTick(() => {
        this.$refs.form.resetForm();
        this.formData = {
          ...this.formData,
          project_id: this.details && this.details.project_id,
          shift_name: "",
          shift_discipline: "",
          shift_date: this.details && this.details.start_date,
          shift_end_date: this.details && this.details.start_date,
          shift_start_time: "",
          shift_end_time: "",
          shift_count: "",
          shift_description: "",
          shift_status: this.details && this.details.project_status,
        };
        delete this.shift_end_time_config.minTime;
        delete this.shift_start_time_config.minTime;
      });
    },
    handleClose() {
      this.resetForm();
      this.$emit("close", false);
    },
    handleAddShift() {
      let mode = "";
      const data = this.formData;
      if (this.mode.toLowerCase() == "addmanager") {
        mode = "create";
        data.is_manager_shift = true;
      } else {
        mode = this.mode.toLowerCase();
      }
      const emit = mode;
      this.resetForm();
      this.$emit(emit, data);
    },
    updateEndDate(selectedDates, dateStr, instance) {
      //Date Objects are better for comparing
      let selectedDate = new Date(dateStr);
      let endDate = new Date(this.formData.shift_end_date);

      // If the selected date is later than the end date, or if the end date is not set,
      // update both the end date and its minimum selectable date.
      if (selectedDate > endDate || this.formData.shift_end_date === "") {
        this.shift_end_date_config.minDate = dateStr;
        this.formData.shift_end_date = dateStr;
      } else {
        // If the selected date is not later than the end date, just update the minimum selectable date.
        this.shift_end_date_config.minDate = dateStr;
      }
    },
    checkIfEndDateIsSet(selectedDates, dateStr, instance) {
      if (
        this.formData.shift_date !== "" &&
        this.formData.shift_end_date !== ""
      ) {
        const isSameDate = date().isSame(
          this.formData.shift_end_date,
          this.formData.shift_date,
          "D"
        );
        if (isSameDate) {
          this.shift_end_time_config.minTime = dateStr;
          //this.formData.shift_end_time = dateStr;
        }
      }
    },
    updateEndTime(selectedDates, dateStr, instance) {
      if (dateStr) {
        if (date().isDateToday(new Date(dateStr))) {
          this.shift_end_time_config.minTime = date().formatDate(
            new Date(),
            "HH:mm"
          );
        } else {
          this.shift_end_time_config.minTime = "";
        }
      }
    },
    setEndDateTime(selectedDates, dateStr, instance) {
      const isSameDate = date().isSame(dateStr, this.formData.shift_date, "D");
      if (isSameDate) {
        this.shift_end_time_config.minTime = this.formData.shift_end_time;
        this.formData.shift_end_time = this.formData.shift_end_time;
        this.shift_end_date_config.minDate = this.formData.shift_date;
        this.formData.shift_end_date = this.formData.shift_date;
      } else {
        this.shift_end_time_config.minTime = "0:00";
        this.formData.shift_end_time = this.formData.shift_end_time;
      }
    },
  },
  mounted() {},
  setup() {
    const i18n = useI18n();
    return { i18n };
  },
};
</script>
<style scoped>
.form-control[readonly] {
  background-color: #ffffff;
  opacity: 1;
}
</style>
