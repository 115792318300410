<template>
    <div class="center">
        <h1>Einen Moment Bitte</h1>
    </div>
</template>

<script setup>
import storage from "@/util/storage";
import { useStore } from 'vuex';
import { onMounted, ref } from 'vue';
import { GETSKILLIDBYUSERAPI, GETCLOUDIDBYSKILLAPI, GETSKILLWATCHEDAPI } from "@/services/private/Skills";
import Skill from './Skill';
import { useRouter, useRoute } from 'vue-router'




const store = useStore();
const router = useRouter();
const route = useRoute();
const skillStatus = ref("todo");

onMounted(async () => {
    await getSkillsList(storage().getUser().user_id)
    router.push({ name: "View Stream", params: { skill_id: route.params.skill_id } })
})


async function fetchSkillsByUser(user_id) {
    const skills = await GETSKILLIDBYUSERAPI(user_id);
    return skills.data.data;
}

async function fetchCloudIdsBySkill(skill_id) {
    const cloudIds = await GETCLOUDIDBYSKILLAPI(skill_id);
    return cloudIds.data.data;
}

async function getSkillsList(user_id) {
    const skillsList = [];
    const skills = await fetchSkillsByUser(user_id);
    for (let i = 0; i < skills.length; i++) {
        await getSkillStatus(user_id, skills[i].skill_id)
        const cloudIds = await fetchCloudIdsBySkill(skills[i].skill_id);
        const videos = createVideoListOfCloudIds(cloudIds);

        skillsList.push(new Skill(skills[i].skill_id, [], skillStatus.value, skills[i].skill_name, videos))
    }
    store.commit("setSkills", skillsList);
    return skillsList;
}

function createVideoListOfCloudIds(cloud_ids) {
    const videos = [];
    for (let i = 0; i < cloud_ids.length; i++) {
        videos.push({
            "cloud_id": cloud_ids[i].cloud_id,
            "url": `https://customer-czy559gndrdvu19x.cloudflarestream.com/${cloud_ids[i].cloud_id}/downloads/default.mp4`
            , "watched": skillStatus === "todo" ? false : "done"
        })
    }
    return videos;
}

async function getSkillStatus(user_id, skill_id) {
    const result = await GETSKILLWATCHEDAPI(user_id);
    if (result.data.data.unwatchedSkills.some(e => e.skill_id === skill_id)) {
        skillStatus.value = "todo"
    }
    else if (result.data.data.watchedSkills.some(e => e.skill_id === skill_id)) {
        skillStatus.value = "done"
    }
}


</script>

<style scoped>
h1 {
    color: black;
}

.center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>