<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
      </div>
      <!-- Card stats -->
      <div class="row">
        <div class="col-xl-3 col-md-6">
          <stats-card title="Anzahl Mitarbeiter" type="gradient-red" :sub-title="staff.data.length.toString()" icon="ni ni-single-02">
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card title="Anzahl Skills" type="gradient-orange" :sub-title="skills.length.toString()" icon="ni ni-mobile-button">
          </stats-card>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <!--Tables-->
      <div class="row">
        <div class="col-xl-6">
          <user-action-table :title="'User Actions'" :tableData="userAction"></user-action-table>
        </div>
        <div class="col-xl-6">
          <user-device-table :title="'User Device'" :tableData="userDevice"></user-device-table>
        </div>
        <div class="col-xl-6">
          <skills-visits-table :title="'Skills angeschaut'" :tableData="watchedSkills"></skills-visits-table>
        </div>
        <div class="col-xl-6">
          <skills-visits-table :title="'Skills nicht angeschaut'" :tableData="unwatchedSkills"></skills-visits-table>
        </div>
      </div>
      <!--End tables-->

    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { messageService } from "@/services/_helper/message";

// Lists
//import ActivityFeed from "./ActivityFeed";
//import UserList from "./UserList";
//import TaskList from "./TaskList";
//import ProgressTrackList from "./ProgressTrackList";

// Tables
//import LightTable from "./LightTable";
import SkillsVisitsTable from "../Skills/SkillsVisitsTable";
import UserActionTable from "./UserActionTable";
import UserDeviceTable from "./UserDeviceTable";
//import SocialTrafficTable from "./SocialTrafficTable";

// Charts
//import { ordersChart } from "@/components/Charts/Chart";
//import Chart from "chart.js";
//let chart;


import { GETALLSKILLSWATCHEDAPI, GETALLSKILLSUNWATCHEDAPI } from "@/services/private/Skills";
import { GETUSERACTIONAPI } from "@/services/private/UserAction";
import { GETUSERDEVICEAPI } from "@/services/private/UserDevice";
import { COMPANYUSERAPI } from "@/services/private/Company";
import { GETALLSKILLSAPI } from "@/services/private/Skills";

export default {
  components: {
    RouteBreadCrumb,
    SkillsVisitsTable,
    UserActionTable,
    UserDeviceTable,
  },
  data() {
    return {
      salesChartID: "salesChart",
      ordersChartID: "ordersChart",
      skills: [],
      staff: {data: {length: 0}},
      watchedSkills: [],
      unwatchedSkills: [],
      userAction: [],
      userDevice: [],
    };
  },
  methods: {
    async getSkillsList(isDeleted) {
      const result = await GETALLSKILLSAPI();
      if (result && result.status === 200) {
        this.skills = result.data.data;
      }
    },
    async getCompanyStaff(state) {
      const result = await COMPANYUSERAPI({ is_active: state });
      if (result && result.status === 200) {
        this.staff = result.data;
      }
    },
    async getAllSkillsWatched() {
      const result = await GETALLSKILLSWATCHEDAPI();
      if (result && result.status === 200) {
        console.log(result.data);
        this.watchedSkills = result.data.data.map((r) => {
          return {
            first_name: r.first_name,
            last_name: r.last_name,
            skill_name: r.skill_name,
            watched_dt: r.watched_dt,
          };
        });
      }
    },
    async getAllSkillsUnWatched() {
      const result = await GETALLSKILLSUNWATCHEDAPI();
      if (result && result.status === 200) {
        console.log(result.data);
        this.unwatchedSkills = result.data.data.map((r) => {
          return {
            first_name: r.first_name,
            last_name: r.last_name,
            skill_name: r.skill_name,
          };
        });
      }
    },
    async getUserAction() {
      const result = await GETUSERACTIONAPI();
      if (result && result.status === 200) {
        console.log(result.data);
        this.userAction = result.data.data.map((r) => {
          return {
            first_name: r.first_name,
            last_name: r.last_name,
            user_action: r.action_type,
            watched_dt: r.action_dt,
          };
        });
      }
    },
    async getUserDevice() {
      const result = await GETUSERDEVICEAPI();
      if (result && result.status === 200) {
        console.log(result.data);
        this.userDevice = result.data.data.map((r) => {
          return {
            first_name: r.first_name,
            last_name: r.last_name,
            created_dt: r.created_dt,
          };
        });
      }
    },
  },
async mounted() {
  await this.getSkillsList(false);
  await this.getCompanyStaff(true);
  await this.getAllSkillsWatched();
  await this.getAllSkillsUnWatched();
  await this.getUserAction();
  await this.getUserDevice();
},
};
</script>

<style></style>