<template>
  <div>
    <div class="header pb-6 d-flex align-items-center pt-4">
      <!-- Mask -->
      <span class="mask profile-background opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-7 col-md-10">
            <h1 class="display-2 text-white">
              {{ i18n.$t("editProfile.hello") }} {{ firstName }}
            </h1>
            <p class="text-white mt-0 mb-5">
              {{ i18n.$t("editProfile.description") }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid mt--6 xs-mt--3">
      <div class="row">
        <div class="col-xl-4 order-xl-2">
          <user-card :profile="profileData" @update="showProfile"></user-card>

          <!-- Progress track -->
          <!-- <card>
            <template v-slot:header>
              <h5 class="h3 mb-0">Progress track</h5>
            </template>
            <progress-track-list></progress-track-list>
          </card> -->
        </div>
        <div class="col-xl-8 order-xl-1">
          <div class="row">
            <div class="col-lg-6">
              <!-- <card gradient="info" class="border-0"> -->
              <!-- <div class="row">
                  <div class="col">
                    <h5
                      class="
                        card-title
                        text-uppercase text-muted
                        mb-0
                        text-white
                      "
                    >
                      Total traffic
                    </h5>
                    <span class="h2 font-weight-bold mb-0 text-white"
                      >350,897</span
                    >
                  </div>
                  <div class="col-auto">
                    <div
                      class="
                        icon icon-shape
                        bg-white
                        text-dark
                        rounded-circle
                        shadow
                      "
                    >
                      <i class="ni ni-active-40"></i>
                    </div>
                  </div>
                </div> -->
              <!-- <p class="mt-3 mb-0 text-sm">
                  <span class="text-white mr-2"
                    ><i class="fa fa-arrow-up"></i> 3.48%</span
                  >
                  <span class="text-nowrap text-light">Since last month</span>
                </p> -->
              <!-- </card> -->
            </div>
            <div class="col-lg-6">
              <!-- <card gradient="danger" class="border-0">
                <div class="row">
                  <div class="col">
                    <h5
                      class="
                        card-title
                        text-uppercase text-muted
                        mb-0
                        text-white
                      "
                    >
                      Performance
                    </h5>
                    <span class="h2 font-weight-bold mb-0 text-white"
                      >49,65%</span
                    >
                  </div>
                  <div class="col-auto">
                    <div
                      class="
                        icon icon-shape
                        bg-white
                        text-dark
                        rounded-circle
                        shadow
                      "
                    >
                      <i class="ni ni-spaceship"></i>
                    </div>
                  </div>
                </div>
                <p class="mt-3 mb-0 text-sm">
                  <span class="text-white mr-2"
                    ><i class="fa fa-arrow-up"></i> 3.48%</span
                  >
                  <span class="text-nowrap text-light">Since last month</span>
                </p>
              </card> -->
            </div>
          </div>
          <edit-profile-form
            :profile="profileData"
            @update="showProfile"
          ></edit-profile-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useRoute } from "vue-router";
import EditProfileForm from "./UserProfile/EditProfileForm.vue";
import UserCard from "./UserProfile/UserCard.vue";
import { messageService } from "@/services/_helper/message";
import { GETUSERINFOAPI } from "@/services/private/Profile";
import { useI18n } from "@/util/i18n";
import storage from "@/util/storage";
import ProgressTrackList from "@/views/Dashboard/ProgressTrackList";

export default {
  components: {
    EditProfileForm,
    UserCard,
    ProgressTrackList,
  },
  data() {
    return {
      route: useRoute(),
      profileData: {
        first_name: "",
        last_name: "",
        email: "",
        address_city: "",
        address_country: "",
        address_houseno: "",
        address_postcode: "",
        address_street: "",
        date_of_birth: "",
        gender: "",
        mobile_no: "",
        personal_info: "",
      },
    };
  },
  computed: {
    firstName() {
      return `${this.$store.getters.firstName}`;
    },
  },
  methods: {
    async showProfile() {
      const user = storage().getUser();
      const data = {
        version_no: user.current_version,
        user_id: user.user_id,
      };
      const result = await GETUSERINFOAPI(data);
      if (result && result.status === 200) {
        const { data } = result.data;
        this.$store.commit("setUser", data[0]);
        this.profileData.first_name = data[0].first_name;
        this.profileData.last_name = data[0].last_name;
        this.profileData.email = data[0].email;
        this.profileData.address_city = data[0].address_city;
        this.profileData.address_country = data[0].address_country;
        this.profileData.address_houseno = data[0].address_houseno;
        this.profileData.address_postcode = data[0].address_postcode;
        this.profileData.address_street = data[0].address_street;
        this.profileData.date_of_birth = data[0].date_of_birth;
        this.profileData.gender = data[0].gender;
        this.profileData.mobile_no = data[0].mobile_no;
        this.profileData.personal_info = data[0].personal_info;
      }
    },
  },
  async mounted() {
    await this.showProfile();
    if (this.route.params.network_token) {
      messageService.sendMessage({
        network_token: this.route.params.network_token,
        company: this.route.params.company,
      });
    }
  },
  setup() {
    const i18n = useI18n();
    return { i18n };
  },
};
</script>
<style>
.profile-header {
  background-size: cover;
  background-position: center top;
  min-height: 500px;
}
.profile-background {
  background: linear-gradient(
    90deg,
    rgba(124, 123, 159, 1) 0%,
    rgba(29, 26, 80, 1) 67%
  ) !important;
}
</style>
