<template>
    <p v-if="store.getters.isLoggedIn" class="back-arrow ni ni-bold-left" @click="goBack"></p>
    <div class="view-stream" ref="videoStream" v-swipe="onSwipe" @wheel="onWheel" :style="state.style">
        <player v-for="(video, i) in store.getters.activeSkill.videos" :video="video.url" :id="video.cloud_id"
            :key="video.cloud_id" :onVideoEnd="onVideoEnd" :onSwipe="onSwipe"
            :poster="'https://customer-czy559gndrdvu19x.cloudflarestream.com/' + video.cloud_id + '/thumbnails/thumbnail.jpg?time=1s'"
            :disabled="!video.watched" :ref="(el) => {
                videoRefs[i] = el;


            }
                " />
    </div>
</template>
      
<script>
import {
    ref,
    reactive,
    computed,
    watch,
    onBeforeUpdate,
    defineComponent,

} from "vue";

import swipe from "../../directives/swipe";
import Player from "./VideoPlayer.vue";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import { useI18n } from "@/util/i18n";
import { useRoute, useRouter } from "vue-router";
import { SETSKILLWATCHEDAPI, GETSKILLWATCHEDAPI } from "@/services/private/Skills";
import storage from "@/util/storage";


export default defineComponent({
    name: "ViewStream",
    directives: {
        swipe
    },
    components: {
        Player,
    },
    setup() {
        const store = useStore();
        const toast = useToast();
        const i18n = useI18n();
        const route = useRoute();
        const router = useRouter();
        //const videos = store.getters.activeSkill.videos;
        const videoRefs = ref([]);
        const showToast = ref(false);
        const showQuiz = ref(false);
        const wheelTimeout = ref(null);




        const skills = store.getters.skills;
        const activeSkill = skills.filter(skill => skill.skill_id === route.params.skill_id)[0];
        store.commit("setActiveSkill", activeSkill)

        const state = reactive({
            currentSlide: 1,//props.param,
            watchedAllVideos: false,
            videoProgress: [],
            amoundOfSlides: store.getters.activeSkill.videos.length,
            style: {
                transform: computed(
                    () => `translate3d(0, ${-(state.currentSlide - 1) * 100}%, 0)`
                ),
            },
        });
        onBeforeUpdate(() => {
            videoRefs.value = [];
        });

        watch(
            () => state.currentSlide,
            (items, oldItems) => {
                videoRefs.value[items - 1].play();
                videoRefs.value[oldItems - 1].pause();
            },
            {
                lazy: false,
            }
        );


        const onVideoEnd = async () => {
            //videos[state.currentSlide - 1].watched = true;
            store.getters.activeSkill.videos[state.currentSlide - 1].watched = true;
            if (store.getters.skills.filter(x => x.skill_id === store.getters.activeSkill.skill_id)[0].status !== "inProgess") {
                store.getters.skills.filter(x => x.skill_id === store.getters.activeSkill.skill_id)[0].status = "inProgress";
            }

            //state.watchedAllVideos = videoRefs.value.every(ref => ref.vidRef.ended);
            state.watchedAllVideos = store.getters.activeSkill.videos.every(video => video.watched == true)
            if (state.watchedAllVideos) {
                store.getters.skills.filter(x => x.skill_id === store.getters.activeSkill.skill_id)[0].status = "done";
                const result = await SETSKILLWATCHEDAPI(storage().getUser().user_id, store.getters.activeSkill.skill_id);
            }
        }
        const goBack = () => {
            router.push({ name: "Skills View" })
        }

        const onSwipe = (direction) => {

            //check whether first or last video and block swipe, if true
            if
                (direction === 1 && state.currentSlide === state.amoundOfSlides) {
                toast.info(i18n.$t("skills.SkillsView.swipeDeclinedLastVideo"));
                return;
            } else if (direction === -1 && state.currentSlide === 1) {
                return;
            }

            //check whether video not watched till end and block swipe, if true
            if (!store.getters.activeSkill.videos[state.currentSlide - 1].watched) {
                toast.warning(i18n.$t("skills.SkillsView.swipeDeclined"))
                return;
            }

            state.currentSlide = Number(state.currentSlide) + Number(direction);

        };

        const onWheel = (event) => {
            event.preventDefault();

            var idx = store.getters.skills.indexOf(store.getters.skills.filter(x => x.skill_id === store.getters.activeSkill.skill_id)[0])
            console.log(idx);



            if (wheelTimeout.value === null) {
                const delta = Math.sign(event.deltaY);
                var timer = 3200;
                if (delta > 0 && store.getters.skills[idx].videos[state.currentSlide - 1].watched) { timer = 2200; }
                else if (delta < 0 && store.getters.skills[idx].videos[state.currentSlide - 1].watched) { timer = 2200; }

                wheelTimeout.value = setTimeout(() => {
                    wheelTimeout.value = null;
                }, timer);
                onSwipe(delta);
            }


        }
        return {
            store,
            i18n,
            route,
            videoRefs,
            showToast,
            showQuiz,
            state,
            onVideoEnd,
            onSwipe,
            onWheel,
            wheelTimeout,
            goBack,
        }
    }
});
</script>
    
<style scoped>
.view-stream {
    background-color: #000041;
    position: fixed;
    height: 100vh;
    width: 100%;
    margin: 0;
    padding: 0;
    transform: translate3d(0, 0, 0);
    transition: transform 200ms ease;
}

.back-arrow {
    position: fixed;
    z-index: 999;
    font-size: 1.5rem;
    top: 6%;
    left: 2%;
    transform: translateY(-50%) translateX(-4%);
    color: white;
    cursor: pointer;
}

.quiz-enter-from {
    opacity: 0;
}

.quiz-enter-to {
    opacity: 1;
}

.quiz-enter-active {
    transition: all 0.3s ease-in;
}

.quiz-leave-from {
    opacity: 1;
}

.quiz-leave-to {
    opacity: 0;
}

.quiz-leave-active {
    transition: all 0.3s ease-in;
}
</style>