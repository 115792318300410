import { createRouter, createWebHashHistory } from "vue-router";
import store from "../store";

import DashboardLayout from "@/views/Layout/DashboardLayout";
import AuthLayout from "@/views/Pages/AuthLayout";
import SkillsLayout from "@/views/Layout/SkillsLayout";

// Dashboard pages
import Dashboard from "../views/Dashboard/Dashboard.vue";
import Alternative from "../views/Dashboard/AlternativeDashboard.vue";
import Widgets from "../views/Widgets.vue";
import Charts from "../views/Charts.vue";
import Calendar from "../views/Calendar/Calendar.vue";

// Pages
import Pricing from "../views/Pages/Pricing.vue";
import Login from "../views/Pages/Login.vue";
import Register from "../views/Pages/Register.vue";
import RegisterPhone from "../views/Pages/RegisterPhone.vue"
import RegisterEmailPassword from "../views/Pages/RegisterEmailPassword.vue";
import RegisterRegistrationCode from "../views/Pages/RegisterRegistrationCode.vue";
import Lock from "../views/Pages/Lock.vue";
import Profile from "../views/Pages/UserProfile.vue";
import Timeline from "../views/Pages/TimeLinePage.vue";

// Components pages
import Buttons from "../views/Components/Buttons.vue";
import Cards from "../views/Components/Cards.vue";
import Grid from "../views/Components/GridSystem.vue";
import Notifications from "../views/Components/Notifications.vue";
import Icons from "../views/Components/Icons.vue";
import Typography from "../views/Components/Typography.vue";

// Tables pages
import RegularTables from "../views/Tables/RegularTables.vue";
import SortableTables from "../views/Tables/SortableTables.vue";
import PaginatedTables from "../views/Tables/PaginatedTables.vue";

// Maps pages
import GoogleMaps from "../views/Maps/GoogleMaps.vue";
import VectorMaps from "../views/Maps/VectorMaps.vue";

// Forms pages
import FormElements from "../views/Forms/FormElements.vue";
import FormComponents from "../views/Forms/FormComponents.vue";
import FormValidation from "../views/Forms/FormValidation.vue";

//Account public Pages
import RegisterSuccess from "../views/Pages/RegisterSuccess.vue";
import RegisterSuccessNetwork from "../views/Pages/RegisterSuccessNetwork.vue";
import RegisterSuccessLinkRequired from "../views/Pages/RegisterSuccessLinkRequired.vue";
import RegisterSuccessGoToLogin from "../views/Pages/RegisterSuccessGoToLogin.vue";
import Activation from "../views/Pages/Activation.vue";
import Unauthorized from "../views/Pages/UnAuthorized.vue";
import Error from "../views/Pages/Error.vue";
import ForgotPassword from "../views/Pages/ForgotPassword.vue";
import ForgotPasswordSuccess from "../views/Pages/ForgotPasswordSuccess.vue";
import ResetPassword from "../views/Pages/ResetPassword.vue";

// Project pages
import Projects from "../views/Projects/ProjectsOverview.vue";
import ProjectsDetails from "../views/Projects/ProjectDetails.vue";

// Network pages
import Network from "../views/Network/NetworkOverview.vue";

// Staff pages
import Staff from "../views/Staff/StaffOverview.vue";
import StaffProfile from "../views/Staff/StaffProfile.vue";

// Locations pages
import Locations from "../views/Locations/LocationsOverview.vue";
import LocationDetails from "../views/Locations/LocationDetails.vue";

// Skills pages
import Skills from "../views/Skills/SkillsOverview.vue";
import SkillsDashboard from "../views/Skills/SkillsDashboard.vue";

// Skill view pages
import SkillsviewOverview from "../views/SkillsView/SkillsviewOverview.vue";
import ViewStream from "../views/SkillsView/ViewStream.vue";
import RedirectPage from "../views/SkillsView/RedirectPage.vue";

//Join pages
import JoinCompany from "../views/Pages/JoinCompany.vue";
import JoinNetwork from "../views/Pages/JoinNetwork.vue";
import { useToast } from "vue-toastification";
import { hasAccess } from "@/services/public/Auth";
import { has_permission } from "@/services/private/Role_Service";
import { permission_list } from "@/services/_helper/user_permission";

//Company Page
import CompanyProfile from "../views/Company/CompanyProfile.vue";


const toast = useToast();
let componentsMenu = {
  path: "/components",
  component: DashboardLayout,
  redirect: "/components/buttons",
  name: "Components",
  children: [
    {
      path: "buttons",
      name: "Buttons",
      components: { default: Buttons },
    },
    {
      path: "cards",
      name: "Cards",
      components: { default: Cards },
    },
    {
      path: "grid-system",
      name: "Grid",
      components: { default: Grid },
    },
    {
      path: "notifications",
      name: "Notifications",
      components: { default: Notifications },
    },
    {
      path: "icons",
      name: "Icons",
      components: { default: Icons },
    },
    {
      path: "typography",
      name: "Typography",
      components: { default: Typography },
    },
  ],
};

let tablesMenu = {
  meta: {
    requiresAuth: true
  },
  path: "/tables",
  component: DashboardLayout,
  redirect: "/tables/regular",
  name: "Tables menu",
  children: [
    {
      path: "regular",
      name: "Tables",
      components: { default: RegularTables },
    },
    {
      path: "sortable",
      name: "Sortable",
      components: { default: SortableTables },
    },
    {
      path: "paginated",
      name: "Paginated",
      components: { default: PaginatedTables },
    },
  ],
};

let mapsMenu = {
  path: "/maps",
  component: DashboardLayout,
  redirect: "/maps/google",
  name: "Maps",
  children: [
    {
      path: "google",
      name: "Google Maps",
      components: { default: GoogleMaps },
    },
    {
      path: "vector",
      name: "Vector Map",
      components: { default: VectorMaps },
    },
  ],
};

let formsMenu = {
  meta: {
    requiresAuth: true
  },
  path: "/forms",
  component: DashboardLayout,
  redirect: "/forms/elements",
  name: "Forms",
  children: [
    {
      path: "elements",
      name: "Forms elements",
      components: { default: FormElements },
    },
    {
      path: "/unauthorized",
      name: "Unauthorized",
      components: { default: Unauthorized },
    },
    {
      path: "/error",
      name: "Error",
      components: { default: Error },
    },
    {
      path: "components",
      name: "Forms Components",
      components: { default: FormComponents },
    },
    {
      path: "validation",
      name: "Forms Validation",
      components: { default: FormValidation },
    },
  ],
};

const routes = [
  {
    path: "/",
    redirect: "/login",
    component: AuthLayout,
    children: [
      {
        path: "/pricing",
        name: "Pricing",
        components: { default: Pricing },
      },
      {
        path: "/login",
        name: "Login",
        components: { default: Login },
      },
      {
        path: "/registerfull",
        name: "Register",
        components: { default: Register },
      },
      {
        path: "/register",
        name: "RegisterPhone",
        components: { default: RegisterPhone },
      },
      {
        path: "/registerEmailPassword",
        name: "RegisterEmailPassword",
        components: { default: RegisterEmailPassword },
      },
      {
        path: "/registerRegistrationCode",
        name: "RegisterRegistrationCode",
        components: { default: RegisterRegistrationCode },
      },
      {
        path: "/register-success",
        name: "RegisterSuccess",
        meta: { requiresAuth: store.getters.isLoggedIn },
        components: { default: RegisterSuccess },
      },
      {
        path: "/register-success",
        name: "RegisterSuccessNetwork",
        components: { default: RegisterSuccessNetwork },
      },
      {
        path: "/register-success",
        name: "RegisterSuccessLinkRequired",
        components: { default: RegisterSuccessLinkRequired },
      },
      {
        path: "/register-success",
        name: "RegisterSuccessGoToLogin",
        components: { default: RegisterSuccessGoToLogin },
      },
      {
        path: "/activation/:userId/:token",
        name: "Activation",
        meta: { requiresAuth: store.getters.isLoggedIn },
        components: { default: Activation },
      },

      {
        path: "/forgot-password",
        name: "ForgotPassword",
        components: { default: ForgotPassword },
      },
      {
        path: "/forgot-password-success",
        name: "ForgotPasswordSuccess",
        components: { default: ForgotPasswordSuccess },
      },
      {
        path: "/reset-password/:userId/:token",
        name: "ResetPassword",
        components: { default: ResetPassword },
      },
      {
        path: "/join-company/:token",
        name: "JoinCompany",
        meta: { requiresAuth: store.getters.isLoggedIn },
        components: { default: JoinCompany }
      },
      {
        path: "/join-network/:token",
        name: "JoinNetwork",
        meta: { requiresAuth: store.getters.isLoggedIn },
        components: { default: JoinNetwork }
      },
      {
        path: "/lock",
        name: "Lock",
        components: { default: Lock },
      },
      
    ],
  },
  {
    path: "/dashboard",
    component: DashboardLayout,
    meta: {
      requiresAuth: true
    },
    name: "Dashboards",
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        components: { default: Dashboard },
        meta: { authorize: [permission_list.menu_dashboard] }
      },
      {
        path: "/alternative",
        name: "Alternative",
        components: { default: Alternative },
        meta: {
          navbarType: "light",
        },
      },
      {
        path: "/widgets",
        name: "Widgets",
        components: { default: Widgets },
      },
      {
        path: "/charts",
        name: "Charts",
        components: { default: Charts },
      },
      {
        path: "/calendar",
        name: "Calendar",
        components: { default: Calendar },
      },
      {
        path: "/pages/user",
        name: "Profile",
        components: { default: Profile },
      },
      {
        path: "/pages/timeline",
        name: "Timeline",
        components: { default: Timeline },
      },
      {
        path: "/projects",
        name: "Projects",
        components: { default: Projects },
        meta: { authorize: [permission_list.menu_projects] }
      },
      {
        path: "/project/:company_id/:id",
        name: "ProjectsDetails",
        components: { default: ProjectsDetails },
        meta: { authorize: [permission_list.menu_projects] }
      },
      {
        path: "/shift/:company_id/:id/:shift_id",
        name: "ShiftDetails",
        components: { default: ProjectsDetails },
        meta: { authorize: [permission_list.menu_projects] }
      },
      {
        path: "/project/:company_id/:id/:extraid",
        name: "ProjectsDetailsExtra",
        components: { default: ProjectsDetails },
        meta: { authorize: [permission_list.menu_projects] }
      },
      {
        path: "/shift/:company_id/:id/:shift_id/:extraid",
        name: "ShiftDetailsExtra",
        components: { default: ProjectsDetails },
        meta: { authorize: [permission_list.menu_projects] }
      },
      {
        path: "/network",
        name: "Network",
        components: { default: Network },
        meta: { authorize: [permission_list.menu_network] }
      },
      {
        path: "/staff",
        name: "Staff",
        components: { default: Staff },
        meta: { authorize: [permission_list.menu_staff] },
      },
      {
        path: "/staff/profile/:company_id/:version/:user_id",
        name: "StaffProfile",
        components: { default: StaffProfile },
        meta: { authorize: [permission_list.menu_dashboard] }
      },
      {
        path: "/locations",
        name: "Locations",
        components: { default: Locations },
        meta: { authorize: [permission_list.menu_locations] }
      },
      {
        path: "/locations/:location_id",
        name: "LocationDetails",
        components: { default: LocationDetails },
        meta: { authorize: [permission_list.menu_locations] }
      },
      {
        path: "/skills",
        name: "Skills",
        components: { default: Skills },
        meta: { authorize: [permission_list.menu_skills] }
      },
      {
        path: "/company/:company_id",
        name: "CompanyProfile",
        components: { default: CompanyProfile },
      },
    ],
  },
  {
    path: "/skillsview",
    component: SkillsLayout,
    meta: {
      requiresAuth: true
    },
    name: "Skills Views",
    children: [
      {
        path: "/skillsview",
        name: "Skills View",
        components: { default: SkillsviewOverview },
        meta: { authorize: [permission_list.menu_myskills] }
      },
      {
        path: "/viewstream/:skill_id",
        name: "View Stream",
        components: { default: ViewStream },
        meta: { authorize: [permission_list.menu_myskills] }
      },
      {
        path: "/redirect/:skill_id",
        name: "Redirect Page",
        components: { default: RedirectPage },
        //meta: { authorize: [permission_list.menu_myskills] }
      },
      {
        path: "/publicskills/:skill_id",
        name: "Public Skills",
        components: { default: ViewStream }
      },
    ],
  },
  {
    path: "/publicskillsview",
    component: SkillsLayout,
   
    name: "Public Skills Views",
    children: [
      {
        path: "/publicskills/:skill_id",
        name: "Public Skills",
        components: { default: ViewStream }
      },
    ],
  },
  componentsMenu,
  tablesMenu,
  mapsMenu,
  formsMenu,
];

const router = createRouter({
  history: createWebHashHistory(),
  linkExactActiveClass: "active",
  routes,
});



router.beforeEach((to, from, next) => {
  const { authorize } = to.meta;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (to.path == '/login' || to.path == '/unauthorized') {
    if (store.getters.isLoggedIn && to.path == '/login') {
      next("/projects");
      return;
    }
    next();
    return;
  }

  if (requiresAuth) {
    if (store.getters.isLoggedIn) {

      // check if route is restricted by role
      if (authorize && authorize.length && to.path !== '/unauthorized' && !has_permission(authorize[0].value)) {
        // role not authorised so redirect to home page
        next("/unauthorized");
        return;
      } else {
        next();
        return;
      }

      // if (!hasAccess('home')) {
      //   toast.error('Test' || "Something went wrong!");
      // } else {
      //   next();
      //   return;
      // }
    }
    next("/login");
  } else {
    if (store.getters.isLoggedIn && to.path !== '/unauthorized') {
      if (authorize && authorize.length && !has_permission(authorize[0].value)) {
        // role not authorised so redirect to home page

        next("/unauthorized");
        return;
      } else {
        next("/projects");
        return;
      }
    } else {
      next();
    }
  }
});


export default router;
