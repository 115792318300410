import { createStore } from 'vuex';
import initialsName from "@/util/initialsName";
import Skill from './views/SkillsView/Skill';

const initialState = {
  access_token: localStorage.getItem('access_token') || '', // manage the access token
  expiredAt: "",
  user: {}, // manage the user details
  authLoading: true, // to indicate that the auth API is in progress
  isAuthenticated: false, // consider as a authentication flag
  userLoginLoading: false, // to indicate that the user signin API is in progress
  loginError: null, // manage the error of the user signin API
  statebar: "todo",
  skills: [
    /**new Skill("1", [], "todo", "Test1", []),
    new Skill("2", [], "todo", "Test2", []),
    new Skill("3", [], "inProgress", "Test3", []),
    new Skill("4", [], "done", "Test4", []),
    new Skill("5", [], "done", "Test5", []),
    new Skill("6", [], "done", "Test6", []),
    new Skill("7", [], "done", "Test7", []),**/
  ],
  activeSkill: Skill,
};
export default new createStore({
  state() {
    return initialState;
  },
  mutations: {
    authRequest(state) {
      state.userLoginLoading = true;
    },
    authSuccess(state, payload) {
      const { access_token, user, expired_at } = payload;
      state.access_token = access_token;
      state.user = user;
      state.expiredAt = expired_at;
      state.isAuthenticated = true;
      state.authLoading = false;
      state.userLoginLoading = false;
    },
    authError(state, error) {
      state.loginError = error;
      state.userLoginLoading = false;
    },
    logout(state) {
      state.access_token = localStorage.getItem('access_token') || ''; // manage the access token
      state.user = {}; // manage the user details
      state.authLoading = true; // to indicate that the auth API is in progress
      state.isAuthenticated = false; // consider as a authentication flag
      state.userLoginLoading = false; // to indicate that the user signin API is in progress
      state.loginError = null; // manage the error of the user signin API
    },
    setUser(state, data) {
      state.user = data;
    },
    setStateBar(state, newstate) {
      state.statebar = newstate;
    },
    setSkills(state, skills) {
      state.skills = [];
      state.skills = skills;

    },
    setActiveSkill(state, activeSkill) {
      state.activeSkill = activeSkill;
    }
  },
  getters: {
    isLoggedIn: state => !!state.access_token,
    firstName: state => {
      const user = state.user || JSON.parse(localStorage.getItem("user"));
      if (user && user.first_name && user.last_name) {
        return `${user.first_name}`;
      }
      return "";
    },
    fullName: state => {
      const user = state.user || JSON.parse(localStorage.getItem("user"));
      if (user && user.first_name && user.last_name) {
        return `${user.first_name} ${user.last_name}`;
      }
      return "";
    },
    initialsName: state => {
      const user = state.user || JSON.parse(localStorage.getItem("user"));
      if (user && user.first_name && user.last_name) {
        return initialsName(
          `${user.first_name} ${user.last_name}`
        );
      }
      return "";
    },
    cityCountry: state => {
      if (state.user && (state.user.address_city || state.user.address_country)) {
        const { user } = state;
        let cityCountry = `${user.address_city ? user.address_city : ""} ${user.address_country ? user.address_country : ""}`;
        return cityCountry;
      }
      return "";
    },
    statebar: state => {
      return state.statebar;
    },
    skills: state => {
      return state.skills
    },
    activeSkill: state => {
      return state.activeSkill;
    }
  },
});
