import axios from '../Axios';
import { GETNOTOFICATION, CLEARALLNOTIFICATION, CLEARNOTIFICATIONBYID, READONENOTIFICATION, CLICKNOTIFICATIONBELL } from '../CONSTANTURL';
export const GETNOTOFICATIONAPI = async () => {
  try {
    const result = await axios.get(`${GETNOTOFICATION}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};
export const CLEARALLNOTIFICATIONAPI = async (data) => {
  try {
    const { user_id } = data;
    const result = await axios.delete(`${CLEARALLNOTIFICATION}/${user_id}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const CLEARNOTIFICATIONBYIDAPI = async (data) => {
  try {
    const { notification_id } = data;
    const result = await axios.put(`${READONENOTIFICATION}/${notification_id}`);
    return result;
  } catch (err) {
      return {
        error: true,
        response: err && err.response,
        message: err && err.message
      };
  }
};
export const READONENOTIFICATIONAPI = async (data) => {
  try {
    const { notification_id } = data;
    const result = await axios.put(`${READONENOTIFICATION}/${notification_id}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};
export const CLICKNOTIFICATIONBELLAPI = async (data) => {
  try {
    const { user_id, is_notified } = data;
    const result = await axios.put(`${CLICKNOTIFICATIONBELL}/${user_id}/${is_notified}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};
