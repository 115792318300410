<template>
  <card>
    <p>Etwas ist schief gelaufen</p>
  </card>
</template>
<script>
export default {};
</script>
<style>
</style>
