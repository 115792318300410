import axios from '../Axios';
import { UPDATEPROFILE, UPDATEPERSONALINFO, GETUSERINFO, GETUSERPERMISSION } from '../CONSTANTURL';
export const GETUSERINFOAPI = async (data) => {
  try {
    const { user_id, version_no } = data;
    const result = await axios.get(`${GETUSERINFO}/${user_id}/${version_no}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};
export const UPDATEPROFILEAPI = async (data) => {
  try {
    const { user_id } = data;
    const result = await axios.put(`${UPDATEPROFILE}/${user_id}`, data);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};
export const UPDATEPERSONALINFORAPI = async (data) => {
  try {
    const { user_id } = data;
    const result = await axios.put(`${UPDATEPERSONALINFO}/${user_id}`, data);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const GETUSERPERMISSIONS = async (id) => {
  try {
    const result = await axios.get(`${GETUSERPERMISSION}/${id}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};
