<template>
  <div>
    <div class="mr-3">
      <span
        class="border-bottom py-3 px-0 d-block"
        v-for="(n, index) in staffs"
        :key="index"
        :class="getStatusClass(n)"
      >
        <div class="d-flex align-items-center">
          <div class="col-auto px-1">
            <span
              @click="openProfile(n)"
              class="avatar avatar-sm rounded-circle cursor-pointer"
            >
              {{ getInitialNames(n) }}
            </span>
          </div>
          <div class="col pl-2 pr-2">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h4 class="mb-0 text-sm font-weight-bolder text-capitalize">
                      {{ getName(n) }}
                    </h4>
                  </div>
                </div>
                <p class="text-sm-2 mb-0">
                  <badge
                    class="
                      badge-dot
                      text-sm-2
                      badge-md
                      p-0
                      d-flex
                      align-items-center
                    "
                    :type="getStatusColor(n)"
                  >
                    <i :class="`bg-${getStatusColor(n)}`"></i>
                    <span class="status text-gray text-capitalize">
                      {{ getStaffStatus(n) }}
                    </span>
                  </badge>
                </p>
                <div v-if="n.request_feedback">
                  <el-popover
                    placement="bottom"
                    :width="250"
                    trigger="click"
                    :content="n.request_feedback"
                  >
                    <template #reference>
                      <span
                        class="
                          d-block
                          mt-2
                          font-weight-500
                          text-sm-2 text-left text-gray-dark
                          cursor-pointer
                        "
                      >
                        {{ i18n.$t("projectDetails.shifts.showTheMessage") }}
                      </span>
                    </template>
                  </el-popover>
                </div>
              </div>
              <div class="p-0" v-if="status === 'requested'">
                <button
                  class="btn btn-sm"
                  :class="
                    n.shift_request_status === shiftStatus.icRequestTentative
                      ? 'btn-outline-warning'
                      : 'btn-outline-dark'
                  "
                  @click="
                    updateRequest(n, shiftStatus.icRequestTentative, 'SINGLE')
                  "
                >
                  <i class="fas fa-question"></i>
                </button>
                <button
                  class="btn btn-outline-dark btn-sm"
                  @click="updateRequest(n, shiftStatus.icUserOpen, 'SINGLE')"
                >
                  <i class="fas fa-check"></i>
                </button>
                <button
                  class="btn btn-sm"
                  :class="
                    n.shift_request_status === shiftStatus.icRequestUnavailable
                      ? 'btn-outline-danger'
                      : 'btn-outline-dark'
                  "
                  @click="
                    updateRequest(n, shiftStatus.icRequestUnavailable, 'SINGLE')
                  "
                >
                  <i class="fas fa-times"></i>
                </button>
              </div>
              <div v-else-if="status === 'scheduled'">
                <div
                  v-if="
                    n.shift_request_status === shiftStatus.icUserAvailable ||
                    n.shift_request_status === shiftStatus.icUserPerhaps ||
                    n.shift_request_status === shiftStatus.icUserRejected
                  "
                >
                  <button
                    class="btn btn-sm"
                    @click="updateScheduleRequest(n, shiftStatus.icUserPerhaps)"
                    :class="
                      n.shift_request_status === shiftStatus.icUserPerhaps
                        ? 'btn-outline-warning'
                        : 'btn-outline-dark'
                    "
                  >
                    <i class="fas fa-question"></i>
                  </button>
                  <button
                    class="btn btn-outline-dark btn-sm"
                    @click="updateScheduleRequest(n, shiftStatus.icUserMapped)"
                  >
                    <i class="fas fa-check"></i>
                  </button>
                  <button
                    class="btn btn-sm"
                    :class="
                      n.shift_request_status === shiftStatus.icUserRejected
                        ? 'btn-outline-danger'
                        : 'btn-outline-dark'
                    "
                    @click="
                      updateScheduleRequest(n, shiftStatus.icUserRejected)
                    "
                  >
                    <i class="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </span>
    </div>
    <div class="px-2 py-4" v-if="status === 'requested' && staffs.length > 0">
      <div class="d-flex flex-column">
        <div class="mb-2">
          <base-checkbox v-model="selectAll">
            {{ i18n.$t("projectDetails.shiftRequest.selectAll") }}
          </base-checkbox>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="mb-0 mr-2 text-sm text-capitalize">
              {{ i18n.$t("projectDetails.shiftRequest.selectNumber") }}
            </h4>
            <Field
              name="selectNumber"
              type="number"
              id="selectNumber"
              v-model="selectNumber"
              min="0"
              :max="staffs && staffs.length"
              class="form-control form-control-sm"
            />
          </div>
          <div class="px-3 py-0">
            <button
              class="btn btn-outline-dark btn-sm"
              @click="
                updateRequest(
                  staffs && staffs[0],
                  shiftStatus.icRequestTentative,
                  'BATCH'
                )
              "
            >
              <i class="fas fa-question"></i>
            </button>
            <button
              class="btn btn-outline-dark btn-sm"
              @click="
                updateRequest(
                  staffs && staffs[0],
                  shiftStatus.icUserOpen,
                  'BATCH'
                )
              "
            >
              <i class="fas fa-check"></i>
            </button>
            <button
              class="btn btn-outline-dark btn-sm"
              @click="
                updateRequest(
                  staffs && staffs[0],
                  shiftStatus.icRequestUnavailable,
                  'BATCH'
                )
              "
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Maybe shift request modal -->
    <maybe-modal
      :showModal="shiftStatusModal.maybeModalVisible"
      @close="closeAllShiftRequestModal"
      @update="updateUserRequestApi"
    >
    </maybe-modal>
    <!-- Rejected Modal -->
    <rejected-modal
      :showModal="shiftStatusModal.rejectedModalVisible"
      @close="closeAllShiftRequestModal"
      @rejected="showConfirmRejectedModal"
    >
    </rejected-modal>
    <!-- Confirm Rejected Modal -->
    <confirm-rejected-modal
      :showModal="shiftStatusModal.confirmRejectedModalVisible"
      @close="closeAllShiftRequestModal"
      @confirm="updateUserRequestApi"
    ></confirm-rejected-modal>
    <!-- Available Modal -->
    <available-modal
      :showModal="shiftStatusModal.availableModalVisible"
      @close="closeAllShiftRequestModal"
      @available="showConfirmAvailableModal"
    >
    </available-modal>
    <!-- Confirm available Modal -->
    <confirm-available-modal
      :showModal="shiftStatusModal.confirmAvailableModalVisible"
      @close="closeAllShiftRequestModal"
      @confirm="updateUserRequestApi"
    ></confirm-available-modal>
  </div>
</template>
<script>
import { computed, reactive, ref } from "vue";
import { ElDropdownMenu, ElDropdown, ElPopover } from "element-plus";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import { useToast } from "vue-toastification";
import { Field } from "vee-validate";
import {
  SHIFTSTATUS,
  SHIFTSTATUSCOLOR,
  SHIFTSTATUSCOLORDOT,
  PARTNERSHIFTSTATUS,
} from "@/util/constant";
import { useI18n } from "@/util/i18n";
import initialsName from "@/util/initialsName";
import storage from "@/util/storage";
import { useRouter } from "vue-router";

import MaybeModal from "../../Modals/ShiftModal/ICModal/MaybeModal.vue";
import RejectedModal from "../../Modals/ShiftModal/ICModal/RejectedModal.vue";
import ConfirmRejectedModal from "../../Modals/ShiftModal/ICModal/ConfirmRejectedModal.vue";
import AvailableModal from "../../Modals/ShiftModal/ICModal/AvailableModal.vue";
import ConfirmAvailableModal from "../../Modals/ShiftModal/ICModal/ConfirmAvailableModal.vue";

import { UPDATESHIFTPARTNERREQUESTAPI } from "@/services/private/ProjectShift";

export default {
  props: {
    type: String,
    shiftName: String,
    staffData: Array,
    shiftData: Object,
    availableStaffData: Object,
  },
  components: {
    [ElDropdown.name]: ElDropdown,
    [ElDropdownMenu.name]: ElDropdownMenu,
    [ElPopover.name]: ElPopover,
    Field,
    MaybeModal,
    RejectedModal,
    ConfirmRejectedModal,
    AvailableModal,
    ConfirmAvailableModal,
  },
  data() {
    return {
      models: {
        addUserBookingModalVisible: false,
      },
      router: useRouter(),
      shiftsRowData: null,
    };
  },
  methods: {
    getInitialNames(staff) {
      if (staff && staff.user_id !== null) {
        return initialsName(
          `${staff && staff.first_name} ${staff && staff.last_name}`
        );
      } else {
        return initialsName(staff && staff.oc_company_name, 1);
      }
    },
    openProfile(staff) {
      if (staff.user_id) {
        let routeData = this.router.resolve({
          name: "StaffProfile",
          params: {
            company_id: staff.company_id,
            version: staff.current_version,
            user_id: staff.user_id,
          },
        });
        window.open(routeData.href, "_blank");
      } else {
        let routeData = this.router.resolve({
          name: "CompanyProfile",
          params: {
            company_id: staff.partner_company_id,
          },
        });
        window.open(routeData.href, "_blank");
      }
    },
    scheduleShift() {
      document.body.classList.add("modal-open");
      this.shiftsRowData = this.shiftData;
      this.models.addUserBookingModalVisible = true;
    },
    closeModal() {
      document.body.classList.remove("modal-open");
      this.shiftsRowData = null;
      this.models.addUserBookingModalVisible = false;
    },
    getName(staff) {
      if (staff && staff.user_id !== null) {
        return `${staff && staff.first_name} ${staff && staff.last_name}`;
      } else {
        return staff && staff.oc_company_name;
      }
    },
  },
  setup(props, context) {
    const i18n = useI18n();
    const toast = useToast();
    const shiftStatus = reactive(SHIFTSTATUS);
    const partnerShiftStatus = reactive(PARTNERSHIFTSTATUS);
    const selectAll = ref(false);
    const selectNumber = ref(0);
    const status = props && props.type;

    const shiftStatusModal = reactive({
      maybeModalVisible: false,
      rejectedModalVisible: false,
      confirmRejectedModalVisible: false,
      availableModalVisible: false,
      confirmAvailableModalVisible: false,
      requestType: "",
    });
    const updateShiftRequestData = reactive({
      shift_partner_request_id: "",
      shift_id: "",
      request_status: "",
      partner_company_id: "",
      partner_company_name: "",
      responded_user_count: 0,
      total_responded: 0,
      request_feedback: "",
      shift_request: [],
    });
    let loader = ref(false);

    const staffs = computed(() => props.staffData);
    const availableStaff = computed(() => props && props.availableStaffData);
    const responded_user_count = computed(() => {
      return (
        props.staffData &&
        props.staffData[0] &&
        props.staffData[0].responded_user_count
      );
    });
    const requested_user_count = computed(() => {
      return (
        props.staffData &&
        props.staffData[0] &&
        props.staffData[0].requested_user_count
      );
    });

    function getStaffStatus(n) {
      const { shift_request_status, request_status } = n;
      if (shift_request_status) {
        return i18n.$t(`projectDetails.shifts.${shift_request_status}`);
      } else {
        return i18n.$t(`projectDetails.shifts.${request_status}`);
      }
    }
    function getStatusClass(n) {
      const { shift_request_status, request_status } = n;
      if (shift_request_status) {
        return SHIFTSTATUSCOLOR[shift_request_status];
      } else {
        return SHIFTSTATUSCOLOR[request_status];
      }
    }
    function getStatusColor(n) {
      const { shift_request_status, request_status } = n;
      if (shift_request_status) {
        return SHIFTSTATUSCOLORDOT[shift_request_status];
      } else {
        return SHIFTSTATUSCOLORDOT[request_status];
      }
    }
    async function updateRequest(row, status, type) {
      const data = {
        shift_partner_request_id: row.shift_partner_request_id,
        shift_id: row.shift_id,
        request_status: status,
        partner_company_id: row.company_id,
        partner_company_name: row.partner_company_name,
        responded_user_count: 0,
        total_responded: parseInt(row.responded_user_count),
        request_feedback: "",
        shift_request: [],
      };
      if (type === "SINGLE") {
        data.responded_user_count = 1;
      } else {
        data.responded_user_count =
          selectAll.value === true
            ? staffs.value && staffs.value.length
            : parseInt(selectNumber.value);
      }
      if (
        data.total_responded === requested_user_count.value ||
        data.total_responded > requested_user_count.value
      ) {
        data.total_responded = parseInt(requested_user_count.value);
      } else {
        data.total_responded =
          parseInt(data.total_responded) + parseInt(data.responded_user_count);
      }
      if (type === "SINGLE") {
        data.shift_request.push(row);
      } else {
        for (let i = 0; i < data.responded_user_count; i++) {
          data.shift_request.push(staffs.value[i]);
        }
      }
      showStatusModal(data);
    }

    //Modal actions functions
    function showStatusModal(data) {
      if (data.request_status === shiftStatus.icRequestTentative) {
        availableUpdateShiftData(data, data.request_status);
        closeAllShiftRequestModal();
        shiftStatusModal.maybeModalVisible = true;
      } else if (data.request_status === shiftStatus.icUserOpen) {
        availableUpdateShiftData(data, data.request_status);
        closeAllShiftRequestModal();
        shiftStatusModal.availableModalVisible = true;
      } else {
        availableUpdateShiftData(data, data.request_status);
        closeAllShiftRequestModal();
        shiftStatusModal.rejectedModalVisible = true;
      }
    }
    function updateScheduleRequest(row, status) {
      let isOpenState = true;
      if (props.type === "scheduled" && status === SHIFTSTATUS.icUserMapped) {
        let isOpenRequestAvailable = availableStaff.value.filter(
          (a) =>
            (a.shift_request_status === SHIFTSTATUS.icUserOpen ||
              a.shift_request_status === SHIFTSTATUS.icUserAdded ||
              a.shift_request_status === SHIFTSTATUS.icOpenPerhaps ||
              a.shift_request_status === SHIFTSTATUS.icOpenRejected) &&
            a.user_id === null
        );
        if (isOpenRequestAvailable && isOpenRequestAvailable.length === 0) {
          isOpenState = false;
        }
      }
      if (isOpenState) {
        const data = {
          partner_company_id: row.company_id,
          shift_request_id: row.shift_request_id,
          shift_id: row.shift_id,
          shift_name: props.shiftName,
          shift_request_status: status,
          user_name: `${row.first_name} ${row.last_name}`,
          user_id: row.user_id,
        };
        context.emit("showConfirmation", data);
      } else {
        toast.error(
          i18n.$t("projectDetails.shiftRequest.noMoreAddUserToShift")
        );
      }
    }
    function closeAllShiftRequestModal() {
      shiftStatusModal.maybeModalVisible = false;
      shiftStatusModal.rejectedModalVisible = false;
      shiftStatusModal.confirmRejectedModalVisible = false;
      shiftStatusModal.availableModalVisible = false;
      shiftStatusModal.confirmAvailableModalVisible = false;
      loader.value = false;
    }
    function emptyShiftDataOnRequestUpdate() {
      updateShiftRequestData.shift_partner_request_id = "";
      updateShiftRequestData.shift_id = "";
      updateShiftRequestData.request_status = "";
      updateShiftRequestData.request_feedback = "";
      updateShiftRequestData.partner_company_name = "";
      updateShiftRequestData.responded_user_count = "";
      updateShiftRequestData.total_responded = "";
      updateShiftRequestData.partner_company_id = "";
      updateShiftRequestData.shift_request = [];
    }
    function availableUpdateShiftData(row, status) {
      updateShiftRequestData.shift_partner_request_id =
        row.shift_partner_request_id;
      updateShiftRequestData.shift_id = row.shift_id;
      updateShiftRequestData.request_status = status;
      updateShiftRequestData.request_feedback = "";
      updateShiftRequestData.partner_company_name = row.partner_company_name;
      updateShiftRequestData.responded_user_count = row.responded_user_count;
      updateShiftRequestData.total_responded = row.total_responded;
      updateShiftRequestData.partner_company_id = row.partner_company_id;
      updateShiftRequestData.shift_request = row.shift_request;
    }
    async function updateUserRequestApi(formData) {
      loader.value = true;
      //Adding Admin/Dispatch user name if request type is perhaps or rejected
      let feedback =
        formData && formData.request_feedback
          ? formData.request_feedback
          : updateShiftRequestData.request_feedback;
      const user = storage().getUser();
      const role = user.role;
      if (
        (role.role_code === "A" || role.role_code === "D") &&
        updateShiftRequestData.request_status !== shiftStatus.icUserOpen
      ) {
        feedback = `${user.first_name} ${user.last_name}: ${feedback}`;
      }
      const data = {
        ...updateShiftRequestData,
        request_feedback: feedback,
      };
      const result = await UPDATESHIFTPARTNERREQUESTAPI(data);
      loader.value = false;
      if (result && result.status === 200) {
        context.emit("updateRequest", updateShiftRequestData.shift_id);
        selectNumber.value = 0;
        const shift_request_status = updateShiftRequestData.request_status;
        let swalMessage;
        if (shift_request_status === shiftStatus.icRequestUnavailable) {
          swalMessage = i18n.$t(`projectDetails.shifts.response.maybeResponse`);
        } else if (shift_request_status === shiftStatus.icRequestTentative) {
          swalMessage = `Das ${updateShiftRequestData.shift_name} wurde erfolgreich gelöscht!`;
        } else {
          swalMessage = i18n.$t(
            `projectDetails.shifts.swal.IC.updateUserRequest`
          );
        }
        const status = i18n.$t(`projectDetails.shifts.${shift_request_status}`);
        swal.fire({
          icon: "success",
          title: status,
          text: swalMessage,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
        closeAllShiftRequestModal();
        emptyShiftDataOnRequestUpdate();
      } else {
        toast.error(i18n.$t("projectDetails.shiftRequest.shiftRequestFailed"));
      }
    }
    function showConfirmRejectedModal(formData) {
      closeAllShiftRequestModal();
      updateShiftRequestData.request_feedback = formData.request_feedback;
      shiftStatusModal.confirmRejectedModalVisible = true;
    }
    function showConfirmAvailableModal() {
      closeAllShiftRequestModal();
      shiftStatusModal.confirmAvailableModalVisible = true;
    }

    return {
      i18n,
      shiftStatus,
      partnerShiftStatus,
      status,
      selectAll,
      selectNumber,
      staffs,
      responded_user_count,
      requested_user_count,
      availableStaff,
      shiftStatusModal,
      updateShiftRequestData,
      getStaffStatus,
      getStatusClass,
      getStatusColor,
      updateRequest,
      updateScheduleRequest,
      closeAllShiftRequestModal,
      showStatusModal,
      availableUpdateShiftData,
      updateUserRequestApi,
      showConfirmRejectedModal,
      showConfirmAvailableModal,
    };
  },
};
</script>
