<template>
  <div>
    <base-header type="default" class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-12 col-md-3">
          <h6 class="h2 text-white d-inline-block mb-2 mb-sm-0">
            {{ i18n.$t("project.PageTitle") }}
          </h6>
        </div>
        <div class="col-12 col-md-9 text-right">
          <div
            class="
              d-flex
              flex-column flex-sm-row
              justify-content-end
              align-items-end
            "
          >
            <base-button
              class="mb-2 mr-0 mr-sm-2 mb-sm-0"
              size="sm"
              type="secondary"
              @click="toggleCanceledList(!showCanceled)"
            >
              {{ displayCanceledListState }}
            </base-button>
            <base-button
              class="mb-2 mr-0 mr-sm-2 mb-sm-0"
              size="sm"
              type="secondary"
              @click="togglePastList(!showPast)"
            >
              {{ displayPastListState }}
            </base-button>
            <base-button
              v-if="isRoleAdminOrDispatch"
              class="mb-2 mr-0 mr-sm-2 mb-sm-0"
              size="sm"
              type="warning"
              @click="toggleCreateProjectModal(!createProjectModalVisible)"
            >
              {{ i18n.$t("project.createProject") }}
            </base-button>
          </div>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div v-if="isRoleAdminOrDispatch" class="col">
          <light-table
            :projectData="projects.value"
            :currentView="currentView"
            @update="getUpdatedProjects"
          />
        </div>
        <div v-else class="col">
          <light-table-user
            :projectData="projects.value"
            :currentView="currentView"
            @update="getUpdatedProjects"
          />
        </div>
      </div>
    </div>
    <create-project-modal
      v-if="createProjectModalVisible"
      :showModal="createProjectModalVisible"
      :loader="loader"
      @close="toggleCreateProjectModal"
      @create="createProjectApi"
    ></create-project-modal>
  </div>
</template>
<script>
import { onMounted, reactive, ref } from "vue";
import { useRoute } from "vue-router";
import { useToast } from "vue-toastification";
import { permission_list } from "@/services/_helper/user_permission";
import { messageService } from "@/services/_helper/message";
import { has_permission } from "@/services/private/Role_Service";
import {
  COMPANYPROJECTAPI,
  GETPROJECTSHIFTBYUSERAPI,
  CREATEPROJECTAPI,
} from "@/services/private/Project";
import date from "@/util/date";
import { useI18n } from "@/util/i18n";
import storage from "@/util/storage";
import LightTable from "./LightTable.vue";
import LightTableUser from "./LightTableUser.vue";
import CreateProjectModal from "./Modals/CreateProjectModal.vue";

export default {
  components: {
    LightTable,
    LightTableUser,
    CreateProjectModal,
  },
  data() {
    return {
      permission: permission_list,
      route: useRoute(),
    };
  },
  methods: {
    isAccessible(permission) {
      return has_permission(permission.value);
    },
  },
  computed: {
    isRoleAdminOrDispatch() {
      const user = this.$store.state.user; //storage().getUser();
      const role = user && user.role;
      return role && (role.role_code === "A" || role.role_code === "D");
    },
  },
  mounted() {
    if (this.route.params.network_token) {
      messageService.sendMessage({
        network_token: this.route.params.network_token,
        company: this.route.params.company,
      });
    }
  },
  setup() {
    const i18n = useI18n();
    const toast = useToast();
    const initialFilter = {
      is_active: true,
      is_canceled: false,
      is_deleted: false,
    };
    let displayPastListState = ref(i18n.$t("project.list.showPast"));
    let displayCanceledListState = ref(i18n.$t("project.list.showCanceled"));
    let displayDeletedListState = ref(i18n.$t("project.list.showDeleted"));
    let showPast = ref(true);
    let showCanceled = ref(true);
    let showDeleted = ref(true);
    let loader = ref(false);
    let filterProject = reactive(initialFilter);
    let createProjectModalVisible = ref(false);
    let projects = reactive({ value: [] });
    let currentView = ref("CURRENT");
    async function togglePastList(state) {
      displayCanceledListState.value = i18n.$t("project.list.showCanceled");
      displayDeletedListState.value = i18n.$t("project.list.showDeleted");
      currentView.value = state === false ? "PAST" : "CURRENT";
      showPast.value = state;
      showCanceled.value = true;
      filterProject = {
        is_active: state,
        is_canceled: false,
        is_deleted: false,
      };
      if (state) {
        displayPastListState.value = i18n.$t("project.list.showPast");
      } else {
        displayPastListState.value = i18n.$t("project.list.hidePast");
      }
      await getProjects(currentView.value);
    }
    async function toggleCanceledList(state) {
      displayPastListState.value = i18n.$t("project.list.showPast");
      displayDeletedListState.value = i18n.$t("project.list.showDeleted");
      currentView.value = state === false ? "CANCELLED" : "CURRENT";
      showCanceled.value = state;
      showPast.value = true;
      filterProject = {
        is_active: !state ? false : true,
        is_canceled: !state ? true : false,
        is_deleted: false,
      };
      if (state) {
        displayCanceledListState.value = i18n.$t("project.list.showCanceled");
      } else {
        displayCanceledListState.value = i18n.$t("project.list.hideCanceled");
      }
      await getProjects(currentView.value);
    }
    async function toggleDeletedList(state) {
      displayPastListState.value = i18n.$t("project.list.showPast");
      displayCanceledListState.value = i18n.$t("project.list.showCanceled");
      currentView.value = state === false ? "DELETED" : "CURRENT";
      showCanceled.value = state;
      showPast.value = true;
      filterProject = {
        is_active: !state ? false : true,
        is_canceled: false,
        is_deleted: !state ? true : false,
      };
      if (state) {
        displayDeletedListState.value = i18n.$t("project.list.showDeleted");
      } else {
        displayDeletedListState.value = i18n.$t("project.list.hideDeleted");
      }
      await getProjects(currentView.value);
    }
    function toggleCreateProjectModal(state) {
      createProjectModalVisible.value = state;
    }
    async function getProjects(flag) {
      const user = storage().getUser();
      const role = user && user.role;
      if (role && (role.role_code === "A" || role.role_code === "D")) {
        const data = { flag };
        const result = await COMPANYPROJECTAPI(data);
        if (result && result.status === 200) {
          const { data } = result.data;
          projects.value = data.map((p) => {
            return {
              ...p,
              project_status: i18n.$t(`project.status.${p.project_status}`),
              project_status_en: p.project_status,
            };
          });
        }
      } else {
        const data = {
          user_id: user && user.user_id,
          flag,
        };
        // const result = await GETPRJECTBYUSERAPI(data);
        const result = await GETPROJECTSHIFTBYUSERAPI(data);
        if (result && result.status === 200) {
          const { data } = result.data;
          projects.value = data.map((p) => {
            return {
              ...p,
              project_status: i18n.$t(`project.status.${p.project_status}`),
              project_status_en: p.project_status,
              shift_date_time: shiftTime(p.shift_start_time, p.shift_end_time),
            };
          });
        }
      }
    }
    function shiftTime(shiftStartTime, shiftEndTime) {
      if (shiftStartTime !== null) {
        const startTime = `${date().formatTime(`${shiftStartTime}`, "HH:mm")}`;
        const endTime = shiftEndTime
          ? `${date().formatTime(`${shiftEndTime}`, "HH:mm")}`
          : "";
        return endTime ? `${startTime} - ${endTime} Uhr` : `${startTime} Uhr`;
      }
      return "-";
    }
    async function createProjectApi(formData) {
      loader.value = true;
      const data = {
        ...formData,
        end_date: formData.end_date !== "" ? new Date(formData.end_date) : null,
        end_time: formData.end_time !== "" ? formData.end_time : null,
        project_description: "",
      };
      const result = await CREATEPROJECTAPI(data);
      loader.value = false;
      if (result && result.status === 200) {
        toast.success(i18n.$t("project.response.created"));
        await getProjects("CURRENT");
        toggleCreateProjectModal(false);
      } else {
        toast.error(i18n.$t("project.response.failed"));
      }
    }
    async function getUpdatedProjects() {
      await getProjects(currentView.value);
    }
    onMounted(async () => {
      await getProjects("CURRENT");
    });
    return {
      i18n,
      showPast,
      showCanceled,
      showDeleted,
      togglePastList,
      toggleCanceledList,
      toggleDeletedList,
      toggleCreateProjectModal,
      displayPastListState,
      displayCanceledListState,
      displayDeletedListState,
      loader,
      currentView,
      createProjectModalVisible,
      projects,
      createProjectApi,
      getProjects,
      filterProject,
      getUpdatedProjects,
    };
  },
};
</script>
