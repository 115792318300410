<template>
  <div class="card">
    <el-table
      class="table-responsive table-flush rounded"
      header-row-class-name="thead-light"
      :data="projects"
      :default-sort="{ prop: 'start_dt', order: 'ascending' }"
      @row-click="goToProjectPage"
    >
      <el-table-column
        label="Name"
        min-width="310"
        prop="project_name"
        sortable
      >
        <template v-slot="{ row }">
          <div
            class="
              font-weight-600
              name
              mb-0
              text-sm text-default text-capitalize
            "
          >
            <div class="media align-items-center">
              <div class="media-body">
                {{ row.project_name }}
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :label="i18n.$t('projectDetails.shifts.th.shift')"
        prop="shift_name"
        min-width="200"
        sortable
      >
        <template v-slot="{ row }">
          <div class="media align-items-center cursor-pointer">
            <div
              class="
                media-body
                text-uppercase
                font-weight-600
                text-sm text-default
              "
            >
              {{ row.shift_name }}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        min-width="190"
        :label="i18n.$t('projectDetails.shifts.th.date')"
        prop="shift_date"
        sortable
      >
        <template v-slot="{ row }">
          <span class="p-0 mr-1 text-xs text-left">
            <i class="fas fa-calendar-alt text-gray mr-1"></i>
            <span class="margin-left">
              {{
                formatDate(row.shift_date, i18n.$t("projectDetails.start_date"))
              }}
            </span>
          </span>
        </template>
      </el-table-column>
      <el-table-column
        min-width="190"
        :label="i18n.$t('projectDetails.shifts.th.time')"
        prop="shift_date_time"
        sortable
      >
        <template v-slot="{ row }">
          <span class="p-0 m-1 text-xs text-left">
            <i class="fas fa-clock text-gray mr-1"></i>
            <span class="margin-left">
              {{ row.shift_date_time }}
            </span>
          </span>
        </template>
      </el-table-column>

      <el-table-column
        label="Company"
        min-width="150"
        prop="company_name"
        sortable
      >
        <template v-slot="{ row }">
          <span class="font-weight-600">{{ row.company_name }}</span>
        </template>
      </el-table-column>

      <el-table-column
        label="Location"
        min-width="150"
        prop="location_name"
        sortable
      >
        <template v-slot="{ row }">
          <span class="font-weight-600">{{ row.location_name }}</span>
        </template>
      </el-table-column>

      <el-table-column min-width="200" prop="actions">
        <template v-slot="{ row }">
          <span
            v-if="
              !row.project_is_cancelled &&
              (row.shift_request_status === shiftStatus.userTentative ||
                row.shift_request_status === shiftStatus.userRequested ||
                row.shift_request_status === shiftStatus.userAssigned)
            "
            class="mt-2 mb-2"
          >
            <button
              v-if="
                row.shift_request_status !== shiftStatus.userAvailable &&
                row.shift_request_status !== shiftStatus.userAssigned
              "
              class="btn btn-sm"
              :class="
                row.shift_request_status === shiftStatus.userTentative
                  ? 'btn-outline-warning'
                  : 'btn-outline-dark'
              "
              @click="
                updateRequestStatusToMaybe(row, shiftStatus.userTentative)
              "
            >
              <i class="fas fa-question"></i>
            </button>
            <button
              class="btn btn-sm"
              :class="
                row.shift_request_status === shiftStatus.userAvailable
                  ? 'btn-outline-success'
                  : 'btn-outline-dark'
              "
              @click="updateRequestStatusToYes(row, shiftStatus.userAvailable)"
            >
              <i class="fas fa-check"></i>
            </button>
            <button
              class="btn btn-sm"
              :class="
                row.shift_request_status === shiftStatus.userUnavailable
                  ? 'btn-outline-danger'
                  : 'btn-outline-dark'
              "
              @click="
                updateRequestStatusToRejected(row, shiftStatus.userUnavailable)
              "
            >
              <i class="fas fa-times"></i>
            </button>
          </span>

          <span
            v-if="
              !row.project_is_cancelled &&
              (row.shift_request_status === shiftStatus.icUserTentative ||
                row.shift_request_status === shiftStatus.icUserRequested ||
                row.shift_request_status === shiftStatus.icUserAssigned)
            "
            class="mt-2 mb-2"
          >
            <button
              v-if="
                row.shift_request_status !== shiftStatus.icUserAvailable &&
                row.shift_request_status !== shiftStatus.icUserAssigned
              "
              class="btn btn-sm"
              :class="
                row.shift_request_status === shiftStatus.icUserTentative
                  ? 'btn-outline-warning'
                  : 'btn-outline-dark'
              "
              @click="
                updateRequestStatusToMaybe(row, shiftStatus.icUserTentative)
              "
            >
              <i class="fas fa-question"></i>
            </button>
            <button
              class="btn btn-sm"
              :class="
                row.shift_request_status === shiftStatus.icUserAvailable
                  ? 'btn-outline-success'
                  : 'btn-outline-dark'
              "
              @click="
                updateRequestStatusToYes(row, shiftStatus.icUserAvailable)
              "
            >
              <i class="fas fa-check"></i>
            </button>
            <button
              class="btn btn-sm"
              :class="
                row.shift_request_status === shiftStatus.icUserUnavailable
                  ? 'btn-outline-danger'
                  : 'btn-outline-dark'
              "
              @click="
                updateRequestStatusToRejected(
                  row,
                  shiftStatus.icUserUnavailable
                )
              "
            >
              <i class="fas fa-times"></i>
            </button>
          </span>
          <span
            v-if="
              row.shift_request_status == shiftStatus.icUserAdded ||
              row.shift_request_status == shiftStatus.icUserAvailable ||
              row.shift_request_status == shiftStatus.userAdded ||
              row.shift_request_status == shiftStatus.userAvailable
            "
          >
            <button class="btn btn-sm btn-outline-dark btn-disable" disabled>
              <i class="fas fa-question"></i>
            </button>

            <button class="btn btn-sm btn-outline-success" disabled>
              <i class="fas fa-check"></i>
            </button>

            <button class="btn btn-sm btn-outline-dark btn-disable" disabled>
              <i class="fas fa-times"></i>
            </button>
          </span>
        </template>
      </el-table-column>
    </el-table>
    <delete-project-modal
      :showModal="models.deleteProjectModalVisible"
      @close="closeAllProjectModal"
      @delete="openConfirmDeleteModal"
    ></delete-project-modal>
    <confirm-delete-project-modal
      :showModal="models.confirmDeleteProjectModalVisible"
      :loader="loader"
      @close="closeAllProjectModal"
      @confirm="sendDeleteProjectAPI"
    ></confirm-delete-project-modal>
    <canceled-project-modal
      :showModal="models.canceledProjectModalVisible"
      @close="closeAllProjectModal"
      @canceled="openConfirmCanceledModal"
    ></canceled-project-modal>
    <confirm-canceled-project-modal
      :showModal="models.confirmCanceledProjectModalVisible"
      :loader="loader"
      @close="closeAllProjectModal"
      @confirm="sendCanceledProjectAPI"
    ></confirm-canceled-project-modal>

    <!-- Maybe shift request modal -->
    <maybe-modal
      :showModal="shiftStatusModal.maybeModalVisible"
      @close="closeAllShiftRequestModal"
      @update="updateUserRequestApi"
    >
    </maybe-modal>
    <!-- Rejected Modal -->
    <rejected-modal
      :requestType="shiftStatusModal.requestType"
      :showModal="shiftStatusModal.rejectedModalVisible"
      @close="closeAllShiftRequestModal"
      @rejected="showConfirmRejectedMoal"
    >
    </rejected-modal>
    <!-- Confirm Rejected Modal -->
    <confirm-rejected-modal
      :showModal="shiftStatusModal.confirmRejectedModalVisible"
      @close="closeAllShiftRequestModal"
      @confirm="updateUserRequestApi"
    ></confirm-rejected-modal>
    <!-- Available Modal -->
    <available-modal
      :requestType="shiftStatusModal.requestType"
      :showModal="shiftStatusModal.availableModalVisible"
      @close="closeAllShiftRequestModal"
      @available="showConfirmAvailableModal"
    >
    </available-modal>
    <!-- Confirm available Modal -->
    <confirm-available-modal
      :showModal="shiftStatusModal.confirmAvailableModalVisible"
      @close="closeAllShiftRequestModal"
      @confirm="updateUserRequestApi"
    ></confirm-available-modal>
  </div>
</template>
<script>
import { reactive, ref } from "vue";
import {
  ElTable,
  ElTableColumn,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
} from "element-plus";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import { useToast } from "vue-toastification";
import { PROJECTSTATUSCOLOR, SHIFTSTATUS } from "@/util/constant";
import date from "@/util/date";
import { useI18n } from "@/util/i18n";
import storage from "@/util/storage";
import {
  DELETEPROJECTAPI,
  CANCELEDPROJECTAPI,
} from "@/services/private/Project";
import {
  GETALLSHIFTREQUESTAPI,
  UPDATESHIFTREQUESTAPI,
  UPDATEICSHIFTREQUESTAPI,
  CREATESHIFTDOCUMENTAPI,
  GETSHIFTDOCUMENTSAPI,
  DELETESHIFTDOCAPI,
} from "@/services/private/ProjectShift";
import { currentuser_role } from "@/services/private/Role_Service";
import DeleteProjectModal from "./Modals/DeleteProjectModal.vue";
import ConfirmDeleteProjectModal from "./Modals/ConfirmDeleteProjectModal.vue";
import CanceledProjectModal from "./Modals/CanceledProjectModal.vue";
import ConfirmCanceledProjectModal from "./Modals/ConfirmCanceledProjectModal.vue";

import MaybeModal from "./Modals/ShiftModal/MaybeModal.vue";
import RejectedModal from "./Modals/ShiftModal/RejectedModal.vue";
import ConfirmRejectedModal from "./Modals/ShiftModal/ConfirmRejectedModal.vue";
import AvailableModal from "./Modals/ShiftModal/AvailableModal.vue";
import ConfirmAvailableModal from "./Modals/ShiftModal/ConfirmAvailableModal.vue";

export default {
  name: "light-table",
  props: {
    projectData: Array,
    currentView: String,
  },
  components: {
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
    DeleteProjectModal,
    ConfirmDeleteProjectModal,
    CanceledProjectModal,
    ConfirmCanceledProjectModal,
    MaybeModal,
    RejectedModal,
    ConfirmRejectedModal,
    AvailableModal,
    ConfirmAvailableModal,
  },
  data() {
    return {
      currentPage: 1,
      currentuser_role: currentuser_role(),
      models: {
        projectName: "",
        canceledProjectModalVisible: false,
        canceledProjectId: "",
        confirmCanceledProjectModalVisible: false,
        deleteProjectModalVisible: false,
        deleteProjectId: "",
        confirmDeleteProjectModalVisible: false,
      },
    };
  },
  computed: {
    projects() {
      return this.projectData;
    },
    setCurrentView() {
      return this.currentView;
    },
    company_id() {
      const user = storage().getUser();
      return user && user.company_id;
    },
  },
  methods: {
    formatDate(projectDate) {
      return date().formatDate(projectDate, "DD.MM.YYYY");
    },
    isSame(start_dt, end_dt) {
      if (end_dt) {
        const isSameDate = date().isSame(start_dt, end_dt, "D");
        if (!isSameDate) {
          return `- ${this.formatDate(end_dt)}`;
        }
      }
    },
    closeAllProjectModal() {
      this.models = {
        projectName: "",
        deleteProjectModalVisible: false,
        deleteProjectId: "",
        confirmDeleteProjectModalVisible: false,
        canceledProjectModalVisible: false,
        canceledProjectId: "",
        confirmCanceledProjectModalVisible: false,
      };
    },
    openConfirmDeleteModal() {
      this.models.deleteProjectModalVisible = false;
      this.models.confirmDeleteProjectModalVisible = true;
    },
    openConfirmCanceledModal() {
      this.models.canceledProjectModalVisible = false;
      this.models.confirmCanceledProjectModalVisible = true;
    },
    canceledProject(project_name, project_id) {
      this.models = {
        projectName: project_name,
        canceledProjectModalVisible: true,
        canceledProjectId: project_id,
        confirmCanceledProjectModalVisible: false,
      };
    },
    deleteProject(project_name, project_id) {
      this.models = {
        projectName: project_name,
        deleteProjectModalVisible: true,
        deleteProjectId: project_id,
        confirmDeleteProjectModalVisible: false,
      };
    },
    async sendDeleteProjectAPI() {
      const swalMessage = `Das ${this.models.projectName} wurde erfolgreich gelöscht!`;
      const toast = useToast();
      const project_id = this.models.deleteProjectId;
      const result = await DELETEPROJECTAPI(project_id);
      if (result && result.status === 200) {
        this.closeAllProjectModal();
        swal.fire({
          icon: "success",
          title: `Gelöscht`,
          text: swalMessage,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
        this.$emit("update");
      } else {
        toast.error("Projekt konnte nicht gelöscht werden");
      }
    },
    async sendCanceledProjectAPI() {
      const swalMessage = `Das ${this.models.projectName} wurde erfolgreich storniert!`;
      const toast = useToast();
      const project_id = this.models.canceledProjectId;
      const result = await CANCELEDPROJECTAPI(project_id);
      if (result && result.status === 200) {
        this.closeAllProjectModal();
        swal.fire({
          icon: "success",
          title: `Storniert`,
          text: swalMessage,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
        this.$emit("update");
      } else {
        toast.error("Projekt konnte nicht abgebrochen werden");
      }
    },
    goToProjectPage(row, column) {
      if (
        column &&
        column.property !== "actions" &&
        row &&
        row.company_id &&
        row.project_id
      ) {
        this.$router.push(`/project/${row.company_id}/${row.project_id}`);
      }
    },
  },
  setup(props, context) {
    const i18n = useI18n();
    const shiftStatus = reactive(SHIFTSTATUS);
    let loader = ref(false);
    const toast = useToast();
    const request_feedback = ref("");
    const requestedStaff = reactive({ value: [] });
    const availableStaff = reactive({ value: [] });
    const scheduledStaff = reactive({ value: [] });

    const shiftStatusModal = reactive({
      maybeModalVisible: false,
      rejectedModalVisible: false,
      confirmRejectedModalVisible: false,
      availableModalVisible: false,
      confirmAvailableModalVisible: false,
      requestType: "",
    });

    const updateShiftRequestData = reactive({
      user_name: "",
      shift_request_id: "",
      shift_id: "",
      user_id: "",
      shift_name: "",
      shift_request_status: "",
      partner_company_id: "",
    });

    function getStatusI18n(status) {
      return i18n.$t(`project.status.${status}`);
    }
    function getProjectStatusColor(status) {
      return PROJECTSTATUSCOLOR[status];
    }

    function closeAllShiftRequestModal() {
      shiftStatusModal.maybeModalVisible = false;
      shiftStatusModal.rejectedModalVisible = false;
      shiftStatusModal.confirmRejectedModalVisible = false;
      shiftStatusModal.availableModalVisible = false;
      shiftStatusModal.confirmAvailableModalVisible = false;
      loader.value = false;
    }
    function updateShiftData(row, status) {
      const user = storage().getUser();
      updateShiftRequestData.shift_request_id = row.shift_request_id;
      updateShiftRequestData.shift_id = row.shift_id;
      updateShiftRequestData.shift_request_status = status;
      updateShiftRequestData.shift_name = row.shift_name;
      updateShiftRequestData.user_name = `${user && user.first_name} ${
        user && user.last_name
      }`;
      updateShiftRequestData.user_id = user && user.user_id;
      shiftStatusModal.requestType = status;
      const isICProjectState = isICProject(row);
      if (isICProjectState !== null) {
        updateShiftRequestData.partner_company_id = isICProjectState;
      }
    }
    function emptyShiftDataOnRequestUpdate() {
      updateShiftRequestData.shift_request_id = "";
      updateShiftRequestData.shift_id = "";
      updateShiftRequestData.shift_request_status = "";
      updateShiftRequestData.shift_name = "";
      updateShiftRequestData.user_name = "";
      updateShiftRequestData.user_id = "";
      updateShiftRequestData.partner_company_id = "";
      shiftStatusModal.requestType = "";
      request_feedback.value = "";
    }
    function updateRequestStatusToMaybe(row, status) {
      updateShiftData(row, status);
      closeAllShiftRequestModal();
      shiftStatusModal.maybeModalVisible = true;
    }
    function updateRequestStatusToRejected(row, status) {
      updateShiftData(row, status);
      closeAllShiftRequestModal();
      shiftStatusModal.rejectedModalVisible = true;
    }
    function updateRequestStatusToYes(row, status) {
      const updateStatus =
        row.shift_request_status === shiftStatus.userAssigned
          ? shiftStatus.userAdded
          : row.shift_request_status === shiftStatus.icUserAssigned
          ? shiftStatus.icUserMapped
          : status;
      updateShiftData(row, updateStatus);
      closeAllShiftRequestModal();
      shiftStatusModal.availableModalVisible = true;
    }
    function showConfirmRejectedMoal(formData) {
      closeAllShiftRequestModal();
      request_feedback.value = formData.request_feedback;
      shiftStatusModal.confirmRejectedModalVisible = true;
    }
    function showConfirmAvailableModal() {
      closeAllShiftRequestModal();
      shiftStatusModal.confirmAvailableModalVisible = true;
    }
    async function updateAdminRequestApi(shift_id) {
      requestedStaff.value = [];
      availableStaff.value = [];
      scheduledStaff.value = [];
      context.emit("update");
    }
    async function updateUserRequestApi(formData) {
      loader.value = true;
      let feedback =
        formData && formData.request_feedback
          ? formData.request_feedback
          : request_feedback.value;
      const user = storage().getUser();
      const role = user.role;
      const requestType = shiftStatusModal.requestType;

      console.log(shiftStatusModal);

      //Adding Admin/Dispatch user name if request type is user_perhaps or user_added
      if (
        (role.role_code === "A" || role.role_code === "D") &&
        (requestType === shiftStatus.userPerhaps ||
          requestType === shiftStatus.userRejected)
      ) {
        feedback = `${user.first_name} ${user.last_name}: ${feedback}`;
      }
      const data = {
        ...updateShiftRequestData,
        request_feedback: feedback,
      };
      let result = null;
      if (updateShiftRequestData.partner_company_id !== "") {
        result = await UPDATEICSHIFTREQUESTAPI(data);
      } else {
        result = await UPDATESHIFTREQUESTAPI(data);
      }
      loader.value = false;
      if (result && result.status === 200) {
        const shift_request_status =
          updateShiftRequestData.shift_request_status;
          console.log("LightTableUse.vue - shift_request_status");
          console.log(shift_request_status);
        const shift_id = updateShiftRequestData.shift_id;
        let swalMessage;
        if (shift_request_status === "user_rejected") {
          const swalMessage = i18n.$t(`projectDetails.shifts.response.maybeResponse`);
        } else if (shift_request_status === "user_tentative") {
          swalMessage = `Das ${updateShiftRequestData.shift_name} wurde erfolgreich gelöscht!`;
        } else {
          if (requestType === shiftStatusModal.maybeModalVisible) {
            const swalMessage = i18n.$t(
              `projectDetails.shifts.swal.IC.updateMaybeModalVisible`
            );
          }

          if (requestType === shiftStatusModal.confirmRejectedModalVisible) {
            swalMessage = i18n.$t(
              `projectDetails.shifts.swal.IC.updateConfirmRejectedModalVisible`
            );
          }

          if (requestType === shiftStatusModal.confirmAvailableModalVisible) {
            swalMessage = i18n.$t(
              `projectDetails.shifts.swal.IC.updateConfirmAvailableModalVisible`
            );
          }
        }

        const status = i18n.$t(`projectDetails.shifts.${shift_request_status}`);

        closeAllShiftRequestModal();
        emptyShiftDataOnRequestUpdate();
        updateAdminRequestApi(shift_id);
        context.emit("update");
        swal.fire({
          icon: "success",
          title: status,
          text: swalMessage,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      } else if (result && result.response && result.response.status === 786) {
        await updateICShiftRequestWithAvailable();
      } else {
        toast.error(i18n.$t("projectDetails.shiftRequest.shiftRequestFailed"));
      }
    }
    async function updateICShiftRequestWithAvailable() {
      loader.value = true;
      const data = {
        ...updateShiftRequestData,
        shift_request_status: shiftStatus.icUserAvailable,
        request_feedback: feedback,
      };
      const result = await UPDATEICSHIFTREQUESTAPI(data);
      loader.value = false;
      if (result && result.status === 200) {
        const shift_request_status =
          updateShiftRequestData.shift_request_status;
        const shift_id = updateShiftRequestData.shift_id;
        const swalMessage = i18n
          .$t("projectDetails.shifts.swal.updateICShiftRequest")
          .replace(/{shift_name}/gi, updateShiftRequestData.shift_name);
        const status = i18n.$t(`projectDetails.shifts.${shift_request_status}`);

        closeAllShiftRequestModal();
        emptyShiftDataOnRequestUpdate();
        updateAdminRequestApi(shift_id);
        context.emit("update");
        swal.fire({
          icon: "success",
          title: status,
          text: swalMessage,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      } else {
        toast.error(i18n.$t("projectDetails.shiftRequest.shiftRequestFailed"));
      }
    }
    function availableUpdateShiftData(row, status) {
      updateShiftRequestData.shift_request_id = row.shift_request_id;
      updateShiftRequestData.shift_id = row.shift_id;
      updateShiftRequestData.shift_request_status = status;
      updateShiftRequestData.shift_name = row.shift_name;
      updateShiftRequestData.user_name = row.user_name;
      updateShiftRequestData.user_id = row.user_id;
      updateShiftRequestData.partner_company_id = row.partner_company_id;
      shiftStatusModal.requestType = status;
    }

    function showStatusModal(data) {
      shiftStatusModal.requestType = data.shift_request_status;
      if (data.shift_request_status === shiftStatus.userPerhaps) {
        availableUpdateShiftData(data, data.shift_request_status);
        closeAllShiftRequestModal();
        shiftStatusModal.maybeModalVisible = true;
      } else if (
        data.shift_request_status === shiftStatus.userAdded ||
        data.shift_request_status === shiftStatus.icUserAdded
      ) {
        availableUpdateShiftData(data, data.shift_request_status);
        closeAllShiftRequestModal();
        shiftStatusModal.availableModalVisible = true;
      } else {
        availableUpdateShiftData(data, data.shift_request_status);
        closeAllShiftRequestModal();
        shiftStatusModal.rejectedModalVisible = true;
      }
    }
    function isICProject(row) {
      const user = storage().getUser();
      if (user && user.company_id !== row.company_id) {
        return user.company_id;
      }
      return null;
    }
    return {
      i18n,
      shiftStatus,
      loader,
      shiftStatusModal,
      request_feedback,
      getStatusI18n,
      getProjectStatusColor,
      closeAllShiftRequestModal,
      emptyShiftDataOnRequestUpdate,
      updateRequestStatusToMaybe,
      updateRequestStatusToYes,
      updateRequestStatusToRejected,
      showConfirmRejectedMoal,
      showConfirmAvailableModal,
      updateAdminRequestApi,
      updateUserRequestApi,
      showStatusModal,
    };
  },
};
</script>
<style scoped>
.btn-disable {
  color: #cad3dc !important;
  border-color: #cad3dc !important;
}

.btn:hover {
  box-shadow: none;
  transform: none;
}

.btn {
  height: 29px;
}
</style>
