<template>
  <modal
    v-model:show="show"
    modal-classes="modal-white"
    header-classes="align-items-center"
    body-classes="pt-0"
    :show-close="showClose"
  >
    <template v-slot:header>
      <h6 class="modal-title text-capitalize text-default">
        {{ i18n.$t("modal.connectNetwork.title") }}
      </h6>
    </template>
    <div class="">
      <p class="text-default mb-3">
        {{ i18n.$t("modal.connectNetwork.subTitle") }} {{ company }}?
      </p>
      <div></div>
    </div>
    <template v-slot:footer>
      <base-button
        native-type="button"
        type="warning"
        class="text-white p-2 px-3"
        @click="connectToCompany"
      >
        <div class="d-flex align-items-center">
          <spinner v-show="loader" color="text-black" />
          <span>Connect</span>
        </div>
      </base-button>
      <base-button
        type=""
        class="ml-auto text-default p-2 px-3"
        @click="handleClose"
      >
        {{ i18n.$t("message.cancel") }}
      </base-button>
    </template>
  </modal>
</template>
<script>
import Modal from "@/components/Modal";
import { useI18n } from "@/util/i18n";
import { CREATENETWORKAPI, VERIFYINVITEAPI } from "@/services/private/Network";
import { useToast } from "vue-toastification";
export default {
  name: "add-network-modal",
  props: {
    showModal: Boolean,
    loader: Boolean,
    invitationLink: String,
    company: String,
  },
  components: {
    Modal,
  },
  data() {
    return {
      invite_link: "",
      toast: useToast(),
      i18n: useI18n(),
    };
  },
  computed: {
    show() {
      return this.showModal;
    },
    showClose() {
      return false;
    },
    company_id() {
      return this.$store.state.user.company_id;
    },
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
    async connectToCompany() {
      const data = { network_token: this.invitationLink };
      const result = await CREATENETWORKAPI(data);
      // const result = await INVITATIONLINKAPI();
      if (
        result.error &&
        result.response &&
        result.response.data &&
        result.response.data.status === 409
      ) {
        this.toast.error(
          this.i18n.$t("modal.connectNetwork.AlreadyConnectedMessage")
        );
        this.$emit("close");
      } else if (
        result.error &&
        result.response &&
        result.response.data &&
        result.response.data.status === 403
      ) {
        this.toast.error(
          this.i18n.$t("modal.connectNetwork.NoPermissionMessage")
        );
        this.$emit("close");
      } else if (result && result.status == 200) {
        this.toast.success(this.i18n.$t("message.ConnectedCompanySuccess"));
        this.$emit("update", data);
        this.$emit("close");
      }
    },
  },
  setup() {
    const i18n = useI18n();

    function getRoleI18n(type) {
      const roleName = type && type.toLowerCase();
      return i18n.$t(`role.${roleName}`);
    }
    return { i18n, getRoleI18n };
  },
};
</script>
