<template>
    <div class="skills">
        <router-view></router-view>
    </div>
</template>

<style scoped>
.skills {
    overflow: hidden;
}
</style>