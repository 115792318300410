<template>
  <div class="card">
    <el-table
      class="table-responsive table-flush rounded"
      header-row-class-name="thead-light"
      :data="locations"
      :default-sort="{ prop: 'name', order: 'ascending' }"
      @row-click="goToLocationPage"
    >
      <el-table-column
        :label="i18n.$t('network.Table.Name')"
        min-width="120px"
        prop="name"
        sortable
      >
        <template v-slot="{ row }">
          <div class="media align-items-center">
            <span
              class="
                font-weight-600
                name
                mb-0
                text-sm text-capitalize
                cursor-pointer
              "
            >
              {{ row.location_name }}
            </span>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        :label="i18n.$t('location.table.address')"
        min-width="300px"
        prop="address"
        sortable
      >
        <template v-slot="{ row }">
          <div class="media align-items-center">
            <span
              @click="redirectToDetails(row)"
              class="
                font-weight-600
                name
                mb-0
                text-sm text-capitalize
                cursor-pointer
              "
            >
              {{ row.location_address_street }} ,
              <span v-if="row.location_address_houseno">
                {{ row.location_address_houseno }} ,
              </span>
              {{ row.location_address_postcode }}
              {{ row.location_address_city }}
            </span>
          </div>
        </template>
      </el-table-column>

      <el-table-column min-width="100px" prop="actions" class-name="text-right">
        <template v-slot="{ row }">
          <el-dropdown
            v-if="
              !row.is_deleted && isAccessible(permission.network_list_action)
            "
            class="dropdown"
            trigger="click"
            placement="bottom-start"
          >
            <span class="p-2 btn-icon-only text-light">
              <i class="fas fa-ellipsis-v mt-2"></i>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <li
                  class="dropdown-item"
                  @click="deleteLocation(row.location_id)"
                >
                  {{ i18n.$t("common.remove") }}
                </li>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>

    <!-- Remove user modal -->
    <delete-location-modal
      :showModal="models.removeLocationModalVisible"
      :loader="loader"
      @close="closeDeleteLocationModal"
      @confirm="showConfirmDeleteModal"
    ></delete-location-modal>

    <confirm-delete-modal
      :showModal="models.confirmDeleteModalVisible"
      :loader="loader"
      @close="closeConfirmDeleteModal"
      @remove="sendRemoveLocationAPI"
    ></confirm-delete-modal>
  </div>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
} from "element-plus";
import { useToast } from "vue-toastification";
import initialsName from "@/util/initialsName";
import { useI18n } from "@/util/i18n";
import { useRouter, useRoute } from "vue-router";
import { has_permission } from "@/services/private/Role_Service";
import { permission_list } from "@/services/_helper/user_permission";
import DeleteLocationModal from "./DeleteLocationModal.vue";
import ConfirmDeleteModal from "./ConfirmDeleteModal.vue";
import { DELETELOCATIONAPI } from "@/services/private/Location";

export default {
  name: "light-table",
  components: {
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
    DeleteLocationModal,
    ConfirmDeleteModal,
  },
  props: {
    locations: Array,
  },
  data() {
    return {
      loader: false,
      router: useRouter(),
      i18n: useI18n(),
      permission: permission_list,
      currentPage: 1,
      models: {
        removeLocationModalVisible: false,
        removeLocationId: "",
        confirmDeleteModalVisible: false,
      },
    };
  },
  methods: {
    isAccessible(permission) {
      return has_permission(permission.value);
    },
    redirectToDetails(raw) {
      this.router.push({
        name: "LocationDetails",
        params: { location_id: raw.location_id },
      });
    },
    deleteLocation(locationId) {
      this.models.removeLocationId = locationId;
      this.models.removeLocationModalVisible = true;
    },
    closeDeleteLocationModal() {
      this.models.removeLocationId = "";
      this.models.removeLocationModalVisible = false;
    },
    showConfirmDeleteModal() {
      this.models.removeLocationModalVisible = false;
      this.models.confirmDeleteModalVisible = true;
    },
    closeConfirmDeleteModal() {
      this.models.confirmDeleteModalVisible = false;
      this.closeDeleteLocationModal();
    },
    async sendRemoveLocationAPI() {
      const toast = useToast();
      //Call API
      const location_id = this.models.removeLocationId;
      const result = await DELETELOCATIONAPI(location_id);

      if (result && result.status === 200) {
        this.closeConfirmDeleteModal();
        this.$emit("update");
        toast.success(
          this.i18n.$t("location.delete.successDeleteLocationMessage")
        );
      } else {
        toast.error(this.i18n.$t("location.delete.failDeleteLocationMessage"));
      }
    },
    goToLocationPage(row, column) {
      if (column && column.property !== "actions" && row && row.location_id) {
        this.$router.push({
          name: "LocationDetails",
          params: { location_id: row.location_id },
        });
      }
    },
  },
  setup() {
    const i18n = useI18n();
    function getStatusI18n(status) {
      const findStatus = status === true ? "connected" : "disconnected";
      return i18n.$t(`common.${findStatus}`);
    }
    return { i18n, getStatusI18n };
  },
  mounted() {
    //this.getAllNetwork();
  },
};
</script>
