import axios from '../Axios';
import {
  GETALLLOCATIONS,
  GETLOCATIONBYID,
  ADDNEWLOCATION,
  DELETELOCATION,
  UPDATELOCATION,
  UPDATELOCATIONTEXT,
  UPLOADLOCATIONDOCUMENT,
  GETLOCATIONDOCUMENTS,
  DELETELOCATIONDOCUMENT
} from '../CONSTANTURL';

export const GETALLLOCATIONSAPI = async (isDeleted) => {
  try {
    const result = await axios.get(`${GETALLLOCATIONS}/${isDeleted}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const ADDNEWLOCATIONAPI = async (data) => {
  try {
    const result = await axios.post(`${ADDNEWLOCATION}`, data);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const DELETELOCATIONAPI = async (data) => {
  try {
    const result = await axios.delete(`${DELETELOCATION}/${data}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};


export const GETLOCATIONBYIDAPI = async (isDeleted) => {
  try {
    const result = await axios.get(`${GETLOCATIONBYID}/${isDeleted}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const UPDATELOCATIONAPI = async (location_id, data) => {
  try {
    const result = await axios.put(`${UPDATELOCATION}/${location_id}`, data);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};


export const UPDATELOCATIONTEXTAPI = async (location_id, data) => {
  try {
    const result = await axios.put(`${UPDATELOCATIONTEXT}/${location_id}`, data);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const UPLOADLOCATIONDOCUMENTAPI = async (formData) => {
  try {
    const result = await axios.post(`${UPLOADLOCATIONDOCUMENT}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};


export const GETLOCATIONDOCUMENTSAPI = async (locationId,isDeleted) => {
  try {
    const result = await axios.get(`${GETLOCATIONDOCUMENTS}/${locationId}/deleted/${isDeleted}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const DELETELOCATIONDOCAPI = async (data) => {
  try {
    const result = await axios.delete(`${DELETELOCATIONDOCUMENT}/${data}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};