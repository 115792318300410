<template>
  <div class="card">
    <el-table
      class="table-responsive table-flush rounded"
      header-row-class-name="thead-light"
      :row-class-name="isICProject"
      :data="projects"
      :default-sort="{ prop: 'start_dt', order: 'ascending' }"
      @row-click="goToProjectPage"
    >
      <el-table-column
        :label="i18n.$t('network.Table.Name')"
        min-width="310px"
        prop="project_name"
        sortable
      >
        <template v-slot="{ row }">
          <div
            class="
              font-weight-600
              name
              mb-0
              text-sm text-default text-capitalize
            "
          >
            <div class="media align-items-center">
              <div class="media-body">
                {{ row.project_name }}
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :label="i18n.$t('common.date')"
        min-width="200px"
        prop="start_date"
        sortable
      >
        <template v-slot="{ row }">
          <span class="font-weight-600">
            {{ formatDate(row.start_date) }}
            {{ isSame(row.start_date, row.end_date) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        :label="i18n.$t('project.Table.company')"
        min-width="200px"
        prop="company_name"
        sortable
      >
        <template v-slot="{ row }">
          <span class="font-weight-600">{{ row.company_name }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="i18n.$t('project.Table.location')"
        min-width="200px"
        prop="location_name"
        sortable
      >
        <template v-slot="{ row }">
          <span class="font-weight-600">{{ row.location_name }}</span>
        </template>
      </el-table-column>
      <el-table-column min-width="100px" prop="actions" class-name="text-right">
        <template v-slot="{ row }">
          <el-dropdown
            placement="bottom-start"
            trigger="click"
            class="dropdown"
            v-if="
              currentuser_role &&
              currentuser_role.role_code &&
              currentuser_role.role_code === 'A' &&
              row.project_status_en !== 'completed' &&
              row.company_id === company_id
            "
          >
            <span class="btn btn-sm btn-icon-only text-light mr-1">
              <i class="fas fa-ellipsis-v mt-2"></i>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <li
                  class="dropdown-item"
                  @click="canceledProject(row.project_name, row.project_id)"
                  v-if="setCurrentView !== 'CANCELLED'"
                >
                  {{ i18n.$t("common.canceledProject") }}
                </li>
                <li
                  class="dropdown-item"
                  @click="deleteProject(row.project_name, row.project_id)"
                  v-if="setCurrentView === 'CANCELLED'"
                >
                  {{ i18n.$t("common.deleteProject") }}
                </li>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>

    <!-- <div class="card-footer py-4 d-flex justify-content-end">
      <base-pagination v-model="currentPage" :total="50"></base-pagination>
    </div> -->
    <delete-project-modal
      :showModal="models.deleteProjectModalVisible"
      @close="closeAllProjectModal"
      @delete="openConfirmDeleteModal"
    ></delete-project-modal>
    <confirm-delete-project-modal
      :showModal="models.confirmDeleteProjectModalVisible"
      :loader="loader"
      @close="closeAllProjectModal"
      @confirm="sendDeleteProjectAPI"
    ></confirm-delete-project-modal>
    <canceled-project-modal
      :showModal="models.canceledProjectModalVisible"
      @close="closeAllProjectModal"
      @canceled="openConfirmCanceledModal"
    ></canceled-project-modal>
    <confirm-canceled-project-modal
      :showModal="models.confirmCanceledProjectModalVisible"
      :loader="loader"
      @close="closeAllProjectModal"
      @confirm="sendCanceledProjectAPI"
    ></confirm-canceled-project-modal>
  </div>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
} from "element-plus";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import { useToast } from "vue-toastification";
import { PROJECTSTATUSCOLOR } from "@/util/constant";
import date from "@/util/date";
import { useI18n } from "@/util/i18n";
import storage from "@/util/storage";
import {
  DELETEPROJECTAPI,
  CANCELEDPROJECTAPI,
} from "@/services/private/Project";
import { currentuser_role } from "@/services/private/Role_Service";
import DeleteProjectModal from "./Modals/DeleteProjectModal.vue";
import ConfirmDeleteProjectModal from "./Modals/ConfirmDeleteProjectModal.vue";
import CanceledProjectModal from "./Modals/CanceledProjectModal.vue";
import ConfirmCanceledProjectModal from "./Modals/ConfirmCanceledProjectModal.vue";

export default {
  name: "light-table",
  props: {
    projectData: Array,
    currentView: String,
  },
  components: {
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
    DeleteProjectModal,
    ConfirmDeleteProjectModal,
    CanceledProjectModal,
    ConfirmCanceledProjectModal,
  },
  data() {
    return {
      currentPage: 1,
      currentuser_role: currentuser_role(),
      loader: false,
      models: {
        projectName: "",
        canceledProjectModalVisible: false,
        canceledProjectId: "",
        confirmCanceledProjectModalVisible: false,
        deleteProjectModalVisible: false,
        deleteProjectId: "",
        confirmDeleteProjectModalVisible: false,
      },
    };
  },
  computed: {
    projects() {
      return this.projectData;
    },
    setCurrentView() {
      return this.currentView;
    },
    company_id() {
      const user = storage().getUser();
      return user && user.company_id;
    },
  },
  methods: {
    formatDate(projectDate) {
      return date().formatDate(projectDate, "DD.MM.YYYY");
    },
    isSame(start_dt, end_dt) {
      if (end_dt) {
        const isSameDate = date().isSame(start_dt, end_dt, "D");
        if (!isSameDate) {
          return `- ${this.formatDate(end_dt)}`;
        }
      }
    },
    closeAllProjectModal() {
      this.models = {
        projectName: "",
        deleteProjectModalVisible: false,
        deleteProjectId: "",
        confirmDeleteProjectModalVisible: false,
        canceledProjectModalVisible: false,
        canceledProjectId: "",
        confirmCanceledProjectModalVisible: false,
      };
    },
    openConfirmDeleteModal() {
      this.models.deleteProjectModalVisible = false;
      this.models.confirmDeleteProjectModalVisible = true;
    },
    openConfirmCanceledModal() {
      this.models.canceledProjectModalVisible = false;
      this.models.confirmCanceledProjectModalVisible = true;
    },
    canceledProject(project_name, project_id) {
      this.models = {
        projectName: project_name,
        canceledProjectModalVisible: true,
        canceledProjectId: project_id,
        confirmCanceledProjectModalVisible: false,
      };
    },
    deleteProject(project_name, project_id) {
      this.models = {
        projectName: project_name,
        deleteProjectModalVisible: true,
        deleteProjectId: project_id,
        confirmDeleteProjectModalVisible: false,
      };
    },
    async sendDeleteProjectAPI() {
      const swalMessage = `Das ${this.models.projectName} wurde erfolgreich gelöscht!`;
      const toast = useToast();
      const project_id = this.models.deleteProjectId;
      const result = await DELETEPROJECTAPI(project_id);
      if (result && result.status === 200) {
        this.closeAllProjectModal();
        swal.fire({
          icon: "success",
          title: `Gelöscht`,
          text: swalMessage,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
        this.$emit("update");
      } else {
        toast.error("Projekt konnte nicht gelöscht werden");
      }
    },
    async sendCanceledProjectAPI() {
      const swalMessage = `Das ${this.models.projectName} wurde erfolgreich storniert!`;
      const toast = useToast();
      const project_id = this.models.canceledProjectId;
      const result = await CANCELEDPROJECTAPI(project_id);
      if (result && result.status === 200) {
        this.closeAllProjectModal();
        swal.fire({
          icon: "success",
          title: `Storniert`,
          text: swalMessage,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
        this.$emit("update");
      } else {
        toast.error("Projekt konnte nicht abgebrochen werden");
      }
    },
    goToProjectPage(row, column) {
      if (
        column &&
        column.property !== "actions" &&
        row &&
        row.company_id &&
        row.project_id
      ) {
        this.$router.push(`/project/${row.company_id}/${row.project_id}`);
      }
    },
    isICProject(e) {
      const user = storage().getUser();
      if (user && user.company_id !== e.row.company_id) {
        return "bg-spindle";
      }
    },
  },
  setup() {
    const i18n = useI18n();
    function getStatusI18n(status) {
      return i18n.$t(`project.status.${status}`);
    }
    function getProjectStatusColor(status) {
      return PROJECTSTATUSCOLOR[status];
    }
    return { i18n, getStatusI18n, getProjectStatusColor };
  },
};
</script>
