import axios from '../Axios';
import { INVITELINKNETWORK, GETALLNETWORK, VERIFYINVITETOKEN, DISCONNECTUSERFROMNETWORK, CREATENETWORK,UPDATEFAVORITE } from '../CONSTANTURL';

export const INVITATIONLINKAPI = async () => {
  try {
    const result = await axios.get(`${INVITELINKNETWORK}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const GETALLNETWORKAPI = async (isActive) => {
  try {
    const result = await axios.get(`${GETALLNETWORK}/${isActive}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const VERIFYINVITEAPI = async (data) => {
  try {
    const result = await axios.get(`${VERIFYINVITETOKEN}/${data}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const DISCONNECTUSERAPI = async (data) => {
  try {
    const result = await axios.put(`${DISCONNECTUSERFROMNETWORK}/${data}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};

export const CREATENETWORKAPI = async (data) => {
  try {
    const result = await axios.post(`${CREATENETWORK}`, data);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};


export const UPDATEFAVORITEAPI = async (data) => {
  try {
    const result = await axios.put(`${UPDATEFAVORITE}/${data.network_id}/${data.is_favourite}`, data);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};
